import axiosInstance from "./axios";
import { ENDPOINT } from "./config";

export const teamApi = {
    getTeamsStats: async (season: number| undefined, team_id: number | undefined = undefined) => axiosInstance.get(`${ENDPOINT.GET_TEAM_STATS}`,
    {
      params: {
        ...season && {season: season},
        ...team_id && {team_id: team_id}
      }
    }
    ),
    getTeamCompetitions: async (season: number | undefined) => axiosInstance.get(`${ENDPOINT.GET_TEAM_COMPETITIONS}`,
    {
      params: {
        ...season && {season: season}
      }
    }
    ),
    getOpponents: async (competition_id: number) => axiosInstance.get(`${ENDPOINT.GET_TEAM_OPPONENTS}`,
    {
      params: {
        competition_id: competition_id
      }
    }
    ),
    updateTeam: async (data: any) => axiosInstance.put(`${ENDPOINT.UPDATE_TEAM}`, data),
  };
