export const Theme = {
  colors: {
    mainColor: "#2AA8E2",
    headingColor: "#AFC3D9",
    primaryBlue: "007CA8",
  },
};

export const latoRegular = '"latoregular", sans-serif';
export const latoSemiBold = '"latosemibold", sans-serif';
export const latoBold = '"latobold", sans-serif';
export const latoMedium = '"latomedium", sans-serif';
export const latoBlack = '"latoblack", sans-serif';
