import { Button } from "@material-ui/core";
import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY } from "../../../assets/colors";

const MostPassesTo = ({ passes, setCurrentPlayer }) => {
  return (
    <Wrapper>
      <Title>Most Passes To</Title>
      {passes !== undefined && passes.length > 0 ? (
        <ContentWrapper>
          {passes.map((passInfo, index) => {
            return (
              <>
                <Button
                  onClick={() => {
                    setCurrentPlayer(passInfo.id);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {passInfo.icon ? (
                      <PlayerIcon src={passInfo.icon} />
                    ) : (
                      <UnknownPlayerIcon>
                        <div
                          style={{
                            width: "2rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <UnknownPlayerHead />
                        </div>
                        <div
                          style={{
                            width: "2rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <UnknownPlayerBody />
                        </div>
                      </UnknownPlayerIcon>
                    )}

                    <PlayerName style={{ textTransform: "capitalize" }}>
                      {passInfo.name.split(" ")[1]}
                    </PlayerName>
                    <PlayerName style={{ textTransform: "uppercase" }}>
                      {passInfo.name.split(" ")[0]}
                    </PlayerName>
                    <PlayerPasses>{`(${passInfo.passes})`}</PlayerPasses>
                  </div>
                </Button>
                {index !== passes.length - 1 ? <VerticalLine /> : <></>}
              </>
            );
          })}
        </ContentWrapper>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  padding: 1rem 0;
  min-height: 4rem;
  margin-bottom: 1rem;
`;
const NoDataWrapper = styled.div`
  font-size: 1.2rem;
  color: ${GREY};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const Title = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;
const PlayerIcon = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  margin-right: 1rem;
`;
const UnknownPlayerIcon = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  margin-right: 1rem;
  background-color: ${GREY};
  overflow: hidden;
`;
const UnknownPlayerHead = styled.div`
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 100%;
  background-color: white;
  margin-top: 5px;
`;
const UnknownPlayerBody = styled.div`
  height: 2rem;
  width: 1.5rem;
  border-radius: 100%;
  background-color: white;
  margin-top: 2px;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const PlayerName = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${DARK_BLUE};
  margin: 0 3px;
  font-family: Karla Bold;
  font-size: 1rem;
`;
const PlayerPasses = styled.div`
  color: ${GREY};
  font-family: Karla Bold;
  font-size: 1rem;
`;
const VerticalLine = styled.div`
  height: 3rem;
  width: 2px;
  background-color: ${LIGHT_GREY};
`;

export default MostPassesTo;
