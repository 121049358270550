import { makeStyles, Button, Dialog } from "@material-ui/core";
import {
  DirectionsRun,
  ExitToApp,
  Email,
  HelpOutline,
  Home,
  SportsSoccer,
  PlayCircleOutline,
  BallotOutlined,
  Timeline
} from "@material-ui/icons";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import store, { useAppDispatch } from "../../app/store";
import { CYAN, DARK_BLUE, LIGHT_GREY, LIGHT_GREY2, RED } from "../../assets/colors";
import ContactUsModal from "../../features/contactUsModal/contactUs";
import DocumentationModal from "../../features/documentationModal/documentationModal";
import { TEAM_ACCOUNT, PLAYER_ACCOUNT, LEAGUE_ACCESS, TEAM_LEAGUE } from "../../models/constant/accountType";
import { changePage, Page } from "../../slices/navigationSlice";
import { selectAccountType, selectUser } from "../../slices/userSlice";
import pbLogo from "./../../assets/images/Playlab logo.svg";
import { authApi } from "../../api/authApi";
import { ACTIVITIES, FEATURE_VALUES } from "../../models/constant/collect";

export const useStyles = makeStyles(() => ({
  root: {
    width: "15%",
    position: "fixed",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "white",
    zIndex: 1,
    borderRight: `2px solid ${LIGHT_GREY}`,
  },
  page: {
    color: CYAN,
    textTransform: "capitalize",
    fontSize: "1.4rem",
    width: "calc(100% - 16px)",
    justifyContent: "left",
    paddingLeft: "1rem",
    margin: "0.3rem 0rem",
    borderRadius: "4px",
    fontFamily: "Karla Bold",
  },
  topIcons: {
    color: "inherit",
    fontSize: "2rem",
    width: "3rem",
  },
  selected: {
    color: DARK_BLUE,
    textTransform: "capitalize",
    fontSize: "1.4rem",
    width: "calc(100% - 16px)",
    justifyContent: "left",
    paddingLeft: "1rem",
    margin: "0.3rem 0rem",
    backgroundColor: LIGHT_GREY2,
    fontFamily: "Karla Bold",
    borderRadius: "4px",
  },
  bottomButton: {
    color: DARK_BLUE,
    fontSize: "0.9rem",
    fontWeight: 600,
    width: "100%",
    borderRadius: "4px",
    border: "3px solid",
    borderColor: DARK_BLUE,
    marginBottom: "1rem",
    fontFamily: "Karla Bold",
    justifyContent: "flex-start",
  },
  bottomIcons: {
    color: "inherit",
    fontSize: "2rem",
    marginRight: "0.5rem",
  },
}));

const getFeature = (item) => {
  switch (item) {
    case "Games":
      return FEATURE_VALUES.GAMES;
    case "Players":
      return FEATURE_VALUES.PLAYERS;
    case "Library":
      return FEATURE_VALUES.LIBRARY;
    case "Rankings":
      return FEATURE_VALUES.RANKINGS;
    default:
      return 0;
  }
}

const SideBar = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDocModalOpen, setIsDocModalOpen] = useState<boolean>(false);
  const [isProfileExpanded, setIsProfileExpanded] = useState<boolean>(false); // State to control profile expansion

  let sideBarData: {label: React.ReactNode, logo: any, link:any }[];

  const accountType = useSelector(selectAccountType);
  const user = useSelector(selectUser);
  switch (accountType) {
    case PLAYER_ACCOUNT:
      sideBarData = [
        {label: "Lab", logo:<Home className={classes.topIcons} />, link: Page.PlayerProfile},
        {label: "Games", logo:<SportsSoccer className={classes.topIcons} />, link: Page.Games},
        {label: "Team", logo:<DirectionsRun className={classes.topIcons} />, link: Page.Home},
        {label: "Library", logo:<PlayCircleOutline className={classes.topIcons} />, link: Page.Library},
        {label: "Rankings", logo: <Timeline className={classes.topIcons} />, link: Page.LeagueStat},
        ];
      break;
    case TEAM_ACCOUNT:
      sideBarData = [
        {label: "Home", logo:<Home className={classes.topIcons} />, link: Page.Home},
        {label: "Games", logo:<SportsSoccer className={classes.topIcons} />, link: Page.Games},
        {label: "Players", logo:<DirectionsRun className={classes.topIcons} />, link: Page.Players},
        {label: "Library", logo:<PlayCircleOutline className={classes.topIcons} />, link: Page.Library},
        {label: "Rankings", logo: <Timeline className={classes.topIcons} />, link: Page.LeagueStat},
      ];
      break;
    case TEAM_LEAGUE:
      sideBarData = [
        {label: "Home", logo:<Home className={classes.topIcons} />, link: Page.Home},
        {label: "Games", logo:<SportsSoccer className={classes.topIcons} />, link: Page.Games},
        {label: "Players", logo:<DirectionsRun className={classes.topIcons} />, link: Page.Players},
        {label: "Library", logo:<PlayCircleOutline className={classes.topIcons} />, link: Page.Library},
        {label: "Rankings", logo: <Timeline className={classes.topIcons} />, link: Page.LeagueStat},
      ];
      break;
    case LEAGUE_ACCESS:
      sideBarData = [
        {label: "Home", logo:<Home className={classes.topIcons} />, link: Page.Home},
        {label: "Games", logo:<SportsSoccer className={classes.topIcons} />, link: Page.Games},
        {label: "Players", logo:<DirectionsRun className={classes.topIcons} />, link: Page.Players},
        {label: "Library", logo:<PlayCircleOutline className={classes.topIcons} />, link: Page.Library},
        {label: "League", logo:<BallotOutlined className={classes.topIcons} />, link: Page.League},
        {label: "Rankings", logo: <Timeline className={classes.topIcons} />, link: Page.LeagueStat},
      ];
      break;
    default:
      sideBarData = [];
      history.push(Page.Logout);
  }

  return (
    <div className={classes.root}>
      <Wrapper>
        <div>
        <Logo>
            <LogoImage src={pbLogo} alt="SportsAI_Logo" />
          </Logo>
          {sideBarData.map((item, i) => {
            return (
              <Button
                className={
                  store.getState().page.page === item.link
                    ? classes.selected
                    : classes.page
                }
                key={i}
                onClick={() => {
                  dispatch(changePage(item.link));
                  authApi.collect(({ activity: ACTIVITIES.NAVIGATION, feature: getFeature(item.label) }));
                }}
              >
                {item.logo}
                {item.label}
              </Button>
            );
          })}
        </div>
        <div>
          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <ContactUsModal setState={setIsModalOpen} />
          </Dialog>
          <Dialog open={isDocModalOpen} onClose={() => setIsDocModalOpen(false)}>
            <DocumentationModal setState={setIsDocModalOpen} />
          </Dialog>
          <WrapperMenu>
            <Button
              onClick={() => setIsProfileExpanded(!isProfileExpanded)} 
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              <ProfileIcon src={user?.profile_url} />
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginBottom: '0.55rem'}}>
                <span>{user?.username}</span>
                {
                  isProfileExpanded ? <ExpandMoreIcon style={{ fontSize: "1.6rem", marginRight: "1.5rem" }} /> : <MenuIcon style={{ fontSize: "1.6rem", marginRight: "1.5rem"}} />
                }
              </div>
            </Button>

            {isProfileExpanded && (
              <WrapperOptions>
                <Button
                  onClick={() => setIsDocModalOpen(true)}
                  style={{ width: '150%', justifyContent: 'flex-start' }}
                >
                  <HelpOutline
                    className={classes.bottomIcons}
                    style={{ fontSize: "1.5rem" }}
                  />
                  <div>
                    <span>Help</span>
                  </div>
                </Button>
                <Button
                  onClick={() => { 
                    dispatch(changePage(Page.Profile)); 
                    authApi.collect(({ activity: ACTIVITIES.NAVIGATION, feature: FEATURE_VALUES.PROFILE }));
                    setIsProfileExpanded(false); }}
                  style={{ width: '150%', justifyContent: 'flex-start' }}
                  >
                  <PersonOutlineIcon
                    className={classes.bottomIcons}
                    style={{ fontSize: "1.5rem" }}
                  />
                  <div>
                    <span>Profile</span>
                  </div>
                </Button>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  style={{ width: '150%', justifyContent: 'flex-start' }}
                >
                   <Email
                    className={classes.bottomIcons}
                    style={{ fontSize: "1.5rem" }}
                  />
                  <div>
                    <span>Contact Us</span>
                  </div>
                </Button>
                <Button
                  onClick={() => history.push(Page.Logout)}
                  style={{ width: '150%', justifyContent: 'flex-start' }}
                >
                  <ExitToApp
                    className={classes.bottomIcons}
                    style={{ fontSize: "1.5rem", color: RED }}
                  />
                  <div style={{ color: RED}}>
                    <span>Logout</span>
                  </div>
                </Button>
              </WrapperOptions>
            )}
          </WrapperMenu>
        </div>
      </Wrapper>
    </div>
  );
};

const LogoImage = styled.img`
  width: 200px; /* Set your desired width */
  height: auto; /* Adjust the height proportionally, or set a specific height */
  margin-bottom: 20px; /* Optional: add some margin if needed */
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 100px;
`;
const Wrapper = styled.div`
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  div {
    flex-direction: inherit;
    display: inherit;
    align-items: center;
    font-size: 1.1rem;
  }
`;
const WrapperOptions = styled.div`
  margin: 0.5rem 0rem 0.5rem 0.6rem !important;
  font-family: Karla Bold;
  font-size: 6rem;
  flex-direction: column;
  align-items: flex-start !important;

  .hover {
    cursor: pointer;
    color: ${DARK_BLUE};
  }
`;
const WrapperMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start !important;
  width: 100%;
  border-top: 1px solid ${LIGHT_GREY};
`;

const ProfileIcon = styled.img`
  height: 3rem;
  width: 3rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

export default SideBar;
