import styled from "styled-components";
import { Button } from "@material-ui/core";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, RED, WHITE } from "../../../assets/colors";
import { useAppDispatch } from "../../../app/store";
import ModalTopbar from "../../../components/navigation/modalTopbar";
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { playerApi } from "../../../api/playerApi";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { selectPlayersStats, selectUserPlayerInfos, setPlayersStats, setUserPlayerInfos } from "../../../slices/playerSlice";
import { useSelector } from "react-redux";

export const DeletePlayerModal = ({ setIsCreateGameComp, player }) => {
    const classes = sportsAiGlobalStyles();
    const dispatch = useAppDispatch();
    const players_info = useSelector(selectUserPlayerInfos);
    const players_stats = useSelector(selectPlayersStats);  

    
    const handleDelete = () => {
      playerApi.deletePlayer(player.id).then((response) => {
          if (response.status === 200) {
              dispatch(setPlayersStats(
                  players_stats.filter((player_stats) => player_stats.id !== player.id)
              ));
              dispatch(setUserPlayerInfos(
                  players_info.filter((player_info) => player_info.id !== player.id)
              ));
              setIsCreateGameComp(false);
          }
      });
    };

    return (
        <Wrapper>
            <ModalTopbar Action={"Delete Player"} onClose={() => { setIsCreateGameComp(false) }} />
            <ConfirmationMessage>
                Are you sure you want to delete the player <strong>{player.firstName} {player.lastName}</strong>?
            </ConfirmationMessage>
            <FormButtonWrapper>
                <Button
                    className="cancelButton"
                    style={{
                        border: `1px solid ${LIGHT_GREY0}`,
                        textTransform: "none",
                        width: "6rem",
                        height: "2.4rem",
                        borderRadius: "0.5rem",
                        margin: "1rem 0 0 0"
                    }}
                    onClick={() => setIsCreateGameComp(false)}
                >
                    Cancel
                </Button>
                <UploadButton
                    className={classes.fullBlueButton}
                    style={{ textTransform: "none", height: "2.4rem", margin: "1rem 0 0 0", backgroundColor: RED }}
                    onClick={handleDelete}
                >
                    <DeleteOutlineIcon style={{ marginRight: '0.3rem', fontSize: '1.4rem' }} />
                    Delete
                </UploadButton>
            </FormButtonWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div` 
  border: 3px solid ${LIGHT_GREY};
  border-radius: 1rem;
  width: 35rem;
  height: 13rem;
  background-color: ${WHITE};
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 1rem;
`;

const ConfirmationMessage = styled.div`
    padding: 1.5rem;
    font-size: 1.2rem;
    text-align: center;
    color: ${DARK_BLUE};
`;

const FormButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding-top: 1rem;
`;

const UploadButton = styled(Button)`
height: 2.2rem;
padding: 0rem 0.8rem 0rem 0.8rem;
margin-bottom: 0.2rem;
`;
