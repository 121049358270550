import { Grid } from "@material-ui/core"
import ModalTopbar from "../../../../components/navigation/modalTopbar"
import styled from "styled-components";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { GREY, LIGHT_GREY, RED, WHITE } from "../../../../assets/colors";
import { videoApi } from "../../../../api/video";
import { useState } from "react";
import logo from "../../../../assets/images/Logo_Ball.png";
import { setPlaylists } from "../../../../slices/videoSlice";
import { useAppDispatch } from "../../../../app/store";
import { authApi } from "../../../../api/authApi";
import { ACTIVITIES, FEATURE_VALUES } from "../../../../models/constant/collect";

enum PlaylistDeleteStatus {
    IDLE,
    PENDING,
    SUCCESS
}

export const PlaylistDeleteModal = ({setIsModalOpen, selectedPlaylists, playlists}) => {
    const [delitionStatus, setDelitionStatus] = useState(PlaylistDeleteStatus.IDLE)
    const dispatch = useAppDispatch();
    
    const deletePlaylists = () => {
        setDelitionStatus(PlaylistDeleteStatus.PENDING)
        videoApi.deleteSequencePlaylist({playlist_ids: selectedPlaylists}).then(() => {
            setDelitionStatus(PlaylistDeleteStatus.SUCCESS);
            dispatch(setPlaylists(playlists.filter((playlist) => playlist.id !== selectedPlaylists[0])));
            authApi.collect(({activity: ACTIVITIES.DELETE, feature: FEATURE_VALUES.PLAYLIST}));
        }).catch(() => {})
    }

    if (delitionStatus === PlaylistDeleteStatus.SUCCESS) {
        return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <ModalTopbar 
                        Action={undefined}
                        onClose={setIsModalOpen}
                        showBar={false}
                    />
                </Grid>
            </Grid>
            <Container>
                <CheckCircle>
                    <CheckIcon style={{ fontSize: "5rem", color: "green" }} />
                </CheckCircle>
                <SuccessText>Deleted!</SuccessText>
                <InfoText>You successfully deleted the selected playlist(s).</InfoText>
                <CloseButton onClick={() => {setIsModalOpen()}}>Close</CloseButton>
            </Container>
        </>
        )
    }

    if (delitionStatus === PlaylistDeleteStatus.PENDING) {
        return (
            <Container>
              <LoadingLogo src={logo} alt="SportsAI_Logo" />
            </Container>
          );
    }

    return (
    <>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <ModalTopbar 
                    Action={undefined}
                    onClose={() => {
                        setIsModalOpen(false);
                    }}
                    showBar={false}
                />
            </Grid>
        </Grid>
        <Container>
            <CheckCircle>
                <ErrorOutlineOutlinedIcon style={{ fontSize: "3rem", color: RED }} />
            </CheckCircle>
            <SuccessText>Are you sure?</SuccessText>
            <InfoText>All sequences concerned will be unlinked and the playlist(s) permanently deleted.</InfoText>
            <DeletePlaylists onClick={() => {deletePlaylists()}}>Delete playlist(s)</DeletePlaylists>
            <CloseButton onClick={() => {setIsModalOpen()}}>Cancel</CloseButton>
        </Container>
    </>
    )
}

const LoadingLogo = styled.img`
  width: 15rem;
  animation: blinkAndGrow 2s infinite ease;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px ;
    width: 320px;
`;

const CheckCircle = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2); 
    margin-top: 10px auto;
`;

const SuccessText = styled.div`
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
`;

const InfoText = styled.div`
    text-align: center;
    color: grey;
    margin-top: 10px;
`;

const DeletePlaylists = styled.button`
    background-color: ${RED};
    border: 1.5px solid ${LIGHT_GREY};
    border-radius: 10px;
    cursor: pointer;
    color: ${WHITE};
    margin-top: 25px;
    width: 100%;
    padding: 8px;
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: 1.5px solid ${LIGHT_GREY};
    border-radius: 10px;
    cursor: pointer;
    color: ${GREY};
    margin: 10px 0 0 0;
    width: 100%;
    padding: 8px;
`;