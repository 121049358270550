import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ValidationErrors } from "../api/apiError";
import { playerApi } from "../api/playerApi";
import type { RootState } from "../app/store";
import { PlayerPosition, PlayerPositions } from "../models/game";
import { MatchPlayer, Player, PlayerInfo, PlayerStats } from "../models/player";

interface GamesState {
  currentPlayer: Player | undefined;
  currentPlayerId: number | undefined;
  teamPlayers: PlayerStats[];
  playersStats: PlayerStats[];
  gamePlayers: MatchPlayer[];
  gamePlayerPositions: PlayerPosition[][];
  gamePlayerSubs: PlayerPosition[];
  userPlayerInfos: PlayerInfo[];
}

export const initialState: GamesState = {
  currentPlayer: undefined,
  currentPlayerId: undefined,
  teamPlayers: [],
  playersStats: [],
  gamePlayers: [],
  gamePlayerPositions: [],
  gamePlayerSubs: [],
  userPlayerInfos: [],
};

export const playerSlice = createSlice({
  name: "playerList",
  initialState,
  reducers: {
    setUserPlayerInfos: (state, action: PayloadAction<PlayerInfo[]>) => {
      state.userPlayerInfos = action.payload;
    },
    setCurrentPlayer: (state, action: PayloadAction<Player | undefined>) => {
      state.currentPlayer = action.payload;
    },
    setCurrentPlayerId: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlayerId = action.payload;
    },
    setTeamPlayers: (state, action: PayloadAction<PlayerStats[]>) => {
      state.teamPlayers = action.payload;
    },
    setPlayersStats: (state, action: PayloadAction<PlayerStats[]>) => {
      state.playersStats = action.payload;
    },
    setGamePlayers: (state, action: PayloadAction<MatchPlayer[]>) => {
      state.gamePlayers = action.payload;
    },
    setGamePlayerPositions: (state, action: PayloadAction<PlayerPositions>) => {
      state.gamePlayerPositions = action.payload.starters;
    },
    setGamePlayerSubs: (state, action: PayloadAction<PlayerPositions>) => {
      state.gamePlayerSubs = action.payload.subs;
    },
    DESTROY_PLAYER_SLICE: (state) => {
      state.currentPlayer = undefined;
      state.currentPlayerId = undefined;
      state.teamPlayers = [];
      state.playersStats = [];
      state.gamePlayers = [];
      state.gamePlayerPositions = [];
      state.gamePlayerSubs = [];
    },
  },
});

export const {
  setUserPlayerInfos,
  setCurrentPlayer,
  setCurrentPlayerId,
  setTeamPlayers,
  setPlayersStats,
  setGamePlayers,
  setGamePlayerPositions,
  setGamePlayerSubs,
  DESTROY_PLAYER_SLICE,
} = playerSlice.actions;

export const getUserPlayerInfos = createAsyncThunk(
  "getUserPlayerInfos",
  async (data: {teamId: number, season: number | undefined}, { dispatch, rejectWithValue }) => {
    try {
      const res = await playerApi.getPlayersInfo(data.teamId, data.season);
      dispatch(setUserPlayerInfos(res.data));
      return res.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTeamPlayers = createAsyncThunk(
  "getTeamPlayers",
  async (
    data: {teamId: number, season: number | undefined},
    { dispatch, rejectWithValue }) => {
    try {
      const { teamId, season } = data;
      const res = await playerApi.getTeamSquad(teamId, season);
      dispatch(setTeamPlayers(res.data));
      return res.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlayersStats = createAsyncThunk(
  "getPlayersStats",
  async (
    data: { teamId: number; filterSize: number, season: number|undefined},
    { dispatch, rejectWithValue }
  ) => {
    const { teamId, filterSize, season } = data;
    try {
      const res = await playerApi.getPlayersPerformances(teamId, filterSize, season);
      dispatch(setPlayersStats(res.data));
      return res.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlayer = createAsyncThunk(
  "getPlayer",
  async (
    data: { playerId: number; numMatches: number, season: number|undefined, team_id: number|undefined },
    { dispatch, rejectWithValue }
  ) => {
    const { playerId, numMatches,season, team_id } = data;
    try {
      const res = await playerApi.getPlayerStats(playerId, numMatches, team_id, season);
      dispatch(setCurrentPlayer(res.data));
      return res.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGamePlayers = createAsyncThunk(
  "getGamePlayers",
  async (matchId: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await playerApi.getPlayersMatch(matchId);
      dispatch(setGamePlayers(res.data));
      return res.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGamePlayerPositions = createAsyncThunk(
  "getGamePlayerPositions",
  async (matchId: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await playerApi.getPlayersStadium(matchId);
      dispatch(setGamePlayerPositions(res.data));
      dispatch(setGamePlayerSubs(res.data));
      return res.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectUserPlayerInfos = (state: RootState) => state.playerSlice.userPlayerInfos;
export const selectCurrentPlayer = (state: RootState) => state.playerSlice.currentPlayer;
export const selectTeamPlayers = (state: RootState) => state.playerSlice.teamPlayers;
export const selectPlayersStats = (state: RootState) => state.playerSlice.playersStats;
export const selectCurrentPlayerId = (state: RootState) => state.playerSlice.currentPlayerId;
export const selectGamePlayers = (state: RootState) => state.playerSlice.gamePlayers;
export const selectGamePlayerPositions = (state: RootState) => state.playerSlice.gamePlayerPositions;
export const selectGamePlayerSubs = (state: RootState) =>state.playerSlice.gamePlayerSubs;

export default playerSlice.reducer;
