import styled from "styled-components";
import { Button, Checkbox, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, makeStyles } from "@material-ui/core"
import FormControl from '@mui/material/FormControl';
import { useState } from "react";
import { selectCompetitions, selectTeamId } from "../../slices/teamSlice";
import { useSelector } from "react-redux";
import { Competition } from "../../models/competition";
import { teamApi } from "../../api/teamApi";
import { TeamSummary } from "../../models/team";
import { matchApi } from "../../api/matchApi";
import VideoUploader from "./videoUploader";
import { useAppDispatch } from "../../app/store";
import { getGamesSummary } from "../../slices/gamesSlice";
import { selectSeason } from "../../slices/userSlice";
import ModalTopbar from "../../components/navigation/modalTopbar";
import { soccerFormations } from "../../models/constant/formations";
import { DARK_BLUE, WHITE } from "../../assets/colors";

export const useStyles = makeStyles(() => ({
    formControl: {
        fontSize: "1rem",
        fontFamily: "Karla ",
        '& label.Mui-focused': {
          color: `${DARK_BLUE} !important`,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: `${WHITE} !important`, 
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: `${WHITE} !important`,
          },
          '&:hover fieldset': {
            borderColor: `${DARK_BLUE} !important`, 
          },
          '&.Mui-focused fieldset': {
            borderColor: `${DARK_BLUE} !important`,
          },
        },
        
      },
    bottomButton: {
      color: DARK_BLUE,
      fontSize: "0.7rem",
      fontWeight: 600,
      width: "100%",
      borderRadius: "4px",
      border: "3px solid",
      borderColor: DARK_BLUE,
      marginBottom: "1rem",
      fontFamily: "Karla Bold",
    },
    grid1: {
        color: DARK_BLUE,
        fontSize: "1rem",
        fontFamily: "Karla ",
        display: 'flex', 
        alignItems: 'center'
      },
      grid2: {
        color: DARK_BLUE,
        fontSize: "1rem",
        fontFamily: "Karla ",
        borderColor: DARK_BLUE,
        marginTop: '5px',    // Separate margin for top
        marginBottom: '5px', // Separate margin for bottom
        marginLeft: '0px',   // Separate margin for left
        marginRight: '0px',  // Separate margin for right
      },
      checkbox: {
        color: `${DARK_BLUE} !important`, // Set the color of the checkbox
        '&.Mui-checked': {
          color: `${DARK_BLUE} !important`, // Set the color of the checkbox when checked
        },
      },
  }));
  

export const CreateGame = ({ setIsCreateGameComp, newGameCounter, setNewGameCounter }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const teamId: number | undefined  = useSelector(selectTeamId);
    const season: number | undefined = useSelector(selectSeason);
    const formations: string[] = soccerFormations;
    const competitions: Competition[] = useSelector(selectCompetitions);

    const [isUploadVideo, setIsUploadVideo] = useState(false);

    const [gameVideoId, setGameVideoId] = useState<number>(0);
    const [opponents, setOpponents] = useState<TeamSummary[]>([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [competition, setCompetition] = useState<Competition>(competitions[0]);
    const [opponent, setOpponent] = useState<TeamSummary | null>(null);
    const [isHome, setIsHome] = useState(false);
    const [formation, setFormation] = useState<string>('');
    const [gameDay, setGameDay] = useState<number>(0);

    const [formErrors, setFormErrors] = useState({
        opponent: "",
        date: "",
        time: "",
        formation: ""
    });

    const validateInput = () => {
        var isValid: boolean = true;
        var opponentError: string = ""
        var dateError: string = ""
        var timeError: string = ''
        var formationError: string = ""

        if (opponent === null) {
            isValid = false;
            opponentError = "Please Select an opponent for the game";
        }
        if (selectedDate === "") {
            isValid = false;
            dateError = "Please Select a valid date";
        }
        if (selectedTime === '') {
            isValid = false;
            timeError =  "Please select a valid time";
        }
        if (formation === "") {
            isValid = false;
            formationError = "Please select a valid formation";
        }
        setFormErrors({
            opponent: opponentError,
            date: dateError,
            time: timeError,
            formation: formationError,
        });
        return isValid
    }

    const changeCompetition = (competition_index: number) => {
        setCompetition(competitions[competition_index])
        teamApi.getOpponents(competitions[competition_index].id).then((ret) => {
            setOpponents((ret.data as TeamSummary[]).filter(team => team.id !== teamId))
        })
    }

    const submitGame = (dispatch, teamId, season) => {
        if (!validateInput()){
            console.log(formErrors)
            return
        }
        matchApi.createMatch({
            team_id: teamId,
            opponent_id: opponent?.id,
            game_day: gameDay,
            competition_id: competition.id,
            home_stadium: isHome,
            date: selectedDate,
            kickoff: selectedTime,
            home_formation: formation
        }).then((response) => {
            setGameVideoId(response.data['id']);
            dispatch(getGamesSummary({teamId, season}));
            setIsUploadVideo(true);
        });
    }
    
    return isUploadVideo ? <VideoUploader gameId={gameVideoId} setIsStatModalOpen={setIsCreateGameComp}/> :  (
        <>
        <ModalTopbar Action={"CREATE A GAME"} onClose={() => {setIsCreateGameComp(false)}}/>
        <FormContainer>
        <Grid container spacing={5}>
            <Grid item xs={6}>
                <FormControl fullWidth className={classes.formControl} style={{ margin: '20px 0px 10px 0px' }}>
                    <InputLabel id="demo-simple-select-label">Competition *</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label = "Competition"
                        onChange={(event) => {changeCompetition(event.target.value as number)}}
                    >
                        {
                            competitions.map((competition, index) => {
                                return <MenuItem key={competition.id} value={index}>{competition.competition}</MenuItem>
                            })
                        }
                    </Select>
                    <FormHelperText></FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={6} style={{ height: '1.5rem' }}>
                {formErrors.opponent && <ErrorText>{formErrors.opponent}</ErrorText>}
                <FormControl fullWidth className={classes.formControl} style={{ margin: '20px 0px 10px 0px' }}>
                    <InputLabel id="label">Opponent</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label = "Opponent"
                        onChange={(event) => setOpponent(opponents[event.target.value as number] as TeamSummary)}
                    >
                        {
                            opponents.map((opponent, index) => {
                                return <MenuItem key={opponent.id} value={index}>{opponent.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} className={classes.grid2}>
                {formErrors.formation && <ErrorText>{formErrors.formation}</ErrorText>}
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Formation</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label = "Formation"
                        onChange={(event) => setFormation(event.target.value as string)}
                        value={formation}
                    >
                        {
                            formations.map((formation) => {
                                return <MenuItem key={formation} value={formation}>{formation}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} className={classes.grid2}>
                <FormControl fullWidth className={classes.formControl}>
                    <TextField 
                        type="number"
                        id="filled-basic" 
                        label="Game Day" 
                        variant="standard" 
                        value={gameDay}
                        onChange={(event) => setGameDay(+event.target.value)}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                              </InputAdornment>
                            ),
                            inputProps: {
                              min: 0,
                              max: 99,
                            },
                          }}
                    />
                </FormControl>
            </Grid>
            
            <Grid item xs={4} className={classes.grid1}>
                {formErrors.time && <ErrorText>{formErrors.time}</ErrorText>}
                <label htmlFor="checkbox"> DATE </label>
                    <input
                        type="date"
                        id="dateInput"
                        value={selectedDate}
                        onChange={(event) => setSelectedDate(event.target.value)}
                    />
            </Grid>
            <Grid item xs={4} className={classes.grid1}>
                {formErrors.time && <ErrorText>{formErrors.time}</ErrorText>}
                <label htmlFor="checkbox"> TIME </label>
                    <input
                        type="time"
                        id="dateInput"
                        value={selectedTime}
                        onChange={(event) => setSelectedTime(event.target.value)}
                    />
            </Grid>
            <Grid item xs={4} className={classes.grid1}>
                <label htmlFor="checkbox"> home stadium </label>
                <Checkbox className={classes.checkbox}
                    id="checkbox" 
                    checked={isHome} 
                    onChange={() => {setIsHome(!isHome)}}
                />
            </Grid>

            <Grid item xs={12}>
                <Button className={classes.bottomButton} onClick={() => submitGame(dispatch, teamId, season)} >
                    Create Game
                </Button>
            </Grid>
        </Grid>
        </FormContainer>
        </>
    )
}

const FormContainer = styled.div`
  padding: 0.5rem 0.5rem 0rem 0.5rem;  // Adjust the value as needed
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
`;
