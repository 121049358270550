import axiosInstance from "./axios";
import { ENDPOINT } from "./config";

export const matchApi = {
  getMatchStats: async (matchId: number, season: number| undefined = undefined) => axiosInstance.get(`${ENDPOINT.MATCH_STATS}`,
  {
    params: {
      match_id: matchId,
      ...season && {season: season}
    }
  }
  ),
  getGameList: async (teamId: number, season: number| undefined = undefined) => axiosInstance.get(`${ENDPOINT.GAME_LIST}`, 
  {
    params: {
      team_id: teamId,
      ...season && {season: season}
    }
  }
  ),  
  createMatch: async (matchData) => axiosInstance.post(`${ENDPOINT.CREATE_MATCH}`, matchData),
  getS3PresignedUrl: async (gameId: number) => axiosInstance.get(`${ENDPOINT.GET_PRESIGNED_URL}`, {
    params: {
      file_name: gameId.toString() + ".mp4"
    }
  }),
  updateMatchVideoKey: async (matchId) => axiosInstance.put(`${ENDPOINT.UPDATE_MATCH_VIDEO_KEY}`,{
    "matchId": matchId,
  }
  ),

};