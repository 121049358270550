import { DialogContent, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { GREY } from "../../assets/colors";
import { createRef, LegacyRef } from "react";
import styled from "styled-components";

const DocumentationModal = ({ setState }) => {
  const form: LegacyRef<HTMLFormElement> | undefined = createRef();

  return (
    <form noValidate ref={form} id="form">
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <DialogTitle>DOCUMENTATION</DialogTitle>
          <IconButton
            style={{ padding: "0" }}
            size="small"
            onClick={() => setState(false)}
          >
            <Close />
          </IconButton>
        </div>
        

        <DialogTitle>Assists</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number 
        of assists the player has made during the game.</div></DialogContentText>

        <DialogTitle>Ball Losses</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This graph shows the number of ball losses allowed by the player during previous games and according to the selected time frame.</div></DialogContentText>

        <DialogTitle>Ball Loss Map</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The Ball Loss Map component displays 28 hexagonal zones with each zone containing information about the total 
         number of ball losses inside the given zone. A darker red color indicates more ball losses occurred inside this zone.</div>
        </DialogContentText>

        <DialogTitle>Ball Loss Reaction</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The Ball Loss Reaction component displays 28 hexagonal zones with each zone containing information about all the players reactions to a ball loss. 
         A bluer color indicates overall positive reactions to a ball loss whereas a redder color indicated no reaction to a ball loss.</div>
        </DialogContentText>

        <DialogTitle>Ball Possession & Pass Success</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The ball possession bar shows the percentage of the game the team has spent in possession of the ball. The pass success bar shows ratio of completed passes compared to all attempted passes. 
         A pass is considered complete if it travelled from one player to another without any interception.</div>
        </DialogContentText>

        <DialogTitle>Ball Recoveries</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The Ball Recoveries component displays 28 hexagonal zones with each zone containing information about the total number of ball recoveries 
         inside the given zone. A darker blue color indicates more ball recoveries were made inside this zone.</div>
        </DialogContentText>

        <DialogTitle>Best Performances</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This section contains all the team’s best performances separated by player 
         and by type and according to the filters selected.</div>
        </DialogContentText>

        <DialogTitle>Blocks (Goalkeeper only)
        </DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the number of blocked shots by the player in the current time frame.</div>
        </DialogContentText>

        <DialogTitle>Clean Sheets (Goalkeeper only)
        </DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the number of clean sheets (full games without allowing a goal) by the player in the current time frame.</div>
        </DialogContentText>

        <DialogTitle>Challenges</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of challenges 
        the player has attempted during the game. A challenge is considered any sort of 
         physical attempt to gain or regain possession of the ball using their body. Two players fighting to gain possession of any type of air ball is considered a challenge.</div></DialogContentText>
        

        <DialogTitle>Challenge Success</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the ratio of successful challenges compared to all attempted challenges. </div>
        </DialogContentText>

        <DialogTitle>Completed Passes</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the team’s total number of completed passes. 
         A pass is considered complete if it travelled from one player to another without any interception.</div>
        </DialogContentText>
        
        <DialogTitle>Conceded Goals (Goalkeepers only)</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the number of conceded goals by the player in the current time frame.</div></DialogContentText>
        

        <DialogTitle>Corners</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of corners given to the team during a game.</div>
        </DialogContentText>

        <DialogTitle>Crosses</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number 
        of crosses the player has attempted during the game.</div></DialogContentText>

        <DialogTitle>Direct Free Kicks</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of direct free kicks taken during the game.</div>
        </DialogContentText>

        <DialogTitle>Dribble Precision</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the player’s ratio of successful dribbles compared to all attempted dribbles. 
        A dribble considered as any attempt by the player to pass another player using technical skills </div></DialogContentText>


        <DialogTitle>Faced Shotss</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This graph shows the number of faced shots by the player (goalkeeper only) during previous games and according to the selected time frame.</div></DialogContentText>

        <DialogTitle>Fouls</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the team’s total number of committed fouls.</div>
        </DialogContentText>

        <DialogTitle>Header & Tagline</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The header of a game shows details such as the score, yellow and 
         red cards as well as the date and where the game was played. An icon will indicate if the game is live with the corresponding minute of the game.</div>
         <div style={{marginBottom: "1rem",}}>The tagline shows major events of the game such as goals, red and yellow cards, and player changes along with the corresponding minute.</div>
         <div style={{marginBottom: "1rem",}}>This entire page updates autonomously every 10 seconds in the event that the current game is live.</div>
        </DialogContentText>

        <DialogTitle>Home</DialogTitle>
        <DialogContentText>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem",}}>
            The Home page shows an overview of important team statistics accumulated along all seasons played. 
            The page header shows the final score of the team’s last game or the current score of a live game 
            with the option to see the live game’s stats by clicking the “Live Stats” button.
          </div>
        </DialogContentText>

        <DialogTitle>Games</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The Games page shows all the team’s played games along with details about the game. The search bar allows searching throughout all games and of any detail that is show. E.g. typing ‘away’ will show all games that were played outside the home team’s stadium. The ‘Sort By’ option will 
         sort al games according to the option selected and in alphabetical order. The ‘Order’ button will reverse the order of the list. </div>
        </DialogContentText>
        
        <DialogTitle>Tackles</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the number of attempted tackles. 
         A tackle is considered as any sort of physical attempt to draw the ball from an opposing player in order 
         regain possession of the ball using only their feet.</div></DialogContentText>

        <DialogTitle>Goals Conceded</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>
         The Goals Conceded component graphs the team’s conceded goals in either the last 5 games, 10 games or the entire season.
         Clicking or hovering on a dot will show the game details such as the opponent as well as their score.</div>
        </DialogContentText>

        <DialogTitle>Goals Ratio</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>
           This component ratios the team’s overall scored goals to their conceded goals. 
           The number in the center of the circle represents the difference between the two values.</div>
        </DialogContentText>

        <DialogTitle>Goals Scored</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>
         The Goals Scored component graphs the team’s scored goals in either the last 5 games, 10 games or the entire season.
         Clicking or hovering on a dot will show the game details such as the opponent as well as their score.</div>
        </DialogContentText>

        <DialogTitle>Minutes Played</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of minutes played by the player during the game.</div></DialogContentText>

        <DialogTitle>Most Passes To</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This section shows the players to which 
        the current player has made the most successful passes up to a maximum of 3 other players.</div></DialogContentText>

        <DialogTitle>Passes</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of attempted passes regardless of if they were successful or not.</div>
        </DialogContentText>

        <DialogTitle>Pass Orientation Ratio</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The pass orientation ratio component shows the distribution 
         in general pass directions of the team for a total of 100%. This graph shows only completed passes from any given player to another.</div>
        </DialogContentText>

        <DialogTitle>Pass Precision</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>The pass orientation ratio component shows the distribution in general pass directions 
        of the player for a total of 100%. This graph shows only completed passes from the given player to another.</div></DialogContentText>
    
        <DialogTitle>Pass Success Ratio</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The Pass Success Ratio component shows the percentage of successful passes 
         distributed according to the direction of the pass.</div>
        </DialogContentText>

        <DialogTitle>Penalty Shots</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of penalty shots taken during the game.</div>
        </DialogContentText>

        <DialogTitle>Penalty Shot Precision</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the teams overall penalty shot precision. 
         A precise penalty shot is defined as one that ended in a goal.</div>
        </DialogContentText>

        <DialogTitle>Players Evolution</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>The filter at the top left of the page lets the user filter how many games compare. Selecting the last game will compare stats from the last game played to the one before that, the last 3 game option will compare the last 3 games played to the 3 before that… and so on to the Season option that compares the ongoing season to the previous one.
          The top right filter will filter stats according to player position.
          </div>
        </DialogContentText>

        <DialogTitle>Player Profile Overview
        </DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>The filter at the top left of the page lets the user filter how many games compare. Selecting the last game will compare stats from the last game played to the one before that, 
        the last 3 game option will compare the last 3 games played to the 3 before that and so on to the Season option that compares the ongoing season to the previous one.</div>
        </DialogContentText>

        <DialogTitle>Players Overview</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>The filter at the top left of the page lets the user filter how many games compare. Selecting the last game will compare stats from the last game played to the one before that, 
        the last 3 game option will compare the last 3 games played to the 3 before that… and so 
        on to the Season option that compares the ongoing season to the previous one.</div></DialogContentText>
        <DialogContentText><div style={{marginBottom: "1rem",}}>The top right filter will filter stats according to player position.</div></DialogContentText>

        <DialogTitle>Player Positions</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the alignment of players on the field as well as the player numbers. 
         The grey lines connecting players to each other show each player’s most-passed-to players for a maximum of 3 players. Clicking on a specific player will highlight his 1-3 most-passed-to players with the total number of passes to each one appearing below their number. A player with an icon to the top left indicates he was subbed in and was not a starting player. 
         The number appearing on the top right shows an overall rating of the player’s performance during the game based on his personal stats.</div>
        </DialogContentText>

        <DialogTitle> Results Ratio</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}> 
        This component shows the team’s overall win – draw – loss ratio along with their quantities and corresponding percentage.</div>
        </DialogContentText>

        <DialogTitle>Second Assists</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number 
        of second assists the player has made during the game.</div></DialogContentText>


        <DialogTitle>Scored Goals Type</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>
         This component shows the distribution of recent goals scored on the field. The filter at the top allows to filter goals that were scored in the last 5 or 10 games or in the entire season. Goal types are indicated by color explained by the legend below the field. Purple goals indicate that more than one type of goal was scored in a specific area. 
         Hovering on any goal will show details on the type of goal and amount in a given area.</div>
        </DialogContentText>

        <DialogTitle>Shots</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of shots that were attempted during the game.</div>
        </DialogContentText>

        <DialogTitle>Shots on Target</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the total number of shots that were on target during the game. A shot on target is defined 
         as one that was aimed at the goal and either ended in a goal or would have ended in a goal had it not been blocked by the goalkeeper or hit a goalpost.</div>
        </DialogContentText>

        <DialogTitle>Shot Precision</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the teams overall shot precision. 
         A precise shot is defined as one that was aimed at the goal and either ended in a goal or would have ended in 
         a goal had it not been blocked by the goalkeeper or hit a goalpost.</div>
        </DialogContentText>

        <DialogTitle>Starters</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the number of times the player was on the starting lineup in the current time frame.</div></DialogContentText>
        

        <DialogTitle>Squad</DialogTitle>
        <DialogContentText><div style={{marginBottom: "1rem",}}>This section contains information on all the team’s players. The search bar, sort option and order option allow the list to be rearranged or filtered. 
        Filters are in alphabetical and and/or numerical order.</div>
        </DialogContentText>

        <DialogTitle>Tackle Success</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the ratio of successful tackles compared to all attempted tackles. 
         A tackle is considered as any sort of physical attempt to draw the ball from an opposing player in order regain possession of the ball using only their feet.</div>
        </DialogContentText>

        <DialogTitle>Yellow / Red Cards</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This component shows the team’s total number of yellow / red cards.</div>
        </DialogContentText>

        <DialogTitle>Warnings</DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This section contains all the team’s worst performances separated by 
         player and by type and according to the filters selected.</div>
        </DialogContentText>

        <DialogTitle>xG (Expected Goals) </DialogTitle>
         <DialogContentText><div style={{marginBottom: "1rem",}}>This metrics quantifies the likelihood of a shot resulting in a goal, based on variables such as shot location,
         type of shot (feet or head) and angle of shot. It is calculated using our historical data to evaluate each shot's probability of scoring. The accuracy and reliability 
         of xG improve as more shots are evaluated over time. </div>
        </DialogContentText>

        </DialogContent>
    </form>
  );
};

const DialogTitle = styled.div`
  font-family: Karla Bold;
  font-size: 1.5rem;
`;
const DialogContentText = styled.div`
  font-family: Karla Regular;
  color: ${GREY};
`;

export default DocumentationModal;
