import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DARK_BLUE, GREY, WHITE } from "../../../assets/colors";
import { useAppDispatch } from "../../../app/store";
import { useSelector } from "react-redux";
import { selectCompetitions, selectTeamId } from "../../../slices/teamSlice";
import { selectSeason } from "../../../slices/userSlice";
import { LeagueSatsModel } from "../../../models/leagueStats";
import { CompetitionApi } from "../../../api/competition";
import { setCurrentPlayerStatParams } from "../../../slices/competitionSlice";
import { changePage, Page } from "../../../slices/navigationSlice";
import SectionHeader from "../../../components/home-page/section-header/section-header";
import { Loading } from "../../../components/navigation/loading";
import RankingTable from "../../../components/stats-base/table/ranking-table";

const GAMES_NUMBER_FITLER = [
  "All",
  "3",
  "5",
  "10",
];

const useStyles = makeStyles(() => ({
  tabButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 5px",
    fontWeight: "bold",
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
  showAllButton: {
    backgroundColor: "transparent",
    color: DARK_BLUE,
    padding: "3px 8px",
    fontWeight: "bold",
    textTransform: "none",
  },
  arrowBtnSelected: {
    transform: "rotate(180deg)",
  },
}));

const PlayersStats = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const competitions = useSelector(selectCompetitions);
  const season: number | undefined = useSelector(selectSeason);
  const teamId = useSelector(selectTeamId);
  const competitionFilterOptions = new Map<string, number>();
  const [positionFilter, setPositionFilter] = useState("All");
  const [leaguePlayersStats, setLeaguePlayersStats] = useState<LeagueSatsModel|undefined>(undefined);
  // Determine the initial competition, avoiding "Friendlies"
  const firstNonFriendliesCompetition = competitions.find(
    (competition) => competition.competition !== "Friendlies"
  );
  // Use the first non-"Friendlies" competition if it exists, otherwise fall back to the first competition
  const competitionInit = firstNonFriendliesCompetition
    ? firstNonFriendliesCompetition.competition
    : competitions.length !== 0
    ? competitions[0].competition
    : '';

  const [competitionFilter, setcompetitionFilter] = useState(competitionInit);

   // Separate the "Friendlies" competition from others
   const friendlies = competitions?.find(competition => competition.competition === "Friendlies");
   const otherCompetitions = competitions?.filter(competition => competition.competition !== "Friendlies");
 
   // Add other competitions first
   otherCompetitions?.forEach(competition => {
     competitionFilterOptions.set(competition.competition, competition.id);
   });
 
   // Add "Friendlies" at the end if it exists
   if (friendlies) {
     competitionFilterOptions.set(friendlies.competition, friendlies.id);
   }

   console.log(competitionInit, competitions)
  useEffect(() => {
    if (competitions === undefined) {
      return
    }
    const competitionId = competitionFilterOptions.get(competitionFilter);
    if (competitionId !== undefined && teamId !== undefined) {
      setLeaguePlayersStats(undefined);
      CompetitionApi.getCompetitionPlayersStats(competitionId, season!, teamId).then((response) => {
        setLeaguePlayersStats(response.data);
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionFilter]);

  const sizeButtons = () => {
    const buttons: JSX.Element[] = [];

    for (const [buttonName, _] of competitionFilterOptions) {
      buttons.push(
        <Button
          style={{ padding: "2px 10px", margin: "0px 5px" }}
          key={buttonName}
          className={
            competitionFilter === buttonName ? classes.selected : classes.tabButton
          }
          onClick={() => {
            setcompetitionFilter(buttonName);
          }}
        >
          {buttonName}
        </Button>
      );
    }

    return buttons;
  };

  const goToPlayersStat = (stat: string) => {
    const competitionId = competitionFilterOptions.get(competitionFilter);
    if (competitionId !== undefined) {
      dispatch(setCurrentPlayerStatParams({competitionId: competitionFilterOptions.get(competitionFilter)!, season: season!, stat: stat}));
      dispatch(changePage(Page.CompetitionPlayersStat));
    }
  };

  return  leaguePlayersStats === undefined ? <Loading/> : (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeButtons()}</SizeFilterWrapper>
        <TypeFilterWrapper>
          Games:
          <Select
            variant="outlined"
            style={{ height: "2rem", marginLeft: "5px" }}
            value={positionFilter}
            fullWidth
            onChange={(event) => {
              setPositionFilter(event.target.value as string);
            }}
          >
            {GAMES_NUMBER_FITLER.map((value, index) => {
              return (
                <MenuItem key={`MenuItem${index}`} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </TypeFilterWrapper>
      </FiltersWrapper>
      <SectionHeader title={"General"} />
      <TablesContainer>
        <RankingTable title={'Ratings'} elements={leaguePlayersStats.ratings!} onSelect={() => {goToPlayersStat('Ratings')}}/>
        <RankingTable title={'Completed Passes'} elements={leaguePlayersStats.passesCompleted} onSelect={() => {goToPlayersStat('Completed Passes')}}/>
      </TablesContainer>
      <SectionHeader title={"Attacking"} />
      <TablesContainer>
        <RankingTable title={'Goals'} elements={leaguePlayersStats.goals} onSelect={() => {goToPlayersStat('Goals')}}/>
        <RankingTable title={'Assists'} elements={leaguePlayersStats.assists} onSelect={() => {goToPlayersStat('Assists')}}/>
      </TablesContainer>
      <SectionHeader title={"Defending"} />
      <TablesContainer>
      <RankingTable title={'Challenges'} elements={leaguePlayersStats.challenges} onSelect={() => {goToPlayersStat('Challenges')}}/>
      <RankingTable title={'Clean Sheets'} elements={leaguePlayersStats.cleanSheets} onSelect={() => {goToPlayersStat('Clean Sheets')}}/>
      <RankingTable title={'Saves'} elements={leaguePlayersStats.saves} onSelect={() => {goToPlayersStat('Saves')}}/>
      </TablesContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const TypeFilterWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 30px 5px 10px;
  }
`;

const TablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default PlayersStats;
