import { useSelector } from "react-redux";
import styled from "styled-components";
import { Game } from "../../../models/game";
import { selectCurrentGame } from "../../../slices/gamesSlice";
import FourSectionPieChart from "../../../components/home-page/four-section-pie-chart/four-section-pie-chart";
import GameStatSectionHeader from "../../../components/game-page/game-stat-section-header/game-stat-section-header";
import PlayerPositions from "../../../components/game-page/player-positions/player-positions";
import SectionField from "../../../components/game-page/section-field/section-field";
import GameSummary from "./game-summary";

const GameOverview = () => {
  const game: Game | undefined = useSelector(selectCurrentGame);

  return game ? (
    <Wrapper>
      <TopRow>
        <GameSummary/>
        <PlayerPositions/>
      </TopRow>
      <GameStatSectionHeader title={""}></GameStatSectionHeader>
      <FourSectionPieWrapper>
        <FourSectionPieChart
          title={"Pass orientation ratio (%)"}
          orientation={game.passOrientationRatio}
          successRatio={[]}
        ></FourSectionPieChart>
        <FourSectionPieChart
          title={"Pass success Ratio (%)"}
          orientation={game.passOrientationRatio}
          successRatio={game.passOrientationSuccessRatio}
        ></FourSectionPieChart>
      </FourSectionPieWrapper>
      <FieldWrapper>
        <SectionField
          title={"Ball loss reactions"}
          data={game.ballLossReactions}
          color={"red_blue"}
        ></SectionField>
      </FieldWrapper>
      <FieldWrapper>
        <SectionField
          title={"Ball recoveries"}
          data={game.ballRecoveries}
          color={"blue"}
        ></SectionField>
      </FieldWrapper>
      <FieldWrapper>
        <SectionField
          title={"Ball loss map"}
          data={game.ballLosses}
          color={"red"}
        ></SectionField>
      </FieldWrapper>
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const FourSectionPieWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;
const FieldWrapper = styled.div`
  margin-bottom: 1rem;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export default GameOverview;
