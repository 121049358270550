import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import { LIGHT_GREY1, LIGHT_GREY2} from "../../assets/colors";
import TopBar from "../../components/navigation/topbar";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";
import ProfileTeam from "./Preferences/Team";
import ProfileSecurity from "./Security/Security";
import GeneralBase from "./General/GeneralBase";
import { selectUser } from "../../slices/userSlice";
import { useSelector } from "react-redux";
import { TEAM_ACCOUNT, TEAM_LEAGUE } from "../../models/constant/accountType";

enum StatPage {
  General,
  Preference,
  Security
}

const Profile = () => {
  const classes = sportsAiGlobalStyles();
  const [statPage, setStatPage] = useState(StatPage.General);
  const accountType = useSelector(selectUser)?.accountType;
  
  return (
    <Wrapper>
      <HeaderWrapper>
      <TopBar name={"Profile"}/>
        <Grid container>
          <Grid item xs={(accountType === TEAM_ACCOUNT || accountType === TEAM_LEAGUE) ? 4: 6}>
            <Button
              className={
                statPage === StatPage.General
                  ? classes.menuHeaderSelected
                  : classes.menuHeader
              }
              onClick={() => {
                setStatPage(StatPage.General);
              }}
            >
              General
            </Button>
          </Grid>
          {
            (accountType === TEAM_ACCOUNT || accountType === TEAM_LEAGUE) ? (
              <Grid item xs={4}>
                <Button
                  className={
                    statPage === StatPage.Preference
                      ? classes.menuHeaderSelected
                      : classes.menuHeader
                  }
                  onClick={() => {
                    setStatPage(StatPage.Preference);
                  }}
                >
                  Team
                </Button>
              </Grid>
            ) : null
          }
          <Grid item xs={(accountType === TEAM_ACCOUNT || accountType === TEAM_LEAGUE) ? 4: 6}>
            <Button
                className={
                  statPage === StatPage.Security
                    ? classes.menuHeaderSelected
                    : classes.menuHeader
                }
                onClick={() => {
                  setStatPage(StatPage.Security);
                //   authApi.collect(({activity: ACTIVITIES.NAVIGATION, feature: FEATURE_VALUES.PLAYLIST}))
                }}
              >
                Security
              </Button>
            </Grid>
          
        </Grid>
      </HeaderWrapper>
      {
        statPage === StatPage.General ? ( <GeneralBase/>) :
        statPage === StatPage.Preference ? ( <ProfileTeam/>) : 
        statPage === StatPage.Security ? ( <ProfileSecurity/>) : 
      (<></>)
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export default Profile;
