import styled from "styled-components";   
import { useSelector } from "react-redux";
import { Dialog } from "@material-ui/core";
import { DARK_BLUE, WHITE } from "../../../assets/colors";
import { selectGamesSummary } from "../../../slices/gamesSlice";
import { useState } from "react";
import { GameSummary } from "../../../models/game";
import FullGameModal from "./fullGameModal";
import React from "react";

const SelectGameModal = (props) => {
  const [matches] = useState<GameSummary[]>(useSelector(selectGamesSummary));
  const [modalIndex, setModalIndex] = useState<number>(-1);
  const openModal = (index: number) => {
    setModalIndex(index);
  };

  const closeModal = () => {
    setModalIndex(-1);
  };

  return (
    <MatchsVideos>
      {matches.map((game, index) => {
        return (game.id && game.has_video) ? ( 
          <div key={game.id}>
            <Dialog
              open={modalIndex === index}
              onClose={closeModal}
            >
              <FullGameModal videoLink={game.video_link} closeModal={closeModal} />
            </Dialog>
            <button className="container" onClick={() => openModal(index)}>
                <div className="MatchVideo">
                <div className="TopRow">
                  <div className="Date">{game.date}</div>
                  <div className="League">{game.competition}</div>
                </div>
                <div className="BottomRow">
                <div className="LogoHome"><img src={game.logo} alt="Base64 1 " width="105" height="75"/> </div>
                  <div className="Score">{game.teamScore}-{game.opponentScore}</div>
                  <div className="LogoOpp"><img src={game.opponentLogo} alt="Base64 2 " width="105" height="75" /></div>
                </div>
              </div>
            </button>
          </div>
        ) : (
          <React.Fragment key={index} />
        );
      })}
    </MatchsVideos>
  );
};

const MatchsVideos = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  gap: 5px;
  margin: 1rem 0 0 2rem;
  width: 95%;
  
  .container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    background: ${WHITE};
    border: 2px solid ${DARK_BLUE};
    border-radius: 15px;
    margin: 2%;
    padding: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .MatchVideo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .TopRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .BottomRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Date, .League {
    text-align: center;
    font-family: Karla Bold;
    font-size: 1rem;
  }

  .Score {
    text-align: center;
    font-family: Karla Bold;
    font-size: 1.5rem;
    width: 25%;
    margin: 3%;
  }

  .LogoHome img, .LogoOpp img {
    max-width: 100%;
  }
`;

export default SelectGameModal;
      