import styled from "styled-components";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import {DARK_BLUE, LIGHT_GREY, LIGHT_GREY1, LIGHT_GREY2} from "../../assets/colors";
import { selectSeason } from "../../slices/userSlice";
import logo from "./../../assets/images/Logo_sports_AI_Couleur.png";
import { loadLigueData } from "../../services/loadData";
import { Competition } from "../../models/competition";
import { useEffect, useState } from "react";
import { TeamSummary } from "../../models/team";
import { selectCompetitions, selectTeamId } from "../../slices/teamSlice";
import { teamApi } from "../../api/teamApi";
import { useAppDispatch } from "../../app/store";
import { Page, changePage } from "../../slices/navigationSlice";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { sportsAiGlobalStyles } from "../../shared/globalStyle";

const League = () => {
  const season = useSelector(selectSeason);
  const classes = sportsAiGlobalStyles();
  const competitions: Competition[] = useSelector(selectCompetitions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [competition, setCompetition] = useState<Competition>(competitions[0]);
  const [opponent, setOpponent] = useState<TeamSummary | null>(null);
  const [opponents, setOpponents] = useState<TeamSummary[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isDataLoaded: Boolean = useSelector(selectTeamId) !== undefined;

  const changeCompetition = (competition_index: number) => {
    setCompetition(competitions[competition_index])
    teamApi.getOpponents(competitions[competition_index].id).then((ret) => {
        setOpponents(ret.data as TeamSummary[])
    })
  }

  const viewTeam = () => {
    if (season !== undefined && opponent !== null){
      loadLigueData(dispatch, season, opponent.id);
      dispatch(changePage(Page.Home));
      history.push(Page.Home);
    }
    else {
      setErrorMessage("Please select both a competition and an opponent to view team.");
    }
  }

  function goBack(event): void {
    dispatch(changePage(Page.Home));
    history.push(Page.Home);
  }

  useEffect(()=> {
    changeCompetition(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <Wrapper>
        {
          isDataLoaded && (
            <ClickableBackContainer onClick={goBack}>
            <ClickableArrowBackIcon/>
            <BackText>Back</BackText>
            </ClickableBackContainer>
          )
        }
    <Grid container component="main" direction="column" alignItems="center">
          <img
            src={logo}
            alt="Logo"
            className={classes.logo}
            width="230"
            height="150"
          />
          <FormWrapper>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Competition</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Competition"
                value={competitions.indexOf(competition)}
                onChange={(event) => {
                    changeCompetition(event.target.value as number);
                }}
            >
                {
                    competitions.map((competition, index) => {
                        return <MenuItem key={competition.id} value={index}>{competition.competition}</MenuItem>
                    })
                }
            </Select>
          </FormControl>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Team</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label = "team"
                    onChange={(event) => setOpponent(opponents[event.target.value as number] as TeamSummary)}
                >
                    {
                        opponents.map((opponent, index) => {
                            return <MenuItem key={opponent.id} value={index}>{opponent.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
          </FormWrapper>
          <Button
            className={classes.fullBlueButton}
            type="submit"
            style={{
              width: "50%",
              backgroundColor: DARK_BLUE,
            }}
            variant="contained"
            color="primary"
            onClick={viewTeam}
          >
            View team
          </Button>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Grid>
      </Wrapper>
  )
}

const ClickableBackContainer = styled.div`
  color: ${DARK_BLUE};
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackText = styled.div`
  margin-left: 4px;
  font-size: 1rem;
`;

const ClickableArrowBackIcon = styled(ArrowBackIosIcon)`
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 1rem;
  font-size: 1rem;
`;

const FormWrapper = styled.div`
  width: 60%;
  margin: 3rem 0rem 0rem 0rem;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;

const Wrapper = styled.div`
  width: 79.7%;
  height: 100%;
  position: static;
  padding: 10% 10% 10% 10%;
  overflow-y: scroll;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;

export default League;
