import styled from "styled-components";
import {
  BLUE_GRADIENT,
  GREY,
  LIGHT_GREY,
  RED_BLUE_GRADIENT,
  RED_GRADIENT,
  RED_TO_GREEN_GRADIENT,
} from "../../../assets/colors";
import HexagonField from "../hexagon-field/hexagon-field";

const SectionFieldVertical = ({ title, data, color }) => {
  let isDataValid = true;
  let domain = 0;

  if (!data) isDataValid = false;

  try {
    if (color === "red_blue") {
      for (const stat of data) {
        domain = Math.max(stat.pressings,stat.placements,stat.noReactions, domain);
      }
    } else {
      domain = Math.max(...data);
    }
    if (domain === 0) isDataValid = false;
  } catch (e) {
    isDataValid = false;
  }

  const colors = () => {
    switch (color) {
      case "blue":
        return BLUE_GRADIENT;
      case "red":
        return RED_GRADIENT;
      case "red_blue":
        return RED_BLUE_GRADIENT;
      default:
        return RED_TO_GREEN_GRADIENT;
    }
  };

  const linearGradient = () => {
    let stringBuilder = "linear-gradient(to top ";

    for (const color of colors()) {
      stringBuilder += `, ${color}`;
    }
    stringBuilder += ")";

    return stringBuilder;
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      {isDataValid ? (
        <>
          <ContentWrapper>
            <HexagonFieldWrapper>
              <HexagonField data={data} color={color} />
            </HexagonFieldWrapper>
            <LegendWrapper
              style={{ right: color === "red_blue" ? "10rem" : "8rem" }}
            >
              <Legend
                style={{
                  backgroundImage: linearGradient(),
                }}
              />
              {color === "red_blue" ? (
                <LegendValues>
                  <div style={{ marginTop: "-0.5rem" }}>
                    {"Placements & Pressings"}
                  </div>
                  <div style={{ marginBottom: "-0.5rem" }}>No Reaction</div>
                </LegendValues>
              ) : (
                <LegendValues>
                  <div style={{ marginTop: "-0.5rem" }}>{domain}</div>
                  <div style={{ marginBottom: "-0.5rem" }}>0</div>
                </LegendValues>
              )}
            </LegendWrapper>
          </ContentWrapper>
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 25px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  max-height: 450px;
  overflow: hidden;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
`;
const HexagonFieldWrapper = styled.div`
  transform: scale(0.45) rotate(-90deg);
  position: relative;
  left: 3rem;
`;
const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 5rem;
  position: relative;
`;
const Legend = styled.div`
  height: calc(424px * 0.5);
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LegendValues = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(424px * 0.5);
  margin-left: 0.5rem;
  font-size: 0.7rem;
  width: 3rem;
`;

export default SectionFieldVertical;
