import { useSelector } from "react-redux";
import GeneralTeam from "./GeneralTeam";
import GeneralPlayer from "./GeneralPlayer";
import { selectUser } from "../../../slices/userSlice";
import { LEAGUE_ACCESS, PLAYER_ACCOUNT, TEAM_ACCOUNT, TEAM_LEAGUE } from "../../../models/constant/accountType";
import GeneralLeague from "./GeneralLeague";

const GeneralBase = () => {
    const userType = useSelector(selectUser)?.accountType;
    switch (userType) {
        case TEAM_ACCOUNT:
            return <GeneralTeam />;
        case TEAM_LEAGUE:
            return <GeneralTeam />;
        case PLAYER_ACCOUNT:
            return <GeneralPlayer />;
        case LEAGUE_ACCESS:
            return <GeneralLeague />;
        default:
            return null;
    }
}

export default GeneralBase;
