import { Button, Slider, makeStyles,Popper } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CLEAR_BLUE_50, DARK_BLUE, WHITE } from '../../../assets/colors';
import {
  Replay5,
  Forward5,
  Pause,
  PlayArrow,
  VolumeUp,
  Fullscreen,
  AllInclusive,
  VolumeOff,
  VolumeDown,
  VolumeMute
} from "@material-ui/icons";
import VideoJSPlayer from './VideoJS';
import React from 'react';

const useStyles = makeStyles(() => ({
  selectedScene: {
    width: '98%',
    backgroundColor: CLEAR_BLUE_50 ,
    borderColor: DARK_BLUE,
    borderRadius: "5px",
    border: "2px solid",
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    justifyContent: 'center',
  },
  sceneList: {
    width: '100%',
    backgroundColor: WHITE,
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    justifyContent: 'center',
    borderRadius: "5px",
  },
  slider: {
    color: DARK_BLUE, 
    
  },
  loopOn: {
    color: DARK_BLUE, 
    width: '1px',
  },
  loopOff: {
    color: "black", 
  },
  loopSelect: {
    width: '2px', // Set the minimum width of the select to avoid resizing when options change
    backgroundColor: DARK_BLUE,
  },

  loopNotSelect: {
    width: '2px', // Set the minimum width of the select to avoid resizing when options change
    "&:hover": {
      backgroundColor: DARK_BLUE,
    },
  },

  loopintervaldropdown: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'white',
    border: '1px solid #d3d3d3',
  },
  }))
  
  
  const VideoPlayer = ({ selectedScene, handleJumpTime ,handleLoopInterval,loopIntervalDuration, videoRef}) => {
    const classes = useStyles();
    const [isPlaying, setIsPlaying] = React.useState(true);
    const [currentTime, setCurrentTime] = React.useState(0); 
    const [volume, setVolume] = React.useState(1); // State to manage the volume of the video
    const [isMuted, setIsMuted] = React.useState(false);
    const [isLooping, setIsLooping] = useState(true);
    const volumeLevels = [
      { level: 0, icon: <VolumeOff /> },
      { level: 0.33, icon: <VolumeMute /> },
      { level: 0.66, icon: <VolumeDown /> },
      { level: 1, icon: <VolumeUp /> },
    ];
    const volumeLevel = volumeLevels.find((item) => volume <= item.level);
    // State to manage menu open/close
    const [isMenuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    // Function to handle menu open
    const handleMenuOpen = () => {
      setMenuOpen(!isMenuOpen);
    };

    // Function to handle menu close
    const handleMenuClose = () => {
      setMenuOpen(false);
    };

    useEffect(() => {
      if (videoRef.current != null) {
        handleJumpTime(selectedScene?.time, selectedScene?.matchId);
        if (isPlaying) {
          videoRef.current.play();
          setCurrentTime(selectedScene.time)
        } else {
          videoRef.current.pause();
        }
      }
    }, [selectedScene, isPlaying]);

    useEffect(() => {
      const handleTimeUpdate = () => {
        if (videoRef.current) {
          setCurrentTime(videoRef.current.currentTime);
        }
      };
  
      if (isPlaying && videoRef.current) {
        videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
      }
    
      return () => {
        if (isPlaying && videoRef.current) {
          videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        }
      };
    }, [isPlaying, videoRef]);
    
    const handleLoopIntervalSelect = (interval) => {
      handleLoopInterval(interval);
      setTimeout(() => {
       handleMenuClose();}, 600);
     };
    const toggleMute = () => {
      if (videoRef.current) {
        videoRef.current.muted = !videoRef.current.muted;
        if(!isMuted){
          setVolume(0)
          setIsMuted((prevMute) => !prevMute);}
        else{
          setVolume(0.5)
          setIsMuted((prevMute) => !prevMute);}
      }
    };
    const handlePlayPause = () => {
      setIsPlaying((prevState) => !prevState);
    };
    const handleSliderChange = (event, newValue) => {
    setCurrentTime(newValue);
    };

    const handleLoopToggle = () => {
      setIsLooping((prevIsLooping) => !prevIsLooping);
    };

    const handleSliderChangeCommitted = (event, newValue) => {
      videoRef.current.currentTime = newValue; // Update the video's current time when slider is released
    };

    const handleVolumeChange = (event, newValue) => {
      setVolume(newValue);
      setIsMuted(newValue === 0);
      videoRef.current.volume = newValue;
    };

    const handleFullscreen = () => {
      if (videoRef.current) {
        // Check if the browser supports the Fullscreen API
        const doc = window.document;
        const videoElement = videoRef.current;
  
        if (doc.fullscreenEnabled || doc.fullscreenEnabled || doc.fullscreenEnabled || doc.fullscreenEnabled) {
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
          }
        }
      }
    };

    // Create a new TimeIndicator component
    const TimeIndicator = ({ currentTime, totalTime }) => {
      // Helper function to format time
      const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
    
        let timeString = '';
        if (hours > 0) {
          timeString += `${String(hours).padStart(2, '0')}:`;
        }
        timeString += `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        
        return timeString;
      };

      return (
        <div className="time">
          { formatTime(currentTime) /*/ {formatTime(totalTime)} */}
        </div>
      );
    };
    return (
      <Container>
        <Wrapper>
        <VideoPlayerContainer>
          <div>
            {selectedScene && <VideoJSPlayer options={undefined} videoRef={videoRef} />}
            {videoRef.current!== null && ( // Conditionally render ControlContainer when isPlaying is true
              <ControlContainer>
            <div className="container">
              <div className="minusTen">
              <Button onClick={() => { videoRef.current.currentTime -= 5; }} >
                  <Replay5 />
                </Button>
              </div>
              <div className="play">
              <Button onClick={handlePlayPause} >
                  {isPlaying ? <Pause /> : <PlayArrow />}
                </Button>
              </div>
              <div className="plusTen">
                <Button onClick={() => { videoRef.current.currentTime += 5; }}>
                  <Forward5 />
                </Button></div>
              <div className="timeslider">
              <Slider
              className={classes.slider }
                  value={currentTime}
                  min={0}
                  max={videoRef.current ? videoRef.current.duration : 0}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderChangeCommitted}
                  aria-labelledby="continuous-slider"
              />
              </div>
              <TimeIndicator currentTime={currentTime} totalTime={videoRef.current ? videoRef.current.duration : 0} />
              <div className="loop">
              <Button
                ref={anchorRef}
                onClick={handleMenuOpen}
                className={isLooping ? classes.loopOn : classes.loopOff}
              >
                <AllInclusive />
              </Button>
              <Popper open={isMenuOpen} anchorEl={anchorRef.current} placement="top-start">
              {isMenuOpen && (
                <div className={classes.loopintervaldropdown}>
                  <Button 
                  className={loopIntervalDuration===10 ? classes.loopSelect : classes.loopNotSelect} onClick={() => handleLoopIntervalSelect(10)}>10</Button>
                  <Button 
                  className={loopIntervalDuration===20 ? classes.loopSelect : classes.loopNotSelect} onClick={() => handleLoopIntervalSelect(20)}>20</Button>
                  <Button 
                  className={loopIntervalDuration===30 ? classes.loopSelect : classes.loopNotSelect} onClick={() => handleLoopIntervalSelect(30)}>30</Button>
                  <Button 
                  className={loopIntervalDuration===40 ? classes.loopSelect : classes.loopNotSelect} onClick={() => handleLoopIntervalSelect(40)}>40</Button>
                <Button 
                  className={loopIntervalDuration===60 ? classes.loopSelect : classes.loopNotSelect} onClick={() => handleLoopIntervalSelect(60)}>60</Button>
                </div>
              )}
              </Popper>
              </div>
              <div className="volumeButton">
              <Button onClick={toggleMute}>
                {volumeLevel?.icon || <VolumeUp />}
              </Button>
            </div>
              <div className="volumeSlider">
              <Slider
              className={classes.slider }
                  value={volume}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={handleVolumeChange}
                  aria-labelledby="continuous-slider"
                />
              </div>
              <div className='Fullscreen'>
              <Button onClick={handleFullscreen}>
                    <Fullscreen />
                  </Button>
              </div>
            </div>
            </ControlContainer>)}
          </div>
        </VideoPlayerContainer>
        </Wrapper>
      </Container>
    );
  };
  
  

const Container = styled.div`
  flex-direction: row;  
  position: fixed;
  width: 100%;
`;   
const Wrapper = styled.div`
position: fixed;
height:100%;
width: 57.9%;
font-family: Karla Bold;
`;

const VideoPlayerContainer = styled.div`
font-family: Karla Bold;
width: 100%; /* Add this line to make the width 100% */
`;


const ControlContainer = styled.div`
.container {  display: grid;
  grid-template-columns: 0.1fr 0.1fr 0.1fr 3fr 0.8fr 0.3fr 0.3fr 0.6fr 0.3fr;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-content: center;
  justify-items: stretch;
  align-items: center;
  grid-template-areas:
    "minusTen play plusTen timeslider time loop volumeButton volumeSlider Fullscreen";
}

.play { grid-area: play;
  button {
    padding: 0; /* Remove extra padding */
  } }

.timeslider { 
  border-top: 50px;
  grid-area: timeslider; 
}

.time { 
  grid-area: time; 
  margin-left: 5%;
  justify-self: center;
  border: "5px solid",
  border-color: "black";
}

.loop { 
  grid-area: loop;
  button {
    padding: 0; /* Remove extra padding */
  } }


.minusTen { 
  grid-area: minusTen;
  button {
    padding: 0; /* Remove extra padding */
  } }

.plusTen { 
  grid-area: plusTen; 
button {
    padding: 0; /* Remove extra padding */
  }}

.volumeButton { 
  grid-area: volumeButton; 
  button {
    padding: 0; /* Remove extra padding */
  }
}

.volumeSlider { 
  grid-area: volumeSlider;
 }
.Fullscreen { 
  grid-area: Fullscreen; 
  justify-self: flex-end; }
`;


export default VideoPlayer;