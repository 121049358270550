import { Pie, PieChart, Cell } from "recharts";
import styled from "styled-components";
import {
  BLUE_GRADIENT,
  GREEN_GRADIENT,
  GREY,
  LIGHT_GREEN_GRADIENT,
  LIGHT_GREY,
  ORANGE_GRADIENT,
  RED_GRADIENT,
  YELLOW_GRADIENT,
} from "../../../assets/colors";

const FourSectionPieChart = ({ title, orientation, successRatio }) => {
  let isDataValid = true;

  if (!orientation || !successRatio) isDataValid = false;

  const domain = {
    min: 0,
    max: isDataValid ? Math.max(...orientation) : 0,
    total: isDataValid ? orientation.reduce((sum, next) => (sum += next)) : 0,
  };

  let orientationPercentages = [0, 0, 0, 0];
  try {
    for (let i = 0; i < orientation.length; i++) {
      orientationPercentages[i] = Math.round(
        (orientation[i] / domain.total) * 100
      );
    }
  } catch (e) {
    isDataValid = false;
  }

  const getColor = (
    success: number,
    percentage: number,
    section: number,
    maxSection: number
  ) => {
    if (successRatio.length === 0) {
      return BLUE_GRADIENT[
        Math.round(
          Math.min(
            (percentage / domain.max) * BLUE_GRADIENT.length,
            BLUE_GRADIENT.length - 1
          )
        )
      ];
    } else {
      const index = Math.floor((success / 100) * 5);
      let colorGradient;

      switch (index) {
        case 0:
          colorGradient = RED_GRADIENT;
          break;
        case 1:
          colorGradient = YELLOW_GRADIENT;
          break;
        case 2:
          colorGradient = ORANGE_GRADIENT;
          break;
        case 3:
          colorGradient = LIGHT_GREEN_GRADIENT;
          break;
        case 4:
        case 5:
          colorGradient = GREEN_GRADIENT;
          break;
      }
      return colorGradient[
        Math.floor(
          (section / Math.max(maxSection, 1)) * (colorGradient.length - 1)
        )
      ];
    }
  };

  const getPieSection = (
    success: number,
    value: number,
    start: number,
    end: number
  ) => {
    const maxSections = 6;
    const percentPerSection = Math.max(...orientationPercentages) / maxSections;
    let radiusInterval = 12;
    const pieSection: JSX.Element[] = [];
    for (let i = 0; i * percentPerSection <= value; i++) {
      pieSection.push(
        <Pie
          key={`${value}${i}${start}`}
          data={[{ name: `${value}`, value: 100 }]}
          startAngle={start}
          endAngle={end}
          dataKey="value"
          innerRadius={(i + 1) * radiusInterval}
          outerRadius={(i + 2) * radiusInterval}
          paddingAngle={10}
        >
          <Cell
            key={`${value}${i}${start}cell`}
            fill={getColor(
              success,
              i * percentPerSection,
              i,
              Math.floor((value / domain.max) * maxSections)
            )}
          />
        </Pie>
      );
    }

    return pieSection;
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      {isDataValid && domain.max !== 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem 0",
          }}
        >
          <div
            style={{
              color: GREY,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            LEFT (
            {successRatio.length === 0
              ? orientationPercentages[3]
              : successRatio[3]}
            %)
          </div>
          <ChartWrapper>
            <div style={{ color: GREY }}>
              FRONT (
              {successRatio.length === 0
                ? orientationPercentages[0]
                : successRatio[0]}
              %)
            </div>
            <PieChart height={200} width={200}>
              {getPieSection(
                successRatio[0],
                orientationPercentages[0],
                135,
                45
              )}
              {getPieSection(
                successRatio[1],
                orientationPercentages[1],
                45,
                -45
              )}
              {getPieSection(
                successRatio[2],
                orientationPercentages[2],
                -45,
                -135
              )}
              {getPieSection(
                successRatio[3],
                orientationPercentages[3],
                -135,
                -225
              )}
            </PieChart>
            <div style={{ color: GREY }}>
              BACK (
              {successRatio.length === 0
                ? orientationPercentages[2]
                : successRatio[2]}
              %)
            </div>
          </ChartWrapper>
          <div
            style={{
              color: GREY,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            RIGHT (
            {successRatio.length === 0
              ? orientationPercentages[1]
              : successRatio[1]}
            %)
          </div>
        </div>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 25px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  height: 100%;
  font-family: Karla Regular;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  font-family: Karla Bold;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${GREY};
  width: 100%;
  height: 267px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export default FourSectionPieChart;
