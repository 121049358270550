import { Pie, PieChart, ResponsiveContainer, Cell } from "recharts";
import styled from "styled-components";
import {
  GREY,
  LIGHT_GREY,
  RED_TO_GREEN_GRADIENT,
} from "../../../assets/colors";

const RatioPieChart = ({ title, ratio }) => {
  if (!ratio) ratio = 0;

  const data = [
    { name: "successes", value: ratio },
    { name: "failures", value: 1 - ratio },
  ];

  const getColor = () =>
    RED_TO_GREEN_GRADIENT[
      Math.floor(
        Math.min(
          ratio * (RED_TO_GREEN_GRADIENT.length - 1),
          RED_TO_GREEN_GRADIENT.length - 1
        )
      )
    ];

  return (
    <Wrapper>
      <Title>{`${title}`}</Title>
      <PieWrapper>
        <ResponsiveContainer width="99%" height={100}>
          <PieChart>
            <Pie
              data={data}
              startAngle={90}
              endAngle={-270}
              dataKey="value"
              innerRadius={30}
              outerRadius={40}
            >
              <Cell fill={getColor()}></Cell>
              <Cell fill={LIGHT_GREY}></Cell>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {ratio ? (
          <Percentage style={{ color: getColor() }}>
            {`${Math.round(ratio * 100)}%`}
          </Percentage>
        ) : (
          <Percentage style={{ color: getColor() }}>0%</Percentage>
        )}
      </PieWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
  background-color: white;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  min-height: 150px;
  height: 100%;
  b {
    text-transform: uppercase;
  }
`;
const Title = styled.div`
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  div {
    margin-left: 5px;
    color: ${GREY};
  }
`;
const PieWrapper = styled.div`
  height: 100px;
`;
const Percentage = styled.div`
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  margin-top: -3.9rem;
`;
export default RatioPieChart;
