import styled from "styled-components";
import { DARK_BLUE, LIGHT_BLUE, LIGHT_GREY1 } from "../../../assets/colors";

const SectionHeader = ({ title }) => {
  return (
    <Wrapper>
      <HR></HR>
      <SectionTitle>
        <div style={{ backgroundColor: LIGHT_GREY1, padding: "0px 10px" }}>
          {title}
        </div>
      </SectionTitle>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;
const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  color: ${DARK_BLUE};
  font-size: 1.1rem;
  font-family: Antique Olive Nord;
  text-transform: uppercase;
  div {
    margin-top: -0.65rem;
    margin-bottom: 1.2rem;
  }
`;
const HR = styled.div`
  height: 1.5px;
  width: calc(100% - 4rem);
  background-color: ${LIGHT_BLUE};
  padding: 0 2rem;
  margin-top: 2rem;
`;

export default SectionHeader;
