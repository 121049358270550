import styled from "styled-components";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import SelectStatModal from "./statSelection/statSelectionModal";
import { RootState } from "../../../../app/store";
import { updateSelectedStats } from "../../../../slices/sequencesSlice";
import { useAppDispatch } from "../../../../app/store";
import { Stats } from "./initStatSelection/initStatSelection";
import { getEvents, setVideoEvents } from "../../../../slices/videoSlice";
import { selectTeamId } from "../../../../slices/teamSlice";

const StatModal = ({ setIsStatModalOpen, isStatModalOpen }) => {
      const prevIsStatModalOpen = useRef(isStatModalOpen); 
      const dispatch = useAppDispatch();
      const teamId = useSelector(selectTeamId);
      const selectedGames = useSelector((state: RootState) => state.sequencesSelection.selectedGames);
      const selectedPlayers = useSelector((state: RootState) => state.sequencesSelection.selectedPlayers);
      const selectedStats = useSelector((state: RootState) => state.sequencesSelection.selectedStats);
      const [buttonStates, setButtonStates] = useState<Stats>(selectedStats);
      const handleButtonOnMouseEnter = (key: string, event: React.MouseEvent<HTMLButtonElement>) => {
        if (key === "recovery12" || key==="recovery17"
            || key === "loss12" || key==="loss17") {
          event.stopPropagation();
        }
      };
    
      const handleButtonOnMouseLeave = (key: string, event: React.MouseEvent<HTMLButtonElement>) => {
        if (key === "recovery12" || key==="recovery17"
        || key === "loss12" || key==="loss17")  {
          event.stopPropagation();
        }
      };
    
      const handleFieldZonesClick = (key: string, event: React.MouseEvent<HTMLButtonElement>) => {
    
        if (key === "recovery12" || key==="recovery17"
        || key === "loss12" || key==="loss17")  {
          event.stopPropagation();
        }
        setButtonStates(prevState => ({
          ...prevState,
          [key]: !prevState[key],
        }));
      };
      
      const handleStatsButtonClick = useCallback((key: string) => {
        switch(key){
          case 'allPasses':
            if(buttonStates["passCompleted"] && buttonStates["passMissed"] 
              && buttonStates["passFront"] && buttonStates["passLeft"] 
              && buttonStates["passRight"] && buttonStates["passBack"] === true){
                setButtonStates({
                  ...buttonStates,
                  passCompleted: false,
                  passMissed: false,
                  passFront: false,
                  passLeft: false,
                  passRight: false,
                  passBack: false,
                })
              }
              else{
                setButtonStates({
                  ...buttonStates,
                  passCompleted: true,
                  passMissed: true,
                  passFront: true,
                  passLeft: true,
                  passRight: true,
                  passBack: true,
                })
              }
            break;
          case 'allDirections':
            if(buttonStates["passFront"] && buttonStates["passLeft"] && 
               buttonStates["passRight"] && buttonStates["passBack"] === true){
              setButtonStates({
                ...buttonStates,
                passFront: false,
                passLeft: false,
                passRight: false,
                passBack: false,
            })
            }
            else{
              setButtonStates({
                ...buttonStates,
                passFront: true,
                passLeft: true,
                passRight: true,
                passBack: true,
            })}
            break;
            case 'allReaction':
            if(buttonStates["lossPressing"] && buttonStates["lossRepo"] && buttonStates["lossNoReact"] === true){
              setButtonStates({
                ...buttonStates,
                lossPressing:false,
                lossRepo:false,
                lossNoReact:false,
            })
            }
            else{
              setButtonStates({
                ...buttonStates,
                lossPressing:true,
                lossRepo:true,
                lossNoReact:true,
            })}
            break;
            case 'allChallenges':
            if(buttonStates["challengeW"] && buttonStates["challengeL"] === true){
              setButtonStates({
                ...buttonStates,
                challengeW: false,
                challengeL: false,
                challengeAir: false,
                challengeDef: false,
                challengeOff: false

            })
            }
            else{
              setButtonStates({
                ...buttonStates,
                challengeW: true,
                challengeL: true,
                challengeAir: true,
                challengeDef: true,
                challengeOff: true
            })}
            break;
            case 'allTackles':
            if(buttonStates["tackleW"] && buttonStates["tackleL"] === true){
              setButtonStates({
                ...buttonStates,
                tackleW: false,
                tackleL: false,
            })
            }
            else{
              setButtonStates({
                ...buttonStates,
                tackleW: true,
                tackleL: true,
            })}
            break;
            case 'allZonesRecup':
            if(buttonStates["recovery1"] && buttonStates["recovery2"] && 
               buttonStates["recovery3"] && buttonStates["recovery4"] && 
               buttonStates["recovery12"] && buttonStates["recovery17"] === true){
              setButtonStates({
                ...buttonStates,
                recovery1: false,
                recovery2: false,
                recovery3: false,
                recovery4: false,
                recovery12: false,
                recovery17: false,
            })
            }
            else{
              setButtonStates({
                ...buttonStates,
                recovery1: true,
                recovery2: true,
                recovery3: true,
                recovery4: true,
                recovery12: true,
                recovery17: true,
            })}
            break;
            case 'allZonesLoss':
            if(buttonStates["loss1"] && buttonStates["loss2"] && 
               buttonStates["loss3"] && buttonStates["loss4"] && 
               buttonStates["loss12"] && buttonStates["loss17"] === true){
              setButtonStates({
                ...buttonStates,
                loss1: false,
                loss2: false,
                loss3: false,
                loss4: false,
                loss12: false,
                loss17: false,
            })
            }
            else{
              setButtonStates({
                ...buttonStates,
                loss1: true,
                loss2: true,
                loss3: true,
                loss4: true,
                loss12: true,
                loss17: true,
            })}
            break;
            case 'allCards':
              if(buttonStates["yellowCard"] && buttonStates["redCard"] === true){
                setButtonStates({
                  ...buttonStates,
                  yellowCard: false,
                  redCard: false,
              })
              }
              else{
                setButtonStates({
                  ...buttonStates,
                  yellowCard: true,
                  redCard: true,
              })}
            break;
          default:
            setButtonStates(prevState => ({
              ...prevState,
              [key]: !prevState[key],
            }));
        }
      }, [buttonStates]);
      
      useEffect(() => {
        if (prevIsStatModalOpen.current && !isStatModalOpen) {
          // Modal was just closed
          if(selectedGames.length > 0 && selectedPlayers.length > 0 && Object.values(buttonStates).some(Boolean))
            {
              dispatch(
                getEvents({
                  teamId: teamId? teamId : 0,
                  stats: buttonStates,
                  players: selectedPlayers,
                  games: selectedGames,
                })
              )}
          else{ 
            dispatch(setVideoEvents([]))
          }
        }
        prevIsStatModalOpen.current = isStatModalOpen;
      }, [isStatModalOpen, handleStatsButtonClick, selectedGames, selectedPlayers, buttonStates, dispatch, teamId]);
      
      useEffect(() => {
        dispatch(updateSelectedStats(buttonStates));
      }, [buttonStates, dispatch]);
      return (
        <Wrapper>
          <SelectStatModal 
            buttonStates={buttonStates}
            setButtonStates={setButtonStates}
            CheckedPlayers={selectedPlayers}
            CheckedGames={selectedGames} 
            handleStatsButtonClick={handleStatsButtonClick}
            handleButtonOnMouseEnter={handleButtonOnMouseEnter}
            handleButtonOnMouseLeave={handleButtonOnMouseLeave}
            handleFieldZonesClick={handleFieldZonesClick}
            setIsStatModalOpen={setIsStatModalOpen}
            dispatch={dispatch}
            /> 
        </Wrapper>
      );
    };
  
  const Wrapper = styled.div`
  position: fixed;
  top: 5%;
  left: 25%;
  width: 50%;
  max-height: 60%;
  font-family: Karla Bold;
`;

export default StatModal;
    