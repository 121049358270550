export enum ACTIVITIES {
    LOGIN = 1,
    NAVIGATION = 2,
    CREATE = 3,
    UPDATE = 4,
    DELETE = 5,
    READ = 6,
    DOWNLOAD = 7,
    LOGOUT = 8,
}

export enum FEATURE_VALUES {
    AUTH = 1,
    GAMES = 2,
    PLAYERS = 3,
    LIBRARY = 4,
    SEQUENCE = 5,
    PLAYLIST = 6,
    SEASON = 7,
    LEAGUE = 8,
    RANKINGS = 9,
    PROFILE = 10,
}