import TagManager from "react-gtm-module";

const GTM_ID: string = "GTM-M9FQS54";
export const homePlayerEvolution: string = "/home-players-evolution";

export const sendPageView = (pagename?: string) =>  {

  if (pagename){
    window.dataLayer.push({
      event : "pageview",
      page: {
          url: document.location.origin + pagename,
          title: "PlayLab"
        }
    });
  } else {
    window.dataLayer.push({
      event : "pageview",
    });
  }
}

export const sendEvent = (category: string, action: string, label: string, valule: number) => {
  window.dataLayer.push({
    event: 'event',
    eventProps: {
        category: category,
        action: action,
        label: label,
        value: valule
    }
  });
}

export const InitAnalytics = () => {
  const tagManagerArgs = {
      gtmId: GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
    sendPageView();
}
