import axios from 'axios';
import { ENDPOINT, BASE_URL } from './config';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  if (sessionStorage.token && config.url !== ENDPOINT.LOGIN ) {
    config.headers.Authorization = sessionStorage.token;
  }

  return config;
});

export default axiosInstance;