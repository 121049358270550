import styled from "styled-components";
import GoalsRatio from "../goals-ratio/goals-ratio";
import GoalsScored from "../goals-scored/goals-scored";
import ResultsRatio from "../results-ratio/results-ratio";
import SectionHeader from "../section-header/section-header";
import SuccessRatio from "../../stats-base/success-ratio/success-ratio";
import FourSectionPieChart from "../four-section-pie-chart/four-section-pie-chart";
import TeamOffenses from "../team-offenses/team-offenses";
import { selectTeam } from "../../../slices/teamSlice";
import { useSelector } from "react-redux";
import { Team } from "../../../models/team";
import { GameSummary } from "../../../models/game";
import { selectGamesSummary } from "../../../slices/gamesSlice";
import GoalsConceded from "../goals-conceded/goals-conceded";
import ScoredGoalsTypes from "../zone-event-position/zone-event-position";
import OccurancesBox from "../../stats-base/occurances-box/occurances-box";
import { SequencesType, setShowSequenceModal, statSequencesParams } from "../../../slices/navigationSlice";
import { useAppDispatch } from "../../../app/store";

const TeamStats = () => {
  const team: Team = useSelector(selectTeam);
  const gamesPlayed: GameSummary[] = useSelector(selectGamesSummary).filter(game => game.isOperated);
  const dispatch = useAppDispatch();

  return team && gamesPlayed.length > 0 ? (
    <Wrapper>
      <RatioWrapper>
        <ResultsRatio
          wins={team.wins}
          draws={team.draws}
          losses={team.losses}
        />
        <GoalsRatio scored={team.scored} conceded={team.conceded} />
      </RatioWrapper>
      <SectionHeader title={"Attacking"} />
      <GoalsScored gamesPlayed={gamesPlayed} />
      <GoalsConceded gamesPlayed={gamesPlayed} />
      <ScoredGoalsTypes />
      <RatioEnvelope3>
      <OccurancesBox title={"key Actions"} value={team.keyActions}/>
        <SuccessRatio
          title={"Shot Precision"}
          successes={team.shotSuccess}
          total={team.shotTotal}
          onClick={() => {
            dispatch(setShowSequenceModal(true));
            dispatch(statSequencesParams({sequenceType: SequencesType.Shots, sequenceOptions: []}))
          }}
        ></SuccessRatio>
        <SuccessRatio
          title={"Penalty Shot Precision"}
          successes={team.penaltySuccess}
          total={team.penaltyTotal}
          onClick={() => {
            dispatch(setShowSequenceModal(true));
            dispatch(statSequencesParams({sequenceType: SequencesType.PenaltyShots, sequenceOptions: []}))
          }}
        ></SuccessRatio>
      </RatioEnvelope3>
      <SectionHeader title={"Passes"} />
      <PassesWrapper>
        <FourSectionPieChart
          title={"Pass orientation ratio (%)"}
          orientation={team.passOrientation}
          successRatio={[]}
        ></FourSectionPieChart>
        <PassesRightSideWrapper>
          <OccurancesBox title={"COMPLETED PASSES"} value={team.passSuccess} />
          <SuccessRatio
            title={"Pass Success"}
            successes={team.passSuccess}
            total={team.passTotal}
          ></SuccessRatio>
        </PassesRightSideWrapper>
      </PassesWrapper>
      <SectionHeader title={"Defense"} />
      <RatioEnvelope>
        <SuccessRatio
          title={"Tackle Success"}
          successes={team.tackleSuccess}
          total={team.tackleTotal}
        ></SuccessRatio>
        <SuccessRatio
          title={"Challenge Success"}
          successes={team.duelSuccess}
          total={team.duelTotal}
        ></SuccessRatio>
      </RatioEnvelope>
      <TeamOffenses
        fouls={team.fouls}
        yCards={team.yellowCards}
        rCards={team.redCards}
      />
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  width: calc(100% - 4rem);
  padding: 2rem;
`;
const RatioWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 11rem;
`;
const RatioEnvelope = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
`;
const RatioEnvelope3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
`;
const PassesWrapper = styled.div`
  display: grid;
  grid-template-columns: 7fr 5fr;
  width: 100%;
  gap: 1rem;
`;
const PassesRightSideWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;
`;
export default TeamStats;
