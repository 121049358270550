import { Button, Grid, makeStyles } from "@material-ui/core";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from "styled-components";
import Attacker_Icon from "../../../assets/images/Attacker_Icon.png";
import Defender_Icon from "../../../assets/images/Defender_Icon.png";
import Midfielder_Icon from "../../../assets/images/Midfielder_Icon.png";
import Goaltender_Icon from "../../../assets/images/Goaltender_Icon.png";
import { useAppDispatch } from "../../../app/store";
import { changePage, Page } from "../../../slices/navigationSlice";
import { setCurrentPlayerId } from "../../../slices/playerSlice";
import {
  DARK_BLUE,
  GREY,
  LIGHT_GREY,
  LIGHT_GREY2,
} from "../../../assets/colors";

const useStyles = makeStyles(() => ({
  profileButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    fontWeight: "bold",
    textTransform: "none",
    fontFamily: 'Karla',
  },
}));

const PlayerPositionCard = ({ id, name, number, position }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const getPlayerIcon = (position: string) => {
    switch (position.toLowerCase()) {
      case "forward":
        return Attacker_Icon;
      case "defender":
        return Defender_Icon;
      case "midfielder":
        return Midfielder_Icon;
      case "goalkeeper":
        return Goaltender_Icon;
      default:
        return "";
    }
  };

  return (
    <Grid item>
      <Wrapper>
        <NameWrapper>
          <Name>
            {name.split(" ")[0].substring(0, 1)}. {name.split(" ")[1]}
          </Name>
          <Number>#{number}</Number>
        </NameWrapper>
        <Position>
          <img src={getPlayerIcon(position)} alt="Position_Icon" />
          <div>{position}</div>
        </Position>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            className={classes.profileButton}
            onClick={() => {
              dispatch(setCurrentPlayerId(id));
              dispatch(changePage(Page.PlayerProfile));
            }}
          >{`Stats `} <ChevronRightIcon></ChevronRightIcon></Button>
        </div>
      </Wrapper>
    </Grid>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0.75rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  width: 150px;
  min-height: 100px;
`;
const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Name = styled.div``;
const Number = styled.div`
  font-size: 0.8rem;
  color: ${DARK_BLUE};
  height: 1rem;
  display: flex;
  align-items: center;
  background: ${LIGHT_GREY2};
  border-radius: 4px;
  padding: 2px 5px;
`;
const Position = styled.div`
  font-size: 0.8rem;
  color: ${GREY};
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    margin-left: 10px;
  }
`;

export default PlayerPositionCard;
