import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectCurrentGame } from "../../../slices/gamesSlice";
import { Game } from "../../../models/game";
import BasicStat from "../../../components/game-page/basic-stat/basic-stat";
import GameStatSectionHeader from "../../../components/game-page/game-stat-section-header/game-stat-section-header";
import SectionField from "../../../components/game-page/section-field/section-field";
import MultiProgressBar from "../../../components/game-page/progress-bars/multi-progress-bar";

const GameDefending = () => {
  const game: Game | undefined = useSelector(selectCurrentGame);

  return game ? (
    <Wrapper>
      <StealsStatWrapper>
        <BasicStatWrapper>
          <BasicStat title={"tackles"} value={game.tackles} />
          <BasicStat title={"fouls"} value={game.fouls} />
        </BasicStatWrapper>
        <BasicStatWrapper>
          <BasicStat title={"Clearances"} value={game.clearances.total} />
          <BasicStat title={"Shot blocks"} value={game.shotBlocked} />
        </BasicStatWrapper>
        <MultiProgressBar
          title={`Challenges Won (${game.challengesWon}/${game.challengesTotal})`}
          percentages={[
            Math.round((game.challenges.aerial.successful / game.challenges.aerial.total) * 100),
            Math.round((game.challenges.defensive.successful / game.challenges.defensive.total) * 100),
            Math.round((game.challenges.offensive.successful / game.challenges.offensive.total) * 100),
          ]} 
          labels={['air','def', 'off']}
          values={[game.challenges.aerial.successful, game.challenges.defensive.successful, game.challenges.offensive.successful, 
                   game.challenges.aerial.total, game.challenges.defensive.total, game.challenges.offensive.total,
                  ]}
        />
      </StealsStatWrapper>
      <GameStatSectionHeader title={"Ball Loss"} />
      <FieldWrapper>
        <SectionField
          title={"Ball Loss Reactions"}
          data={game.ballLossReactions}
          color={"red_blue"}
        ></SectionField>
      </FieldWrapper>
      <FieldWrapper>
        <SectionField
          title={"Ball Loss Map"}
          data={game.ballLosses}
          color={"red"}
        ></SectionField>
      </FieldWrapper>
    </Wrapper>
  ) : (
    <></>
  );
};

const BasicStatWrapper = styled.div`
  display: grid;
  flex-direction: column;
  gap: 0.5rem;
`;

const Wrapper = styled.div`
  width: 100%;
`;
const FieldWrapper = styled.div`
  margin-bottom: 1rem;
`;
const StealsStatWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  gap: 1rem;
`;
export default GameDefending;
