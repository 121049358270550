import styled from "styled-components";
import { Button, Grid, InputAdornment, OutlinedInput, makeStyles } from "@material-ui/core"
import { Search } from "@material-ui/icons";
import { DARK_BLUE, GREY, LIGHT_BLUE, LIGHT_GREY, LIGHT_GREY1 } from "../../../../assets/colors";
import AddIcon from '@mui/icons-material/Add';
import { sportsAiGlobalStyles } from "../../../../shared/globalStyle";
import { black } from "material-ui/styles/colors";
import { useSelector } from "react-redux";
import { selectPlaylists, selectSelectedSequences } from "../../../../slices/videoSlice";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import logo from "../../../../assets/images/Logo_Ball.png";
import { videoApi } from "../../../../api/video";
import { SequenceConfirmationModal } from "./sequenceConfirmationModal";
import ModalTopbar from "../../../../components/navigation/modalTopbar";
import { DESTROY_SELECTED_SEQUENCE } from "../../../../slices/videoSlice";
import { useAppDispatch } from "../../../../app/store";

export const searchBarStyles = makeStyles(() => ({
    root: {
      height: "2.4rem",
      margin: "0 1rem",
      width: "calc(100% - 2rem)",
      borderColor: LIGHT_GREY,
      borderRadius: '8px',
      "&$focused $notchedOutline": {
        borderColor: DARK_BLUE,
      },
    },
    focused: {},
    notchedOutline: {},
}));        

export const PlaylistTableModal = ({newPlaylistCallback, next ,closeModal}) => {
    const dispatch = useAppDispatch();
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();
    const playlists = useSelector(selectPlaylists);
    const selectedSequences = useSelector(selectSelectedSequences);

    const [selectedPlaylist, setSelectedPlaylist] = useState<number>(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [orderingSequences, setOrderingSequences] = useState<boolean>(false);

    const applySearchFilter = (value: string) =>  { }

    const addClipsToPlaylist = (playlistName: number) => {
      if (selectedSequences.length === 0) {
        setError("No sequence selected. Please select sequences to add to the playlist.")
        return;
      }
      setSelectedPlaylist(playlistName);
      setOrderingSequences(true);
    }

    const confirmPlaylistSelection = () => {
      if (selectedPlaylist === -1) {
        return;
      }
      setIsLoading(true);
      videoApi.addSequencePlaylist(selectedPlaylist, {events: selectedSequences}).then(() => {
        setIsLoading(false);
        dispatch(DESTROY_SELECTED_SEQUENCE());
        next();
      })
    }

    if (isLoading) {
      return (
        <Container style={{width: '37rem', height: '22.9rem'}}>
          <LoadingLogo src={logo} alt="SportsAI_Logo" />
        </Container>
      );
    }

    if (orderingSequences) {
      return (
          <SequenceConfirmationModal 
            next={() => { setOrderingSequences(false); } }
            cancel={() => { addClipsToPlaylist(-1); setOrderingSequences(false); } }
            playlist_id={selectedPlaylist} 
            closeModal={closeModal}         
          />
      )
    }

    return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ModalTopbar 
              Action={'Add sequence to a playlist'}
              onClose={closeModal}
              showBar={true}
          />
        </Grid>
          <Grid item xs={8}>
              <SearchWrapper>
                  <OutlinedInput
                      classes={searchBarClass}
                      placeholder="Search a playlist..."
                      onChange={(event) => applySearchFilter(event.target.value)}
                      startAdornment={
                          <InputAdornment position="start">
                              <Search style={{ color: LIGHT_GREY }} />
                          </InputAdornment>
                      }
                  />
              </SearchWrapper>
          </Grid>
          <Grid item xs={4}>
              <AddPlaylistWrapper>
                  <Button 
                    className={classes.transparantButton}
                    onClick={newPlaylistCallback}
                  > 
                      <AddIcon/> New playlist
                  </Button>
              </AddPlaylistWrapper>
          </Grid>
      </Grid>
      <Table>
          <TableHeader>
            <div style={{ textAlign: "left" }}>Playlist Name </div>
            <div style={{ textAlign: "left" }}>Created on</div>
            <div style={{ textAlign: "left" }}>Clips</div>
            <div></div>
          </TableHeader>
      {
          playlists.map((playlist, i) => {
              return(
                  < PlaylistInfo
                  key={i}
                  style={
                      i % 2 === 0
                      ? { backgroundColor: "white" }
                      : { backgroundColor: LIGHT_GREY1 }
                  }
                  onClick={() => {}}
                  >
                  <div style={{ textAlign: "left", textTransform: "capitalize"}}>
                    <div style={{ display: "inline-block", textAlign: "left", textTransform: "capitalize", maxWidth: "120px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",  }}>
                      {playlist.name} 
                    </div>
                    {playlist.isnew && <NewPlaylist style={{ display: "inline-block", verticalAlign: "top" }}> NEW </NewPlaylist>}
                  </div>
                  <div style={{ textAlign: "left", textTransform: "capitalize" }}>{playlist.createdOn}</div>
                  <div style={{ textAlign: "left", textTransform: "capitalize" }}>{playlist.clips}</div>

                  {selectedPlaylist !== playlist.id && 
                    <Button 
                      className={classes.transparantButton}
                      onClick={() => addClipsToPlaylist(playlist.id)}
                    >
                      <AddIcon/> Add
                    </Button>
                  }
                  {selectedPlaylist === playlist.id && 
                    <Button 
                      className={classes.transparantButton}
                      style={{color: "green"}}
                    >
                      <CheckIcon/> Added
                    </Button>
                  }
                  </PlaylistInfo>
              )
          })
      }
      </Table>
        {error!==null && 
            <ErrorWrapper>{error}</ErrorWrapper>
        }
      <Grid container spacing={0}>
        <Grid item xs={7}>
          </Grid>
          <Grid item xs={2}>
              <Button 
                  style={{border: "10px", borderColor: LIGHT_GREY, height: "70px"}}
                  className={classes.transparantButton}
                  onClick={closeModal}
              >
                  Cancel
              </Button>
          </Grid>
          <Grid item xs={2}>
              <Button 
                  className={(selectedPlaylist === -1) ? classes.fullGreyButton: classes.fullGreenButton}
                  onClick={confirmPlaylistSelection}
              >
                  <CheckIcon/> Confirm
              </Button>
        </Grid>
      </Grid>
    </>
    )
}

const LoadingLogo = styled.img`
  width: 15rem;
  animation: blinkAndGrow 2s infinite ease;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const Container = styled.div`
  margin: 10rem auto;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchWrapper = styled.div`
    padding: 1rem 0rem 1rem 0rem 
  `;
 
const AddPlaylistWrapper = styled.div`
  width: 90%;
  text-align: center;
  display: inline-block;
  border: 1px solid ${LIGHT_GREY};
  border-radius: 8px;
  padding: 4px;
  margin: 0.9rem 0rem 0rem 0rem;
`;

const Table = styled.div`
  width: 100%;
  margin: 40px 0px;
`;


const NewPlaylist = styled.div`
  background-color: ${LIGHT_BLUE};
  color: ${DARK_BLUE};
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  display: inline-block;
  text-transform: capitalize;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 25% 15% 15%;
  text-align: center;
  align-items: center;
  padding: 10px;
  margin: 0 2rem;
  color: ${GREY};
`;


const PlaylistInfo = styled.div`
  display: grid;
  grid-template-columns: 40% 25% 15% 15%;
  text-align: center;
  align-items: center;
  color: ${black};
  padding: 10px;
  border-radius: 8px;
  margin: 0 2rem;
  > div {
    text-transform: capitalize;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ErrorWrapper = styled.div`
  color: red;
  text-align: center;
  margin: 0rem 0rem 0rem 3rem;
`

