import { Button, ThemeProvider, List, ListItem, ListItemText, makeStyles, Switch, createMuiTheme} from "@material-ui/core";
import styled, {  } from "styled-components";
import { DARK_BLUE, GREY, WHITE, BLACK, LIGHT_GREY1 } from "../../../../../assets/colors";

const useStyles = makeStyles(() => ({
    directionsButton: {
      textAlign: "right",
      flexDirection: 'column',
      color: GREY,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      borderRadius: '20px',
      backgroundColor: WHITE,
      border: "2px solid",
      borderColor: DARK_BLUE,
      padding: "12px 32px 12px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&:hover": {
        backgroundColor: WHITE,
        color:DARK_BLUE,
      },
    },
    directionsButtonClicked: {
      textAlign: "right",
      flexDirection: 'column',
      borderRadius: '20px',
      backgroundColor: DARK_BLUE,
        color:WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&:hover": {
        backgroundColor: DARK_BLUE,
        color:WHITE,
      },
    },
    buttonStat: {
      color: "black",
      backgroundColor: WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      borderRadius: '10px',
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    buttonStatBottom: {
      color: BLACK,
      backgroundColor: WHITE,
      justify: "end",
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      borderTop: "1.5px solid",
      borderColor: DARK_BLUE,
      borderBottomLeftRadius: '20px', 
    borderBottomRightRadius: '20px',
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    buttonSubStat: {
      color: BLACK,
      fontFamily: "Karla Bold",
      justify: "end","&:hover": {
      backgroundColor: WHITE,
      },
    },
    SubStatContainer: {
      backgroundColor: LIGHT_GREY1,
    },
  }));
  
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: DARK_BLUE,
      },
    },
  });
  
export const PassesFilter = ({handleStatsButtonClick, buttonStates}) => {
    const classes = useStyles();
    return(
        <>
        <ThemeProvider theme={theme}>
        <GridPass>
            <div className="container">
              <div className="List">
              <List>
                <ListItem className={classes.buttonStat}
                  key={"allPasses"}
                  onChange={() => handleStatsButtonClick('allPasses')} 
                  button> ALL PASSES 
                  <ListItemText className={classes.SubStatContainer} color="primary" />
                  <Switch color="primary" checked=
                                          {buttonStates["passCompleted"] && buttonStates["passMissed"] &&
                                          buttonStates["passFront"] && buttonStates["passLeft"] && 
                                          buttonStates["passRight"] && buttonStates["passBack"]}/>
                </ListItem>
                <ListItem
                  className={classes.buttonSubStat}
                  key={"passCompleted"}
                  onChange={() => handleStatsButtonClick('passCompleted')} 
                  button> COMPLETED 
                <ListItemText/>
                  <Switch color="primary" checked={buttonStates["passCompleted"]}/>
                </ListItem>
                <ListItem className={classes.buttonSubStat}
                  key={"passMissed"}
                  onChange={() => handleStatsButtonClick('passMissed')} 
                  button> MISSED 
                <ListItemText  />
                  <Switch color="primary" checked={buttonStates["passMissed"]} />
                </ListItem>
             
              <ListItem className={classes.buttonStatBottom}
                  key={"allDirections"}
                  onChange={() => handleStatsButtonClick('allDirections')} 
                  button > ALL DIRECTIONS
                <ListItemText  />
                <Switch color="primary" checked={buttonStates["passFront"] && buttonStates["passLeft"] && buttonStates["passRight"] && buttonStates["passBack"]}/>
              </ListItem> 
              </List>
              </div>
              <div className="Directions">
                    <div className="Front">
                        <Button className={
                            buttonStates["passFront"] === true
                                ? classes.directionsButtonClicked
                                : classes.directionsButton}
                            onClick={() => handleStatsButtonClick('passFront')} >
                          {`Front`}
                        </Button>
                    </div>
                    <div className="Left">
                      <Button className={
                            buttonStates["passLeft"] === true
                                ? classes.directionsButtonClicked
                                : classes.directionsButton}
                            onClick={() => handleStatsButtonClick('passLeft')} 
                      >
                        {`Left`}
                      </Button>
                    </div>
                    <div className="Right">
                    <Button className={
                            buttonStates["passRight"] === true
                                ? classes.directionsButtonClicked
                                : classes.directionsButton}
                            onClick={() => handleStatsButtonClick('passRight')} 
                      >
                        {`Right`}
                      </Button>
                    </div>
                    <div className="Back">
                    <Button className={
                        buttonStates["passBack"] === true
                            ? classes.directionsButtonClicked
                            : classes.directionsButton}
                        onClick={() => handleStatsButtonClick('passBack')} 
                      >
                        {`Back`}
                      </Button>
                    </div>
              </div>
            </div>
            </GridPass></ThemeProvider>
        </>
    )
 }



 const GridPass = styled.div`
 .container {  
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr;
   gap: 0px 0px;
   grid-auto-flow: row;
   grid-template-areas: "List Directions";
   padding: 10px 0 20px 0;
 }

 .List { 
   grid-area: List; 
   border: 1px solid ${DARK_BLUE}; 
   border-radius: 20px;
   margin-left:7%;
   
 }

 .Directions {  
   display: grid;
   grid-template-columns: repeat(4, 1fr); /* Changed to use repeat for easier grid area definition */
   grid-template-rows: repeat(3, 1fr); /* Changed to use repeat for easier grid area definition */
   gap: 0px 0px;
   grid-template-areas: 
     "Front Front Front Front" 
     "Left Left Right Right" /* Combined Right and Left into the same row */
     "Back Back Back Back";
   grid-area: Directions;
 }

 .Front { 
   grid-area: Front; 
   justify-self: center; 
   align-self: end;  
 }

 .Left { 
   grid-area: Left; 
   justify-self: end; 
   align-self: center; 
   margin-right: 3px;
 }

 .Right { 
   grid-area: Right; 
   justify-self: start; 
   align-self: center; 
   margin-left: 3px;
 }

 .Back { 
   grid-area: Back; 
   justify-self: center; 
   align-self: start; 
 }
`;

