import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectCurrentGame } from "../../../slices/gamesSlice";
import { Game } from "../../../models/game";
import FourSectionPieChart from "../../../components/home-page/four-section-pie-chart/four-section-pie-chart";
import BasicStat from "../../../components/game-page/basic-stat/basic-stat";
import GameStatSectionHeader from "../../../components/game-page/game-stat-section-header/game-stat-section-header";
import SingleProgressBar from "../../../components/game-page/progress-bars/single-progress-bar";
import SectionField from "../../../components/game-page/section-field/section-field";
import SuccessRatio from "../../../components/stats-base/success-ratio/success-ratio";

const GameAttacking = () => {
  const game: Game | undefined = useSelector(selectCurrentGame);
  return game ? (
    <Wrapper>
      <GameStatSectionHeader title={"Possession"} />
      <div style={{ marginBottom: "1rem" }}>
        <SingleProgressBar
          title1={"Ball Possession %"}
          percentage1={game.ballPossession} 
        />
      </div>
      <FieldWrapper>
        <SectionField
          title={"Ball Recoveries"}
          data={game.ballRecoveries}
          color={"blue"}
        />
      </FieldWrapper>
      <PossessionEnd>
        <SuccessRatio title={'Dribbles'} successes={game.dribbles.successful} total={game.dribbles.total}/>
        <BasicStat title={'Key Actions'} value={game.keyActions} />
        <BasicStat title={'Offsides'} value={game.offsides.total} />
      </PossessionEnd>
      <GameStatSectionHeader title={"Passes"} />
      <PassStatWrapper>
        <BasicStat title={"passes"} value={game.passTotal} />
        <SingleProgressBar
          title1={"Pass Precision %"}
          percentage1={game.passPrecision} 
        />
        <BasicStat title={"Key Passes"} value={game.keyPasses} />
      </PassStatWrapper>
      <FourSectionPieWrapper>
        <FourSectionPieChart
          title={"Pass orientation ratio (%)"}
          orientation={game.passOrientationRatio}
          successRatio={[]}
        ></FourSectionPieChart>
        <FourSectionPieChart
          title={"Pass success Ratio (%)"}
          orientation={game.passOrientationRatio}
          successRatio={game.passOrientationSuccessRatio}
        ></FourSectionPieChart>
      </FourSectionPieWrapper>
      <CrossStatWrapper>
        <BasicStat title={"corners"} value={game.corners.totalLong + game.corners.totalShort} />
        <SuccessRatio title={'Crosses'} successes={game.crosses.successful} total={game.crosses.total}/>
      </CrossStatWrapper>
      <GameStatSectionHeader title={"Shots"} />
      <ShotStatWrapper>
        <BasicStat title={"shots"} value={game.shots} />
        <BasicStat title={"shots on target"} value={game.shotsOnTarget} />
        <BasicStat title={"penalty shots"} value={game.penaltyShots} />
        <BasicStat title={"direct free kicks"} value={game.directFreeKicks} />
      </ShotStatWrapper>
      <SingleProgressBar
        title1={"Shot Precision %"}
        percentage1={game.shotPrecision} 
      />
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const FourSectionPieWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;
const FieldWrapper = styled.div`
  margin-bottom: 1rem;
`;

const PossessionEnd = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: fit-content;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const PassStatWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  height: fit-content;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const CrossStatWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: fit-content;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const ShotStatWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;
export default GameAttacking;
