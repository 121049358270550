import { SportsSoccer } from "@material-ui/icons";
import { GREY, LIGHT_RED, PURPLE } from "../../../assets/colors";


export const EventPositionIcon = (props) => {
    const { cx, cy, payload } = props;

    const isSpaceOccupied: boolean = payload.id !== payload.dots[0].id;

    let isMultipleTypes = false;
    for (let i = 1; i < payload.dots.length; i++) {
      if (payload.dots[i].type !== payload.dots[i - 1].type) {
        isMultipleTypes = true;
        break;
      }
    }

    return !isSpaceOccupied ? (
      <>
        <circle
          r={15}
          fill="white"
          stroke={GREY}
          strokeWidth={1}
          cx={cx}
          cy={cy}
        />
        <SportsSoccer
          x={cx - 12}
          y={cy - 200}
          width={24}
          style={{
            color: isMultipleTypes ? PURPLE : payload.color,
            backgroundColor: "white",
          }}
        />
        {payload.dots.length > 1 ? (
          <>
            <circle r={8} fill={LIGHT_RED} cx={cx + 12} cy={cy - 14} />
            {payload.dots.length < 10 ? (
              <text x={cx + 9} y={cy - 11.5} fill="white" fontSize={11}>
                {payload.dots.length}
              </text>
            ) : (
              <text x={cx + 7} y={cy - 11} fill="white" fontSize={9}>
                {payload.dots.length}
              </text>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    );
  };
