import * as React from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "./VideoJSPlayer.styles.css";
import "video.js/dist/video-js.css";

interface IVideoPlayerProps {
    options: VideoJsPlayerOptions; // Use VideoJsPlayerOptions instead of videojs.PlayerOptions
    videoRef: React.RefObject<HTMLVideoElement>;
}

const VideoJSPlayer: React.FC<IVideoPlayerProps> = ({ options, videoRef }) => {
    const [, setIsPlaying] = React.useState(true);
    React.useEffect(() => {
        if (videoRef.current) {
            // Initialize Video.js player with the provided options
            const player = videojs(videoRef.current, {
                ...options,
                controls: false,
                fluid: true,
            }).ready(function (this: VideoJsPlayer) {
                // Set volume to 20% when video starts playing
                this.on("play", () => {
                    this.volume(0.2); // 20% volume (0 to 1)
                    setIsPlaying(true);
                });
                this.on("pause", () => {
                    setIsPlaying(false);
                });
                this.controlBar.addChild("button", {
                    text: "-5s",
                    className: "rewindIcon",
                    click: () => {
                        this.currentTime(this.currentTime() - 5);
                    },
                }, 0);
                this.controlBar.addChild("button", {
                    text: "+5s",
                    className: "fast-forward-icon",
                    click: () => {
                        this.currentTime(this.currentTime() + 5);
                    },
                }, 1);
            });

            return () => {
                if (player) {
                    player.dispose();
                }
            };
        }
    }, [options, videoRef]);

    return <video ref={videoRef} className="video-js vjs-default-skin" />
        ;
};

export default VideoJSPlayer;