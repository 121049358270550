import styled from "@emotion/styled";
import { searchBarStyles, sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, LIGHT_GREY1, LIGHT_GREY2 } from "../../../assets/colors";
import { selectUser, setUser } from "../../../slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { teamApi } from "../../../api/teamApi";
import { CompetitionApi } from "../../../api/competition";

const GeneralLeague = () => {
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();

    const user = useSelector(selectUser);
    const competition = user?.competitions[0];
    const dispatch = useDispatch();

    const [name, setName] = useState<undefined|string>(undefined);
    const [logo, setlogo] = useState<undefined|string>(undefined);

    const [newProfilePic, setNewProfilePic] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        setNewProfilePic(file);
        setPreviewUrl(URL.createObjectURL(file));
    }, []);

    const handleConfirm = () => {
        if (newProfilePic && competition) {
            const formData = new FormData();
            formData.append("logo", newProfilePic);
            
            CompetitionApi.updateCompetition(formData).then((response) => {
                if (response.status === 200) {
                    // const updatedUser = { ...competition, profile_url: response.data.profile_url };
                    // dispatch(setUser(upd atedUser));
                    setNewProfilePic(null);
                    setPreviewUrl(null);
                }
            });
        }
    };

    const handleCancel = () => {
        setNewProfilePic(null);
        setPreviewUrl(null);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Wrapper>
          <Title>GENERAL</Title>
          <div {...getRootProps()}>
          <input {...getInputProps()}/>
          <ProfileEntry>
              <ProfileText>Profile Picture</ProfileText>
              <ProfileIconWrapper>
                      <ProfileIcon src={previewUrl || user?.profile_url} />
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                  {newProfilePic ? (
                      <>
                          <UploadButton 
                              className={classes.fullBlueButton}
                              style={{ textTransform: "none"}}
                              onClick={(event) => {event.stopPropagation();handleConfirm()}}
                          >
                              <CheckIcon style={{marginRight: '0.2rem'}}/>
                              Confirm
                          </UploadButton>
                          <Button
                              className={classes.transparantButton}
                              style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                              onClick={handleCancel}
                          >
                              Cancel
                          </Button>
                      </>
                  ) : (
                      <UploadButton 
                          className={classes.fullBlueButton}
                          style={{ textTransform: "none"}}
                          onClick={() => {}}
                      >
                          Upload New
                      </UploadButton>
                  )}
              </UploadButtonWrapper>

          </ProfileEntry>
          </div>
          <ProfileEntry>
              <ProfileText>Competition Name</ProfileText>
              <ProfileIconWrapper>
                {name !== undefined ? (<>
                <OutlinedInput         
                    classes={searchBarClass}
                    style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setName('')}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </>) : 
                (<>{competition?.competition}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                name !== undefined ? (
                <>
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => {
                            if (name !== undefined && name !=='' && user !== undefined)
                                teamApi.updateTeam({name: name}).then((response) => {
                                    if (response.status === 203) {
                                        setUser({...user, team: response.data});
                                        // setTeam(response.data);
                                        // setFirstName(undefined);
                                    }
                                });
                        }}  
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setName(undefined)}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setName(competition?.competition)}
                        >
                            Edit
                        </Button>
                    </>
                )
                }
                  
              </UploadButtonWrapper>              
          </ProfileEntry>
      </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 81.59%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
  padding: 2rem;
`;

const Title = styled.div`
  color: ${DARK_BLUE};
  padding: 2rem 0rem 1rem 0.5rem;
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Antique Olive Nord;
  display: flex;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileEntry = styled.div`
  display: flex;
  align-items: center; 
  padding: 1rem 0rem 3rem 0.5rem;
  font-size: 1.3rem;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center; 
  flex: 1;
`;

const ProfileIcon = styled.img`
  height: 4rem;
  width: 4rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
`;

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  flex: 1; 
  padding-right: 1rem;
`;

const UploadButton = styled(Button)`
  margin-left: auto;
  height: 2.2rem;
  padding: 0rem 0.7rem 0rem 0.7rem;
`;
export default GeneralLeague;
