import axiosInstance from "./axios";
import { ENDPOINT } from "./config";

export interface AuthorizeUserData {
  username: string;
  password: string;
}

export interface RegisterUserData {
  username: string;
  email: string;
  password: string;
}

export interface UpdateUserData {
  username?: string;
  email?: string;
  password?: string;
  oldPassword?: string;
}
export const authApi = {
  login: async (userData: AuthorizeUserData) =>
    axiosInstance.post(ENDPOINT.LOGIN, userData),
  logout: async () => axiosInstance.post(ENDPOINT.LOGOUT),
  getUser: async () => axiosInstance.get(ENDPOINT.GET_USER),
  register: async (userData: RegisterUserData) => axiosInstance.post(ENDPOINT.REGISTER, userData),
  updateUser: async (userData: UpdateUserData) => axiosInstance.put(ENDPOINT.UPDATE_USER, userData),
  collect: async (data : {activity: number, feature: number}) => axiosInstance.post(ENDPOINT.COLLECT, data),
};
