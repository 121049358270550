import { Grid } from "@material-ui/core";
import styled from "styled-components";
import BasicStat from "../../components/game-page/basic-stat/basic-stat";
import RatioPieChart from "../../components/game-page/ratio-pie-chart/ratio-pie-chart";
import FourSectionPieChart from "../../components/home-page/four-section-pie-chart/four-section-pie-chart";
import SectionField from "../../components/game-page/section-field/section-field";
import { LIGHT_GREY1, LIGHT_GREY, WHITE } from "../../assets/colors";
import SuccessRatio from "../../components/stats-base/success-ratio/success-ratio";
import ProgressBar from "../../components/stats-base/progress-bars/progress-bar-compare";

const PlayerProfileAttacking = ({ player, sizeFilterButtons }) => {
  if (player.position === "Goalkeeper") {
    return (
      <Wrapper>
        <FiltersWrapper>
          <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
        </FiltersWrapper>
        <HR></HR>
        <SectionHeader>Passes</SectionHeader>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FourSectionPieChart
              title={"Pass Orientation (%)"}
              orientation={player.passOrientation}
              successRatio={[]}
            />
          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                display: "grid",
                flexDirection: "column",
                gridTemplateRows: "1fr 2fr",
                width: "100%",
                height: "100%",
                gap: "1rem",
              }}
            >
              <BasicStat
                title={"Key Passes"}
                value={player.keyPasses}
              />
              <SuccessRatio 
                title={"Passes"} 
                successes={player.successfulPasses} 
                total={player.totalPasses}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"Assists"} value={player.assists} />
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"Second Assists"} value={player.secondAssists} />
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"Crosses"} value={player.crosses} />
          </Grid>
          <Grid item xs={12}>
            <SummaryWrapper>
              <ProgressBar teamAStat={30} teamBStat={20} title={"GoalKicks"} scale={50}/>
            </SummaryWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
      </FiltersWrapper>
      <HR></HR>
      <SectionHeader>Shots</SectionHeader>
      <Grid container spacing={2}>
        <Grid item container spacing={2} xs={6}>
          <Grid item xs={4}>
            <BasicStat title={"Shots"} value={player.shots} />
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"Goals"} value={player.goals} />
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"xG"} value={player.xg} />
          </Grid>
          <Grid item xs={6}>
            <BasicStat title={"Penalty shots"} value={player.penalties} />
          </Grid>
          <Grid item xs={6}>
            <BasicStat
              title={"Direct free-kicks"}
              value={player.directFreeKicks}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <RatioPieChart
            title={"Shots"}
            ratio={player.shotPrecision / 100}
          />
        </Grid>
        <Grid item xs={3}>
          <SuccessRatio
            title={"Dribbles"}
            successes={player.dribblesSuccess}
            total={player.dribbles}
          />
        </Grid>
      </Grid>
      <HR></HR>
      <SectionHeader>Passes</SectionHeader>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <FourSectionPieChart
            title={"Pass Orientation (%)"}
            orientation={player.passOrientation}
            successRatio={[]}
          />
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: "grid",
              flexDirection: "column",
              gridTemplateRows: "1fr 2fr",
              width: "100%",
              height: "100%",
              gap: "1rem",
            }}
          >
            <BasicStat
              title={"Key Passes"}
              value={player.keyPasses}
            />
            <SuccessRatio 
              title={"Passes"} 
              successes={player.successfulPasses} 
              total={player.totalPasses}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <BasicStat title={"Assists"} value={player.assists} />
        </Grid>
        <Grid item xs={4}>
          <BasicStat title={"Second Assists"} value={player.secondAssists} />
        </Grid>
        <Grid item xs={4}>
          <BasicStat title={"Crosses"} value={player.crosses} />
        </Grid>
      </Grid>
      <HR></HR>
      <SectionHeader>Possession</SectionHeader>
      <SectionField
        title={"ball recoveries"}
        data={player.ballRecoveries}
        color={"blue"}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const SectionHeader = styled.div`
  padding-right: 10px;
  width: fit-content;
  background-color: ${LIGHT_GREY1};
  margin-bottom: 1rem;
`;
const HR = styled.div`
  height: 2px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: -0.7rem;
  background-color: ${LIGHT_GREY};
`;

const SummaryWrapper = styled.div`
    background-color: ${WHITE};
    border-radius: 10px;
    border: 2px solid ${LIGHT_GREY};
    padding: 1rem 0.5rem;
    width:100%;
`;

export default PlayerProfileAttacking;
