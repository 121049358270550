import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY } from "../../../assets/colors";

const BasicStat = ({ title, value }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {value !== undefined ? <Stat>{value}</Stat> : <NoDataWrapper>No Data</NoDataWrapper>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  padding: 1rem 0;
  min-height: 4rem;
`;
const NoDataWrapper = styled.div`
  font-size: 1.2rem;
  color: ${GREY};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const Title = styled.div`
  width: 100%;
  text-align: center;
`;

const Stat = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
  color: ${DARK_BLUE};
`;

export default BasicStat;
