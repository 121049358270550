import styled from "@emotion/styled";
import { searchBarStyles, sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, LIGHT_GREY1, LIGHT_GREY2, WHITE } from "../../../assets/colors";
import { selectUser, setUser } from "../../../slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button, CircularProgress, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { playerApi } from "../../../api/playerApi";

const GeneralPlayer = () => {
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState<undefined|string>(undefined);
    const [lastName, setLastName] = useState<undefined|string>(undefined);
    const [birthDate, setBirthDate] = useState<undefined|string>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [newProfilePic, setNewProfilePic] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        setNewProfilePic(file);
        setPreviewUrl(URL.createObjectURL(file));
    }, []);

    const handleConfirm = () => {
        if (newProfilePic && user) {
            setLoading(true);
            const formData = new FormData();
            if (user?.player?.id){
                formData.append("id", user.player.id.toString());
                formData.append("profileFile", newProfilePic);
                playerApi.postPlayer(formData).then((response) => {
                    if (response.status === 201) {
                        const updatedUser = { ...user, profile_url: response.data.profile_url };
                        dispatch(setUser(updatedUser));
                        setNewProfilePic(null);
                        setPreviewUrl(null);
                    }
                }).finally(() => setLoading(false));
            }

        }
    };

    const handleCancel = () => {
        setNewProfilePic(null);
        setPreviewUrl(null);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Wrapper>
          <Title>GENERAL</Title>
          <div {...getRootProps()}>
          <input {...getInputProps()}/>
          <ProfileEntry>
              <ProfileText>Profile Picture</ProfileText>
              <ProfileIconWrapper>
                      <ProfileIcon src={previewUrl || user?.profile_url} />
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                  {newProfilePic ? (
                      <>
                         { loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                          <UploadButton 
                              className={classes.fullBlueButton}
                              style={{ textTransform: "none"}}
                              onClick={(event) => {event.stopPropagation();handleConfirm()}}
                          >
                              <CheckIcon style={{marginRight: '0.2rem'}}/>
                              Confirm
                          </UploadButton>
                          <Button
                              className={classes.transparantButton}
                              style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                              onClick={handleCancel}
                          >
                              Cancel
                          </Button>
                      </>
                  ) : (
                      <UploadButton 
                          className={classes.fullBlueButton}
                          style={{ textTransform: "none"}}
                          onClick={() => {}}
                      >
                          Upload New
                      </UploadButton>
                  )}
              </UploadButtonWrapper>

          </ProfileEntry>
          </div>
          <ProfileEntry>
              <ProfileText>First Name</ProfileText>
              <ProfileIconWrapper>
                {firstName !== undefined ? (<>
                <OutlinedInput         
                    classes={searchBarClass}
                    style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setFirstName('')}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </>) : 
                (<>{user?.player?.firstName}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                firstName !== undefined ? (
                <>
                    { loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => {
                            if (firstName !== undefined && firstName !=='' && user !== undefined)
                                playerApi.postPlayer({id: user.player?.id, firstName: firstName}).then((response) => {
                                    if (response.status === 201) {
                                        if (user?.player)
                                            setUser({
                                                ...user,
                                                player: {
                                                    ...user.player,
                                                    firstName: firstName
                                                }
                                            });
                                        setFirstName(undefined);
                                    }
                                });
                        }}  
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setFirstName(undefined)}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setFirstName(user?.player?.firstName)}
                        >
                            Edit
                        </Button>
                    </>
                )
                }
                  
              </UploadButtonWrapper>              
          </ProfileEntry>
          <ProfileEntry>
              <ProfileText>Last Name</ProfileText>
              <ProfileIconWrapper>
                {lastName !== undefined ? (<>
                <OutlinedInput         
                    classes={searchBarClass}
                    style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setLastName('')}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </>) : 
                (<>{user?.player?.lastName}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                lastName !== undefined ? (
                <>
                    { loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => {
                            if (setLastName !== undefined && lastName !=='' && user !== undefined && lastName.length <= 3){
                                setLoading(true);
                                playerApi.postPlayer({id: user.player?.id, lastName: lastName}).then((response) => {
                                    if (response.status === 201) {
                                        if (user?.player)
                                            setUser({
                                                ...user,
                                                player: {
                                                    ...user.player,
                                                    lastName: lastName
                                                }
                                            });
                                        setLastName(undefined);
                                    }
                                }).finally(() => setLoading(false));
                            }
                        }}  
                        disabled={loading}
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setLastName(undefined)}
                            disabled={loading}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setLastName(user?.player?.lastName)}
                        >
                            Edit
                        </Button>
                    </>
                )
                }
              </UploadButtonWrapper>              
          </ProfileEntry>

          <ProfileEntry>
              <ProfileText>Birth Date</ProfileText>
              <ProfileIconWrapper>
                {birthDate !== undefined ? (<>
                    <StyledDateInput
                        style={{ marginLeft: '1.9rem' }}
                        type="date"
                        id="dateInput"
                        value={birthDate}
                        onChange={(event) => setBirthDate(event.target.value)}
                    />
                </>) : 
                (<>{user?.player?.birthDate}</>)}
              </ProfileIconWrapper>
              
              <UploadButtonWrapper>
                {
                birthDate !== undefined ? (
                <>
                    { loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => {
                            if (birthDate !== undefined && user !== undefined){
                                setLoading(true);
                                playerApi.postPlayer({id: user.player?.id, birthDate: birthDate}).then((response) => {
                                    if (response.status === 201) {
                                        if (user?.player){
                                            dispatch(setUser({
                                                ...user,
                                                player: {
                                                    ...user.player,
                                                    birthDate: birthDate
                                                }
                                            }))
                                        }
                                        setBirthDate(undefined);
                                    }
                                }).finally(() => setLoading(false));
                            }

                        }}  
                        disabled={loading}
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setBirthDate(undefined)}
                            disabled={loading}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setBirthDate(user?.player?.birthDate)}
                        >
                            Edit
                        </Button>
                    </>
                )
                }
              </UploadButtonWrapper>              
          </ProfileEntry>

          <ProfileEntry>
              <ProfileText>Position</ProfileText>
              <ProfileIconWrapper style={{marginRight: '32.7rem'}}>
                {user?.player?.position}
              </ProfileIconWrapper>             
          </ProfileEntry>

          <ProfileEntry>
              <ProfileText>Number</ProfileText>
              <ProfileIconWrapper style={{marginRight: '32.7rem'}}>
                {user?.player?.number}
              </ProfileIconWrapper>             
          </ProfileEntry>
      </Wrapper>
    );
}

const StyledDateInput = styled.input`
  width: 10rem;
  height: 2.2rem;
  border-radius: 0.5rem;
  border: 1px solid ${LIGHT_GREY0};
  padding: 0rem 0.5rem 0rem 0.5rem;
  font-size: 1rem;
  font-family: "Karla", sans-serif;
  background-color: ${WHITE};
  
  &:focus {
    outline: none;
    border-color: ${DARK_BLUE};
    box-shadow: 0 0 5px ${DARK_BLUE};
  }
`;

const Wrapper = styled.div`
  width: 81.59%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
  padding: 2rem;
`;

const Title = styled.div`
  color: ${DARK_BLUE};
  padding: 2rem 0rem 1rem 0.5rem;
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Antique Olive Nord;
  display: flex;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileEntry = styled.div`
  display: flex;
  align-items: center; 
  padding: 1rem 0rem 2.6rem 0.5rem;
  font-size: 1.3rem;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center; 
  flex: 1;
`;

const ProfileIcon = styled.img`
  height: 4rem;
  width: 4rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
`;

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  flex: 1; 
  padding-right: 1rem;
`;

const UploadButton = styled(Button)`
  margin-left: auto;
  height: 2.2rem;
  padding: 0rem 0.7rem 0rem 0.7rem;
`;
export default GeneralPlayer;
