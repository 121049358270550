import SideBar from "../components/navigation/sidebar";
import styled from "styled-components";
import { AuthenticationRouteProps } from "../route/Route";
import { useSelector } from "react-redux";
import { Page, selectPage, selectShowSequence, setShowSequenceModal } from "../slices/navigationSlice";
import { Dialog } from "@mui/material";
import { SequenceVideoModal } from "./sequenceVideoModal/SequenceVideoModal";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../app/store";

const Workspace = ({ component: Component }: AuthenticationRouteProps) => {
  var showSidebar = useSelector(selectPage) !== Page.League;
  const dispatch = useAppDispatch();
  const showSequence = useSelector(selectShowSequence);
  const [isSequenceVideoModalOpen, setIsSequenceVideoModalOpen] = useState(showSequence);

  useEffect(() => {
    setIsSequenceVideoModalOpen(showSequence);
  }, [showSequence]);

  
  const handleClose = (prop) => {
    dispatch(setShowSequenceModal(false));
    setIsSequenceVideoModalOpen(false);
  }

  return (
    <Wrapper>
      <Dialog 
          PaperProps={{
              style: {
                  borderRadius: "1em",
                  maxWidth: "none",
              },
          }}        
      open={isSequenceVideoModalOpen}>
        <SequenceVideoModal setIsCreateGameComp={handleClose}/>
      </Dialog>
      {showSidebar ? <SideBar/> : <></> }
      <Component/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;

export default Workspace;
