import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  OutlinedInput,
  TextareaAutosize,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { DARK_BLUE, GREY } from "../../assets/colors";
import emailjs from "@emailjs/browser";
import { createRef, LegacyRef, useState } from "react";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
  textarea: {
    marginTop: "1rem",
    padding: "0.5rem",
    width: "100%",
    maxWidth: "calc(100% - 1.2rem)",
    minWidth: "calc(100% - 1.2rem)",
    minHeight: "10rem",
    maxHeight: "30vh",
    fontFamily: "Karla Regular",
    fontSize: "1rem",
    overflowY: "scroll",
  },
}));

const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    margin: "1rem 0",
    height: "3rem",
    fontFamily: "Karla Regular",
    "& $notchedOutline": {
      borderColor: "black",
    },
    "&$focused $notchedOutline": {
      borderColor: "black",
      border: "1px solid",
    },
  },
  focused: {},
  notchedOutline: {},
}));

const ContactUsModal = ({ setState }) => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const classes = useStyles();
  const form: LegacyRef<HTMLFormElement> | undefined = createRef();
  const [fields, setFields] = useState({});

  const handleChange = (e, field) => {
    fields[field] = e.target.value;
    setFields(fields);
  };

  const isFormValid = () =>
    typeof fields["name"] !== "undefined" &&
    typeof fields["email"] !== "undefined" &&
    typeof fields["team"] !== "undefined" &&
    typeof fields["message"] !== "undefined";

  const sendEmail = (e) => {
    e.preventDefault();
    if (form.current !== null && isFormValid()) {
      emailjs
        .sendForm(
          "service_ahbumo3",
          "template_7zn08z9",
          form.current,
          "5uKiJIVfgAE6VQTHt"
        )
        .then(
          (result) => {
            alert(`Email has be sent`);
          },
          (error) => {
            console.log(error.text);
          }
        );
      setState(false);
    } else {
      alert("Form is incomplete");
    }
  };

  return (
    <form noValidate onSubmit={sendEmail} ref={form} id="form">
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <DialogTitle>Contact Us</DialogTitle>
          <IconButton
            style={{ padding: "0" }}
            size="small"
            onClick={() => setState(false)}
          >
            <Close />
          </IconButton>
        </div>
        <DialogContentText>
          Please enter your personal information and a message explaining your
          situation and we will make sure to contact you as soon as possible!
        </DialogContentText>
        <OutlinedInput
          classes={outlinedInputClasses}
          fullWidth
          id="name"
          name="name"
          placeholder="Name"
          autoFocus
          required
          type="text"
          value={fields["name"]}
          onChange={(e) => handleChange(e, "name")}
        />
        <OutlinedInput
          classes={outlinedInputClasses}
          fullWidth
          id="team"
          name="team"
          placeholder="Team"
          autoFocus
          required
          type="text"
          value={fields["team"]}
          onChange={(e) => handleChange(e, "team")}
        />
        <OutlinedInput
          classes={outlinedInputClasses}
          fullWidth
          id="email"
          name="email"
          placeholder="Email"
          autoFocus
          required
          type="email"
          value={fields["email"]}
          onChange={(e) => handleChange(e, "email")}
        />
        <OutlinedInput
          classes={outlinedInputClasses}
          fullWidth
          id="phone"
          name="phone"
          placeholder="Phone Number"
          type="text"
          value={fields["phone"]}
          onChange={(e) => handleChange(e, "phone")}
        />
        <TextareaAutosize
          id="message"
          name="message"
          placeholder="Write your message here"
          value={fields["message"]}
          className={classes.textarea}
          onChange={(e) => handleChange(e, "message")}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ fontFamily: "Karla Bold" }}
          onClick={() => setState(false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          style={{
            backgroundColor: DARK_BLUE,
            color: "white",
            fontFamily: "Karla Bold",
          }}
        >
          Send
        </Button>
      </DialogActions>
    </form>
  );
};

const DialogTitle = styled.div`
  font-family: Karla Bold;
  font-size: 1.5rem;
`;
const DialogContentText = styled.div`
  font-family: Karla Regular;
  color: ${GREY};
`;

export default ContactUsModal;
