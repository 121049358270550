import styled from "@emotion/styled";
import { searchBarStyles, sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, LIGHT_GREY1, LIGHT_GREY2 } from "../../../assets/colors";
import { selectUser, setUser } from "../../../slices/userSlice";
import { useSelector } from "react-redux";
import { Button, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { authApi } from "../../../api/authApi";
import { useAppDispatch } from "../../../app/store";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const ProfileSecurity = () => {
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();
    const dispatch = useAppDispatch();

    const user = useSelector(selectUser);

    const [email, setEmail] = useState<undefined|string>(undefined);
    const [userName, setUserName] = useState<undefined|string>(undefined);
    const [editPassword, setEditPassword] = useState<boolean>(false);
    const [oldpassword, setOldPassword] = useState<undefined|string>(undefined);
    const [password, setPassword] = useState<undefined|string>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);


    const [typePassword, setTypepassword] = useState<string>('password');
    const [typeOldPassword, setTypeOldPassword] = useState<string>('password');

    const confirmChange = (field: 'email' | 'username' | 'password') => {
        if (field === 'email' && email !== undefined) {
            authApi.updateUser({email: email}).then(() => {
                dispatch(setUser({
                    ...user!,
                    email: email
                }));
                setEmail(undefined);
            });
        } else if (field === 'username' && userName !== undefined) {
            authApi.updateUser({username: userName}).then(() => {
                dispatch(setUser({
                    ...user!,
                    username: userName
                }));
                setUserName(undefined);
            });
        } else if (field === 'password' && password !== undefined) {
            authApi.updateUser({password: password, oldPassword: oldpassword}).then(() => {
                setErrorMessage(undefined);
                setPassword(undefined);
                setOldPassword(undefined);
                setEditPassword(false);
            }).catch((error) => {
                setErrorMessage(error.response?.data?.error?.message);
            });
        }
    }
    
    return (
      <Wrapper>
          <Title>
              SECURITY
          </Title>
          <ProfileEntry>
              <ProfileText>Email Address</ProfileText>
              <ProfileIconWrapper>
                {email !== undefined ? (<>
                <OutlinedInput         
                    classes={searchBarClass}
                    style={{ width: "20rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setEmail('')}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </>) : 
                (<>{user?.email}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                email !== undefined ? (
                <>
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => confirmChange('email')}  
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setEmail(undefined)}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setEmail(user?.email)}
                        >
                            Update
                        </Button>
                    </>
                )
                }
                  
              </UploadButtonWrapper>              
          </ProfileEntry>
          <ProfileEntry>
              <ProfileText>Username</ProfileText>
              <ProfileIconWrapper>
                {userName !== undefined ? (<>
                <OutlinedInput         
                    classes={searchBarClass}
                    style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                    value={userName}
                    onChange={(event) => setUserName(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setUserName('')}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </>) : 
                (<>{user?.username}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                userName !== undefined ? (
                <>
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => confirmChange('username')}  
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setUserName(undefined)}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setUserName(user?.username)}
                        >
                            Update
                        </Button>
                    </>
                )
                }
              </UploadButtonWrapper>              
          </ProfileEntry>
          <ProfileEntry>
              <ProfileText>Password</ProfileText>
              <ProfileIconWrapper>
                {editPassword ? (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '0.5rem', display: 'block' }}>Old Password</label>
                            <OutlinedInput         
                                classes={searchBarClass}
                                style={{ width: "100%", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                                value={oldpassword}
                                type={typeOldPassword}
                                onChange={(event) => setOldPassword(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear input"
                                            onClick={() => setTypeOldPassword(typeOldPassword === "password" ?  'none':  'password')}
                                            edge="end"
                                        >
                                            <RemoveRedEyeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div>
                            <label style={{ marginBottom: '0.5rem', display: 'block' }}>New Password</label>
                            <OutlinedInput         
                                classes={searchBarClass}
                                style={{ width: "100%", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                                value={password}
                                type= {typePassword}
                                onChange={(event) => setPassword(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear input"
                                            onClick={() => setTypepassword(typePassword === "password" ?  'none':  'password')}
                                            edge="end"
                                        >
                                            <RemoveRedEyeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div>
                            <ErrorMessage>{errorMessage}</ErrorMessage>
                        </div>
                    </div>) : 
                (<> .... </>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                editPassword ? (
                <>
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => confirmChange('password')}  
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => {setEditPassword(false); setOldPassword(undefined); setPassword(undefined)}}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 0rem 0rem 0rem"}}
                            onClick={() => setEditPassword(true)}
                        >
                            Update
                        </Button>
                    </>
                )
                }
              </UploadButtonWrapper>  
                        
          </ProfileEntry>
      </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 82%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
  padding: 2rem;
`;

const Title = styled.div`
  color: ${DARK_BLUE};
  padding: 2rem 0rem 1rem 0.5rem;
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Antique Olive Nord;
  display: flex;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileEntry = styled.div`
  display: flex;
  align-items: center; 
  padding: 1rem 0rem 3rem 0.5rem;
  font-size: 1.3rem;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center; 
  flex: 1;
`;

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  flex: 1; 
  padding-right: 1rem;
`;

const UploadButton = styled(Button)`
  margin-left: auto;
  height: 2.2rem;
  padding: 0rem 0.7rem 0rem 0.7rem;
`;
const ErrorMessage = styled.div`
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    `;
export default ProfileSecurity;
