import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY } from "../../../assets/colors";

const OccurancesBox = ({ title, value }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {value ? <Info>{value}</Info> : <NoDataWrapper>No Data</NoDataWrapper>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 25px 25px 0px 25px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Info = styled.div`
  font-size: 2rem;
  color: ${DARK_BLUE};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export default OccurancesBox;
