import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Stats, initialButtonStates } from '../features/library/sequences/statModal/initStatSelection/initStatSelection';

export interface SequencesState {
  selectedStats: Stats;
  selectedGames: number[];
  selectedPlayers: number[];
}

const initialState: SequencesState = {
  selectedStats: initialButtonStates,
  selectedGames: [],
  selectedPlayers: [],
};

export const sequencesSlice = createSlice({
  name: 'sequences',
  initialState,
  reducers: {
    updateSelectedStats: (state, action: PayloadAction<Stats>) => {
      state.selectedStats = action.payload;
    },
    updateSelectedGames: (state, action: PayloadAction<number[]>) => {
      state.selectedGames = action.payload;
    },
    updateselectedPlayers: (state, action: PayloadAction<number[]>) => {
      state.selectedPlayers = action.payload;
    },
  },
});

export const {
  updateSelectedStats,
  updateSelectedGames,
  updateselectedPlayers,
} = sequencesSlice.actions;

export default sequencesSlice.reducer;
