import axiosInstance from "./axios";
import { ENDPOINT } from "./config";

export const videoApi = {
  getmatchVideos: async (team_id: number) => axiosInstance.get(`${ENDPOINT.VIDEO}?team_id=${team_id}`),
  getEventInformation: async (team_id, data) => axiosInstance.post(`${ENDPOINT.EVENT}?team_id=${team_id}`,data),
  getPlaylists: async () => axiosInstance.get(`${ENDPOINT.PLAYLIST_GET}`),
  createPlaylist: async (playlist: any) => axiosInstance.post(`${ENDPOINT.PLAYLIST_CREATE}`, playlist),
  addSequencePlaylist: async (playlistId: number, data: any) => axiosInstance.post(`${ENDPOINT.PLAYLIST_COMPUTE}/${playlistId}/`, data),
  deleteSequencePlaylist: async (data: any) => axiosInstance.post(`${ENDPOINT.PLAYLIST_DELETE}`, data)
};