import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY, LIGHT_GREY1, LIGHT_GREY2 } from "../../../assets/colors";
import { selectTeam } from "../../../slices/teamSlice";
import { useSelector } from "react-redux";

export interface TableElement {
    key: string;
    value: number;
    rank?: number;
    games?: number;
    team: string;
    logo?: string;
    position?: string;
}

const RankingTable = ({ title, elements, onSelect }: { title: string, elements: TableElement[], onSelect: any }) => {
  const currentTeam = useSelector(selectTeam).name;
  console.log(currentTeam);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Table>
        {elements.map((element, index) => (
          <TableRow key={element.key} isEven={index % 2 === 0}>
            <TableCell>{element.rank}</TableCell>
            <TableCell>
              <PlayerInfo>
                {element.logo && <Logo src={element.logo} alt={`${element.team} logo`} />}
                <div>
                  <PlayerName isCurrentTeam={element.team === currentTeam}>
                    {element.key}
                  </PlayerName>
                  <TeamName>
                    {element.team}
                  </TeamName>
                </div>
              </PlayerInfo>
            </TableCell>
            <TableCell>{element.value}</TableCell>
          </TableRow>
        ))}
      </Table>
      <ViewButton onClick={() => onSelect()}>Show all</ViewButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1 1 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 25px 25px 0px 25px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  margin: 10px;
`;

const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 20px;
`;

const TableRow = styled.tr<{ isEven: boolean }>`
  background-color: ${props => (props.isEven ? LIGHT_GREY1 : 'white')};
`;

const TableCell = styled.td`
  padding: 8px;
  height: 40px;
`;

const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
`;

const PlayerName = styled.div<{ isCurrentTeam: boolean }>`
  font-weight: bold;
  color: ${props => (props.isCurrentTeam ? DARK_BLUE : 'black')};
`;

const TeamName = styled.div`
  font-size: 12px;
  color: ${GREY};
`;

const ViewButton = styled.button`
  margin: 15px 0px 10px;
  background-color: white;
  color: ${DARK_BLUE};
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${LIGHT_GREY2};
  }
`;

export default RankingTable;
