import styled from "styled-components";
import { LIGHT_GREY, WHITE } from "../../assets/colors";
import ModalTopbar from "../../components/navigation/modalTopbar";
import { useSelector } from "react-redux";
import { SequencesType, selectSelequencesParams } from "../../slices/navigationSlice";
import VideoPlayer from "../../components/video/videoPlayer/VideoPlayer";
import { useEffect, useRef, useState } from "react";
import { selectGamesSummary } from "../../slices/gamesSlice";
import SequenceCard from "../../components/video/sequenceCard";
import { selectSelectedSequences, updateSelectedSequence } from "../../slices/videoSlice";
import { useAppDispatch } from "../../app/store";
import { Sequence } from "../../models/game";
import { videoApi } from "../../api/video";
import buileventRequest from "../../services/processFilter";
import { selectTeamId } from "../../slices/teamSlice";
import { Stats, initialButtonStates } from "../library/sequences/statModal/initStatSelection/initStatSelection";

export const SequenceVideoModal = ({setIsCreateGameComp}) => {
    const dispatch = useAppDispatch();
    const teamId = useSelector(selectTeamId);
    const games = useSelector(selectGamesSummary);
    const params = useSelector(selectSelequencesParams);

    const [, setSelectedSceneIndex] = useState(0);
    const [currentPage,] = useState(1);
    const [selectedScene, setSelectedScene] = useState<Sequence | null>(null);
    const [videoList, setVideoList] = useState([]);

    const selectedSequence = useSelector(selectSelectedSequences);

    const getSequences = (params: {sequenceType: SequencesType, sequenceOptions: string[]}, teamId: number) => {
        var players = [];
        var games: number[] = [];
        var stats: Stats = {...initialButtonStates}
        if (params.sequenceType === SequencesType.GoalsScored) {
            params.sequenceOptions.map((option) => {
                return parseInt(option)
            });
            stats.goals = true
            games = params.sequenceOptions.map((option) => {
                return parseInt(option)
            });
        }
        
        if (params.sequenceType === SequencesType.Shots) {
            stats.shots = true
        }
        if (params.sequenceType === SequencesType.PenaltyShots) {
            stats.penalty = true
        }
        videoApi.getEventInformation(teamId, buileventRequest(stats, players, games)).then((res) => {
            console.log(res.data);
            setVideoList(res.data)
        }).catch((err) => {
            return 0
        });
    }

    useEffect(() => {
        getSequences(params, teamId!);
    }, [params, teamId]);

    const [loopIntervalDuration, setLoopIntervalDuration] = useState(20);
    const [loopInterval, setLoopInterval] = useState<NodeJS.Timeout | null>(null);


    const delay = loopIntervalDuration/2;
    const videoRef = useRef<HTMLVideoElement>(null);
    const videosPerPage = 8;
    // const totalPages = Math.ceil(0/ videosPerPage);
    const slicedVideos: Sequence[] = videoList.slice((currentPage - 1) * videosPerPage, currentPage * videosPerPage);


    const handleJumpToTime = (time, matchID) => {
        if (videoRef.current && time > 0 ) {
          let videoLink = games.find(game => game.id === matchID)?.video_link
          if (videoLink) {
            if (videoRef.current.src !== videoLink) {
              videoRef.current.src = videoLink;
              videoRef.current.load();
            }
          }
          videoRef.current.currentTime = time - delay;
          if (loopInterval) {
            clearInterval(loopInterval);
          }
          const newLoopInterval = setInterval(() => {
            if (videoRef.current ) {
              if (videoRef.current.currentTime >= time + delay) {
                videoRef.current.currentTime = time - delay;
              }
            }
          }, 1000 / loopIntervalDuration);
          setLoopInterval(newLoopInterval);
          return () => {
            clearInterval(newLoopInterval);
          };
        }
    };
    
    const handleCardClick = (index, sequence) => {
      setSelectedSceneIndex(index);
      setSelectedScene(sequence);
    }
    setIsCreateGameComp(false);
    return (
        <Wrapper>
            <ModalTopbar Action={"Sequences"} onClose={() => { setIsCreateGameComp(false) }}/>
            <VideoControl> 
                <VideoPlayerGrid>
                    <VideoPlayer
                            selectedScene={selectedScene}
                            handleJumpTime={handleJumpToTime}
                            handleLoopInterval={setLoopIntervalDuration}
                            loopIntervalDuration={loopIntervalDuration}
                            videoRef={videoRef}
                        />
                </VideoPlayerGrid>
            <SceneList>
                {slicedVideos.map((video, i) => {
                        return video.event ? (
                        <SequenceCard
                            sequence={video} 
                            index={i} 
                            isSelected={video.id === selectedScene?.id} 
                            isPlaylistSelected={selectedSequence.includes(video.id)}
                            onCardClick={(index, video) => handleCardClick(index, video)} 
                            onCheckboxClick={(id) => {console.log(id);setSelectedScene(video);}} 
                        />
                      ) : (
                      <></>
                      );
                  })}
            </SceneList>
            </VideoControl>
        </Wrapper>
    )
}

const Wrapper = styled.div` 
    border: 3px solid ${LIGHT_GREY};
    border-radius: 1rem;
    width: 73rem;
    height: 36rem;
    background-color: ${WHITE};
    z-index: 1000;
    top: 0;
    left: 0;
    overflow-y: auto;
`;  

const VideoControl = styled.div`
width: 98%;
margin-top: 1px;
height: 90%;
display: grid; 
  grid-template-columns: 1fr 1fr 0.6fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "VideoPlayerGrid VideoPlayerGrid SceneList"
    "VideoPlayerGrid VideoPlayerGrid SceneList"
    "VideoPlayerGrid VideoPlayerGrid SceneList"; 
  grid-area: VideoControl; 
  max-height:98%; 
  box-sizing: border-box; 
`;

const VideoPlayerGrid = styled.div`
  grid-area: VideoPlayerGrid; 
  height: 100%; 
  overflow: hidden;
`;

const SceneList = styled.div`
grid-area: SceneList;
width: 98%;
overflow-y: auto; 
`;