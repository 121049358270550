import styled from "styled-components";
import CheckIcon from '@mui/icons-material/Check';
import { DARK_BLUE, LIGHT_GREY } from "../../../../assets/colors";
import ModalTopbar from "../../../../components/navigation/modalTopbar";
import { Grid } from "@material-ui/core";

export const PlaylistModalConfirm = ({ closeModal }) => {
    return (
    <Grid container spacing={0}>
        <Grid item xs={12}>
          <ModalTopbar 
              Action={'Add sequence to a playlist'}
              onClose={closeModal}
              showBar={true}
          />
        </Grid>
        <Container>
            <CheckCircle>
                <CheckIcon style={{ fontSize: "5rem", color: "green" }} />
            </CheckCircle>
            <SuccessText>Success!</SuccessText>
            <InfoText>You successfully added your sequence(s) to the desired playlist(s).</InfoText>
            <CloseButton onClick={closeModal}>Close</CloseButton>
        </Container>
    </Grid>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    max-width: 320px;
`;

const CheckCircle = styled.div`
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2); 
    margin-top: 10px auto;
`;

const SuccessText = styled.div`
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
`;

const InfoText = styled.div`
    text-align: center;
    color: grey; /* Set text color to grey */
    margin-top: 10px;
`;

const CloseButton = styled.button`
    font-weight: bold;
    background-color: transparent;
    border: 1.5px solid ${LIGHT_GREY};
    border-radius: 10px;
    cursor: pointer;
    color: ${DARK_BLUE};
    margin-top: 25px;
    width: 100%;
    padding: 8px;
`;