import axiosInstance from "./axios";
import { ENDPOINT } from "./config";

export const playerApi = {
  getTeamSquad: async (teamId: number, season: number | undefined) =>
    axiosInstance.get(`${ENDPOINT.TEAM_SQUAD}/`,
    {
      params: {
        team_id: teamId,
        ...season && {season: season}
      }
    }
    ),
  getPlayerStats: async (playerId: number, numMatches: number, team_id: number | undefined, season: number | undefined = undefined) =>
    axiosInstance.get(
      `${ENDPOINT.PLAYER_STATS}`,
      {
        params: {
          player_id: playerId,
          matches: numMatches,
          ...team_id && {team_id: team_id},
          ...season && {season: season}
        }
      }
    ),
  getPlayersPerformances: async (teamId: number, numMatches: number, season: number | undefined) =>
    axiosInstance.get(
      `${ENDPOINT.PLAYER_PERFORMANCES}/`,
      {
        params: {
          team_id: teamId,
          matches: numMatches,
          ...season && {season: season}
        }
      }
    ),
  getPlayersMatch: async (matchId: number) => axiosInstance.get(`${ENDPOINT.PLAYER_MATCH}/?match_id=${matchId}`),
  getPlayersStadium: async (matchId: number) => axiosInstance.get(`${ENDPOINT.PLAYER_STADIUM}/?match_id=${matchId}`),
  getPlayersInfo: async (teamId: number, season: number | undefined) => axiosInstance.get(`${ENDPOINT.PLAYERS_INFOS}/?team_id=${teamId}&season=${season}`),
  postPlayer: async (player: any) => axiosInstance.post(`${ENDPOINT.PLAYER_CREATE}/`, player),
  deletePlayer: async (playerId: number) => axiosInstance.put(`${ENDPOINT.PLAYER_DELETE}/?player_id=${playerId}`),
};
