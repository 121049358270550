export interface Stats {
    assist: boolean;
    keyPasses: boolean;
    challengeLStatus: boolean;
    passCompleted: boolean;
    passMissed: boolean;
    passFront: boolean;
    passLeft: boolean;
    passRight: boolean;
    passBack: boolean;
    clearances: boolean;
    clearancesAdversary:boolean; // new
    throwins: boolean;  // new
    throwinsAdversary:boolean; // new
    crosses:boolean;
    keyAction: boolean; //new
    shots: boolean; 
    shotsAdversary: boolean;
    recovery1: boolean;
    recovery2: boolean;
    recovery3: boolean;
    recovery4: boolean;
    recovery12: boolean;
    recovery17: boolean;
    recoveryNoReaction: boolean;
    recoveryInterception: boolean; // new
    recoveryChallenge: boolean;//new
    loss1: boolean;
    loss2: boolean;
    loss3: boolean;
    loss4: boolean;
    loss12: boolean;
    loss17: boolean;
    lossPressing: boolean;
    lossRepo: boolean;
    lossNoReact: boolean;
    lossReasonDuel:boolean;//new, needs fix to remove for now
    lossReasonChallenge:boolean;//new
    goals: boolean; // goal type fiilter not added
    corners: boolean;
    // cornersLong: boolean;//no short/long filter added, need to add shoort oor long
    // cornersShort: boolean;//no short/long filter added, need to add shoort oor long
    // cornersAdversary: boolean; // new
    freekicks: boolean;// no far freekick added, neeed tooo add types
    freekickAdversary:boolean;
    dribbles: boolean; //no failed/succcess (old), add success, faiiledd
    penalty: boolean;
    challengeW: boolean;
    challengeL: boolean;
    challengeDef: boolean; // new
    challengeOff: boolean; //new
    challengeAir: boolean; //new
    tackleW: boolean;
    tackleL: boolean;
    fouls: boolean;
    foulReceived: boolean//new
    yellowCard: boolean; 
    redCard: boolean; 
    offside:boolean;
    offsideAdversary: boolean;
    goalkick: boolean; // too remove
    goalkickLong:boolean;
    goalkickShort:boolean;
    glClearances:boolean; // no success, need success fail filltere
    gkSaves:boolean;
    goalsAdversary:boolean; //new
  }
  
  export const initialButtonStates: Stats = {
    passCompleted: false,
    keyPasses: false,
    passMissed: false,
    passFront: false,
    passLeft: false,
    passRight: false,
    clearances: false,
    clearancesAdversary: false,
    throwins: false,
    throwinsAdversary: false,
    crosses: false,
    keyAction: false,
    shots: false,
    shotsAdversary: false,
    passBack: false,
    recovery1: false,
    recovery2: false,
    recovery3: false,
    recovery4: false,
    recovery12: false,
    recovery17: false,
    recoveryNoReaction: false,
    recoveryInterception: false,
    recoveryChallenge: false,
    loss1: false,
    loss2: false,
    loss3: false,
    loss4: false,
    loss12: false,
    loss17: false,
    lossPressing: false,
    lossRepo: false,
    lossNoReact: false,
    lossReasonDuel: false, //new, needs fix to remove for now
    lossReasonChallenge: false, 
    goals: false, // goal type fiilter not added
    corners: false,
    // cornersLong: false, //no short/long filter added, need to add shoort oor long
    // cornersShort: false, //no short/long filter added, need to add shoort oor long
    // cornersAdversary: false, // new
    freekicks: false, // no far freekick added, neeed tooo add types
    freekickAdversary: false,
    dribbles: false, //no failed/succcess (old), add success, faiiledd
    penalty: false,
    challengeW: false,
    challengeL: false,
    challengeDef: false,
    challengeOff: false,
    challengeAir: false,
    tackleW: false,
    tackleL: false,
    fouls: false,
    yellowCard: false,
    redCard: false,
    foulReceived: false,
    offside: false,
    offsideAdversary: false,
    goalkick: false, // too remove
    glClearances: false, // no success, need success fail filter
    goalkickLong: false,
    goalkickShort: false,
    challengeLStatus: false,
    assist: false,
    gkSaves: false,
    goalsAdversary: false,
  };
  
  export const setButtonStates = (currentState: Stats, updatedState: Partial<Stats>) => {
    return { ...currentState, ...updatedState };
  };
