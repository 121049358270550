import { Route, Redirect } from "react-router-dom";
import { useAppDispatch } from "../app/store";
import { getUser } from "../slices/userSlice";
import { AuthenticationRouteProps } from "./Route";

const GuestRoute = ({ component: Component }: AuthenticationRouteProps) => {
  const authToken = localStorage.token;
  const dispatch = useAppDispatch();
  
  dispatch(getUser());
  
  return (
    <Route
      render={() => (authToken ? <Redirect to="/home" /> : <Component />)}
    />
  );
};

export default GuestRoute; 
