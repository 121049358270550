import { Button, Grid, makeStyles } from "@material-ui/core";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from "styled-components";
import { useAppDispatch } from "../../../app/store";
import {
  DARK_BLUE,
  FOREST_GREEN,
  GREY,
  LIGHT_GREY,
  RED,
} from "../../../assets/colors";
import { changePage, Page } from "../../../slices/navigationSlice";
import { setCurrentPlayerId } from "../../../slices/playerSlice";

const useStyles = makeStyles(() => ({
  profileButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    textTransform: "none",
    fontFamily: "Karla ",
  },
}));

const PlayerCard = ({ stat }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const printPercentage = () => {
    let statColor = stat.isWarning ? RED : FOREST_GREEN;
    if (stat.performance >= 0)
      return (
        <div style={{ color: statColor }}>
          {`+${Math.round(stat.performance)}${
            stat.isPerformancePercentage ? `%` : ``
          }`}
        </div>
      );
    else if (stat.performance < 0)
      return (
        <div style={{ color: statColor }}>
          {`${Math.round(stat.performance)}${
            stat.isPerformancePercentage ? `%` : ``
          }`}
        </div>
      );
    else return <></>;
  };

  return stat ? (
    <>
      {
        <Grid item>
          <Wrapper>
            <Header>
              <div>
                {stat.playerName.split(" ")[0].substring(0, 1)}.{" "}
                {stat.playerName.split(" ")[1]}
              </div>
              {printPercentage()}
            </Header>
            <PlayerStat>
              {stat.value < 1 && stat.value > 0 ? (
                <Stat>{`${Math.round(stat.value * 100)}%`}</Stat>
              ) : (
                <Stat>{stat.value}</Stat>
              )}

              <StatLabel>{stat.statHeader}</StatLabel>
            </PlayerStat>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                className={classes.profileButton}
                onClick={() => {
                  dispatch(setCurrentPlayerId(stat.playerId));
                  dispatch(changePage(Page.PlayerProfile));
                }}
              >{`Stats`} <ChevronRightIcon></ChevronRightIcon></Button>
            </div>
          </Wrapper>
        </Grid>
      }
    </>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0.75rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  width: 150px;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;
const PlayerStat = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0px;
`;
const Stat = styled.div`
  font-size: 1.75rem;
  color: ${DARK_BLUE};
`;
const StatLabel = styled.div`
  font-size: 0.8rem;
  color: ${GREY};
  text-transform: capitalize;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export default PlayerCard;
