import styled from "styled-components";

export const goalTypeCounter = (goalCluster) => {
    const counter = { s: 0, g: 0, k: 0, p: 0,r:0,l:0,h:0, total: 0 };
    for (const row of goalCluster) {
      for (const goal of row) {
        switch (goal.type) {
          case "s":
            counter.s++;
            break;
          case "g":
            counter.g++;
            break;
          case "k":
            counter.k++;
            break;
          case "p":
            counter.p++;
            break;
          case "r":
            counter.r++;
            break;
          case "l":
            counter.l++;
            break;
          case "h":
            counter.h++;
            break;
        }
        counter.total++;
      }
    }
    return counter;
  };

export const EventPositionTooltip = (props) => {
    const { payload, active } = props;

    if (active) {
      const dots = payload[0].payload.dots;
      const counter = goalTypeCounter([dots]);

      return (
        <ToolTipWrapper>
          {counter.s > 0 ? <div>Simple Shot Goal: {counter.s}</div> : <></>}
          {counter.k > 0 ? <div>Direct Free-kick: {counter.k}</div> : <></>}
          {counter.g > 0 ? (
            <div>Dead Ball Assisted Goal: {counter.g}</div>
          ) : (
            <></>
          )}
          {counter.p > 0 ? <div>Penalty Kicks: {counter.p}</div> : <></>}
        </ToolTipWrapper>
      );
    }

    return null;
  };

const ToolTipWrapper = styled.div`
  background-color: black;
  opacity: 0.6;
  color: white;
  font-size: 0.8rem;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0.3rem;
  font-family: Karla Regular;
`;