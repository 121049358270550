import { Button, ListItem, ListItemText, makeStyles, Switch} from "@material-ui/core";
import styled from "styled-components";
import { DARK_BLUE, GREY, WHITE, BLACK, LIGHT_GREY1, GREY2 } from "../../../../../assets/colors";
import fieldImage from '../../../../../assets/images/recuperations-image1.png';
import { BaseFilter } from "./baseFilter";


const useStyles = makeStyles(() => ({
    statButton: {
      backgroundColor: WHITE,
      color: GREY,
      padding: "5px 5px",
      marginBottom: "10px",
      fontFamily: "Karla Bold",
      fontSize: "0,5rem",
      "&:hover": {
        backgroundColor: DARK_BLUE,
        opacity: "80%",
        color: WHITE,
      },
    },
    clear: {
      width: '50%',
      backgroundColor: GREY,
      borderColor: WHITE,
      borderRadius: "5px",
      border: "2px solid",
      color: WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1.2rem",
      justifyContent: 'center',
      "&:hover": {
        color:WHITE,
      backgroundColor: GREY,
      cursor: "pointer",
      },
    },
    tabButton: {
      textAlign: "center",
      width: "100%",
      borderRadius: "0px",
      color: GREY,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
  
      backgroundColor: WHITE,
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    tabButtonSelected: {
      textAlign: "center",
      width: "100%",
      borderRadius: "0px",
      borderBottom: "3px solid",
      borderColor: DARK_BLUE,
      color: DARK_BLUE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      backgroundColor: WHITE,
    },
    directionsButton: {
      textAlign: "right",
      flexDirection: 'column',
      color: BLACK,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&:hover": {
        backgroundColor: GREY,
        color:WHITE,
      },
    },
    directionsButtonClicked: {
      textAlign: "right",
      flexDirection: 'column',
      backgroundColor: DARK_BLUE,
        color:WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&:hover": {
        backgroundColor: DARK_BLUE,
        color:WHITE,
      },
    },
    applyFilter: {
      width: '50%',
      backgroundColor: DARK_BLUE,
      borderColor: WHITE,
      borderRadius: "5px",
      border: "2px solid",
      fontFamily: "Karla Bold",
      fontSize: "1.2rem",
      color: WHITE,
      justifyContent: 'center',
      "&:hover": {
        color:WHITE,
      backgroundColor: DARK_BLUE,
      },
    },
    cancelFilter: {
      width: '50%',
      backgroundColor: GREY,
      borderColor: WHITE,
      borderRadius: "5px",
      border: "2px solid",
      color: WHITE,
      justifyContent: 'center',
      "&:hover": {
        color:WHITE,
      backgroundColor: GREY2,
      },
    },
    soccerFieldContainer: {
      justifyContent: 'end',
      alignItems: 'end',
    },
    buttonStat: {
      color: "black",
      backgroundColor: WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
    },
    buttonSubStat: {
      color: BLACK,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      justify: "end",
      "&:hover": {
      backgroundColor: WHITE,
      },
    },
    SubStatContainer: {
      backgroundColor: LIGHT_GREY1,
    },
    buttonFieldStyle: {
      position: 'relative',
      display: 'inline-block',
      width: '25%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: "7px",
      borderWidth: "1.5px 1.5px 1.5px 1.5px",
      borderStyle: "solid",
      borderColor: DARK_BLUE,
      zIndex:0,
      "&:hover": {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
      
    },
    fieldClickedButton: {
      position: 'relative',
      display: 'inline-block',
      width: '25%',
      height: '100%',
      backgroundColor: DARK_BLUE,
      borderRadius: "10px",
      border: "1.5px solid",
      borderColor: WHITE,
      zIndex:0,
      "&:hover": {
        backgroundColor: DARK_BLUE,
      },
    },
    fieldBox1: {
      position: 'absolute',
      width:'30%',
      height: '60%',
      top: '20%',
      left:'0%',
      right:'0%',
      borderRadius: "15px",
      border: "1px solid",
      borderColor: DARK_BLUE,
      backgroundColor: WHITE,
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    clickedFieldBox1: {
      position: 'absolute',
      width:'30%',
      height: '60%',
      top: '20%',
      left:'0%',
      right:'0%',
      borderRadius: "15px",
      border: "2px solid",
      borderColor: WHITE,
      backgroundColor: DARK_BLUE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: DARK_BLUE,
      },
    },
    fieldBox2: {
      position: 'absolute',
      width:'30%',
      height: '60%',
      top: '20%',
      right:'0%',
      borderRadius: "15px",
      border: "1px solid",
      borderColor: DARK_BLUE,
      backgroundColor: WHITE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    clickedFieldBox2: {
      position: 'absolute',
      width:'30%',
      height: '60%',
      top: '20%',
      right:'0%',
      borderRadius: "10px",
      border: "2px solid",
      backgroundColor: DARK_BLUE,
      borderColor: WHITE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: DARK_BLUE,
      },
    },
    containerStyle: {
      position: 'relative',
      display: 'inline-block',
      width: '85%',
      height: 'min(17vh)', // set the minimum height of the container element
      maxHeight: '20vh',
      backgroundImage: `url(${fieldImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: "7%",
    },
  }));
  

export const RecoveriesFilter = ({handleStatsButtonClick, handleFieldZonesClick, handleButtonOnMouseEnter, handleButtonOnMouseLeave, buttonStates}) => {
    const classes = useStyles();
    return(
        <>
          {/* <BaseFilter handleStatsButtonClick={handleStatsButtonClick} statName={'recoveryInterception'} buttonStates={buttonStates} title={'interception'}/>
          <BaseFilter handleStatsButtonClick={handleStatsButtonClick} statName={'recoveryChallenge'} buttonStates={buttonStates} title={'challenge'}/> */}
            <Recoveries>
            <div className="container">
            <div className="Stats">
              <BaseFilter handleStatsButtonClick={handleStatsButtonClick} statName={'recoveryNoReaction'} buttonStates={buttonStates} title={'all recoveries'}/>
              <ListItem className={classes.buttonSubStat}
                  key={"allZonesRecup"}
                  onChange={() => handleStatsButtonClick('allZonesRecup')} 
                  button> ALL ZONES
              <ListItemText className={classes.buttonStat} color="primary" />
              <Switch color="primary" checked={buttonStates["recovery1"] &&  buttonStates["recovery12"] && 
                                      buttonStates["recovery2"] && 
                                      buttonStates["recovery3"] && 
                                      buttonStates["recovery4"] && buttonStates["recovery17"]}/>
              </ListItem>
            </div>
            <div className="Field">
            <FieldContainer>
              <div className={classes.containerStyle}>
                <Button className={
                          buttonStates["recovery1"] === true
                              ? classes.fieldClickedButton
                              : classes.buttonFieldStyle}
                          onClick={(e) => handleFieldZonesClick('recovery1',e)} >
                  <Button className={
                          buttonStates["recovery12"] === true
                              ? classes.clickedFieldBox1
                              : classes.fieldBox1}
                              onClick={(e) => handleFieldZonesClick('recovery12',e)} 
                              onMouseEnter={(e) => handleButtonOnMouseEnter("recovery12", e)}
                              onMouseLeave={(e) => handleButtonOnMouseLeave("recovery12", e)}>
                  </Button>
                </Button>
                <Button className={
                          buttonStates["recovery2"] === true
                              ? classes.fieldClickedButton
                              : classes.buttonFieldStyle}
                              onClick={(e) => handleFieldZonesClick('recovery2', e)} /> 
                <Button className={
                          buttonStates["recovery3"] === true
                              ? classes.fieldClickedButton
                              : classes.buttonFieldStyle}
                              onClick={(e) => handleFieldZonesClick('recovery3', e)} /> 
                <Button className={
                          buttonStates["recovery4"] === true
                              ? classes.fieldClickedButton
                              : classes.buttonFieldStyle}
                              onClick={(e) => handleFieldZonesClick('recovery4', e)} > 
                  <Button className={
                          buttonStates["recovery17"] === true
                              ? classes.clickedFieldBox2
                              : classes.fieldBox2}
                              onClick={(e) => handleFieldZonesClick('recovery17',e)} 
                              onMouseEnter={(e) => handleButtonOnMouseEnter("recovery17", e)}
                              onMouseLeave={(e) => handleButtonOnMouseLeave("recovery17", e)}>
                  </Button>
                </Button>
              </div>
            </FieldContainer>
            </div>
            </div>
            </Recoveries>
        </>
    )
 }


const FieldContainer = styled.div`
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
`

const Recoveries = styled.div` 
  .container {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  padding: 10px 0 20px 0;
  grid-template-areas: 
    "Stats Field"; 
}
.Stats { grid-area: Stats;
  padding: 5% 0 0 5%;
  margin-top: 2%;
  margin-left: 7%;
  border: 1px solid ${DARK_BLUE};
  border-radius: 25px;
.Field { grid-area: Field; }
`;