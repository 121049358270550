import { Pie, PieChart, ResponsiveContainer, Cell } from "recharts";
import styled from "styled-components";
import { GREY, LIGHT_GREY, RED_TO_GREEN_GRADIENT } from "../../../assets/colors";

const SuccessRatio = ({ title, successes, total, onClick=() => {} }) => {
  let isDataValid = true;
  if (!title || !total) isDataValid = false;

  const data = [
    { name: "successes", value: successes },
    { name: "failures", value: total - successes },
  ];

  const getColor = () =>
    RED_TO_GREEN_GRADIENT[
      Math.floor(
        Math.min(
          (successes / total) * (RED_TO_GREEN_GRADIENT.length - 1),
          RED_TO_GREEN_GRADIENT.length - 1
        )
      )
    ];

  return (
    <Wrapper onClick={() => onClick()}>
      {isDataValid ? (
        <>
          <Title>
            {`${title}`}
            <b>{`(${successes}/${total})`}</b>
          </Title>
          <PieWrapper>
            <ResponsiveContainer width="99%" height={100}>
              <PieChart>
                <Pie
                  data={data}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                  innerRadius={30}
                  outerRadius={40}
                >
                  <Cell fill={getColor()}></Cell>
                  <Cell fill={LIGHT_GREY}></Cell>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Percentage style={{ color: getColor() }}>{`${Math.round(
              (successes / total) * 100
            )}%`}</Percentage>
          </PieWrapper>
        </>
      ) : (
        <>
          <Title style={{ marginTop: "1rem" }}>{`${title}`}</Title>
          <NoDataWrapper>No Data</NoDataWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 0.5rem;
  background-color: white;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  min-height: 150px;
  height: calc(100% - 4px);
  b {
    text-transform: uppercase;
  }
`;
const Title = styled.div`
  text-transform: capitalize;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  b {
    margin-left: 5px;
    color: ${GREY};
  }
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PieWrapper = styled.div`
  height: 100px;
`;
const Percentage = styled.div`
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  margin-top: -3.9rem;
`;
export default SuccessRatio;
