import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY, LIGHT_RED } from "../../../assets/colors";

const GoalsRatio = ({ scored, conceded }) => {
  let isDataValid = true;

  if (scored === undefined || conceded === undefined) isDataValid = false;

  return (
    <Wrapper>
      <Title>Goals Ratio</Title>
      {isDataValid ? (
        <div
          style={{ display: "flex", height: "100%", justifyContent: "center" }}
        >
          <div style={{ height: "100px", width: "100px" }}>
            {scored >= conceded ? (
              <GoalIndicator style={{ color: DARK_BLUE }}>
                +{scored - conceded}
              </GoalIndicator>
            ) : (
              <GoalIndicator style={{ color: LIGHT_RED }}>
                -{conceded - scored}
              </GoalIndicator>
            )}
            <ResponsiveContainer height="80%">
              <PieChart>
                <Pie
                  data={[
                    { name: "conceded", value: conceded },
                    { name: "scored", value: scored },
                  ]}
                  startAngle={90}
                  endAngle={470}
                  dataKey="value"
                  innerRadius={30}
                  outerRadius={38}
                >
                  <Cell fill={LIGHT_RED}></Cell>
                  <Cell fill={DARK_BLUE}></Cell>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <LegendWrapper>
            <div style={{ display: "flex", margin: "5px 0px" }}>
              <ColorSquare style={{ backgroundColor: DARK_BLUE }}></ColorSquare>
              <Legend>Scored: {scored}</Legend>
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
              <ColorSquare style={{ backgroundColor: LIGHT_RED }}></ColorSquare>
              <Legend>Conceded: {conceded}</Legend>
            </div>
          </LegendWrapper>
        </div>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0rem 0rem 0rem 0.5rem;
  padding: 25px 10px 0px;
  align-items: center;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const GoalIndicator = styled.div`
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  position: relative;
  top: 3.35rem;
`;
const ColorSquare = styled.div`
  height: 0.7rem;
  width: 0.7rem;
  margin: 0px 5px;
`;
const Legend = styled.div`
  width: fit-content;
  font-size: 0.7rem;
`;
const LegendWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default GoalsRatio;
