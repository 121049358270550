import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useAppDispatch } from "../../../app/store";
import { changePage, Page } from "../../../slices/navigationSlice";
import { Game } from "../../../models/game";
import { getGame, selectLatestGame } from "../../../slices/gamesSlice";
import {DARK_BLUE, GREY, LIGHT_RED, RED } from "../../../assets/colors";
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";

const HomeHeader = () => {
  const dispatch = useAppDispatch();
  const currentGame: Game | undefined = useSelector(selectLatestGame);
  const classes = sportsAiGlobalStyles();

  return currentGame ? (
        <Container>
          <HomeTeam> <TeamName style={{ textAlign: "right" }}>{currentGame.name}</TeamName> </HomeTeam>
          <HomeTeamLogo>  {currentGame.logo ? (
          <img src={currentGame.logo} alt="Logo1" />
        ) : (
          <></>
        )}</HomeTeamLogo>
          <Score1>
            <ScoreMatch> {`${currentGame.teamScore} - ${currentGame.opponentScore}`} </ScoreMatch>
            <ScoreXG> 
                <Pen>
                {currentGame.isPenaltySession ? (
                <PenaltiesWrapper> {'('}
                  {`${currentGame.teamPenaltySessionGoals} - 
                  ${currentGame.opponentPenaltySessionGoals}`}{')'}
                </PenaltiesWrapper>
              ) : (
                <></>
              )}
                </Pen>
                <XGLabel>xG</XGLabel>
                <XGValues>{`${currentGame.xg} | ${currentGame.xgOpponent}`}</XGValues>
              
            </ScoreXG>
          </Score1>
          <AwayTeamLogo>
            {currentGame.opponentLogo ? (
              <img src={currentGame.opponentLogo} alt="Logo2" />
            ) : (
              <></>
            )}
          </AwayTeamLogo>
          <AwayTeam>  
            <TeamName style={{ textAlign: "left" }}>
              {currentGame.opponent}
            </TeamName>
          </AwayTeam>
          <ButtonStats>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {currentGame.isLiveGame ? (
              <Button
                className={classes.fullBlueButton}
                onClick={() => dispatch(changePage(Page.GameStat))}
              >
                Live Stats
              </Button>
                ) : (
              currentGame.isOperated ? (
                <Button
                className={classes.fullBlueButton}
                onClick={() => {    
                    dispatch(getGame(currentGame.id));
                    dispatch(changePage(Page.GameStat));
                }}
              >
                Game Stats
              </Button>
              ):
                <></>
            )}
          </div>
          </ButtonStats>
          <GameStatus>
            {currentGame.isOperated ? (
              <GameEnded>LAST GAME</GameEnded>
                ) : (
              currentGame.isOperated ?(
                <>
                  <div style={{ alignItems: " center", display: "flex" }}>
                    <LiveCircle></LiveCircle>
                    <div>Live Match</div>
                  </div>
                  <Minutes>{`${currentGame.minutesPlayed}'`}</Minutes>
                </>        
              ): (<GameEnded>PROCESSING</GameEnded>)
            )}
          </GameStatus>
        </Container>
  ) : (
    <></>
  );
};

const LiveCircle = styled.div`
  border-radius: 15px;
  background-color: ${RED};
  height: 7px;
  width: 7px;
  margin-right: 5px;
  animation: blink 1s infinite ease;
`;
const GameEnded = styled.div`
  color: ${GREY};
  margin-bottom: 15px;
`;
const Minutes = styled.div`
  font-size: 1rem;
  color: ${RED};
  margin-top: 15px;
`;



const TeamName = styled.div`
  font-size: 1.2rem;
  margin: 0rem 1.5rem;
  width: 10rem;
`;

const PenaltiesWrapper = styled.div`
  display: flex;
  font-size: 0.75rem;
  justify-content: space-between;
`;

const Container = styled.div`
  display: grid;
  width:100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  grid-template-columns: 0.25fr 0.10fr 0.10fr 0.10fr 0.25fr;
  grid-template-rows: 0.1fr 0.8fr 0.5fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . GameStatus . ."
    "HomeTeam HomeTeamLogo Score AwayTeamLogo AwayTeam"
    ". . ButtonStats . .";
`;

const HomeTeam = styled.div`
  grid-area: HomeTeam;
  display: flex;
  justify-content:right;
  align-items: center
`;

const HomeTeamLogo = styled.div`
  grid-area: HomeTeamLogo;
  img {
    height: auto;
    width: 80px;
  };
  display: flex;
  justify-content:center;
  align-items: center
`;

const Score1 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "ScoreMatch"
    "ScoreXG";
  grid-area: Score;
`;

const ScoreMatch = styled.div`
  grid-area: ScoreMatch;
  font-size: 1.75rem;
  display: flex;
  justify-content:center;
  align-items: flex-end;
  color:${DARK_BLUE}; 
`;

const ScoreXG = styled.div`
display: grid; 
grid-template-columns: 1fr 1fr 1fr; 
grid-template-rows:  0.75fr 1fr 1fr; 
gap: 0px 0px; 
grid-template-areas: 
". Pen ."
". XGLabel ."
"XGValues XGValues XGValues"; 
grid-area: ScoreXG; 
`;

const Pen = styled.div`
font-size: 0.75rem;
grid-area: Pen;
color:${GREY}; 
display: flex;
justify-content:center;
align-items: start;
`;

const XGLabel = styled.div`
  font-size: 0.90rem;
  color:${LIGHT_RED}; 
  grid-area: XGLabel;
display: flex;
justify-content:center;
align-items: flex-end;
`;

const XGValues = styled.div`
  font-size: 1rem;
  color: ${LIGHT_RED};
  grid-area: XGValues;

display: flex;
justify-content:center;
align-items: flex-end;
`;

const AwayTeamLogo = styled.div`
  grid-area: AwayTeamLogo;
  img {
    height: auto;
    width: 80px;
  };
  display: flex;
  justify-content:center;
  align-items: center;
`;

const AwayTeam = styled.div`
  grid-area: AwayTeam;
  display: flex;
  justify-content: left;
  align-items: center
`;

const ButtonStats = styled.div`
  grid-area: ButtonStats;

  display: flex;
  justify-content:center;
  align-items: center;
`;

const GameStatus = styled.div`
  grid-area: GameStatus;
  display: flex;
  justify-content:center;
  align-items: center;
`;


export default HomeHeader;
