import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY, LIGHT_RED } from "../../../assets/colors";

const ResultsRatio = ({ losses, draws, wins }) => {
  let isDataValid = true;

  if (losses === undefined || draws === undefined || wins === undefined) {
    isDataValid = false;
  }

  const getPercentage = (ele: string) => {
    const total = wins + losses + draws;
    let percentage = 0;
    switch (ele) {
      case "wins":
        percentage = Math.round((wins / total) * 100);
        break;
      case "losses":
        percentage = Math.round((losses / total) * 100);
        break;
      case "draws":
        percentage = Math.round((draws / total) * 100);
        break;
    }
    return percentage;
  };

  return (
    <Wrapper>
      <Title>Results Ratio</Title>
      {isDataValid ? (
        <>
          <div
            style={{ display: "flex", width: "100%", margin: "1.5rem 0rem" }}
          >
            <div style={{ width: `${getPercentage("wins")}%` }}>
              {wins > 0 ? (
                <WinSegmentLabel>
                  {<b style={{ margin: "0px 3px" }}>{wins}</b>}
                  {` (${getPercentage("wins")}%)`}
                </WinSegmentLabel>
              ) : (
                <></>
              )}
              <WinSegment style={{ backgroundColor: DARK_BLUE }}></WinSegment>
            </div>
            <div style={{ width: `${getPercentage("draws")}%` }}>
              {draws > 0 ? (
                <DrawsSegmentLabel>
                  {<b style={{ margin: "0px 3px" }}>{draws}</b>}
                  {` (${getPercentage("draws")}%)`}
                </DrawsSegmentLabel>
              ) : (
                <></>
              )}
              <DrawsSegment
                style={{ backgroundColor: LIGHT_GREY }}
              ></DrawsSegment>
            </div>
            <div style={{ width: `${getPercentage("losses")}%` }}>
              {losses > 0 ? (
                <LossesSegmentLabel>
                  {<b style={{ margin: "0px 3px" }}>{losses}</b>}
                  {` (${getPercentage("losses")}%)`}
                </LossesSegmentLabel>
              ) : (
                <></>
              )}
              <LossesSegment
                style={{ backgroundColor: LIGHT_RED }}
              ></LossesSegment>
            </div>
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <ColorSquare style={{ backgroundColor: DARK_BLUE }}></ColorSquare>
            <Legend>Wins</Legend>
            <ColorSquare style={{ backgroundColor: LIGHT_GREY }}></ColorSquare>
            <Legend>Draws</Legend>
            <ColorSquare style={{ backgroundColor: LIGHT_RED }}></ColorSquare>
            <Legend>Losses</Legend>
          </div>
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}{" "}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0rem 0.5rem 0rem 0rem;
  padding: 25px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const WinSegmentLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${GREY};
  margin: 5px 0px;
`;
const WinSegment = styled.div`
  height: 10px;
  width: 100%;
  animation: expand 1s ease;
`;
const DrawsSegmentLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${GREY};
  margin: 5px 0px;
`;
const DrawsSegment = styled.div`
  height: 10px;
  width: 100%;
  animation: expand 0.5s ease;
`;
const LossesSegmentLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${GREY};
  margin: 5px 0px;
`;
const LossesSegment = styled.div`
  height: 10px;
  width: 100%;
  animation: expand 0.5s ease;
`;
const ColorSquare = styled.div`
  height: 0.7rem;
  width: 0.7rem;
  margin: 0px 5px;
`;
const Legend = styled.div`
  font-size: 0.7rem;
  margin-right: 10px;
`;

export default ResultsRatio;
