import { Stats } from "../features/library/sequences/statModal/initStatSelection/initStatSelection";

const buileventRequest = (stats: Stats, players: number[], games:number[]) => {  
    var stats_out = get_stats_object(stats);
    return {
      stats: stats_out,
      match_id: games,
      player_id: players
    }
}

const get_stats_object = (stats: Stats) => {
    const challengeStatus: boolean | null = get_success_status(stats.challengeW, stats.challengeL);
    const tackleStatus: boolean | null = get_success_status(stats.tackleW, stats.tackleL);
    const recoveries_zones = build_recoveries(stats);
    return {
        passes: build_passes(stats),
        losses: build_losses(stats),
        ...(stats.keyPasses && {keyPasses: {}}),
        ...((stats.clearances || stats.clearancesAdversary) && {clearances: {success:null, adversary: get_is_adversary(stats.clearances,stats.clearancesAdversary)}}),
        ...((stats.throwins || stats.throwinsAdversary) && {throwIns: {adversary: get_is_adversary(stats.throwins, stats.throwinsAdversary)}}),
        ...(stats.crosses && {crosses: {success: null}}),
        ...(stats.keyAction && {keyAction: {}}),
        ...((stats.shots || stats.shotsAdversary)  && {shots: {success:null, adversary: get_is_adversary(stats.shots, stats.shots), type:null, blocked:null}}),
        ...((recoveries_zones !== undefined) && {recoveries: {zone: recoveries_zones, type:get_recovery_type(stats)}}),
        ...(stats.dribbles && {dribbles: {success: null}}),
        ...(stats.fouls && {fouls: {success: null}}),
        ...(stats.freekicks && {freekicks: build_freekicks(stats)}),
        ...(stats.penalty && {penalty: {success: null}}),
        ...((stats.offside || stats.offsideAdversary) && {offside: build_offside(stats)}),
        ...(stats.goalkick && {goalkick: {success: null, length: null, type: get_stat_length(stats.goalkickLong, stats.goalkickShort)}}),
        ...(stats.glClearances && {gkClearances: {success: null}}),
        ...((stats.challengeW || stats.challengeL) && {challenges: build_challenge(stats, challengeStatus)}),
        ...(stats.yellowCard && {yellowCard: {}}),
        ...(stats.redCard && {redCard: {}}),
        ...((stats.corners) && {corners: {adversary: get_is_adversary(stats.corners, false), length: null}}),
        ...(stats.goals && {goals: [{type: null}]}),
        ...((stats.tackleW ||  stats.tackleW) && {tackles: {success: tackleStatus}}),
        ...(stats.assist && {assist: {}}),
        ...(stats.gkSaves && {gkSaves: {}}),
        ...(stats.goalsAdversary && {goalsAdversary: {}})
    }
}

// stats builders
const build_passes = (stats) => {
    const category: boolean|null = get_success_status(stats.passCompleted, stats.passMissed);
    var passes: {direction:string, success:boolean|null}[] = []
    if (stats.passBack)
        passes.push({direction: "back", success: category});
    if (stats.passFront)
        passes.push({direction: "forward", success: category});
    if (stats.passLeft)
        passes.push({direction: "left", success: category});
    if (stats.passRight)
        passes.push({direction: "right", success: category});
    return passes;
}

const build_losses = (stats) => {
    const zones: number[] = get_zone(stats.loss1, stats.loss2, stats.loss3, stats.loss4, stats.loss12, stats.loss17);
    var losses: {type:string, zone:number[],reason: string | null}[] = [];

    var reason: string | null = null;
    if (stats.lossReasonChallenge && !stats.lossReasonDuel){
        reason = "challenge"
    } else if (!stats.lossReasonChallenge && stats.lossReasonDuel) {
        reason = "duel"
    }

    if (stats.lossPressing)
        losses.push({type: "pressing", zone: zones,reason: reason});
    if (stats.lossRepo)
        losses.push({type: "repositioning", zone: zones,reason: reason});
    if (stats.lossNoReact)
        losses.push({type: "no_reaction", zone: zones,reason: reason});
    return losses;
}

const build_recoveries = (stats) => {
    if (!stats.recovery1 && !stats.recovery2 && !stats.recovery3 && !stats.recovery4 && !stats.recovery12 && !stats.recovery17){
        return undefined;
    }
    return get_zone(stats.recovery1, stats.recovery2, stats.recovery3, stats.recovery4, stats.recovery12, stats.recovery17);
}

const build_offside = (stats) => {
    var freekicks: {success: boolean | null, adversary: boolean | null}[] = []; // TODO: replace with existing models

    if (stats.offside) // todo use lists at different places
        freekicks.push({success: null, adversary: false});    
    if (stats.offsideAdversary)
        freekicks.push({success: null, adversary: true});

    return freekicks;
}

const build_freekicks = (stats) => {
    var freekicks: {isFar: boolean | null, adversary: boolean | null}[] = []; // TODO: replace with existing models

    if (stats.freekicks) // todo use lists at different places
        freekicks.push({isFar: null, adversary: false});    
    if (stats.freekicks)
        freekicks.push({isFar: null, adversary: true});

    return freekicks;
}

const build_challenge = (stats, challengeStatus) => {

    var challenges: {success: boolean | null, type: string | null}[] = [];
    if (stats.challengeAir)
        challenges.push({success: challengeStatus, type: "aerial"}); 
    if (stats.challengeOff)
        challenges.push({success: challengeStatus, type: "offensive"}); 
    if (stats.challengeDef)
        challenges.push({success: challengeStatus, type: "defensive"}); 

    return challenges;
}

// utils
const get_success_status = (completed: boolean, missed: boolean) => {
    if (completed && missed){
        return null;
    } else if (completed){
        return true;
    } else if (missed){
        return false;
    }
    return null;
}

const get_zone = (zone1: boolean, zone2: boolean, zone3: boolean, zone4: boolean, zone12: boolean, zone17: boolean) => {
    var zones: number[] = [];
    if (zone1)
        zones.push(1,2,7,13,18,23,24);
    if (zone2)
        zones.push(2,3,8,9,13,14,19,20,24,25);
    if (zone3)
        zones.push(4,5,9,10,15,16,20,21,26,27);
    if (zone4)
        zones.push(5,6,11,16,22,27,28);
    if (zone12)
        zones.push(12);
    if (zone17)
        zones.push(17);
    return [...new Set(zones)];
}

const get_is_adversary = (home, adversary) => {
    if (home && adversary)
        return null;
    if (home)
        return false
    return true;
}


const get_stat_length = (long, short) => {
    if (long && short)
        return null;
    if (long && !short)
        return "long"
    if (short && !long)
        return "short"
    return null;
}


const get_recovery_type = (stats) => {
    // if (stats.recoveryChallenge && stats.recoveryInterception)
    //     return null
    // if (stats.recoveryChallenge)
    //     return 'challenge'
    // if (stats.recoveryInterception)
    //     return 'interception'
    if (stats.recoveryNoReaction)
        return null
    return null
}
export default buileventRequest;