import { Grid, InputLabel, FormControl, MenuItem, ListSubheader} from "@mui/material"
import Select from '@mui/material/Select';
import { DARK_BLUE, LIGHT_BLUE, LIGHT_GREY, RED } from "../../assets/colors";
import styled from "styled-components";
import { selectSeason, selectUser, selectTeamView } from "../../slices/userSlice";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import { loadData, loadLigueData } from "../../services/loadData";
import { useEffect, useState } from "react";
import { teamApi } from "../../api/teamApi";
import { Competition } from "../../models/competition";
import { TeamSummary } from "../../models/team";
import { PLAYER_ACCOUNT, TEAM_ACCOUNT, TEAM_LEAGUE } from "../../models/constant/accountType";
import { authApi } from "../../api/authApi";
import { ACTIVITIES, FEATURE_VALUES } from "../../models/constant/collect";

interface CompetitionWithOpponents extends Competition {
    opponents: TeamSummary[];
}

const range = (start, end) => Array.from(Array(end - start + 1).keys()).map(x => x + start);


const setError = (error: unknown) => {}

const setErrorMessage = (arg0: string) => {}

const TopBar = (props) => {
    const dispatch = useAppDispatch()
    const user = useSelector(selectUser);
    const teamView = useSelector(selectTeamView)
    const initSeason = useSelector(selectSeason);
    const [competitionData, setCompetitionData] = useState<CompetitionWithOpponents[] >([]);
    const [selectedTeamName, setSelectedTeamName] = useState<string>(""); // State to hold the selected team name

    useEffect(() => {
        if (teamView) {
            const selectedTeam = competitionData.flatMap(item => item.opponents).find(opponent => opponent.id === teamView);
            if (selectedTeam) {
                setSelectedTeamName(selectedTeam.name);
            }
        }
    }, [teamView, competitionData]);

    const viewTeam = (opponent) => {
        if (initSeason !== undefined && opponent !== null) {
            loadLigueData(dispatch, initSeason, opponent.id);
        } else {
            setErrorMessage("Please select both a competition and an opponent to view team.");
        }
    };
    var values: number[] = []
    if (user?.startSeason !== undefined && user?.endSeason !== undefined)
        values = range(user?.startSeason, user?.endSeason)

        const fetchAllTeamsData = async () => {
            if (!user) return;
            try {
                const competitions = user.competitions;
                for (const competition of competitions) {
                    const competitionExists = competitionData.some(item => item.id === competition.id);
                    if (!competitionExists) {
                        teamApi.getOpponents(competition.id)
                            .then((ret) => {
                                let opponents = ret.data;
                                opponents = opponents.slice().sort((a, b) => {
                                    return a.name.localeCompare(b.name);
                                });
                                const newCompetitionDataItem = {
                                    ...competition,
                                    opponents: opponents
                                };
                                setCompetitionData(prevData => {
                                    const competitionExists = prevData.some(item => item.id === competition.id);
                                    if (!competitionExists) {
                                        return [...prevData, newCompetitionDataItem];
                                    }
                                    return prevData;
                                });
                            })
                            .catch((error) => {
                                setError(error);
                            });
                    }
                }
            } catch (error) {
                setError(error); // Set the error state
            }
        };

        const handleTeamSelectChange = (event) => {
            const opponentId = event.target.value; 
            const opponent = competitionData.flatMap(item => item.opponents).find(opponent => opponent.id === opponentId);
            if (opponent) {
                viewTeam(opponent)
            } else {
                console.log('No opponent found for id:', opponentId);
                // Handle the case when no opponent is found
            }
            authApi.collect(({activity: ACTIVITIES.NAVIGATION, feature: FEATURE_VALUES.LEAGUE}))
        };        
    
        const [loading, setLoading] = useState(true); // Initialize loading state

        useEffect(() => {
            fetchAllTeamsData()
                .then(() => setLoading(false)) // Set loading to false when data fetching is complete
                .catch((error) => {
                    console.error('Error fetching all teams data:', error);
                    setError(error); // Handle any errors
                    setLoading(false); // Set loading to false even in case of error
                });
        }, []);
    

    const gridMdValue = user && user.accountType === TEAM_LEAGUE ? 9 :
    user && (user.accountType === TEAM_ACCOUNT || user.accountType === PLAYER_ACCOUNT) ? 10 : 11;

    // Return null during loading state
    if (loading) {
        return null;
    }

    return (
        <Grid container justifyContent="space-between"> 
            <Grid item md={gridMdValue} >
                <Title>
                    <div style={{marginTop:"15px"}}>{props.name}</div>
                    <HorizontalLine />
                </Title>
            </Grid>
            {user && user.accountType === TEAM_LEAGUE && (
                <Grid item md={3}> 
                {/* Team Selector */}
                <FormControl
                    sx={{
                        width: '90%',
                        color: DARK_BLUE,
                        marginTop: '0.3rem',
                        height: '1rem',
                        borderRadius: '8px',
                    }}
                    >
                    <InputLabel
                        style={{
                        color: DARK_BLUE,
                        fontFamily: 'Antique Olive',
                        fontSize: '1rem',
                        marginTop: '0.3rem',
                        }}
                    >
                        {selectedTeamName}
                    </InputLabel>
                    <Select
                        variant="outlined"
                        label={selectedTeamName}
                        onChange={handleTeamSelectChange}
                        sx={{
                        height: '2rem',
                        marginTop: '1rem',
                        width: '100%',
                        color: DARK_BLUE,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: LIGHT_GREY,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: LIGHT_GREY,
                        },
                        }}
                    >
                        {competitionData.map((competitionDataItem) => [
                        <ListSubheader
                            key={competitionDataItem.competition}
                            style={{
                            color: DARK_BLUE,
                            fontFamily: 'Antique Olive',
                            fontSize: '0.90rem',
                            marginBottom: '0',
                            }}
                        >
                            {competitionDataItem.competition}
                        </ListSubheader>,
                        
                        ...competitionDataItem.opponents.map((opponent) => (
                            <MenuItem
                            key={opponent.id}
                            value={opponent.id}
                            style={{
                                fontFamily: 'karla',
                                fontSize: '0.95rem',
                                color: opponent.id === teamView? RED : DARK_BLUE,
                                fontWeight: opponent.id === user.team?.id ?'bold' : 'normal',
                            }}
                            >
                            {opponent.name} 
                            </MenuItem>
                        )),
                        ])}
                    </Select>
                </FormControl>
                </Grid>
            )}
            {user && (user.accountType === TEAM_ACCOUNT || user.accountType === PLAYER_ACCOUNT) && (
                <Grid item md={2}> 
                
                {/* Season Selector */}
                {<FormControl 
                    sx={{ 
                        color: DARK_BLUE,
                        marginTop: "0.2rem",
                        height: "1rem", 
                        borderRadius: '8px',
                        borderSize: '1px',
                        textAlign: 'center',
                        '& .MuiSelect-icon': { 
                            color: DARK_BLUE,
                        },
                    }}>
                        <Select
                            variant = "outlined"
                            sx={{ 
                                fontFamily: 'karla bold', 
                                height: "2rem", 
                                marginTop: "1rem", 
                                display:"flex",
                                merginLeft:"5rem", 
                                width: '200%',
                                color: DARK_BLUE,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: LIGHT_GREY, 
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: LIGHT_GREY, // Set hover border color 
                                },

                                }}

                            labelId="season"
                            id="season-select"
                            value={initSeason}
                            onChange={(event) => {
                                authApi.collect(({activity: ACTIVITIES.NAVIGATION, feature: FEATURE_VALUES.SEASON}))
                                loadData(dispatch, event.target.value as number);
                            }}
                        >
                            {values.map((season) => (
                                <MenuItem
                                    key={season}
                                    value={season}
                                    style={{ fontFamily: 'karla', fontSize: '1rem', color: DARK_BLUE, textAlign: 'center',fontWeight: season === initSeason ? 'bold' : 'normal', // Apply bold font weight to the selected value
                                }}
                                >
                                    {season}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl> }
                </Grid>
            )}
            
        </Grid> 
    )
}

const HorizontalLine = styled.div`
  background-color: ${LIGHT_BLUE};
  height: 2px;
  width: 100%;
  margin: 1rem 2rem 0rem 2rem;
`;

const Title = styled.div`
  color: ${DARK_BLUE};
  padding: 2rem 0rem 2rem 0rem;
  margin: 0 0 2rem 2rem;
  font-size: 1.8rem;
  font-style: italic;
  font-family: Antique Olive Nord;
  display: flex;
`;

export default TopBar;

