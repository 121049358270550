import styled from "styled-components";
import { DARK_BLUE, LIGHT_BLUE, LIGHT_GREY1 } from "../../../assets/colors";

const GameStatSectionHeader = ({ title }) => {
  return (
    <Wrapper>
      <HR />
      <Title>{title}</Title>
      <HR />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  color: ${DARK_BLUE};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: ${LIGHT_GREY1};
  margin: 3rem 0 1rem;
  font-family: Antique Olive Nord;
`;
const Title = styled.div`
  background-color: ${LIGHT_GREY1};
  padding: 0 0.5rem;
  z-index: 1;
`;
const HR = styled.div`
  width: 100%;
  background-color: ${LIGHT_BLUE};
  height: 1.5px;
`;
export default GameStatSectionHeader;
