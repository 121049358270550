import styled from "@emotion/styled";
import { searchBarStyles, sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, LIGHT_GREY1, LIGHT_GREY2 } from "../../../assets/colors";
import { selectUser, setUser } from "../../../slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button, CircularProgress, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { teamApi } from "../../../api/teamApi";

const GeneralTeam = () => {
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState<undefined|string>(undefined);
    const [code, setCode] = useState<undefined|string>(undefined);
    const [team, setTeam] = useState(user?.team);

    const [newProfilePic, setNewProfilePic] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [errorMessageCode, setErrorMessageCode] = useState<string | undefined>(undefined);


    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        setNewProfilePic(file);
        setPreviewUrl(URL.createObjectURL(file));
    }, []);

    const handleConfirm = () => {
        if (newProfilePic && user) {
            setLoading(true); 
            const formData = new FormData();
            formData.append("logo", newProfilePic);
            
            teamApi.updateTeam(formData).then((response) => {
                if (response.status === 203) {
                    const updatedUser = { ...user, profile_url: previewUrl! };
                    dispatch(setUser(updatedUser));
                    setNewProfilePic(null);
                    setPreviewUrl(null);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const handleCancel = () => {
        setNewProfilePic(null);
        setPreviewUrl(null);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Wrapper>
          <Title>GENERAL</Title>
          <div {...getRootProps()}>
          <input {...getInputProps()}/>
          <ProfileEntry>
              <ProfileText>Profile Picture</ProfileText>
              <ProfileIconWrapper>
                      <ProfileIcon src={previewUrl || user?.profile_url} />
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                  {newProfilePic ? (
                      <>
                         {loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                          <UploadButton 
                              className={classes.fullBlueButton}
                              style={{ textTransform: "none"}}
                              onClick={(event) => {event.stopPropagation();handleConfirm()}}
                              disabled={loading}
                          >
                              <CheckIcon style={{marginRight: '0.2rem'}}/>
                              Confirm
                          </UploadButton>
                          <Button
                              className={classes.transparantButton}
                              style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                              onClick={(event) => {event.stopPropagation();handleCancel()}}
                              disabled={loading}
                          >
                              Cancel
                          </Button>
                      </>
                  ) : (
                    <UploadIconeButtonWrapper>
                        <UploadButton 
                            className={classes.fullBlueButton}
                            style={{ textTransform: "none", marginRight: "0.8rem"}}
                            onClick={() => {}}
                        >
                            Upload New
                      </UploadButton>
                      <UploadHintText>Drop file or click to upload</UploadHintText>               
                    </UploadIconeButtonWrapper>
    
                  )}
              </UploadButtonWrapper>

          </ProfileEntry>
          </div>
          <ProfileEntry>
              <ProfileText>Team Name</ProfileText>
              <ProfileIconWrapper>
                {firstName !== undefined ? (<>
                <OutlinedInput         
                    classes={searchBarClass}
                    style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear input"
                                onClick={() => setFirstName('')}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </>) : 
                (<>{team?.name}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                firstName !== undefined ? (
                <>
                        { loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                        <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => {
                            if (firstName !== undefined && firstName !=='' && user !== undefined){
                                setLoading(false);
                                teamApi.updateTeam({name: firstName}).then((response) => {
                                    if (response.status === 203) {
                                        setUser({...user, team: response.data});
                                        setTeam(response.data);
                                        setFirstName(undefined);
                                    }
                                }).finally(() => {
                                    setLoading(false);
                                });
                            }
                        }}  
                        disabled={loading}
                    >
                        <CheckIcon style={{marginRight: '0.2rem'}}/>
                        Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setFirstName(undefined)}
                            disabled={loading}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 1.5rem 0rem 0rem"}}
                            onClick={() => setFirstName(team?.name)}
                        >
                            Edit
                        </Button>
                    </>
                )
                }
                  
              </UploadButtonWrapper>              
          </ProfileEntry>
          <ProfileEntry>
              <ProfileText>Team Code</ProfileText>
              <ProfileIconWrapper>
                {code !== undefined ? (
                <div style={{flexDirection: 'column'}}>
                    <OutlinedInput         
                        classes={searchBarClass}
                        style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem"}}
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear input"
                                    onClick={() => setCode('')}
                                    edge="end"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {errorMessageCode && <ErrorMessage>{errorMessageCode}</ErrorMessage>}
                </div>) : 
                (<>{team?.code}</>)}
              </ProfileIconWrapper>
              <UploadButtonWrapper>
                {
                code !== undefined ? (
                <>
                    { loading && <CircularProgress size={24} style={{ color: DARK_BLUE }} />}
                    <UploadButton 
                    className={classes.fullBlueButton}
                    style={{ textTransform: "none"}}
                    onClick={() => {
                        if (code !== undefined && code !=='' && user !== undefined && code.length <= 3){
                            setLoading(false);
                            setErrorMessageCode(undefined);
                            teamApi.updateTeam({code: code})
                                .then((response) => {
                                    if (response.status === 203) {
                                        setUser({...user, team: response.data});
                                        setTeam(response.data);
                                        setCode(undefined);
                                    }
                                }).finally(() => {
                                    setLoading(false);
                                });
                        } else if (code.length > 3) {
                            setErrorMessageCode('Code must be 3 characters or less');
                        }
                    }}  
                    disabled={loading}
                    >
                    <CheckIcon style={{marginRight: '0.2rem'}}/>
                    Confirm
                    </UploadButton>
                    < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem"}}
                            onClick={() => setCode(undefined)}
                            disabled={loading}
                        >
                            Cancel
                    </Button>
                </>
                ) : (
                    <>
                        < Button
                            className={classes.transparantButton}
                            style={{ border: `1px solid ${LIGHT_GREY0}`, textTransform: "none", width: "6rem", height: "2.2rem", borderRadius: "0.5rem", margin: "1rem 1.5rem 0rem 0rem"}}
                            onClick={() => setCode(team?.code)}
                        >
                            Edit
                        </Button>
                    </>
                )
                }
              </UploadButtonWrapper>              
          </ProfileEntry>
      </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 81.59%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
  padding: 2rem;
`;

const Title = styled.div`
  color: ${DARK_BLUE};
  padding: 2rem 0rem 1rem 0.5rem;
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  font-style: italic;
  font-family: Antique Olive Nord;
  display: flex;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileEntry = styled.div`
  display: flex;
  align-items: center; 
  padding: 1rem 0rem 3rem 0.5rem;
  font-size: 1.3rem;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center; 
  flex: 1;
`;

const ProfileIcon = styled.img`
  height: 4rem;
  width: 4rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
`;

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  flex: 1; 
  padding-right: 1rem;
`;

const UploadHintText = styled.div`
  font-size: 0.7rem;
  color: ${LIGHT_GREY0};
`;
const UploadIconeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadButton = styled(Button)`
  margin-left: auto;
  height: 2.2rem;
  padding: 0rem 0.7rem 0rem 0.7rem;
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
`;

export default GeneralTeam;
