import styled from "styled-components";
import {
  BLUE_GRADIENT,
  GREY,
  LIGHT_GREY,
  RED_BLUE_GRADIENT,
  RED_GRADIENT,
  RED_TO_GREEN_GRADIENT,
} from "../../../assets/colors";
import HexagonField from "../hexagon-field/hexagon-field";

const SectionField = ({ title, data, color }) => {
  let isDataValid = true;
  let domain = 0;

  if (!data) isDataValid = false;

  domain = Math.max(...data);
  if (domain === 0) isDataValid = false;

  const colors = () => {
    switch (color) {
      case "blue":
        return BLUE_GRADIENT;
      case "red":
        return RED_GRADIENT;
      case "red_blue":
        return RED_BLUE_GRADIENT;
      default:
        return RED_TO_GREEN_GRADIENT;
    }
  };

  const linearGradient = () => {
    let stringBuilder = "linear-gradient(to top ";

    for (const color of colors()) {
      stringBuilder += `, ${color}`;
    }
    stringBuilder += ")";

    return stringBuilder;
  };

  const getTotalPerSection = (type: string) => {
    let sum = 0;
    for (const element of data) sum += element[type];
    return sum;
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      {isDataValid ? (
        <>
          {color !== "red_blue" ? (
            <TotalGoals>
              Total: {data.reduce((sum, next) => (sum += next))}
            </TotalGoals>
          ) : (
            <div style={{ display: "flex" }}>
              <TotalGoals>
                {`Placements: ${getTotalPerSection("placements")}`}
              </TotalGoals>
              <TotalGoals>
                {`Pressings: ${getTotalPerSection("pressings")}`}
              </TotalGoals>
              <TotalGoals>
                {`No Reactions: ${getTotalPerSection("noReactions")}`}
              </TotalGoals>
            </div>
          )}
          <ContentWrapper>
            <HexagonFieldWrapper>
              <HexagonField data={data} color={color} />
            </HexagonFieldWrapper>
            <LegendWrapper>
              <Legend
                style={{
                  backgroundImage: linearGradient(),
                }}
              />
              {color === "red_blue" ? (
                <LegendValues>
                  <div style={{ marginTop: "-0.5rem" }}>
                    {"Placements & Pressings"}
                  </div>
                  <div style={{ marginBottom: "-0.5rem" }}>No Reaction</div>
                </LegendValues>
              ) : (
                <LegendValues>
                  <div style={{ marginTop: "-0.5rem" }}>{domain}</div>
                  <div style={{ marginBottom: "-0.5rem" }}>0</div>
                </LegendValues>
              )}
            </LegendWrapper>
          </ContentWrapper>
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 25px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  max-height: 450px;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
const HexagonFieldWrapper = styled.div`
  margin-top: 4rem;
  transform: scale(0.75);
`;
const LegendWrapper = styled.div`
  position: relative;
  top: -4rem;
  left: -2rem;
  display: flex;
  align-items: end;
  width: 6rem;
  transform: scale(0.7);
`;
const Legend = styled.div`
  height: 424px;
  width: 20px;
`;
const LegendValues = styled.div`
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 424px;
  position: relative;
  left: 1rem;
  font-size: 1.5rem;
`;
const TotalGoals = styled.div`
  margin: 0.5rem 0.5rem 0;
`;

export default SectionField;
