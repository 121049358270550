import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_BLUE, LIGHT_GREY } from "../../../assets/colors";

const CustomizedDot = (props) => {
  const { cx, cy, payload, color } = props;

  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r={7}
        stroke={color}
        strokeWidth={2.5}
        fill="white"
      />
      <text
        x={cx}
        y={cy}
        dy={-15}
        fill={color}
        fontSize={16}
        fontWeight="bold"
        textAnchor="middle"
      >
        {payload.value}
      </text>
    </>
  );
};

const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          border: "1px solid",
          borderColor: DARK_BLUE,
          borderRadius: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>
          {`Opponent: ${payload[0].payload.opponent}`}
        </div>
      </div>
    );
  }

  return null;
};

const PlayerStatGraph = ({ stats, title, borderColor, fillColor }) => {
  let isDataValid = true;

  if (!stats) {
    isDataValid = false;
    stats = [];
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      {isDataValid ? (
        <>
          <ResponsiveContainer width="99%" height={200}>
            <AreaChart
              data={stats}
              margin={{
                top: 30,
                right: 30,
                left: 30,
                bottom: 30,
              }}
            >
              <CartesianGrid vertical={false} stroke={LIGHT_BLUE} />
              <Tooltip content={<CustomTooltip />} animationDuration={150} />
              <Area
                type="monotone"
                dataKey="value"
                stroke={borderColor}
                strokeWidth={2.5}
                fill={fillColor}
                dot={<CustomizedDot color={borderColor} />}
                activeDot={{ stroke: borderColor, strokeWidth: 2, r: 8 }}
                animationDuration={300}
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-top: 1.5rem;
  padding-top: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export default PlayerStatGraph;
