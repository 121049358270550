import { Route, Redirect, useHistory } from "react-router-dom";
import { AuthenticationRouteProps } from "./Route";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPage } from "../slices/navigationSlice";
import Workspace from "../features/workspace";

const PrivateRoute = ({ component: Component }: AuthenticationRouteProps) => {
  const token = sessionStorage.token;
  const history = useHistory();
  const page = useSelector(selectPage);
  useEffect(() => {
    history.push(page);
  }, [page, history]);

  if (token === undefined) {
    history.push("/logout");
    return <Redirect to="/logout" />;
  }

  return (
    <Route
      render={() =>
        token ? <Workspace component={Component} /> : <Redirect to="/logout" />
      }
    />
  );
};

export default PrivateRoute;
