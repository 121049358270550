import styled from "styled-components";
import { Button, Dialog, Grid, InputAdornment, MenuItem, OutlinedInput, Popover } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DARK_BLUE, GREY, LIGHT_BLUE, LIGHT_GREY, LIGHT_GREY1 } from "../../../assets/colors";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@mui/icons-material/Add';
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { black } from "material-ui/styles/colors";
import { useSelector } from "react-redux";
import { getPlaylists, selectPlaylists, setSelectedPlaylist } from "../../../slices/videoSlice";
import { useEffect, useState } from "react";
import { PlaylistCreate } from "./playlistModals.tsx/playlistCreate";
import { useAppDispatch } from "../../../app/store";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { PlaylistDeleteModal } from "./playlistModals.tsx/playlistDeleteModal";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import logo from "../../../assets/images/Logo_Ball.png";
import useDownloader from "react-use-downloader";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { authApi } from "../../../api/authApi";
import { ACTIVITIES, FEATURE_VALUES } from "../../../models/constant/collect";

export const searchBarStyles = makeStyles(() => ({
    root: {
      height: "2.4rem",
      margin: "0 1rem",
      width: "calc(100% - 2rem)",
      borderColor: LIGHT_GREY,
      borderRadius: '8px',
      "&$focused $notchedOutline": {
        borderColor: DARK_BLUE,
      },
    },
    focused: {},
    notchedOutline: {},
}));

export const Playlists = () => {
    const classes = sportsAiGlobalStyles();
    const { download } = useDownloader();
    const searchBarClass = searchBarStyles();
    const dispatch = useAppDispatch();

    const allPlaylists = useSelector(selectPlaylists);
    const [playlists, setPlaylists] = useState(allPlaylists);
    const [playlistsToDelete, setPlaylistsToDelete] = useState<number[]>([]);

    const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const open = Boolean(anchorEl);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getPlaylists()).then(() => setIsLoading(false));
    }, [dispatch])

    const applySearchFilter = (value: string) => {
      setPlaylists(allPlaylists.filter((playlist) => {
        return playlist.name.toLowerCase().includes(value.toLowerCase());
      }));
    }

    const handleClick = (event, playlistId) => {
      if (open)
        return;
      setAnchorEl(event.currentTarget);
      setSelectedPlaylistId(playlistId);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSort = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
      }
      setSortConfig({ key, direction });
      sortPlaylists(key, direction);
  };

  const sortPlaylists = (key, direction) => {
    const sortedPlaylists = [...playlists].sort((a, b) => {
      if (key === 'createdOn') {
        if (new Date(a[key]) < new Date(b[key])){
          return direction === 'ascending' ? -1 : 1;
        }
        if (new Date(a[key]) > new Date(b[key])){
          return direction === 'ascending' ? 1 : -1;
        }
      }
      if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setPlaylists(sortedPlaylists);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
        if (sortConfig.direction === 'ascending') {
            return <KeyboardArrowUpIcon style={{ color: DARK_BLUE }} />;
        } else {
            return <KeyboardArrowDownIcon style={{ color: DARK_BLUE }} />;
        }
    }
    else{
      return <KeyboardArrowDownIcon/>;
    }
  };

    return (
        <>
        <Dialog open={isPlaylistModalOpen} onClose={() => setIsPlaylistModalOpen(false)}>
          <PlaylistCreate cancelCallback={() => setIsPlaylistModalOpen(false)} />
        </Dialog>
        <Dialog open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
          <PlaylistDeleteModal setIsModalOpen={setIsDeleteModalOpen} selectedPlaylists={playlistsToDelete} playlists={playlists} />
        </Dialog>
        <Grid container spacing={0}>
            <Grid item xs={10}>
                <SearchWrapper>
                    <OutlinedInput
                        classes={searchBarClass}
                        placeholder="Search a playlist..."
                        onChange={(event) => applySearchFilter(event.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search style={{ color: LIGHT_GREY }} />
                            </InputAdornment>
                        }
                    />
                </SearchWrapper>
            </Grid>
            <Grid item xs={2}>
                <AddPlaylistWrapper>
                    <Button 
                      className={classes.transparantButton}
                      onClick={() => setIsPlaylistModalOpen(true)}
                    > 
                        <AddIcon/> New playlist
                    </Button>
                </AddPlaylistWrapper>
            </Grid>
        </Grid>
        <Table>
          <TableHeader>
            <HeaderCell onClick={() => handleSort('name')} isActive={sortConfig.key === 'name'}>
                Playlist Name {renderSortIcon('name')}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort('createdOn')} isActive={sortConfig.key === 'createdOn'}>
                Created on {renderSortIcon('createdOn')}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort('clips')} isActive={sortConfig.key === 'clips'}>
                Clips {renderSortIcon('clips')}
            </HeaderCell>
            <HeaderCell onClick={() => handleSort('status')} isActive={sortConfig.key === 'status'}>
                Status {renderSortIcon('status')}
            </HeaderCell>
            <div>
                <Button onClick={() => {
                    setIsLoading(true);
                    dispatch(getPlaylists()).then(() => setIsLoading(false));
                }}>
                    <ReplayOutlinedIcon style={{ color: DARK_BLUE }} />
                </Button>
            </div>
            </TableHeader>
            {
              isLoading ? <LoadingLogo src={logo} alt="SportsAI_Logo" /> :
              playlists.map((playlist, i) => {
                  return(
                      < PlaylistInfo
                      key={i}
                      style={
                          i % 2 === 0
                          ? { backgroundColor: "white" }
                          : { backgroundColor: LIGHT_GREY1 }
                      }
                      onClick={() => {
                        if (playlist.status === 'completed')
                          dispatch(setSelectedPlaylist(playlist.id));
                          authApi.collect(({activity: ACTIVITIES.READ, feature: FEATURE_VALUES.PLAYLIST}))
                      }}
                      >
                      <div style={{ textAlign: "left", textTransform: "capitalize"}}>
                        <div style={{ display: "inline-block", textAlign: "left", textTransform: "capitalize", maxWidth: "500px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",  }}>
                          {playlist.name} 
                        </div>
                        {playlist.isnew && <NewPlaylist style={{ display: "inline-block", verticalAlign: "top" }}> NEW </NewPlaylist>}
                      </div>
                      <div style={{ textAlign: "left", textTransform: "capitalize" }}>{playlist.createdOn}</div>
                      <div style={{ textAlign: "left", paddingLeft: "1rem",  textTransform: "capitalize" }}>{playlist.clips}</div>
                      <div style={{ textAlign: "left", textTransform: "capitalize" }}>{playlist.status}</div>
                      <Button 
                          className={classes.transparantButton}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClick(event, playlist.id);
                          }}
                        >
                          <MoreVertIcon style={{color: GREY}}/>
                        </Button>
                        <Popover
                          id={playlist.id.toString()}
                          open={open &&selectedPlaylistId === playlist.id}
                          anchorEl={anchorEl}
                          onClick={(event) => event.stopPropagation()}
                          onClose={() => {
                            handleClose();
                            setAnchorEl(null);
                            setSelectedPlaylistId(null);
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          style={{borderRadius: "10px"}}
                        >
                          <MenuItem 
                            onClick={(event) => {
                              event.stopPropagation();
                              if (playlist.videoLink) {
                                download(playlist.videoLink, playlist.name + ".mp4")
                                authApi.collect(({activity: ACTIVITIES.DOWNLOAD, feature: FEATURE_VALUES.PLAYLIST}))
                              }
                            }}> 
                          <DownloadIcon style={{marginRight: '1rem'}}/> Download
                          </MenuItem> 
                          <MenuItem 
                            id={playlist.id.toString()}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (selectedPlaylistId !== null) {
                                setPlaylistsToDelete([selectedPlaylistId]);
                                setIsDeleteModalOpen(true);
                                setAnchorEl(null);
                              }
                            }}> 
                          <DeleteOutlinedIcon style={{marginRight: '1rem'}}/> Delete
                          </MenuItem>
                        </Popover>
                      </PlaylistInfo>
                  )
              })
          }
        </Table>
        </>
        )
}

const LoadingLogo = styled.img`
  width: 15rem;
  animation: blinkAndGrow 2s infinite ease;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const SearchWrapper = styled.div`
    padding: 1rem 0rem 1rem 0rem 
`;
 
const AddPlaylistWrapper = styled.div`
  width: 90%;
  text-align: center;
  display: inline-block;
  border: 1px solid ${LIGHT_GREY};
  border-radius: 8px;
  padding: 4px;
  margin: 0.9rem 0rem 0rem 0rem;
`;

const Table = styled.div`
  width: 98%;
  margin: 40px 20px 0px 20px;
  background-color: white;
  border-radius: 18px;
  border: 1px solid ${LIGHT_GREY};
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 50% 25% 10% 10% 5%;
  text-align: center;
  align-items: center;
  padding: 10px;
  margin: 0 2rem;
  color: ${GREY};
`;

const HeaderCell = styled.div<{isActive: boolean;}>`
    text-align: left;
    cursor: pointer;
    color: ${({ isActive }) => (isActive ? DARK_BLUE : 'inherit')};
    display: flex;
    align-items: center;
`;
const NewPlaylist = styled.div`
  background-color: ${LIGHT_BLUE};
  color: ${DARK_BLUE};
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  display: inline-block;
  text-transform: capitalize;
`;



const PlaylistInfo = styled.div`
  display: grid;
  grid-template-columns: 50% 25% 10% 10% 5%;
  text-align: center;
  align-items: center;
  color: ${black};
  padding: 10px;
  border-radius: 8px;
  margin: 0 2rem;
  > div {
    text-transform: capitalize;
  }
  &:hover {
    cursor: pointer;
  }
`;
