import { Button, Grid, TextField } from "@material-ui/core"
import { sportsAiGlobalStyles } from "../../../../shared/globalStyle";
import { LIGHT_GREY } from "../../../../assets/colors";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { Playlist } from "../../../../models/playlists";
import { createPlaylist } from "../../../../slices/videoSlice";
import { useAppDispatch } from "../../../../app/store";
import styled from "styled-components";
import ModalTopbar from "../../../../components/navigation/modalTopbar";

export const PlaylistCreate = ({cancelCallback}) => {
    const classes = sportsAiGlobalStyles();
    const dispatch = useAppDispatch();
    const [playlistName, setPlaylistName] = useState("");
    const [description, setDescription] = useState("");
    const [duration, setDuration] = useState(10);

    const handleSubmit = (dispatch) => {
        if (!playlistName || !description) return;
        const newPlaylist: Playlist = {
            id: -1,
            name: playlistName,
            description: description,
            sequence_duration: duration,
            createdOn: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
            clips: 0,
            events: [],
            isnew: true,
            videoLink: "",
            status: "pending"
        }
        dispatch(createPlaylist(newPlaylist));
        cancelCallback()
    };
    return (
    <>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                    <ModalTopbar 
                        Action={'Create a new playlist'}
                        onClose={cancelCallback}
                        showBar={true}
                    />
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    style={{width: '95%', margin: '1rem', borderRadius: '20px'}}
                    id="playlist-name" 
                    label="Playlist name" 
                    variant="outlined" 
                    value={playlistName}
                    onChange={(e) => setPlaylistName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    style={{width: '95%',margin: '1rem', borderRadius: '20px'}}
                    id="outlined-baic" 
                    label="Add an optional description" 
                    variant="outlined" 
                    multiline
                    rows={3}
                    rowsMax={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <ClipDurationWrapper>
                Clips duration:
                <Button 
                    className={duration === 10? classes.fullBlueButton: classes.transparantButton} 
                    style={{margin: '0rem 1rem 0rem 0.5rem'}}
                    onClick={() => setDuration(10)}
                >
                    10s
                </Button>
                <Button 
                    className={duration === 15? classes.fullBlueButton: classes.transparantButton} 
                    style={{margin: '0rem 1rem 0rem 0rem'}}
                    onClick={() => setDuration(15)}
                >
                    15s 
                </Button>
                <Button 
                    className={duration === 30? classes.fullBlueButton: classes.transparantButton} 
                    style={{margin: '0rem 1rem 0rem 0rem'}}
                    onClick={() => setDuration(30)}
                >
                    30s
                </Button>
                </ClipDurationWrapper>
            </Grid>            
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={2}>
                <Button 
                    style={{border: "10px", borderColor: LIGHT_GREY, height: "70px"}}
                    className={classes.transparantButton}
                    onClick={cancelCallback}
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button 
                    className={(playlistName && description) ? classes.fullBlueButton: classes.fullGreyButton}
                    onClick={() => {handleSubmit(dispatch)}}
                >
                    <CheckIcon/> create playlist
                </Button>
            </Grid>
        </Grid>
    </>
    )
}

const ClipDurationWrapper = styled.div`
    padding: 0rem 0rem 0rem 1.5rem
`;