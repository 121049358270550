import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";


export enum LeagueStatPage {
  Overview,
  Squad,
}

interface CompetitionState {
  playersStat: {competitionId: number, season: number, stat: string} | undefined;
  clubStat: {competitionId: number, season: number, stat: string} | undefined;
  leaguePage: LeagueStatPage; 
}

export const initialState: CompetitionState = {
    playersStat: undefined,
    clubStat: undefined,
    leaguePage: LeagueStatPage.Overview
};



export const competitionSlice = createSlice({
  name: "CompetitionSlice",
  initialState,
  reducers: {
    setCurrentPlayerStatParams: (state, action: PayloadAction<{competitionId: number, season: number, stat: string} | undefined>) => {
        state.playersStat = action.payload;
    },
    setCurrentClubStatParams: (state, action: PayloadAction<{competitionId: number, season: number, stat: string} | undefined>) => {
        state.clubStat = action.payload
    },
    setLeaguePage: (state, action: PayloadAction<LeagueStatPage>) => {
        state.leaguePage = action.payload
    }
  },
});

export const { setCurrentPlayerStatParams, setCurrentClubStatParams, setLeaguePage } = competitionSlice.actions;
export const selectPlayersStatParams = (state: RootState) => state.competitionSlice.playersStat;
export const selectClubStatParams = (state: RootState) => state.competitionSlice.clubStat;  
export const selectLeaguePage = (state: RootState) => state.competitionSlice.leaguePage;
export default competitionSlice.reducer;
