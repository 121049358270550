import styled from "styled-components";
import logo from "./../../assets/images/Logo_Ball.png";
import { LIGHT_GREY1, LIGHT_GREY2 } from "../../assets/colors";
import { useSelector } from "react-redux";
import { selecIsLoading } from "../../slices/navigationSlice";

export const Loading = () => {
    return (
        <Wrapper
            style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            }}
            >
            <LoadingLogo src={logo} alt="SportsAI_Logo" />
        </Wrapper>
    )
}

export const LoadingWrapper = (props) => {
  const isLoading: boolean = useSelector(selecIsLoading);
  return !isLoading ? <>{props.comp}</> : (
      <Wrapper
          style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          }}
          >
          <LoadingLogo src={logo} alt="SportsAI_Logo" />
      </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;

const LoadingLogo = styled.img`
  width: 15rem;
  animation: blinkAndGrow 2s infinite ease;
`;