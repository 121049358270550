import styled from "styled-components";
import { changePage, Page } from "../../slices/navigationSlice";
import store, { useAppDispatch } from "../../app/store";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  Dialog,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import {
  getGame,
  selectGamesSummary,
  setCurrentGame,
} from "../../slices/gamesSlice";
import { GameSummary } from "../../models/game";
import { useSelector } from "react-redux";
import {
  DARK_BLUE,
  GREY,
  LIGHT_GREY1,
  LIGHT_GREY,
  LIGHT_GREY2,
  RED,
  GREEN,
  YELLOW,
} from "../../assets/colors";
import { sendEvent } from "../../services/gAnalytics";
import TopBar from "../../components/navigation/topbar";
import { LoadingWrapper } from "../../components/navigation/loading";
import { CreateGame } from "./createGames";
import VideoUploader from "./videoUploader";
import { selectAccountType } from "../../slices/userSlice";
import { TEAM_ACCOUNT, TEAM_LEAGUE } from "../../models/constant/accountType";
import { searchBarStyles, sportsAiGlobalStyles } from "../../shared/globalStyle";

const sortOptions = ["Opponent", "Date", "Competition", "Place"];

const statsLabel = (game: GameSummary) => {
  if (!game.has_video){
    return <>Upload Video</>
  }
  if (!game.isOperated) {
        return <>Processing </>
  }
  else {
    return <> Stats </>
  }
}

const GamesList = () => <LoadingWrapper comp={<GamesListBase/>}/>

const GamesListBase = () => {
  const classes = sportsAiGlobalStyles();
  const searchBarClass = searchBarStyles();
  const dispatch = useAppDispatch();
  const [games, setGames] = useState<GameSummary[]>(useSelector(selectGamesSummary));

  const [sortType, setSortType] = useState("");
  const [isReversed, setIsReversed] = useState(false);
  const [newGameCounter, setNewGameCounter] = useState(0);

  const [isCreateGameComp, setIsCreateGameComp] = useState<boolean>(false);
  const [isUploadVideoComp, setIsUploadVideoComp] = useState<boolean>(false);
  const [gameVideoId, setGameVideoId] = useState<number>(0);

  const accountType = useSelector(selectAccountType);


  useEffect(() => {
    setGames(selectGamesSummary(store.getState()));
  }, [isCreateGameComp, isUploadVideoComp])

  const getScore = (game:  GameSummary) => {
    if (!game.isOperated)
      return (<div></div>)

    let color;
    if (game.teamScore > game.opponentScore) color = GREEN;
    else if (game.teamScore < game.opponentScore) color = RED;
    else color = GREY;

    if (game.place === "Home") {
      return (
        <div style={{ color: color }}>
          {`${game.teamScore} - ${game.opponentScore}`}
        </div>
      );
    } else {
      return (
        <div style={{ color: color }}>
          {`${game.opponentScore} - ${game.teamScore}`}
        </div>
      );
    }
  };

  const applySearchFilter = (searchFilter: string) => {
    searchFilter = searchFilter.toLowerCase();
    const filteredGames: GameSummary[] = [];

    for (const game of store.getState().gamesSlice.gamesSummary) {
      if (
        game.opponentName.toLowerCase().includes(searchFilter) ||
        game.date.toLowerCase().includes(searchFilter) ||
        game.competition.toLowerCase().includes(searchFilter) ||
        game.place.toLowerCase().includes(searchFilter) ||
        game.teamScore.toString().toLowerCase().includes(searchFilter) ||
        game.opponentScore.toString().toLowerCase().includes(searchFilter)
      ) {
        filteredGames.push(game);
      }
    }
    setGames(filteredGames);
  };

  const sortGames = (sortType: string) => {
    setIsReversed(false);

    const gamesCopy = [...games];
    switch (sortType) {
      case "Opponent":
        setGames(
          gamesCopy.sort((a, b) => (a.opponentName < b.opponentName ? -1 : 1))
        );
        break;
      case "Date":
        setGames(gamesCopy.sort((a, b) => (a.date < b.date ? -1 : 1)));
        break;
      case "Competition":
        setGames(
          gamesCopy.sort((a, b) => (a.competition < b.competition ? -1 : 1))
        );
        break;
      case "Place":
        setGames(gamesCopy.sort((a, b) => (a.place < b.place ? -1 : 1)));
        break;
    }
  };

  const reverseArray = () => {
    const gamesCopy = [...games];
    setGames(gamesCopy.reverse());
    setIsReversed(!isReversed);
  };

  const goToGameStats = (gameId: number) => {
    dispatch(setCurrentGame(undefined));
    dispatch(getGame(gameId));
    dispatch(changePage(Page.GameStat));
  };

  return (
    <Wrapper>
      <TopBar name={"Games"}/>
      <Dialog 
        open={isCreateGameComp} 
        onClose={() => setIsCreateGameComp(false)}
      >
        <CreateGame setIsCreateGameComp={setIsCreateGameComp} newGameCounter={newGameCounter} setNewGameCounter={setNewGameCounter}/>
      </Dialog>
      <Dialog 
        open={isUploadVideoComp} 
        onClose={() => setIsUploadVideoComp(false)}
        >
        <VideoUploader gameId={gameVideoId} setIsStatModalOpen={setIsUploadVideoComp}/>
      </Dialog>
      <OutlinedInput
        classes={searchBarClass}
        placeholder="Search a game..."
        onChange={(event) => applySearchFilter(event.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <Search style={{ color: GREY }} />
          </InputAdornment>
        }
      />
      <FiltersWrapper>
        <SortTypeWrapper>
          Sort by:
          <Select
            variant="outlined"
            style={{ height: "2rem", marginLeft: "5px", width: '9rem' }}
            value={sortType}
            onChange={(event) => {
              sendEvent("stats", "sort-Games",  event.target.value as string, 5);
              setSortType(event.target.value as string);
              sortGames(event.target.value as string);
            }}
          >
            {sortOptions.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          {
            (accountType === TEAM_ACCOUNT || accountType === TEAM_LEAGUE) &&
            <Button 
              className={classes.outlinedButton}
              onClick={() => setIsCreateGameComp(true)} 
            >
              Add Game
            </Button>
          }
        </SortTypeWrapper>
        <SortTypeWrapper>
          Order:
          <ArrowWrapper>
            {isReversed ? (
              <ArrowUpward
                className={classes.orderArrow}
                onClick={
                  () => reverseArray()}
              />
            ) : (
              <ArrowDownward
                className={classes.orderArrow}
                onClick={() => reverseArray()}
              />
            )}
          </ArrowWrapper>
        </SortTypeWrapper>
      </FiltersWrapper>
      <Table>
        <TableHeader>
          <div style={{ textAlign: "left" }}>Opponent</div>
          <div>Date</div>
          <div>Competition</div>
          <div>H/A</div>
          <div>Result</div>
        </TableHeader>
        {games.map((game, i) => {
          return game.opponentName &&
            game.date &&
            game.competition &&
            game.place ? (
            <GameInfo
              key={i}
              style={
                i % 2 === 0
                  ? { backgroundColor: "white" }
                  : { backgroundColor: LIGHT_GREY1 }
              }
              onClick={() => {
                if (!game.has_video) {
                  setGameVideoId(game.id)
                  setIsUploadVideoComp(true)
                }
                else if (game.isOperated) {
                  sendEvent("stats", "select-game-Stats", game.place, i);
                  goToGameStats(game.id);
                }
              }}
            >
              <div style={{ textAlign: "left", textTransform: "capitalize" }}>{game.opponentName}</div>
              <div>{game.date}</div>
              <div>{game.competition}</div>
              <div>{game.place}</div>
              <div>{getScore(game)}</div>
              <Button className={classes.transparantButton}>
                {statsLabel(game)}
              </Button>    
            </GameInfo>
          ) : (
            <></>
          );
        })}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  font-family: Karla Bold;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem;
`;
const SortTypeWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 10px;
  }
`;
const ArrowWrapper = styled.div`
  padding: 3px;
  border: 1px solid ${GREY};
  border-radius: 4px;
  background-color: white;
  margin-left: 10px;
`;
const Table = styled.div`
  width: 100%;
  margin: 40px 0px;
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 15% 12.5% 12.5%;
  text-align: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1.5px solid ${LIGHT_GREY};
  margin: 0 2rem;
`;
const GameInfo = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 15% 12.5% 12.5%;
  text-align: center;
  align-items: center;
  color: ${DARK_BLUE};
  padding: 10px;
  border-radius: 8px;
  margin: 0 2rem;
  > div {
    text-transform: capitalize;
  }
  &:hover {
    cursor: pointer;
  }
`;

export default GamesList;
