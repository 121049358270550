import { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Button, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import {
  DARK_BLUE,
  GREY,
  LIGHT_BLUE,
  LIGHT_GREY,
  LIGHT_GREY2,
  WHITE,
} from "../../../assets/colors";
import { SequencesType, setShowSequenceModal, statSequencesParams } from "../../../slices/navigationSlice";
import { useAppDispatch } from "../../../app/store";

const useStyles = makeStyles(() => ({
  tabButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 10px 40px 10px",
    fontFamily: "Karla Bold",
  },
  tabButtonSelected: {
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    margin: "0px 10px 40px 10px",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
}));

const CustomizedDot = (props) => {
  const { cx, cy, payload } = props;
  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r={7}
        stroke={DARK_BLUE}
        strokeWidth={2.5}
        fill="white"
      />
      <text
        x={cx}
        y={cy}
        dy={-15}
        fill={DARK_BLUE}
        fontSize={16}
        fontWeight="bold"
        textAnchor="middle"
      >
        {payload.teamScore}
      </text>
    </>
  );
};

const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          border: "1px solid",
          borderColor: DARK_BLUE,
          borderRadius: "8px",
        }}
      >
        <div
          style={{ fontWeight: "bold" }}
        >{`${payload[0].payload.name}: ${payload[0].payload.teamScore}`}</div>
        <div
          style={{ fontWeight: "bold" }}
        >{`${payload[0].payload.opponentName}: ${payload[0].payload.opponentScore}`}</div>
      </div>
    );
  }

  return null;
};

const GoalsScored = ({ gamesPlayed }) => {

  const dispatch = useAppDispatch();

  const onGameClick = (props) => {
    if (props?.activePayload){
      dispatch(setShowSequenceModal(true))
      dispatch(statSequencesParams({
        sequenceType: SequencesType.GoalsScored,
        sequenceOptions: [props.activePayload[0].payload.id]
      }));
    }
  }

  let isDataValid = true;
  let formattedGames;
  if (!gamesPlayed) {
    isDataValid = false;
    gamesPlayed = [];
  } else{
    formattedGames = [...gamesPlayed].reverse();
  }

  const [graphDataAmount, setGraphDataAmount] = useState(5);

  const classes = useStyles();

  const formatData = (size: number) => {
    if (size < formattedGames.length) {
      return formattedGames.slice(formattedGames.length - size, gamesPlayed.length);
    }
    return formattedGames;
  };

  return (
    <Wrapper>
      <Title>Goals Scored</Title>
      {isDataValid ? (
        <>
          <div style={{ display: "flex" }}>
            <Button
              className={
                graphDataAmount === 5
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => setGraphDataAmount(5)}
            >
              Last 5
            </Button>
            <Button
              className={
                graphDataAmount === 10
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => setGraphDataAmount(10)}
            >
              Last 10
            </Button>
            <Button
              className={
                graphDataAmount === Infinity
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => setGraphDataAmount(Infinity)}
            >
              Season
            </Button>
          </div>
          <ResponsiveContainer width="99%" height={300}>
            <AreaChart
              data={formatData(graphDataAmount)}
              onClick={(props) => onGameClick(props)}
              margin={{
                top: 30,
                right: 30,
                left: 30,
                bottom: 30,
              }}
            >
              <CartesianGrid vertical={false} stroke={LIGHT_BLUE} />
              <Tooltip content={<CustomTooltip />} animationDuration={150}/>
              <Area
                type="monotone"
                dataKey="teamScore"
                stroke={DARK_BLUE}
                strokeWidth={2.5}
                fill={LIGHT_GREY2}
                dot={<CustomizedDot />}
                activeDot={{ stroke: DARK_BLUE, strokeWidth: 2, r: 8 }}
                isAnimationActive={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-bottom: 2rem;
  padding-top: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export default GoalsScored;
