import styled from "styled-components";
import { GREY, GREY2, LIGHT_GREY, RED_TO_GREEN_GRADIENT } from "../../../assets/colors";

const getBarColor = (percentage: number) =>
  RED_TO_GREEN_GRADIENT[
    Math.min(
      Math.floor(percentage * (RED_TO_GREEN_GRADIENT.length - 1)),
      RED_TO_GREEN_GRADIENT.length - 1
    )
  ];
  
const MultiProgressBar = ({title, labels, percentages, values}) => {
  const progresions: {label: string, percentage: number, ratio:number[] }[] = labels.map((label, index) => ({
    label: label, 
    percentage: percentages[index],
    ratio: [values[index], values[index+3]]
  }));
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        {progresions.length !== 0  ? (
          <>
          {progresions.map((progresion) => {
            return (
            <>
              <ProgressBarWrapper>
                <Label>{progresion.label}</Label>
                <ProgressBarFilled
                  style={{
                    width: `calc(${progresion.percentage*0.85}% - 2.5rem)`,
                    backgroundColor: getBarColor(progresion.percentage/100),
                  }}
                />
                <Percentage>{progresion.percentage}% 
                <Value>{"("}{progresion.ratio[0]}/{progresion.ratio[1]}{")"}</Value></Percentage>
              </ProgressBarWrapper>
            </>
            )
          })}
          </>
        ) : (
          <NoDataWrapper>No Data</NoDataWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding-top: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  margin-right: 0.5rem;
`;

const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const NoDataWrapper = styled.div`
  font-size: 1.2rem;
  color: ${GREY};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1rem;
`;

const Percentage = styled.div`
  position: absolute;
  right: 10px; /* Adjusted right property to move the percentage slightly to the left */
  display: flex;
  align-items: center;
  height: 1rem;
  margin-left: 0px;
`;
const Value = styled.div`
color: ${GREY2};
margin-left: 2px;
`;

const ProgressBarWrapper = styled.div`
  width: calc(100% - 4rem);
  display: flex;
  align-items: center;
  margin: 0 2rem 2rem;
  position: relative;
`;

const ProgressBarFilled = styled.div`
  height: 0.75rem;
  background-color: black;
  border-radius: 5px;
  animation: expand 1s ease;
`;

export default MultiProgressBar;
