import { PlaylistTableModal } from "./playlistTableModal";
import { useState } from "react";
import { PlaylistCreate } from "./playlistCreate";
import { PlaylistModalConfirm } from "./playlistModalConfirm";

enum ModalState {
    table,
    create,
    confirm
}

export const PlaylistModal = ({setIsModalOpen}) => {
    const [modalState, setModalState] = useState(ModalState.table);
    return (
        <>
            {
                modalState === ModalState.table ? (<PlaylistTableModal newPlaylistCallback={() => {setModalState(ModalState.create)}} next={() => {setModalState(ModalState.confirm)}} closeModal={() => {setIsModalOpen(false)}}/>):
                (modalState === ModalState.create? <PlaylistCreate cancelCallback={() => {setModalState(ModalState.table)}}/> : 
                (<PlaylistModalConfirm closeModal={() => {setIsModalOpen(false)}}/>))
            }
        </>
    )
}