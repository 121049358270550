import logo from "./../../assets/images/Logo_sports_AI_Couleur.png";
import {
  Grid,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
  Button,
} from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getUser, loginUser, setSeason, setTeamView, UserCredentials } from "../../slices/userSlice";
import { useAppDispatch } from "../../app/store";
import {
  AccountCircleOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { useState } from "react";
import { DARK_BLUE } from "../../assets/colors";
import { Page, changePage } from "../../slices/navigationSlice";
import { sendEvent } from "../../services/gAnalytics";
import { TEAM_ACCOUNT, PLAYER_ACCOUNT, LEAGUE_ACCESS, TEAM_LEAGUE } from "../../models/constant/accountType";
import { loadData, loadPlayerData } from "../../services/loadData"
import { setCompetitions } from "../../slices/teamSlice";
import { sportsAiGlobalStyles, sportsAiOutlinedInput } from "../../shared/globalStyle";


export const LoginForm = () => {
  const classes = sportsAiGlobalStyles();
  const outlinedInputClasses = sportsAiOutlinedInput({isError: false});
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm({ mode: "onChange" });
  const history = useHistory();

  const [showPassword, setPasswordVisibility] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const checkLoginResponse = (data) => {
    if (data["error"] !== undefined){
      if (data["error"]["code"] === 1){
        setErrorMessage("Invalid login credentials. Please make sure your username and password are correct. If you've forgotten your password, you can use the 'Forgot password' link or contact our support team at info@sportsai.ca for assistance.");
      }
      else if (data["error"]["code"] === 2){
        setErrorMessage("Oops! Something went wrong. It seems there is an issue with your account. Please double-check your username and password. If the problem persists, contact our support team as info@sportsai.ca for assistance.");
      }
      else {

        throw Error();
      }
      return true
    }
    else {
      return false
    } 
  }

  const onSubmit = (userData: UserCredentials) => {
    dispatch(loginUser(userData)).then((data) => {
      var season: number;
      sendEvent("login", "account", userName, 1);
      if (data.payload !== undefined){
        if (checkLoginResponse(data.payload))
          return;
        season = data.payload["endSeason"]; 
        const account_type = data.payload["accountType"]; 
        const userdata = data.payload;
        dispatch(setSeason(season));
        dispatch(getUser()).then(() => {
          switch(account_type) {
            case PLAYER_ACCOUNT:
              loadPlayerData(dispatch, season, data.payload)
              history.push(Page.PlayerProfile);
              break;
            case TEAM_ACCOUNT:
              loadData(dispatch, season);
              history.push(Page.Home);
              break;
            case TEAM_LEAGUE:  
              loadData(dispatch, season);
              history.push(Page.Home);
              break;
            case LEAGUE_ACCESS:
              console.log("TEAM_LEAGUE")
              dispatch(setCompetitions(userdata["competitions"]));
              dispatch(changePage(Page.League));
              history.push(Page.League);
              break;
            default:
              break;
            }
        });
      } else {
        throw Error();
      }
    }).catch(() => {
      setErrorMessage("Oops! An unexpected error occurred. Our team has been notified, and we're working to fix it. In the meantime, please try again later. If the issue persists, feel free to contact our support team for assistance.");
    });
  };

  const isFormValid = () => {
    return userName.length > 0 && password.length > 0;
  };

  return (
    <div className={classes.root}>
      <Grid container component="main" direction="column" alignItems="center">
        <img
          src={logo}
          alt="Logo"
          className={classes.logo}
          width="230"
          height="150"
        />
        <Wrapper>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <LoginTitle> Login</LoginTitle>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  classes={outlinedInputClasses}
                  inputRef={register({ required: true })}
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  placeholder="Username"
                  autoFocus
                  onChange={(event) => setUsername(event.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircleOutlined style={{ color: DARK_BLUE }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  inputRef={register({ required: true })}
                  classes={outlinedInputClasses}
                  name="password"
                  id="password"
                  label="Password"
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={(event) => setPassword(event.target.value)}
                  type={showPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockOutlined style={{ color: DARK_BLUE }} />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setPasswordVisibility(!showPassword)}
                      >
                        {showPassword ? (
                          <Visibility style={{ color: DARK_BLUE }} />
                        ) : (
                          <VisibilityOff style={{ color: DARK_BLUE }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>       
              {/* <div style={{ marginTop: "20px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      inputRef={register()}
                      name="stayConnected"
                      style={{ color: DARK_BLUE, marginLeft: "-10px" }}
                    />
                    <div>Remember Me</div>
                  </div> 
                  <Link
                    href="/forgot"
                    className={classes.link}
                    style={{ color: DARK_BLUE }}
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>  */}
              <Button
                className={classes.fullBlueButton}
                type="submit"
                style={{
                  backgroundColor: DARK_BLUE,
                  width: "100%",
                }}
                variant="contained"
                color="primary"
                disabled={!isFormValid()}
              >
                {!isFormValid() ? (
                  <LockOutlined style={{ marginRight: "5px" }} />
                ) : (
                  ""
                )}
                Sign In
              </Button>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    marginRight: "4px",
                    color: "black",
                    opacity: "60%",
                  }}
                >
                  Don't have an account ?
                </div>
                <Link href="/register" style={{ color: DARK_BLUE }}>
                  Sign Up
                </Link>
                
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    marginRight: "4px",
                    color: "black",
                    opacity: "60%",
                  }}
                >
                  Have a look at our 
                </div>
                <Link href="/demo" style={{ color: RED }}>
                  DEMO
                </Link>
              </div> */}
            </form>
            {errorMessage && <div className={classes.errorText} style={{ textAlign: "center"}}>{errorMessage}</div>}
        </Wrapper>
      </Grid>
    </div>
  );
};

const LoginTitle = styled.div`
  text-align: left;
  font-size: 2rem;
  font-style: italic;
  font-family: Antique Olive Nord;
`;

const Wrapper = styled.div`
  textAlign: center;
  width: 80%;
  marginBottom: 30px;
  margin: 3rem;
  fontFamily: 'Karla Bold';
`;

export default LoginForm;
