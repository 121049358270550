import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState, useAppDispatch } from "../app/store";
import { User } from "../models/user";
import { authApi } from "../api/authApi";
import { RejectError, ValidationErrors } from "../api/apiError";
import { DESTROY_GAME_SLICE } from "./gamesSlice";
import { DESTROY_PLAYER_SLICE } from "./playerSlice";
import { DESTROY_TEAM_SLICE } from "./teamSlice";
import { changePage, Page } from "./navigationSlice";
import { useHistory } from "react-router-dom";
import { DESTROY_SELECTED_SEQUENCE, DESTROY_VIDEO_SLICE } from "./videoSlice";

export interface UserCredentials {
  username: string;
  password: string;
  stayConnected: boolean;
}

export interface RegisterUserData {
  username: string;
  email: string;
  password: string;
}

interface UserState {
  userData: User | undefined;
  season: number | undefined;
  teamView: number | undefined;
  error: string;
}

export const loginUser = createAsyncThunk<User, UserCredentials, RejectError>(
  "user/login",
  async (userData: UserCredentials, { rejectWithValue }) => {
    try {
      const res = await authApi.login(userData);
      sessionStorage.setItem("token", `Token ${res.data.token}`);
      if (userData.stayConnected) {
        localStorage.setItem("token", `Token ${res.data.token}`);
      }
      const userDetailsResponse = await authApi.getUser();
      const user = userDetailsResponse.data;
      setUser({
        ...user,
        teamView: user?.team?.id,
      });
      setSeason(userDetailsResponse.data.endSeason);
      return userDetailsResponse.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk<
  Record<string, ValidationErrors>,
  void,
  RejectError
>("user/logout", async (_, { dispatch, rejectWithValue }) => {
  try {
    const history = useHistory();
    await authApi.logout().then(() => {
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
    }).finally(() => {
      dispatch(DESTROY_GAME_SLICE());
      dispatch(DESTROY_PLAYER_SLICE());
      dispatch(DESTROY_TEAM_SLICE());
      dispatch(DESTROY_VIDEO_SLICE());
      dispatch(DESTROY_SELECTED_SEQUENCE())
      dispatch(changePage(Page.Home))
      history.push(Page.Login);
    });

    return {};
  } catch (err) {
    const error: AxiosError<ValidationErrors> =
      err as AxiosError<ValidationErrors>;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const registerUser = createAsyncThunk<
  Record<string, ValidationErrors>,
  RegisterUserData,
  RejectError
>(
  "user/register",
  async (registerUserData: RegisterUserData, { rejectWithValue }) => {
    try {
      await authApi.register(registerUserData);
      return {};
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: UserState = {
  userData: undefined,
  error: "",
  season: undefined, 
  teamView: undefined
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.userData = action.payload;
      state.season = action.payload.endSeason;
      state.teamView = action.payload.team?.id;
    },
    setSeason: (state, action: PayloadAction<number>) => {
      state.season = action.payload;
    },
    setTeamView: (state, action: PayloadAction<number>) => {
      state.teamView = action.payload
      },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state: UserState, { payload }) => {
      state.userData = payload;
    });
    builder.addCase(logoutUser.fulfilled, (state: UserState, _) => {
      state.userData = {
        id: 0,
        accountType:-1,
        player: undefined,
        team: undefined,
        email:"",
        username:"",
        startSeason:0,
        endSeason:0, 
        competitions:[],
        profile_url:""
      };
    });
    builder.addCase(
      loginUser.rejected || logoutUser.rejected || registerUser.rejected,
      (state, action) => {
        if (action.payload) {
          state.error = action.payload.errorMessage;
        } else {
          state.error = action.error.message as string;
        }
      }
    );
  },
});

export const { setUser, setSeason, setTeamView } = userSlice.actions;

export const getUser = createAsyncThunk("", async (_, thunkAPI) => {
  if (sessionStorage.token !== undefined) {
    const dispatch = useAppDispatch();
    const userDetailsResponse = await authApi.getUser();
    dispatch(setUser(userDetailsResponse.data));
    return userDetailsResponse.data;
  } 
  return;
});

export const checkAuthentication = createAsyncThunk("", async (_, thunkAPI) => {
  if (!sessionStorage.token) {
    if (localStorage.token) {
      sessionStorage.setItem("token", localStorage.token);
    }
  }
  return;
});

export const selectUser = (state: RootState) => state.user?.userData;
export const selectSeason = (state: RootState) => state.user.season;
export const selectTeam = (state: RootState) => state.user.teamView; 
export const selectAccountType = (state: RootState) => state.user.userData?.accountType;
export const selectTeamView = (state: RootState) => state.user.teamView; 


export default userSlice.reducer;
