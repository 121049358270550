import { useAppDispatch } from '../../app/store';
import { logoutUser } from '../../slices/userSlice';

const Logout = () => {
  const dispatch = useAppDispatch();
  dispatch(logoutUser());
  return null;
};

export default Logout;
