import { BrowserRouter as Router, Switch } from "react-router-dom";
import GuestRoute from "./route/GuestRoute";
import { LoginForm } from "./features/login/loginForm";
import PrivateRoute from "./route/PrivateRoute";
import { useAppDispatch } from "./app/store";
import Logout from "./features/logout/logout";
import RegisterForm from "./features/register/registerForm";
import CreateGlobalStyle from "./shared/globalStyle";
import { checkAuthentication } from "./slices/userSlice";
import Home from "./features/home/home";
import League from "./features/league/league";
import Library from "./features/library/library";
import GamesList from "./features/games/games";
import GameStat from "./features/game-stat/game-stat";
import PlayerProfile from "./features/player-profile/player-profile";
import PlayerList from "./features/player/players";
import { InitAnalytics } from "./services/gAnalytics";
import LeagueSats from "./features/rankings/leagueStats";
import { PlayerTableRanking } from "./features/rankings/player-rankings/playerStatsTable";
import { ClubTableRanking } from "./features/rankings/team-rankings/clubStatsTable";
import Profile from "./features/profile/Profile";

declare global {
  interface Window { dataLayer: any[];}
}

function App() {
  const dispatch = useAppDispatch();

  dispatch(checkAuthentication());
  InitAnalytics();

  return (
    <>
      <CreateGlobalStyle />
      <Router>
        <Switch>
          <GuestRoute exact path="/" component={LoginForm} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/league" component={League} />
          <PrivateRoute exact path="/league-stats" component={LeagueSats} />
          <PrivateRoute exact path="/competition-players-stats" component={PlayerTableRanking} />
          <PrivateRoute exact path="/competition-club-stats" component={ClubTableRanking} />
          <PrivateRoute exact path="/library" component={Library} />
          <PrivateRoute exact path="/games" component={GamesList} />
          <PrivateRoute exact path="/game-stats" component={GameStat} />
          <PrivateRoute exact path="/players" component={PlayerList} />
          <PrivateRoute exact path="/player-profile" component={PlayerProfile} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <GuestRoute exact path="/logout" component={Logout} />
          <GuestRoute exact path="/register" component={RegisterForm} />
        </Switch>
      </Router>
    </>
  );
}

export default App;