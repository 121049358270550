import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ValidationErrors } from "../api/apiError";
import { videoApi } from "../api/video";
import type { RootState } from "../app/store";
import { Sequence } from "../models/game";
import buileventRequest from "../services/processFilter";
import { Stats } from "../features/library/sequences/statModal/initStatSelection/initStatSelection";
import { Playlist } from "../models/playlists";

interface VideoState {
  events: Sequence[];
  playlists: Playlist[];
  selectedSequences: number[];
  selectedPlaylist: number | undefined;
}

export const initialState: VideoState = {
  events: [],
  playlists: [],
  selectedSequences: [],
  selectedPlaylist: undefined,
};

export const VideoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
      setVideoEvents: (state, action: PayloadAction<Sequence[]>) => {
        state.events = action.payload
      },
      setPlaylists: (state, action: PayloadAction<Playlist[]>) => {
        state.playlists = action.payload;
      },
      addPlaylist: (state, action: PayloadAction<Playlist>) => {
        state.playlists.unshift(action.payload);
      },
      updateSelectedSequence: (state, action: PayloadAction<number>) => {
        if (state.selectedSequences.includes(action.payload)) {
          state.selectedSequences = state.selectedSequences.filter(
            (sequence) => sequence !== action.payload
          );
        } else {
          state.selectedSequences.push(action.payload);
        }
      },
      setSelectedPlaylist: (state, action: PayloadAction<number | undefined>) => {
        state.selectedPlaylist = action.payload;
      },
      DESTROY_VIDEO_SLICE: (state) => {
        state.events = [];
        state.playlists = [];
      },
      DESTROY_VIDEO_EVENTS_SLICE: (state) => {
        state.events = [];
      },
      DESTROY_SELECTED_SEQUENCE: (state) => {
        state.selectedSequences = [];
      },
    },
});

export const { setVideoEvents, setPlaylists, addPlaylist, updateSelectedSequence, setSelectedPlaylist, DESTROY_VIDEO_SLICE, DESTROY_SELECTED_SEQUENCE, DESTROY_VIDEO_EVENTS_SLICE } = VideoSlice.actions;

export const selectEventVideos = (state: RootState) => state.videoSlice.events;
export const selectmatchVideos = (state: RootState) => {
  var gamesSummary = state.gamesSlice.gamesSummary;
  var videos: any[] = [];
  gamesSummary.forEach(element => {
    videos.push({
      ...element
    })
  });
  return videos;
};

export const getEvents = createAsyncThunk(
  "GET",
  async (data: { teamId: number, stats: Stats; players: any; games:any}, { dispatch, rejectWithValue }) => {
    try {
      const res = await videoApi.getEventInformation(data.teamId, buileventRequest( data.stats, data.players, data.games));
      dispatch(setVideoEvents(res.data));
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlaylists = createAsyncThunk(
  "GET",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await videoApi.getPlaylists();
      dispatch(setPlaylists(
        res.data.map((playlist: Playlist) => {
          return {
            ...playlist,
            createdOn: new Date(playlist.createdOn).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric'}),
            clips: playlist.events.length,
          }
        }
        ))
      );
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPlaylist = createAsyncThunk(
  "POST",
  async (playlist: Playlist, { dispatch, rejectWithValue }) => {
    try {
      const res = await videoApi.createPlaylist({
        name: playlist.name,
        description: playlist.description,
        sequence_duration: playlist.sequence_duration,
      });
      dispatch(addPlaylist({
        ...playlist,
        id : res.data.playlistId,
      }));
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectEvents = (state: RootState) => state.videoSlice.events;
export const selectPlaylists = (state: RootState) => state.videoSlice.playlists;
export const selectSelectedSequences = (state: RootState) => state.videoSlice.selectedSequences; 
export const selectSelectedPlaylist = (state: RootState) => state.videoSlice.selectedPlaylist;

export default VideoSlice.reducer;