import styled from "styled-components";
import { LIGHT_GREY, WHITE } from "../../../assets/colors";
import ProgressBar from "../../../components/stats-base/progress-bars/progress-bar-compare";
import { selectCurrentGame } from "../../../slices/gamesSlice";
import { Game } from "../../../models/game";
import { useSelector } from "react-redux";

function sumListElements(list: number[]): number {
    let sum = 0;
    for (let i = 0; i < list.length; i++) {
        sum += list[i];
    }
    return sum;
}

const GameSummary = () => {
    const game: Game | undefined = useSelector(selectCurrentGame);

    var percentgeChallengesWon = 0;
    var percentgeChallengesLost = 0;
    if (game!.challengesTotal !== 0) {
        percentgeChallengesWon = Math.round((game!.challengesWon/game!.challengesTotal)*100);
        percentgeChallengesLost = 100 - percentgeChallengesWon;
    }
    const recoveries: number = sumListElements(game!.ballRecoveries);
    const losses: number = sumListElements(game!.ballLosses);

    return (
        <SummaryWrapper>
            <ProgressBar title={'POSSESSION (%)'} teamAStat={game!.ballPossession} teamBStat={100 - game!.ballPossession} isPercent={true} scale={0}/>
            <ProgressBar title={'PASSES'} teamAStat={game!.passTotal} teamBStat={game!.passOpponent} scale={game!.passTotal + game!.passOpponent} />
            <ProgressBar title={'SHOTS'} teamAStat={game!.shots} teamBStat={game!.shotAgainst} scale={game!.shots + game!.shotAgainst} />
            <ProgressBar title={'SHOTS ON TARGET'} teamAStat={game!.shotsOnTarget} teamBStat={game!.shotAgainstOnTarget} scale={game!.shotsOnTarget + game!.shotAgainstOnTarget} />
            <ProgressBar title={'CHALLENGES WON (%)'} teamAStat={percentgeChallengesWon} teamBStat={percentgeChallengesLost} isPercent={true} scale={0}/>
            <ProgressBar title={'CORNERS'} teamAStat={game!.corners.totalLong + game!.corners.totalShort} teamBStat={game!.corners.totalAgainst} scale={game!.corners.totalLong + game!.corners.totalShort + game!.corners.totalAgainst} />
            <ProgressBar title={'OFFSIDES'} teamAStat={game!.offsides.total} teamBStat={game!.offsides.against} scale={game!.offsides.total + game!.offsides.against} />
            <ProgressBar title={'FREEKICKS'} teamAStat={game!.directFreeKicks} teamBStat={game!.directFreeKicksAgainst} scale={game!.directFreeKicks + game!.directFreeKicksAgainst} />
            <ProgressBar title={'OFFENSIVE TRANSITIONS'} teamAStat={recoveries} teamBStat={losses} scale={recoveries+ losses} />
        </SummaryWrapper>
    );
}

const SummaryWrapper = styled.div`
    background-color: ${WHITE};
    border-radius: 10px;
    border: 2px solid ${LIGHT_GREY};
    margin: 2rem 0 0 0;
    padding: 1rem;
    width:100%;
`;

export default GameSummary;
