import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import { LIGHT_GREY1, LIGHT_GREY2} from "../../assets/colors";
import FullGameVideos from "./fullGames/fullvideo";
import EventVideos from "./sequences/eventVideo";
import TopBar from "../../components/navigation/topbar";
import { LoadingWrapper } from "../../components/navigation/loading";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";
import { Playlists } from "./playlist/playlists";
import { useSelector } from "react-redux";
import { selectSelectedPlaylist } from "../../slices/videoSlice";
import PlaylistDetails from "./playlist/playlistDetails";
import { authApi } from "../../api/authApi";
import { ACTIVITIES, FEATURE_VALUES } from "../../models/constant/collect";

enum StatPage {
  Games,
  Sequences,
  playlists
}

const Library = () => <LoadingWrapper comp={<LibraryBase/>}/>

const LibraryBase = () => {
  const classes = sportsAiGlobalStyles();
  const [statPage, setStatPage] = useState(StatPage.Games);
  const playlistSelected = useSelector(selectSelectedPlaylist);

  if (playlistSelected !== undefined) {
    return <PlaylistDetails/>
  }
  
  return (
    <Wrapper>
      <HeaderWrapper>
      <TopBar name={"Library"}/>
        <Grid container>
          <Grid item xs={4}>
            <Button
              className={
                statPage === StatPage.Games
                  ? classes.menuHeaderSelected
                  : classes.menuHeader
              }
              onClick={() => {
                setStatPage(StatPage.Games);
              }}
            >
              Full Games
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={
                statPage === StatPage.Sequences
                  ? classes.menuHeaderSelected
                  : classes.menuHeader
              }
              onClick={() => {
                setStatPage(StatPage.Sequences);
                authApi.collect(({activity: ACTIVITIES.NAVIGATION, feature: FEATURE_VALUES.LIBRARY}))
              }}
            >
              Sequences
            </Button>
          </Grid>
            <Grid item xs={4}>
              <Button
                className={
                  statPage === StatPage.playlists
                    ? classes.menuHeaderSelected
                    : classes.menuHeader
                }
                onClick={() => {
                  setStatPage(StatPage.playlists);
                  authApi.collect(({activity: ACTIVITIES.NAVIGATION, feature: FEATURE_VALUES.PLAYLIST}))
                }}
              >
                Playlists
              </Button>
            </Grid>
          
        </Grid>
      </HeaderWrapper>
      {
        statPage === StatPage.Games ? ( <FullGameVideos/>) :
        statPage === StatPage.Sequences ? ( <EventVideos/>) : 
        statPage === StatPage.playlists ? ( <Playlists/>) : 
      (<></>)
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export default Library;
