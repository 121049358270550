import React, { useState } from "react";
import { Button, Collapse, createMuiTheme,Grid, List, ListItem, ListItemText, makeStyles, Switch, ThemeProvider} from "@material-ui/core";
import styled from "styled-components";
import { DARK_BLUE, GREY, WHITE, BLACK, LIGHT_GREY1, GREY2 } from "../../../../../assets/colors";
import { ExpandLess, ExpandMore,  } from "@material-ui/icons";
import fieldImage from '../../../../../assets/images/recuperations-image1.png';
import { getEvents } from "../../../../../slices/videoSlice";
import { ListItemButton } from '@mui/material';
import { initialButtonStates } from "../initStatSelection/initStatSelection";
import { PassesFilter } from "./passesfilter";
import { RecoveriesFilter } from "./recoveriesFilter";
import { BaseFilter } from "./baseFilter";
import { ChallengeFilter } from "./challengesFilter";
import twocards from '../../../../../assets/images/two-cards.png';
import YC from '../../../../../assets/images/YC.png';
import RC from '../../../../../assets/images/RC.png';
import { useSelector } from "react-redux";
import { selectTeamId } from "../../../../../slices/teamSlice";

const useStyles = makeStyles(() => ({
  clear: {
    width: '100%',
    backgroundColor: GREY,
    borderColor: WHITE,
    borderRadius: "20px",
    border: "2px solid",
    color: WHITE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    justifyContent: 'center',
    "&:hover": {
      color:WHITE,
    backgroundColor: GREY,
    cursor: "pointer",
    },
  },
  tabButton: {
    textAlign: "center",
    width: "100%",
    borderRadius: "0px",
    color: GREY,
    fontFamily: "Karla Bold",
    fontSize: "1rem",

    backgroundColor: WHITE,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  tabButtonSelected: {
    textAlign: "center",
    width: "100%",
    borderRadius: "0px",
    borderBottom: "3px solid",
    borderColor: DARK_BLUE,
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    backgroundColor: WHITE,
  },
  tabButtonFilter: {
    display: "flex",           // Add flex display
    alignItems: "center",      // Center items vertically
    justifyContent: "center",  // Center items horizontally
    textAlign: "center",
    width: "100%",
    margin:"5% 0 3% 0",
    borderRadius: "0px",
    color: GREY,
    fontFamily: "Antique Olive Nord",
    fontSize: "1.25rem",
    fontStyle: "italic",  
    backgroundColor: WHITE,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  tabButtonSelectedFilter: {
    textAlign: "center",
    width: "100%",
    margin:"5% 0 3% 0",
    borderRadius: "0px",
    color: DARK_BLUE,
    fontFamily: "Antique Olive Nord",
    fontStyle: "italic",  
    fontSize: "1.25rem",
    backgroundColor: WHITE,
  },
  directionsButton: {
    textAlign: "right",
    flexDirection: 'column',
    color: BLACK,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    padding: "12px 32px 12px",
    paddingLeft: "32px",
    paddingRight: "32px",
    "&:hover": {
      backgroundColor: GREY,
      color:WHITE,
    },
  },
  directionsButtonClicked: {
    textAlign: "right",
    flexDirection: 'column',
    backgroundColor: DARK_BLUE,
      color:WHITE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    padding: "12px 32px 12px",
    paddingLeft: "32px",
    paddingRight: "32px",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      color:WHITE,
    },
  },
  applyFilter: {
    width: '50%',
    backgroundColor: DARK_BLUE,
    borderColor: WHITE,
    borderRadius: "5px",
    border: "2px solid",
    fontFamily: "Karla Bold",
    fontSize: "1.2rem",
    color: WHITE,
    justifyContent: 'center',
    "&:hover": {
      color:WHITE,
    backgroundColor: DARK_BLUE,
    },
  },
  cancelFilter: {
    width: '50%',
    backgroundColor: GREY,
    borderColor: WHITE,
    borderRadius: "5px",
    border: "2px solid",
    color: WHITE,
    justifyContent: 'center',
    "&:hover": {
      color:WHITE,
    backgroundColor: GREY2,
    },
  },
  soccerFieldContainer: {
    justifyContent: 'end',
    alignItems: 'end',
  },
  buttonStat: {
    color: "black",
    backgroundColor: WHITE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    borderRadius: "25px",
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  buttonStatTopBorder: {
    color: "black",
    backgroundColor: WHITE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    borderTop: "1px solid",
    borderColor: DARK_BLUE,
  },
  buttonStatBottomBorder: {
    color: "black",
    backgroundColor: WHITE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    borderBottom: "1px solid",
    borderColor: DARK_BLUE,
  },
  buttonSubStat: {
    color: "black",
    fontFamily: "Karla Bold",
    justify: "end",

    borderRadius: "25px",
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  buttonSubStatAll: {
    color: "black",
    fontStyle: "italic", 
    fontFamily: "Karla Bold",
    justify: "end",
    "&:hover": {
      backgroundColor: WHITE,
      borderRadius: "20px",
    }
  },
  filterButtonExpand: {
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontStyle: "italic", 
    fontSize: "1.15rem",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: WHITE,
    },
    
  },
  SubStatContainer: {
    backgroundColor: LIGHT_GREY1,
  },    
  buttonFieldStyle: {
    position: 'relative',
    display: 'inline-block',
    width: '25%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: "7px",
    borderWidth: "1.5px 1.5px 1.5px 1.5px",
    borderStyle: "solid",
    borderColor: DARK_BLUE,
    zIndex:0,
    "&:hover": {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    
  },
  fieldClickedButton: {
    position: 'relative',
    display: 'inline-block',
    width: '25%',
    height: '100%',
    backgroundColor: DARK_BLUE,
  
    borderRadius: "10px",
    border: "1.5px solid",
    borderColor: WHITE,
    zIndex:0,
    "&:hover": {
      backgroundColor: DARK_BLUE,
    },
  },
  fieldBox1: {
    position: 'absolute',
    width:'40%',
    height: '60%',
    top: '20%',
    left:'0%',
    right:'0%',
    borderRadius: "10px",
    border: "1px solid",
    borderColor: DARK_BLUE,
    backgroundColor: WHITE,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  clickedFieldBox1: {
    position: 'absolute',
    width:'20%',
    height: '60%',
    top: '20%',
    left:'0%',
    right:'0%',
    borderRadius: "10px",
    border: "2px solid",
    borderColor: WHITE,
    backgroundColor: DARK_BLUE,
    zIndex: 1,
    "&:hover": {
      backgroundColor: DARK_BLUE,
    },
  },
  fieldBox2: {
    position: 'absolute',
    width:'40%',
    height: '60%',
    top: '20%',
    right:'0%',
    borderRadius: "10px",
    border: "1px solid",
    borderColor: DARK_BLUE,
    backgroundColor: WHITE,
    zIndex: 1,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  clickedFieldBox2: {
    position: 'absolute',
    width:'40%',
    height: '60%',
    top: '20%',
    right:'0%',
    borderRadius: "10px",
    border: "2px solid",
    borderColor: WHITE,
    backgroundColor: DARK_BLUE,
    zIndex: 1,
    "&:hover": {
      backgroundColor: DARK_BLUE,
    },
  },
  containerStyle: {
    position: 'relative',
    display: 'inline-block',
    width: '85%',
    height: 'min(25vh)', // set the minimum height of the container element
    maxHeight: '30vh',
    backgroundImage: `url(${fieldImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "7%",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: DARK_BLUE,
    },
  },
});

const BASE_STATS = [
  {title:'goals for', stats: 'goals'},
  {title:'goals against', stats: 'goalsAdversary'},
  {title:'assist', stats: 'assist'},
  {title:'key pass', stats: 'keyPasses'},
  {title:'shots', stats: 'shots'}, 
  {title:'dribbles', stats: 'dribbles'}, 
  {title:'crosses', stats: 'crosses'}, 
  {title:'corners', stats: 'corners'}, 
  {title:'free kicks', stats: 'freekicks'}, 
  {title:'GK saves', stats: 'gkSaves'},
  {title:'goalkick', stats: 'goalkick'}, 
  {title:'penalty', stats: 'penalty'}, 
  {title:'throw-ins', stats: 'throwins'}, 
];

function SelectStatModal(props) { 
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openPasses, setOpenPasses] = React.useState(false);
  const [openRecoveries, setOpenRecoveries] = React.useState(false);
  const [openNested4, setOpenNested4] = React.useState(currentPage === 3);
  const [openNested5, setOpenNested5] = React.useState(false);
  const [openNested6, setOpenNested6] = React.useState(false);

  var teamId = useSelector(selectTeamId)

  function handleStatsButtonClick(buttonName) {
    // Change when this functions is called
    props.handleStatsButtonClick(buttonName);
    props.dispatch(
      getEvents({
        teamId: teamId? teamId : 0,
        stats: props.buttonStates,
        players: props.CheckedPlayers,
        games: props.CheckedGames,
      })
    );
   }

  const ModalWrapper = styled.div`
  position: sticky;
  top: 65%;
  left: 5%;
  max-width: 100%;
  max-height: 75%;
  min-height: 300px;
  background-color: ${WHITE};
  margin: 3% 3% 3% 3%;
  border: 25px solid ${WHITE};
  border-radius: 50px;
  z-index: 1000;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${WHITE};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${DARK_BLUE};
    border-radius: 20px;
    border: 30px solid ${WHITE};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${DARK_BLUE};
  }
`;
  const ScrollableContainer = styled.div`
  max-height: 60vh; // Adjust based on your requirement
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px; // Width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: ${WHITE}; // Color of the scrollbar track
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${DARK_BLUE}; // Color of the scrollbar thumb
    border-radius: 20px; // Rounded corners for the scrollbar thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${DARK_BLUE}; // Color of the scrollbar thumb on hover
  }
`;

  const classes = useStyles();
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    
  };

  const handleClickNested = () => {
    setOpenPasses(!openPasses);
    props.dispatch(
      getEvents({
        teamId: teamId? teamId : 0,
        stats: props.buttonStates,
        players: props.CheckedPlayers,
        games: props.CheckedGames,
      })
    );
  };
  const handleClickNested2 = () => {
    setOpenRecoveries(!openRecoveries);
    props.dispatch(
      getEvents({
        teamId: teamId? teamId : 0,
        stats: props.buttonStates,
        players: props.CheckedPlayers,
        games: props.CheckedGames,
      })
    );
  };
  const handleClickNested4 = () => {
    setOpenNested4(!openNested4);
    props.dispatch(
      getEvents({
        teamId: teamId? teamId : 0,
        stats: props.buttonStates,
        players: props.CheckedPlayers,
        games: props.CheckedGames,
      })
    );
  };
  const handleClickNested5 = () => {
    setOpenNested5(!openNested5);
    props.dispatch(
      getEvents({
        teamId: teamId? teamId : 0,
        stats: props.buttonStates,
        players: props.CheckedPlayers,
        games: props.CheckedGames,
      })
    );
  };
  const handleClickNested6 = () => {
    setOpenNested6(!openNested6);
    props.dispatch(
      getEvents({
        teamId: teamId? teamId : 0,
        stats: props.buttonStates,
        players: props.CheckedPlayers,
        games: props.CheckedGames,
      })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <ModalWrapper>
            <Grid container style = {{borderBottom: "4px solid ", borderColor: DARK_BLUE}}>
              <Grid item xs={4}>
                <Button className={currentPage === 1 ?
                  classes.tabButtonSelectedFilter : classes.tabButtonFilter}
                  onClick={() => { handlePageChange(1) }} >
                  Attacking
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button className={currentPage === 2 ?
                  classes.tabButtonSelectedFilter : classes.tabButtonFilter}
                  onClick={() => { handlePageChange(2) }} >
                  Defending
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button className={currentPage === 3 ?
                  classes.tabButtonSelectedFilter : classes.tabButtonFilter}
                  onClick={() => { handlePageChange(3) }} >
                  disciplinary
                </Button>
              </Grid>
            </Grid>
          
          <StatsContainer>
          <div style={{ display: currentPage === 1 ? "block" : "none" }}>
            <List>
              <ScrollableContainer>
              <ListItemButton className={classes.filterButtonExpand} onClick={handleClickNested} >
                PASSES 
                <ListItemText/>
                {openPasses ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openPasses} timeout="auto" unmountOnExit>
                <PassesFilter handleStatsButtonClick={handleStatsButtonClick} buttonStates={props.buttonStates} />
              </Collapse>     
              <ListItemButton className={classes.filterButtonExpand} onClick={handleClickNested2} >
                BALL RECOVERIES   
                <ListItemText />
                {openRecoveries ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openRecoveries} timeout="auto" unmountOnExit>
                <RecoveriesFilter 
                  handleStatsButtonClick={handleStatsButtonClick} 
                  handleFieldZonesClick={props.handleFieldZonesClick} 
                  handleButtonOnMouseEnter={props.handleButtonOnMouseEnter} 
                  handleButtonOnMouseLeave={props.handleButtonOnMouseLeave} 
                  buttonStates={props.buttonStates}/>
              </Collapse>
              <Grid22>
                <div className="container">
                  {
                    BASE_STATS.map((bastStat) => {
                      return(
                        <BaseFilter handleStatsButtonClick={handleStatsButtonClick} statName={bastStat.stats} title={bastStat.title} buttonStates={props.buttonStates}/>
                      )
                    })
                  }
                </div>
              </Grid22>
            </ScrollableContainer>
            </List>
          </div>
          <div style={{ display: currentPage === 2 ? "block" : "none" }}>
            <List>
              <ScrollableContainer>
              <ContainerModal>
              <ListItemButton className={classes.filterButtonExpand}  onClick={handleClickNested4}>
                BALL LOSS ( CHOOSE REACTION & ZONE)  
                <ListItemText />
                {openNested4 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openNested4} timeout="auto" unmountOnExit>
                <LossGrid>
                <div className="container">
                  <div className="Stats">
                  <ListItem className={classes.buttonSubStatAll}
                    key={"allReaction"}
                    onChange={() => handleStatsButtonClick('allReaction')} 
                    button> ALL REACTIONS
                    <ListItemText className={classes.buttonStat} color="primary" />
                    <Switch color="primary" checked={props.buttonStates["lossPressing"] &&  
                                                    props.buttonStates["lossRepo"] && 
                                                    props.buttonStates["lossNoReact"]}/>
                  </ListItem>
                  <ListItem className={classes.buttonStatTopBorder}
                    key={"lossPressing"}
                    onChange={() => handleStatsButtonClick('lossPressing')} 
                    button> PRESSING
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["lossPressing"]}/>
                  </ListItem>
                  <ListItem className={classes.buttonSubStat}
                    key={"lossRepo"}
                    onChange={() => handleStatsButtonClick('lossRepo')} 
                    button> REPOSITION
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["lossRepo"]}/>
                  </ListItem>
                  <ListItem className={classes.buttonStatBottomBorder}
                    key={"lossNoReact"}
                    onChange={() => handleStatsButtonClick('lossNoReact')} 
                    button> NO REACTION
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["lossNoReact"]}/>
                  </ListItem>
                
                <ListItem className={classes.buttonSubStatAll}
                    key={"allZonesLoss"}
                    onChange={() => handleStatsButtonClick('allZonesLoss')} 
                    button> ALL ZONES
                  <ListItemText className={classes.buttonStat} color="primary" />
                  <Switch color="primary" checked={props.buttonStates["loss1"] &&  props.buttonStates["loss12"] && 
                                                    props.buttonStates["loss2"] && 
                                                    props.buttonStates["loss3"] && 
                                                    props.buttonStates["loss4"] && props.buttonStates["loss17"]}/>
                </ListItem>
                  </div>
                  <div className="Field">
                  <FieldContainer>
                  <div className={classes.containerStyle}>
                    <Button className={
                              props.buttonStates["loss1"] === true
                                  ? classes.fieldClickedButton
                                  : classes.buttonFieldStyle}
                              onClick={(e) => props.handleFieldZonesClick('loss1',e)} >
                      <Button className={
                              props.buttonStates["loss12"] === true
                                  ? classes.clickedFieldBox1
                                  : classes.fieldBox1}
                                  onClick={(e) => props.handleFieldZonesClick('loss12',e)} 
                                  onMouseEnter={(e) => props.handleButtonOnMouseEnter("loss12", e)}
                                  onMouseLeave={(e) => props.handleButtonOnMouseLeave("loss12", e)}>
                      </Button>
                    </Button>
                    <Button className={
                              props.buttonStates["loss2"] === true
                                  ? classes.fieldClickedButton
                                  : classes.buttonFieldStyle}
                                  onClick={(e) => props.handleFieldZonesClick('loss2', e)} /> 
                    <Button className={
                              props.buttonStates["loss3"] === true
                                  ? classes.fieldClickedButton
                                  : classes.buttonFieldStyle}
                                  onClick={(e) => props.handleFieldZonesClick('loss3', e)} /> 
                    <Button className={
                              props.buttonStates["loss4"] === true
                                  ? classes.fieldClickedButton
                                  : classes.buttonFieldStyle}
                                  onClick={(e) => props.handleFieldZonesClick('loss4', e)} > 
                      <Button className={
                              props.buttonStates["loss17"] === true
                                  ? classes.clickedFieldBox2
                                  : classes.fieldBox2}
                                  onClick={(e) => props.handleFieldZonesClick('loss17',e)} 
                                  onMouseEnter={(e) => props.handleButtonOnMouseEnter("loss17", e)}
                                  onMouseLeave={(e) => props.handleButtonOnMouseLeave("loss17", e)}>
                      </Button>
                    </Button>
                  </div>
                </FieldContainer>
                  </div>
                </div>
                </LossGrid>
              </Collapse>
              <ListItemButton className={classes.filterButtonExpand}  onClick={handleClickNested5}>
                CHALLENGES 
                <ListItemText />
                {openNested5 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openNested5} timeout="auto" unmountOnExit>
                <ChallengeFilter handleStatsButtonClick={handleStatsButtonClick} buttonStates={props.buttonStates}/>
              </Collapse>
              <ListItemButton className={classes.filterButtonExpand}  onClick={handleClickNested6}>
                TACKLES 
                <ListItemText />
                {openNested6 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openNested6} timeout="auto" unmountOnExit>
                <List>
                  <Grid3>
                    <div className="container">
                      <div className="All">
                    <ListItem className={classes.buttonSubStatAll} 
                    key={"allTackles"}
                    onChange={() => handleStatsButtonClick('allTackles')} 
                    button> ALL TACKLES 
                    <ListItemText className={classes.buttonStat} color="primary" />
                    <Switch color="primary" checked={props.buttonStates["tackleW"] && props.buttonStates["tackleL"]}/>
                  </ListItem>
                  </div>
                  <div className="first">
                  <ListItem className={classes.buttonSubStat}
                    key={"tackleW"}
                    onChange={() => handleStatsButtonClick('tackleW')} 
                    button> SUCCEED
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["tackleW"]}/>
                  </ListItem>
                  </div>
                  <div className="second">
                  <ListItem className={classes.buttonSubStat}
                    key={"tackleL"}
                    onChange={() => handleStatsButtonClick('tackleL')} 
                    button> MISSED
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["tackleL"]}/>
                  </ListItem>
                  </div>
                    </div>
                  </Grid3>
                  
                </List>
              </Collapse>
              <GridStats>
              <ListItem className={classes.buttonStat}
                      key={"clearances"}
                      onChange={() => handleStatsButtonClick('clearances')} 
                      button> CLEARANCES 
                <ListItemText />
                <Switch color="primary" checked={props.buttonStates["clearances"]} />
              </ListItem>
              </GridStats>
              </ContainerModal>
              </ScrollableContainer>
              </List>
          </div>
          <div style={{ display: currentPage === 3 ? "block" : "none" }}>
              <List>
                <List>
                <ListItem className={classes.buttonStat}
                      key={"fouls"}
                      onChange={() => handleStatsButtonClick('fouls')} 
                      button> FOULS 
                <ListItemText />
                <Switch color="primary" checked={props.buttonStates["fouls"]}/>
              </ListItem>
              <BaseFilter handleStatsButtonClick={handleStatsButtonClick} statName={'offside'} buttonStates={props.buttonStates} title={'offside'} />
              <Grid3>
                    <div className="container">
                      <div className="All">
                  <ListItem className={classes.buttonSubStatAll}
                    key={"allCards"}
                    onChange={() => handleStatsButtonClick('allCards')} 
                    button> ALL CARDS <img src={twocards} alt="pass" style={{ width: '32px', height: '32px', marginLeft: '0px', }}/>
                    <ListItemText className={classes.buttonStat} color="primary" />
                    <Switch color="primary" checked={props.buttonStates["yellowCard"] && props.buttonStates["redCard"]}/>
                  </ListItem>
                  </div>
                  <div className="first">
                  <ListItem className={classes.buttonStat}
                      key={"yellowCard"}
                      onChange={() => handleStatsButtonClick('yellowCard')} 
                      button> <img src={YC} alt="pass" style={{ width: '28px', height: '28px', marginLeft: '0px', }}/>
                      CARDS
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["yellowCard"]}/>
                  </ListItem>
                  </div>
                  <div className="second">
                  <ListItem className={classes.buttonStat}
                      key={"redCard"}
                      onChange={() => handleStatsButtonClick('redCard')} 
                      button> <img src={RC} alt="pass" style={{ width: '28px', height: '28px', marginLeft: '0px', }}/> CARDS
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={props.buttonStates["redCard"]}/>
                  </ListItem>
                  </div>
                  </div>
                  </Grid3>
                </List>
            </List>
          </div>
          </StatsContainer>
          <ControlButton>
            <Button
                className={classes.clear}
                onClick={() => {
                  props.setButtonStates(initialButtonStates)
                }}
                disabled={!Object.values(props.buttonStates).some(Boolean)}
              > CLEAR STATS
            </Button> 
          </ControlButton>
      </ModalWrapper>
    </ThemeProvider>
  );
};

const ContainerModal = styled.div`
    min-height: 25%;
    max-height: 50%;
    width: 100%;
    font-family: Karla Bold;
    background-color: ${WHITE};
`;
const StatsContainer = styled.div`
    width: 100%;
    max-height:20%;
    border: "5px solid",
    background-color: ${WHITE};
`;
const Grid22 = styled.div`
.container {  display: grid;
  padding: 10px 0 20px 0;
  margin:0 2% 0 2%;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  justify-content: start;
  align-content: center;
  justify-items: stretch;
  align-items: stretch;
  grid-template-areas:
    "A B C D"
    "E F G ";
}
.A { grid-area: A; }
.B { grid-area: B; }
.C { grid-area: C; }
.D { grid-area: D; }
.E { grid-area: E; }
.F { grid-area: F; }
.G { grid-area: G; }
`;

const GridStats = styled.div`
.container {  
  padding: 10px 0 20px 0;
  margin:0 2% 0 2%;
`;

const FieldContainer = styled.div`
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
`;

const LossGrid = styled.div`
.container {  display: grid;
  padding: 10px 0 20px 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  
  gap: 0px 0px;
  grid-auto-flow: row;

  grid-template-areas:
    "Stats Field";
}

.Stats { grid-area: Stats; 
  border: 2px solid ${DARK_BLUE}; 
  border-radius: 20px;
  margin-left: 7%;
}

.Field { 
  grid-area: Field; 
  padding-top: 2%;
}
`;

const Grid3 = styled.div`
.container {  display: grid;
  width: 95%;
  grid-template-columns: 1fr 0.2fr 0.2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  border: 1px solid ${DARK_BLUE}; 
  border-radius: 20px;
  margin: 0 2% 0 2%;
  grid-auto-flow: row;
  grid-template-areas:
  "All first second"; 
  }
  .All { grid-area: All;
    margin: 0 30px 0 10px;
   }
  .first { grid-area: first;
    margin: 0 10px 0 10px;
   }
  .second { grid-area: second;
    margin: 0 10px 0 10px;
   }
  `;

  const ControlButton = styled.div`
  width: 100%;
  margin-top:1%;
  `;
export default SelectStatModal
