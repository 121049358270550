import styled from "styled-components";
import { DARK_BLUE, GREY2, LIGHT_GREY, LIGHT_GREY1, LIGHT_GREY2 } from "../../../assets/colors";
import { selectPlaylists, selectSelectedPlaylist, setSelectedPlaylist } from "../../../slices/videoSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/store";
import { Button, Grid } from "@material-ui/core";
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { KeyboardArrowLeft } from "@material-ui/icons";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import SequenceCard from "../../../components/video/sequenceCard";
import { selectTeamPlayers } from "../../../slices/playerSlice";
import { selectGamesSummary } from "../../../slices/gamesSlice";
import VideoPlayer from "../../../components/video/videoPlayer/VideoPlayer";
import { useRef, useState } from "react";
import { Sequence } from "../../../models/game";

const PlaylistDetails = () => {
    const classes = sportsAiGlobalStyles();
    const dispatch = useAppDispatch();

    const selectedPlaylistId = useSelector(selectSelectedPlaylist);
    const playlist = useSelector(selectPlaylists).find((playlist) => playlist.id === selectedPlaylistId);

    const [selectedScene, setSelectedScene] = useState<Sequence | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [loopInterval, setLoopInterval] = useState<NodeJS.Timeout | null>(null);
    const loopIntervalDuration = playlist?.sequence_duration || 30;

    const handleJumpToTime = (time, matchID) => {
        if (videoRef.current && time >= 0 ) {
          let videoLink = playlist?.videoLink;
          if (videoLink) {
            if (videoRef.current.src !== videoLink) {
              videoRef.current.src = videoLink;
              videoRef.current.load();
            }
          }
          videoRef.current.currentTime = time;
          if (loopInterval) {
            clearInterval(loopInterval);
          }
          const newLoopInterval = setInterval(() => {
            if (videoRef.current ) {
              if (videoRef.current.currentTime === time + loopIntervalDuration) {
                videoRef.current.currentTime = time;
              }
            }
          }, 1000 / loopIntervalDuration);
          setLoopInterval(newLoopInterval); // Set the loop interval ID to state
          return () => {
            clearInterval(newLoopInterval);
          };
        }
      };
    var players_name = {}
    var opponent_name = {}
    var game_name = {}

    useSelector(selectTeamPlayers).forEach(element => {
      players_name[element.id] = element.name
    });
    
    useSelector(selectGamesSummary).forEach(element => {
      game_name[element.id] = 
                            element.opponentName+ " (" + 
                            element.teamScore + "-" + 
                            element.opponentScore + ") | " + 
                            element.date + " " 
      opponent_name[element.id] = element.opponentName
    });

    return (
        <Wrapper>
            <HeaderWrapper>
                <Button
                    className={classes.back}
                    onClick={() => {dispatch(setSelectedPlaylist(undefined))}}
                >
                    <KeyboardArrowLeft /> All playlists
                </Button>
                <Grid container spacing={0}>
                    <Grid item xs={8}>
                        <PlaylistInfoWrapper>
                            <PlaylistNameWrapper>
                                {playlist?.name}
                            </PlaylistNameWrapper>
                            <DescriptionWrapper style={{fontFamily: 'Karla Regular', color: GREY2}}>
                                {playlist?.description}
                            </DescriptionWrapper>
                        </PlaylistInfoWrapper>
                    </Grid>
                    <Grid item xs={2}>
                        <PlaylistInfoWrapper2 style={{borderRight: '1px solid #F0F0F0'}}>
                          <div style={{fontFamily: 'Karla Regular', color: GREY2}}>Created On</div>
                          <div>{playlist?.createdOn}</div>
                        </PlaylistInfoWrapper2>
                    </Grid>
                    <Grid item xs={2}>
                        <PlaylistInfoWrapper2>
                          <div style={{fontFamily: 'Karla Regular', color: GREY2}}>Clips</div>
                          <div>{playlist?.clips}</div>
                        </PlaylistInfoWrapper2>
                    </Grid>
                </Grid>
            </HeaderWrapper>
            <ContentWrapper>
                <VideoComponent>
                    { selectedScene !== null ? (
                        <VideoPlayer
                            selectedScene={selectedScene}
                            handleJumpTime={handleJumpToTime}
                            handleLoopInterval={undefined}
                            loopIntervalDuration={undefined}
                            videoRef={videoRef}
                        />
                        ) : 
                    <NoScenesMessage> 
                        <SmartDisplayIcon style={{width: '10rem', height: '10rem', color: LIGHT_GREY}}/>
                        <div style={{fontWeight: 'bold', fontFamily: 'karla bold', fontSize: '2rem'}}>Select a sequence from the list</div>
                    </NoScenesMessage>
                    }
                </VideoComponent>
                <ScrollableList>
                    {playlist?.events.map((event, i) => {
                        return (
                          <SequenceCard 
                            sequence={event} 
                            index={i} 
                            isSelected={selectedScene?.id === event.id} 
                            isPlaylistSelected={false}
                            onCardClick={() => setSelectedScene(event)} 
                            onCheckboxClick={() => {}} 
                          />
                        );
                    }
                    )}
                </ScrollableList>
            </ContentWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 85%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    left: 15%;
    border: 2px solid ${LIGHT_GREY2};
    background-color: ${LIGHT_GREY1};
    font-family: Karla Bold;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

const PlaylistInfoWrapper = styled.div`
  width: 100%;
  margin: 2rem;
  display: flex;
  flex-direction: column; 
`;

const PlaylistNameWrapper = styled.div`
  font-size: 2rem;
`;

const DescriptionWrapper = styled.div`
  margin-top: 1rem; 
`;

const PlaylistInfoWrapper2 = styled.div`
    width: 60%;
    margin: 2rem;
    flex-direction: column; 
    text-align: left;
`;  

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 75%;
`;

const VideoComponent = styled.div`
    width: 80%; 
`;

const ScrollableList = styled.div`
    width: 20%; 
    overflow-y: auto; 
`;

const NoScenesMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  vertical-align: baseline;
  color: ${DARK_BLUE};
  text-align: center;
  font-family: 'Karla Light';
  font-size: 1rem;
  margin: 12rem 0rem 0 0;
  flex-direction: column;
`;

export default PlaylistDetails;