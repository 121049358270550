import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DARK_BLUE, GREY, WHITE } from "../../../assets/colors";
import { useAppDispatch } from "../../../app/store";
import { useSelector } from "react-redux";
import { selectCompetitions } from "../../../slices/teamSlice";
import { selectSeason } from "../../../slices/userSlice";
import SectionHeader from "../../../components/home-page/section-header/section-header";
import RankingTable from "../../../components/stats-base/table/ranking-table";
import { CompetitionApi } from "../../../api/competition";
import { LeagueSatsModel } from "../../../models/leagueStats";
import { Loading } from "../../../components/navigation/loading";
import { changePage, Page } from "../../../slices/navigationSlice";
import { setCurrentClubStatParams } from "../../../slices/competitionSlice";

const competitionFilterOptions = new Map<string, number>();

const GAMES_NUMBER_FITLER = [
  "All",
  "3",
  "5",
  "10",
];

const useStyles = makeStyles(() => ({
  tabButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 5px",
    fontWeight: "bold",
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
  showAllButton: {
    backgroundColor: "transparent",
    color: DARK_BLUE,
    padding: "3px 8px",
    fontWeight: "bold",
    textTransform: "none",
  },
  arrowBtnSelected: {
    transform: "rotate(180deg)",
  },
}));

const SquadStats = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const competitions = useSelector(selectCompetitions).filter((competition) => competition.is_under_contract);
  const season: number | undefined = useSelector(selectSeason);

  const [positionFilter, setPositionFilter] = useState("All");
  const [leaguePlayersStats, setLeaguePlayersStats] = useState<LeagueSatsModel|undefined>(undefined);
  const competitionInit =  competitions.length !== 0 ? competitions![0].competition : '';
  const [competitionFilter, setcompetitionFilter] = useState(competitionInit);

  competitions?.forEach((competition, idx) => {
    competitionFilterOptions.set(competition.competition, competition.id);
  });

  useEffect(() => {
    if (competitions === undefined) {
      return
    }
    const competitionId = competitionFilterOptions.get(competitionFilter);
    if (competitionId !== undefined) {
      setLeaguePlayersStats(undefined);
      CompetitionApi.getCompetitionClubStats(competitionId, season!).then((response) => {
        const data: LeagueSatsModel = response.data;
        setLeaguePlayersStats(data);
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sizeButtons = () => {
    const buttons: JSX.Element[] = [];

    for (const [buttonName, _] of competitionFilterOptions) {
      buttons.push(
        <Button
          style={{ padding: "2px 10px", margin: "0px 5px" }}
          key={buttonName}
          className={
            competitionFilter === buttonName ? classes.selected : classes.tabButton
          }
          onClick={() => {
            setcompetitionFilter(buttonName);
          }}
        >
          {buttonName}
        </Button>
      );
    }

    return buttons;
  };

  const goToClubStat = (stat: string) => {
    const competitionId = competitionFilterOptions.get(competitionFilter);
    if (competitionId !== undefined) {
      dispatch(setCurrentClubStatParams({competitionId: competitionFilterOptions.get(competitionFilter)!, season: season!, stat: stat}));
      dispatch(changePage(Page.CompetitionClubStat));
    }
  };

  return leaguePlayersStats === undefined ? <Loading/> : (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeButtons()}</SizeFilterWrapper>
        <TypeFilterWrapper>
          Games:
          <Select
            variant="outlined"
            style={{ height: "2rem", marginLeft: "5px" }}
            value={positionFilter}
            fullWidth
            onChange={(event) => {
              setPositionFilter(event.target.value as string);
            }}
          >
            {GAMES_NUMBER_FITLER.map((value, index) => {
              return (
                <MenuItem key={`MenuItem${index}`} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </TypeFilterWrapper>
      </FiltersWrapper>
      <SectionHeader title={"General"} />
      <TablesContainer>
        <RankingTable title={'Rankings'} elements={leaguePlayersStats.ranking!} onSelect={() => {goToClubStat('Ranking')}}/>
        <RankingTable title={'Passes per game'} elements={leaguePlayersStats.passesCompleted} onSelect={() => {goToClubStat('Completed Passes')}}/>
      </TablesContainer>
      <SectionHeader title={"Attacking"} />
      <TablesContainer>
        <RankingTable title={'Goals'} elements={leaguePlayersStats.goals} onSelect={() => {goToClubStat('Goals')}}/>
        <RankingTable title={'Shots per game'} elements={leaguePlayersStats.shots!} onSelect={() => {goToClubStat('Shots')}}/>
        <RankingTable title={'xG per Game'} elements={leaguePlayersStats.xg!} onSelect={() => {goToClubStat('Assists')}}/>
      </TablesContainer>
      <SectionHeader title={"Defending"} />
      <TablesContainer>
      <RankingTable title={'Conceded goals'} elements={leaguePlayersStats.concededGoals} onSelect={() => {goToClubStat('Conceded Goals')}}/>
      <RankingTable title={'Clean Sheets'} elements={leaguePlayersStats.cleanSheets} onSelect={() => {goToClubStat('Clean Sheets')}}/>
      </TablesContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const TypeFilterWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 30px 5px 10px;
  }
`;

const TablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default SquadStats;
