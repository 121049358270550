
import styled from "styled-components";
import { Button, Grid, InputAdornment, OutlinedInput, makeStyles } from "@material-ui/core";
import { DARK_BLUE, GREY, GREY2, LIGHT_BLUE, LIGHT_GREY, LIGHT_GREY1, LIGHT_GREY2 } from "../../../assets/colors";
import { useEffect, useState } from "react";
import { KeyboardArrowLeft, Search } from "@material-ui/icons";
import { black } from "material-ui/styles/colors";
import { useSelector } from "react-redux";
import { CompetitionApi } from "../../../api/competition";
import { useAppDispatch } from "../../../app/store";
import { Loading } from "../../../components/navigation/loading";
import { TableElement } from "../../../components/stats-base/table/ranking-table";
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { selectClubStatParams } from "../../../slices/competitionSlice";
import { changePage, Page } from "../../../slices/navigationSlice";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const searchBarStyles = makeStyles(() => ({
    root: {
        height: "2.4rem",
        margin: "0 1rem",
        width: "calc(100% - 2rem)",
        borderColor: LIGHT_GREY,
        borderRadius: '8px',
        "&$focused $notchedOutline": {
            borderColor: DARK_BLUE,
        },
    },
    focused: {},
    notchedOutline: {},
}));

export const ClubTableRanking = () => {
    const searchBarClass = searchBarStyles();
    const classes = sportsAiGlobalStyles();

    const dispatch = useAppDispatch();
    const playersStatsParams = useSelector(selectClubStatParams);

    const [allPlayers, setAllPlayers] = useState<TableElement[]>([]);
    const [players, setPlayers] = useState<TableElement[]>([]);
    const [competition, setCompetition] = useState<string>('');
    const [playerNumber, setPlayerNumber] = useState<number|undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const playersPerPage = 10;

    useEffect(() => {
        setIsLoading(true);
        CompetitionApi.getClubTable(playersStatsParams!.competitionId, playersStatsParams!.season, playersStatsParams!.stat)
            .then((response) => {
                setAllPlayers(response.data.ranking as TableElement[]);
                setPlayers(response.data.ranking as TableElement[]);
                setCompetition(response.data.competition);
                setPlayerNumber(response.data.ranking.length);
            })
            .finally(() => setIsLoading(false));
    }, [dispatch, playersStatsParams]);

    const applySearchFilter = (value: string) => {
        const filteredPlayers = allPlayers.filter((player) => player.key.toLowerCase().includes(value.toLowerCase()));
        setPlayers(filteredPlayers);
        setCurrentPage(0);
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    const displayedPlayers = players.slice(currentPage * playersPerPage, (currentPage + 1) * playersPerPage);
    const totalPages = Math.ceil(players.length / playersPerPage);
    const startPage = Math.max(0, Math.min(totalPages - 4, currentPage - 1));
    const endPage = Math.min(totalPages, startPage + 4);

    return (
        <Wrapper>
            <HeaderWrapper>
            <Button
                className={classes.back}
                onClick={() => {
                    dispatch(changePage(Page.LeagueStat));
                }}
                >
                <KeyboardArrowLeft />
                All Clubs Stats
            </Button>
            <Grid container spacing={0}>
                <Grid item xs={8}>
                    <PlaylistInfoWrapper>
                        <DescriptionWrapper style={{fontFamily: 'Karla Regular', color: GREY2}}>
                            Club Stats
                        </DescriptionWrapper>
                        <PlaylistNameWrapper>
                            {playersStatsParams?.stat}
                        </PlaylistNameWrapper>
                    </PlaylistInfoWrapper>
                </Grid>
                <Grid item xs={2}>
                    <PlaylistInfoWrapper2 style={{borderRight: '1px solid #F0F0F0'}}>
                        <div style={{fontFamily: 'Karla Regular', color: GREY2}}>Competition</div>
                        <div>{competition}</div>
                    </PlaylistInfoWrapper2>
                </Grid>
                <Grid item xs={2}>
                    <PlaylistInfoWrapper2>
                        <div style={{fontFamily: 'Karla Regular', color: GREY2}}>Clubs</div>
                        <div>{playerNumber}</div>
                    </PlaylistInfoWrapper2>
                </Grid>
            </Grid>
            </HeaderWrapper>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <SearchWrapper>
                        <OutlinedInput
                            classes={searchBarClass}
                            placeholder="Search by team name..."
                            onChange={(event) => applySearchFilter(event.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search style={{ color: LIGHT_GREY }} />
                                </InputAdornment>
                            }
                        />
                    </SearchWrapper>
                </Grid>
            </Grid>
            <Table>
                <TableHeader>
                    <HeaderCell>RANK</HeaderCell>
                    <HeaderCell>TEAM</HeaderCell>
                    <HeaderCell>GAMES PLAYED</HeaderCell>
                    <HeaderCell>{playersStatsParams?.stat.toUpperCase()}</HeaderCell>
                </TableHeader>
                {isLoading ? <Loading /> : (
                    displayedPlayers.map((player, i) => (
                        <PlaylistInfo
                            key={i}
                            style={i % 2 === 0 ? { backgroundColor: "white" } : { backgroundColor: LIGHT_GREY1 }}
                        >
                            <div style={{ display: "inline-block", textAlign: "left", textTransform: "capitalize", maxWidth: "500px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                {player.rank}
                            </div>
                            <div style={{ textAlign: "left", textTransform: "capitalize" }}>
                                <TeamWrapper>
                                    {player.logo && <Logo src={player.logo} alt={`${player.team} logo`} />}
                                    {player.key}
                                </TeamWrapper>
                            </div>
                            <div style={{ textAlign: "left", textTransform: "capitalize" }}>{player.games}</div>
                            <div style={{ textAlign: "left", textTransform: "capitalize" }}>{player.value}</div>
                        </PlaylistInfo>
                    ))
                )}
                <Pagination>
                    <PaginationButton onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 0}>
                        <ArrowBackIosIcon />
                    </PaginationButton>
                    {Array.from({ length: endPage - startPage }, (_, i) => (
                        <PageOption
                            key={startPage + i}
                            selected={currentPage === startPage + i}
                            onClick={() => handlePageClick(startPage + i)}
                        >
                            {startPage + i + 1}
                        </PageOption>
                    ))}
                    <PaginationButton onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage + 1 >= totalPages}>
                        <ArrowForwardIosIcon />
                    </PaginationButton>
                </Pagination>
            </Table>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  font-family: Karla Bold;
`;

const PlaylistInfoWrapper = styled.div`
  width: 100%;
  margin: 0rem 2rem;
  display: flex;
  flex-direction: column; 
`;

const PlaylistInfoWrapper2 = styled.div`
    width: 60%;
    margin: 2rem;
    flex-direction: column; 
    text-align: left;
`;  

const DescriptionWrapper = styled.div`
  margin-top: 0rem 0rem 0rem 2rem; 
`;

const PlaylistNameWrapper = styled.div`
  font-size: 3rem;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
`;

const SearchWrapper = styled.div`
    padding: 1rem 0rem 1rem 0rem;
`;

const Table = styled.div`
    width: 97%;
    height: 40.5rem;
    margin: 40px 20px 0px 20px;
    background-color: white;
    border-radius: 18px;
    border: 1px solid ${LIGHT_GREY};
    overflow-y: auto;
`;

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 15% 30% 25% 15% 15%;
    text-align: center;
    align-items: center;
    padding: 10px;
    margin: 0 2rem;
    color: ${GREY};
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
`;


const HeaderCell = styled.div`
    text-align: left;
    cursor: pointer;
    color: inherit;
    display: flex;
    align-items: center;
`;

const PlaylistInfo = styled.div`
    display: grid;
    grid-template-columns: 20% 30% 25% 25%;
    text-align: center;
    align-items: center;
    color: ${black};
    padding: 10px;
    border-radius: 8px;
    margin: 0 2rem;
    > div {
        text-transform: capitalize;
    }
    &:hover {
        cursor: pointer;
    }
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;
`;

const PaginationButton = styled.button`
    background: none;
    border: none;
    color: ${DARK_BLUE};
    cursor: pointer;
    &:disabled {
        color: ${LIGHT_GREY};
    }
    &:focus {
        outline: none;
    }
`;

const PageOption = styled.button<{ selected: boolean }>`
    background: ${({ selected }) => (selected ? LIGHT_BLUE : 'none')};
    border: ${({ selected }) => (selected ? `1px solid ${LIGHT_BLUE}` : 'none')};
    color: ${({ selected }) => (selected ? 'white' : GREY)};
    border-radius: 4px;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;