import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { LIGHT_GREY1, LIGHT_GREY2 } from "../../assets/colors";
import TopBar from "../../components/navigation/topbar";
import { LoadingWrapper } from "../../components/navigation/loading";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";
import { useSelector } from "react-redux";
import { LeagueStatPage, selectLeaguePage, setLeaguePage } from "../../slices/competitionSlice";
import { useState } from "react";
import { useAppDispatch } from "../../app/store";
import { selectCompetitions } from "../../slices/teamSlice";
import PlayersStats from "./player-rankings/playerStats";
import SquadStats from "./team-rankings/squadStats";

const Rankings = () => <LoadingWrapper comp={<RankingsBase />} />

const RankingsBase = () => {
  const classes = sportsAiGlobalStyles();
  const [statPage, setStatPage] = useState<LeagueStatPage>(useSelector(selectLeaguePage));
  const dispatch = useAppDispatch();
  const competitions = useSelector(selectCompetitions).filter((competition) => competition.is_under_contract);

  if (competitions.length === 0) {
    return (
      <Wrapper>
      <HeaderWrapper>
        <TopBar name={"League"} />
        <PlayersStats />
      </HeaderWrapper>
    </Wrapper>
    )
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <TopBar name={"League"} />
        <>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  className={
                    statPage === LeagueStatPage.Overview
                      ? classes.tabButtonSelected
                      : classes.tabButton
                  }
                  onClick={() => {
                    dispatch(setLeaguePage(LeagueStatPage.Overview));
                    setStatPage(LeagueStatPage.Overview);
                  }}
                >
                  PLAYERS STATS
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={
                    statPage === LeagueStatPage.Squad
                      ? classes.tabButtonSelected
                      : classes.tabButton
                  }
                  onClick={() => {
                    dispatch(setLeaguePage(LeagueStatPage.Squad));
                    setStatPage(LeagueStatPage.Squad);
                  }}
                >
                  CLUB STATS
                </Button>
              </Grid>
            </Grid>
            {statPage === LeagueStatPage.Overview ? (
              <PlayersStats />
            ) : (
              <div style={{ padding: "2rem" }}>
                <SquadStats />
              </div>
            )}
          </>
      </HeaderWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export default Rankings;
