import { Tooltip } from "@material-ui/core";
import Hexagon from "react-hexagon";
import styled from "styled-components";
import {
  BLUE_GRADIENT,
  RED_BLUE_GRADIENT,
  RED_GRADIENT,
  RED_TO_GREEN_GRADIENT,
} from "../../../assets/colors";

const HexagonField = ({ data, color }) => {
  let domain = 0;
  domain = Math.max(...data);
  let max_blue = 0
  let max_red = 0
  if (color === 'red_blue'){
    for(let i = 0; i < data.length; i++){
      if(data[i].noReactions > max_red){
        max_red = data[i].noReactions;
      };
      if((data[i].placements + data[i].pressings) >= max_blue){
        max_blue = (data[i].placements + data[i].pressings);
      };
    }
    domain = max_blue + max_red
  }
  const getColors = () => {
    switch (color) {
      case "blue":
        return BLUE_GRADIENT;
      case "red":
        return RED_GRADIENT;
      case "red_blue":
        return RED_BLUE_GRADIENT;
      default:
        return RED_TO_GREEN_GRADIENT;
    }
  };

  const getSectionColor = (value: any) => {
    if (color === "red_blue") {
      if (value.pressings + value.placements + value.noReactions === 0) {
        return getColors()[Math.floor((getColors().length - 1) / 2)];
      } else {
        let ratio = 0
        if(value.pressings === 0 && value.placements === 0 && value.noReactions !== 0){
          ratio = 0.5 - (((value.noReactions / max_red))*0.5) ;
        }
        else if((value.pressings!== 0 || value.placements !== 0) && value.noReactions === 0){
          ratio = ((((value.pressings + value.placements) / max_blue))*0.5) + 0.50;
        }
        else if(value.pressings + value.placements === value.noReactions && value.noReactions !== 0){
          ratio = 0.45
        }
        else if((value.pressings + value.placements) > value.noReactions && value.noReactions !== 0){
          ratio = 0.5 + (((value.pressings + value.placements - value.noReactions)/ (max_blue))) 
        }
        else if((value.pressings + value.placements) < value.noReactions && (value.pressings !== 0  || value.placements !== 0)){
          ratio = 0.5 - (((value.noReactions - (value.pressings + value.placements))/ (max_red))) 
        }
        if(ratio>=1){ratio=1}
        if(ratio<=0){ratio=0}
        return getColors()[Math.round(ratio * (getColors().length - 1))];
      }
    }
    if (value === 0) return "#ffffff";
    return getColors()[Math.round((value / domain) * (getColors().length - 1))];
  };

  const formattedData = () => {
    const newData: number[][] = [];

    if (!data) return [];

    newData.push(data.slice(0, 6));
    newData.push(data.slice(6, 11));
    newData.push(data.slice(11, 17));
    newData.push(data.slice(17, 22));
    newData.push(data.slice(22, 28));

    return newData;
  };

  const CustomToolTip = (value) => {
    return color === "red_blue" ? (
      <>
        <TooltipElement>Placements: {value.placements}</TooltipElement>
        <TooltipElement>Pressings: {value.pressings}</TooltipElement>
        <TooltipElement>No Reactions: {value.noReactions}</TooltipElement>
      </>
    ) : (
      <TooltipElement>{value}</TooltipElement>
    );
  };

  const getSection = (i: number, j: number, value: number) => {
    if (i === 2 && j === 0)
      return (
        <>
          <LeftTopGoalSegment
            style={{
              borderLeftColor: getSectionColor(value),
              borderBottomColor: getSectionColor(value),
            }}
          />
          <HexagonWrapper>
            <Hexagon
              style={{
                fill: getSectionColor(value),
                stroke: getSectionColor(value),
              }}
            />
          </HexagonWrapper>
          <LeftBotGoalSegment
            style={{
              borderLeftColor: getSectionColor(value),
              borderTopColor: getSectionColor(value),
            }}
          />
        </>
      );
    if (i === 2 && j === 5)
      return (
        <>
          <RightTopGoalSegment
            style={{
              borderRightColor: getSectionColor(value),
              borderBottomColor: getSectionColor(value),
            }}
          />
          <HexagonWrapper>
            <Hexagon
              style={{
                fill: getSectionColor(value),
                stroke: getSectionColor(value),
              }}
            />
          </HexagonWrapper>
          <RightBotGoalSegment
            style={{
              borderRightColor: getSectionColor(value),
              borderTopColor: getSectionColor(value),
            }}
          />
        </>
      );

    return (
      <HexagonWrapper>
        <Hexagon
          style={{
            fill: getSectionColor(value),
            stroke: getSectionColor(value),
          }}
        />
      </HexagonWrapper>
    );
  };

  return (
    <FieldWrapper>
      <Center>
        <Head/>
        <Arrow />
      </Center>
      <FieldInnerWrapper>
        <GoalOutline style={{ marginRight: "-29px" }} />
        <GridWrapper>
          {formattedData().map((row, rowIndex) => {
            return (
              <HexagonRow>
                {row.map((value, hexIndex) => {
                  return (
                    <Tooltip key={(rowIndex+hexIndex)*(rowIndex+hexIndex+1)/2+hexIndex} title={CustomToolTip(value)} placement="top">
                      <div>{getSection(rowIndex, hexIndex, value)}</div>
                    </Tooltip>
                  );
                })}
              </HexagonRow>
            );
          })}
        </GridWrapper>
        <GoalOutline style={{ marginLeft: "-29px" }} />
      </FieldInnerWrapper>
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FieldInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const GoalOutline = styled.div`
  width: 25px;
  height: 100px;
  border: 2px solid black;
  position: relative;
  z-index: 1;
`;
const Center = styled.div`
  width: 100px;
  height: 100px;
  border: 2px solid black;
  border-radius: 100%;
  margin-bottom: calc(-424px / 2 - 50px - 6px);
  position: relative;
  z-index: 1;
`;
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  left: 58%;
  margin-top: -16px; /* Half of the arrow's height */
  margin-left: -8px; /* Adjust the distance between the Center div and the arrow */
  border: 16px solid transparent;
  border-left-color: black; /* Arrow color */
`;
const Head = styled.div`
  position: absolute;
  top: 50%;
  left: 58%;
  width: 20px;
  margin-top: -3px; /* Half of the arrow's height */
  margin-left: -24px; /* Adjust the distance between the Center div and the arrow */
  border: 3px solid ;
`;

const GridWrapper = styled.div`
  background-color: white;
  width: 558px;
  height: 424px;
  border: 2px solid black;
  border-radius: 8px;
  padding: 3px 0px;
  > div:nth-child(even) {
    margin-left: 46px;
  }
`;
const HexagonRow = styled.div`
  display: flex;
  clear: left;
`;
const HexagonWrapper = styled.div`
  width: 86px;
  float: left;
  margin-left: 6px;
  margin-bottom: -22px;
`;
const LeftTopGoalSegment = styled.div`
  height: 53px;
  width: 0;
  border-left: 38px solid;
  border-top: 22px solid transparent;
  border-bottom: 28px solid;
  border-right: 48px solid transparent;
  margin: -78px 0 -25px 6px;
`;
const LeftBotGoalSegment = styled.div`
  height: 54px;
  width: 0;
  border-left: 38px solid;
  border-bottom: 22px solid transparent;
  border-top: 28px solid;
  border-right: 48px solid transparent;
  margin: 74px 0 -100px 6px;
`;
const RightTopGoalSegment = styled.div`
  height: 53px;
  width: 0;
  border-right: 38px solid;
  border-top: 22px solid transparent;
  border-bottom: 28px solid;
  border-left: 48px solid transparent;
  margin: -78px 0 -25px 6px;
`;
const RightBotGoalSegment = styled.div`
  height: 54px;
  width: 0;
  border-right: 38px solid;
  border-bottom: 22px solid transparent;
  border-top: 28px solid;
  border-left: 48px solid transparent;
  margin: 74px 0 -100px 6px;
`;
const TooltipElement = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
`;
export default HexagonField;
