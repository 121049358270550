export const WHITE = "#FFFFFF";
export const CYAN = "#66B0CB";
export const DARK_BLUE = "#007CA8";
export const GREY = "#9F9F9F";
export const GREY2 = "#707070";
export const GREY3 = "#D0D0D0";
export const LIGHT_GREY = "#F0F0F0";
export const LIGHT_GREY0 = "#BCBCBC"
export const LIGHT_GREY1 = "#FAFAFA";
export const LIGHT_GREY2 = "#F2F8FA";
export const YELLOW = "#FFEB33";
export const RED = "#E43B10";
export const RED2 = "#F48E7F";
export const LIGHT_RED = "#F48E7F";
export const LIGHT_RED1 = "#FBD9D4";
export const GREEN = "#108F0D";
export const LIGHT_GREEN = "#F1F9F0";
export const FOREST_GREEN = "#569954";
export const LIGHT_BLUE = "#CCE5EE";
export const PURPLE = "#6141E1";
export const LIGHT_PURPLE = "#F1EEFF";
export const BLACK = "#000000";
export const LIGHT_BLACK= "#464646";
export const CLEAR_BLUE = '#66B0CB';
export const CLEAR_BLUE_50 = '#9FCDDE'

export const BLUE_GRADIENT = [
  "#F0F7FA",
  "#D1E7EF",
  "#C7E2EC",
  "#BCDDE8",
  "#B2D7E5",
  "#A8D2E1",
  "#9ECDDE",
  "#93C8DA",
  "#89C3D7",
  "#80BDD3",
  "#75B8D0",
  "#6BB3CC",
  "#61AEC9",
  "#57A8C5",
  "#4CA3C2",
  "#439FBF",
  "#3999BB",
  "#2D93B7",
  "#248EB4",
  "#1989B0",
  "#0F84AD",
];

export const RED_GRADIENT = [
  "#faf2f0",
  "#fae4df",
  "#fbd7ce",
  "#fbc9bd",
  "#fbbbab",
  "#fcae9a",
  "#fca089",
  "#fd9378",
  "#fd8567",
  "#fd7756",
  "#fe6a45",
  "#fe5c33",
  "#fe4e22",
  "#ff4111",
  "#ff3300",
];

export const ORANGE_GRADIENT = [
  "#FFE9CC",
  "#FFBE67",
  "#F4B460",
  "#FFA834",
  "#FF9201",
];

export const YELLOW_GRADIENT = [
  "#FFFACC",
  "#FFF499",
  "#FFEF67",
  "#FFE934",
  "#FFE401",
];

export const LIGHT_GREEN_GRADIENT = [
  "#E9F2CD",
  "#D3E59B",
  "#BED86A",
  "#A8CB38",
  "#92BE06",
];

export const GREEN_GRADIENT = [
  "#CFE9CF",
  "#9FD29E",
  "#70BC6E",
  "#40A53D",
  "#108F0D",
];

export const RED_TO_GREEN_GRADIENT = [
  "#FF0F00",
  "#FF2D00",
  "#FF4A01",
  "#FF6801",
  "#FF8601",
  "#FF9C01",
  "#FFAC01",
  "#FFBD01",
  "#FFCD01",
  "#FFDC01",
  "#F3E002",
  "#DFD903",
  "#C9D104",
  "#B2C905",
  "#9DC206",
  "#81BB08",
  "#65B70D",
  "#48B211",
  "#2DAE14",
  "#0EA919",
];

export const RED_BLUE_GRADIENT = [
  "#ff3300",
  "#ff4212",
  "#ff5024",
  "#ff5f37",
  "#ff6d49",
  "#ff7c5b",
  "#ff8a6d",
  "#ff9980",
  "#ffa892",
  "#ffb6a4",
  "#ffc5b6",
  "#ffd3c8",
  "#ffe2db",
  "#fff0ed",
  "#ffffff",
  "#eff6f9",
  "#deedf4",
  "#cee4ee",
  "#bddbe8",
  "#add2e3",
  "#9cc9dd",
  "#8cc0d8",
  "#7cb7d2",
  "#6baecc",
  "#5ba5c7",
  "#4a9cc1",
  "#3a93bb",
  "#298ab6",
  "#1981b0",
];