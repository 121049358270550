import { Grid } from "@material-ui/core";
import styled from "styled-components";
import {
  DARK_BLUE,
  FOREST_GREEN,
  LIGHT_GREY,
  RED,
} from "../../../assets/colors";

const PlayerProfileCard = ({ stat }) => {
  const printPercentage = () => {
    let statColor = stat.isWarning ? RED : FOREST_GREEN;
    if (stat.performance >= 0)
      return (
        <div style={{ color: statColor }}>
          {`+${Math.round(stat.performance)}${
            stat.isPerformancePercentage ? `%` : ``
          }`}
        </div>
      );
    else if (stat.performance < 0)
      return (
        <div style={{ color: statColor }}>
          {`${Math.round(stat.performance)}${
            stat.isPerformancePercentage ? `%` : ``
          }`}
        </div>
      );
    else return <></>;
  };

  return (
    <>
      {stat ? (
        <Grid item>
          <Wrapper>
            <Header>
              <StatLabel>{stat.statHeader}</StatLabel>
              {printPercentage()}
            </Header>
            <PlayerStat>
              {stat.value < 1 && stat.value > 0 ? (
                <Stat>{`${Math.round(stat.value * 100)}%`}</Stat>
              ) : (
                <Stat>{stat.value}</Stat>
              )}
            </PlayerStat>
          </Wrapper>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0.75rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  width: 175px;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;
const PlayerStat = styled.div`
  display: flex;
  width: 100%;
  margin: 0.75rem 0px;
`;
const Stat = styled.div`
  font-size: 1.75rem;
  color: ${DARK_BLUE};
`;
const StatLabel = styled.div`
  text-transform: capitalize;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

export default PlayerProfileCard;
