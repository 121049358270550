import React from "react";
import {
  Avatar,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../app/store";
import { registerUser } from "../../slices/userSlice";
import { changePage, Page } from "../../slices/navigationSlice";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormData {
  username: string;
  email: string;
  password: string;
  password2: string;
  allowExtraEmails: string;
}

export const RegisterForm = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { register, handleSubmit, formState, setError, clearErrors, errors } =
    useForm({ mode: "onChange" });

  const onSubmit = ({ username, email, password, password2 }: FormData) => {
    if (password !== password2) {
      // TODO: add form validation
      console.log("passwords doest not match");
      setError("password2", {
        type: "manual",
        message: "Confirmation password should match!",
      });
    } else {
      const newUser = {
        username,
        email,
        password,
      };
      dispatch(registerUser(newUser)).then(() => {
        dispatch(changePage(Page.Login));
      });
    }
  };
  const { isDirty, isValid } = formState;
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                name="username"
                variant="outlined"
                required
                fullWidth
                id="userName"
                label="User Name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                variant="outlined"
                required
                fullWidth
                id="password2"
                label="Confirm password"
                name="password2"
                type="password"
                error={errors.password2?.type === "manual"}
                helperText={
                  errors.password2?.type === "manual" &&
                  errors.password2?.message
                }
                onChange={() => clearErrors("password2")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    inputRef={register}
                    name="allowExtraEmails"
                    color="primary"
                  />
                }
                label="I want to receive marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isDirty || !isValid}
          >
            Sign Up
          </Button>
          <Grid container justify="center">
            <Grid item>
              <Link href="/" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default RegisterForm;
