import { Button, Grid } from '@material-ui/core';
import styled from "styled-components";
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { matchApi } from '../../api/matchApi';
import ModalTopbar from '../../components/navigation/modalTopbar';
import BackupIcon from '@mui/icons-material/Backup';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useAppDispatch } from '../../app/store';
import { getGamesSummary } from '../../slices/gamesSlice';
import { useSelector } from 'react-redux';
import { selectTeamId } from '../../slices/teamSlice';
import { selectSeason } from '../../slices/userSlice';
import { DARK_BLUE, RED } from '../../assets/colors';
import { sportsAiGlobalStyles } from '../../shared/globalStyle';

const IDLE: number= 0;
const LOADING: number= 1;
const UPDATING: number= 2;
const LOADED: number= 3;

const MAX_SIZE: number = 1000000000; // 10gb
const MIN_SIZE: number = 50000000; // 500 mb
const FILE_TYPE: string = "video/mp4";

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number, state: number }) => {
  if (props.state === IDLE)
    return <></>

  if (props.state === UPDATING)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">100%</Typography>
        </Box>
      </Box>
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const UploadIcon = ({isHovered, state}) => {
  if (state === LOADED )
    return <CloudDoneIcon style={{color: DARK_BLUE}}/>
  if (isHovered)
    return <BackupIcon style={{ animation: 'bounce 1s infinite', color: DARK_BLUE}} />
  if (state === LOADING|| state === UPDATING) 
    return <BackupIcon style={{ transition: 'transform 0.3s ease-in-out', transform: `translateY(-5px)`, color: DARK_BLUE}} />
  return <BackupIcon/>
}

const validateFile = (file: File, setErrorMessage) => {
  if (file.type !== FILE_TYPE){
    setErrorMessage("The file does not have the right type (mp4)");
    return false;
  }
  if (file.size > MAX_SIZE){
    setErrorMessage("File size is higher then 10Gb");
    return false
  }
  if (file.size < MIN_SIZE){
    setErrorMessage("File size is lower then 500Mb, please upload another video");
    return false
  }

  return true
}

const VideoUploader = ({gameId, setIsStatModalOpen}: {gameId: number, setIsStatModalOpen: any}) =>  {
    const classes = sportsAiGlobalStyles();
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [presignedUrl, setPresignedUrl] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [uploadState, setUploadState] = useState(IDLE);

    const dispatch = useAppDispatch();
    const teamId = useSelector(selectTeamId) || 0;
    const season = useSelector(selectSeason);

    useEffect(() => {
      matchApi.getS3PresignedUrl(gameId).then((response) => {
        setPresignedUrl(response.data['url']);
      });
    }, [gameId])

    const onDrop = useCallback( (files) => {
    setErrorMessage("");
      if (uploadState === LOADED)
        return;
      const file = files[0];
      if (!validateFile(file, setErrorMessage))
        return false;
      setIsHovered(false)
      setUploadState(LOADING);
      const newFile = new File([file], gameId.toString() + ".mp4", {
        type: file.type,
        lastModified: file.lastModified
      });

      try {
        axios.put(presignedUrl, newFile, {
          headers: {
            'Content-Type': "application/mp4",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(progressEvent.loaded * 100/ progressEvent.total);
            setUploadProgress(progress)
          }
        }).then((response) => {
          setUploadState(UPDATING);
          matchApi.updateMatchVideoKey(gameId).then((response) => {
            if (response.status !== 200){
              setErrorMessage("could not complete upload of the video. Please try again later");
            }
            else {
              setUploadState(LOADED);
              dispatch(getGamesSummary({teamId, season}));
            }

          })
        })
      } catch (error){
        setErrorMessage("Error when uploading the video. Please try again later.")
      }
    }, [presignedUrl, season, teamId, uploadState, gameId, dispatch]);

    const { getRootProps, getInputProps } = useDropzone({ 
      onDrop,
      onDragEnter: () => setIsHovered(true),
      onDragLeave: () => setIsHovered(false),
    });

    return (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <ModalTopbar Action={"Upload game video"} onClose={() => {setIsStatModalOpen(false)}}/>
          </Grid>     
          <Grid item xs={12}>
              <div {...getRootProps()} >
              <UploadIconStyle>
                <input {...getInputProps()} />
                <UploadIcon isHovered={isHovered} state={uploadState}/>
                  {
                    uploadState === LOADED ? (
                      <>Upload of the game is completed</>
                    ): (
                      <div>
                        <>Drop files or click here to upload videos</>
                          <Button className={classes.outlinedButton} >
                            Click here to select File
                          </Button>
                      </div>
                    )
                  }
                </UploadIconStyle>
                <ErrorIconStyle>
                  {
                    errorMessage === '' ? (
                      <>
                      </>
                    ): (
                      <>
                        <ReportProblemOutlinedIcon/>
                        {errorMessage}
                      </>
                    )
                  }
                </ErrorIconStyle>

            <ProgressBarStyle>
              <LinearProgressWithLabel value={uploadProgress} state={uploadState}/>
            </ProgressBarStyle>
      
            </div>
          </Grid>
        </Grid>
    );
};


export default VideoUploader;

const UploadIconStyle = styled.div`
  width: auto;
  overflow-x: hidden;
  padding: 15%;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  & svg {
    font-size: 4.5rem;
    margin: 0%;
    color: grey;
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  & Button {
    margin-top: 40px; 
    width: 60%
  }
`;
const ErrorIconStyle = styled.div`
  width: auto;
  padding: 0%;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  & svg {
    font-size: 1.5rem;
    margin: 1%;
    color: ${RED};
  }
`;

const ProgressBarStyle = styled.div`
  width: 'auto';
  display: 'flex';
  alignItems: 'center';
  padding: 0rem 0.5rem;
`;
