import { Button, Grid } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useAppDispatch } from "../../app/store";
import GameAttacking from "./game/game-attacking";
import GameDefending from "./game/game-defending";
import GameOverview from "./game/game-overview";
import GamePlayerStats from "./player/game-player-stats";
import GameHeader from "../../components/home-page/game-header/game-header";
import { Game } from "../../models/game";
import { selectCurrentGame, getGame } from "../../slices/gamesSlice";
import {
  getGamePlayerPositions,
  getGamePlayers,
} from "../../slices/playerSlice";
import { changePage, Page } from "../../slices/navigationSlice";
import { LIGHT_GREY1, LIGHT_GREY2 } from "../../assets/colors";
import logo from "./../../assets/images/Logo_Ball.png";
import { selectAccountType } from "../../slices/userSlice";
import { PLAYER_ACCOUNT } from "../../models/constant/accountType";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";

enum StatPage {
  Game,
  Players,
}
enum GamePageState {
  Overview,
  Attacking,
  Defending,
}

const GameStat = () => {
  const accountType = useSelector(selectAccountType);
  const classes = sportsAiGlobalStyles();
  const dispatch = useAppDispatch();
  const currentGame: Game | undefined = useSelector(selectCurrentGame);

  if (currentGame) {
    dispatch(getGamePlayers(currentGame.id));
    dispatch(getGamePlayerPositions(currentGame.id));
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentGame && currentGame.isLiveGame)
        dispatch(getGame(currentGame.id));
    }, 60000);
    return () => clearInterval(interval);
  });

  const [statPage, setStatPage] = useState(StatPage.Game);
  const [gamePageState, setGamePageState] = useState(GamePageState.Overview);

  const renderGamePage = () => {
    switch (gamePageState) {
      case GamePageState.Overview:
        return <GameOverview />;
      case GamePageState.Attacking:
        return <GameAttacking />;
      case GamePageState.Defending:
        return <GameDefending />;
    }
  };

  return currentGame ? (
    <Wrapper>
      <HeaderWrapper>
        <Button
          className={classes.back}
          onClick={() => {
            dispatch(changePage(Page.Games));
          }}
        >
          <KeyboardArrowLeft />
          Games
        </Button>
        {currentGame ? <GameHeader {...currentGame}></GameHeader> : <></>}
        <Grid container>
          <Grid item xs={6}>
            <Button
              className={
                statPage === StatPage.Game
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setStatPage(StatPage.Game);
              }}
            >
              Game Stats
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={
                statPage === StatPage.Players
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setStatPage(StatPage.Players);
              }}
            > 
              {accountType === PLAYER_ACCOUNT ? "My Stats" : "Player Stats"}
            </Button>
          </Grid>
        </Grid>
      </HeaderWrapper>
      {statPage === StatPage.Game ? (
        <GameStatsWrapper>
          <GameStatTabWrapper>
            <Button
              className={
                gamePageState === GamePageState.Overview
                  ? classes.tabButtonSecondarySelected
                  : classes.tabButtonSecondary
              }
              onClick={() => {
                setGamePageState(GamePageState.Overview);
              }}
            >
              Overview
            </Button>
            <Button
              className={
                gamePageState === GamePageState.Attacking
                ? classes.tabButtonSecondarySelected
                : classes.tabButtonSecondary
              }
              onClick={() => {
                setGamePageState(GamePageState.Attacking);
              }}
            >
              Attacking
            </Button>
            <Button
              className={
                gamePageState === GamePageState.Defending
                ? classes.tabButtonSecondarySelected
                : classes.tabButtonSecondary
              }
              onClick={() => {
                setGamePageState(GamePageState.Defending);
              }}
            >
              Defending
            </Button>
          </GameStatTabWrapper>
          {renderGamePage()}
        </GameStatsWrapper>
      ) : (
        <GameStatsWrapper>
          <GamePlayerStats /> 
        </GameStatsWrapper>
      )}
    </Wrapper>
  ) : (
    <Wrapper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingLogo src={logo} alt="SportsAI_Logo" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  font-family: Karla Bold;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;
const GameStatsWrapper = styled.div`
  width: calc(100% - 4rem);
  background-color: ${LIGHT_GREY1};
  padding: 2rem;
`;
const GameStatTabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const LoadingLogo = styled.img`
  width: 15rem;
  animation: blinkAndGrow 2s infinite ease;
`;
export default GameStat;
