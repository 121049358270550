import styled from "styled-components";
import { DARK_BLUE, LIGHT_GREY, RED, YELLOW } from "../../../assets/colors";

const TeamOffenses = ({ fouls, yCards, rCards }) => {
  let isDataValid = true;

  if (!fouls || !yCards || !rCards) isDataValid = true;
  return isDataValid ? (
    <Wrapper>
      <CardWrapper style={{ marginRight: "1rem" }}>
        <Number style={{ color: DARK_BLUE }}>{fouls}</Number>
        <b>Fouls</b>
      </CardWrapper>
      <CardWrapper>
        <Number style={{ color: YELLOW }}>{yCards}</Number>
        <b>Yellow Cards</b>
      </CardWrapper>
      <CardWrapper style={{ marginLeft: "1rem" }}>
        <Number style={{ color: RED }}>{rCards}</Number>
        <b>Red Cards</b>
      </CardWrapper>
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 10px;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  height: 100%;
  b {
    text-transform: uppercase;
    margin-left: 10px;
  }
`;
const Number = styled.div`
  font-size: 2rem;
`;

export default TeamOffenses;
