import { Button, ListItem, ListItemText, makeStyles, Switch } from "@material-ui/core";
import styled from "styled-components";
import { DARK_BLUE, GREY, WHITE, BLACK, GREY3, LIGHT_GREY1, GREY2, LIGHT_BLACK } from "../../../../../assets/colors";
import fieldImage from '../../../../../assets/images/recuperations-image.png';

const useStyles = makeStyles(() => ({
    clear: {
      width: '50%',
      backgroundColor: GREY,
      borderColor: WHITE,
      borderRadius: "5px",
      border: "2px solid",
      color: WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1.2rem",
      justifyContent: 'center',
      "&:hover": {
        color:WHITE,
      backgroundColor: GREY,
      cursor: "pointer",
      },
    },
    buttonSubStatAll: {
      color: "black",
      fontStyle: "italic", 
      fontFamily: "Karla Bold",
      justify: "end","&:hover": {
      justifyContent: 'center',
      },
    },
    tabButton: {
      textAlign: "center",
      width: "100%",
      borderRadius: "0px",
      color: GREY,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
  
      backgroundColor: WHITE,
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    tabButtonSelected: {
      textAlign: "center",
      width: "100%",
      borderRadius: "0px",
      borderBottom: "3px solid",
      borderColor: DARK_BLUE,
      color: DARK_BLUE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      backgroundColor: WHITE,
    },
    directionsButton: {
      textAlign: "right",
      flexDirection: 'column',
      color: BLACK,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&:hover": {
        backgroundColor: GREY,
        color:WHITE,
      },
    },
    directionsButtonClicked: {
      textAlign: "right",
      flexDirection: 'column',
      backgroundColor: DARK_BLUE,
        color:WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&:hover": {
        backgroundColor: DARK_BLUE,
        color:WHITE,
      },
    },
    chalButton: {
      textAlign: "right",
      width:'150px',
      flexDirection: 'column',
      color: GREY,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      border:  "2px solid",
      borderColor: DARK_BLUE,
      borderRadius: "20px",
      "&:hover": {
        color:DARK_BLUE,
      backgroundColor: WHITE,
      },
    },
    chalButtonClicked: {
      textAlign: "right",
      width:'150px',
      flexDirection: 'column',
      backgroundColor: DARK_BLUE,
      color:WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      padding: "12px 32px 12px",
      border:  "2px solid",
      borderRadius: "20px",
      "&:hover": {
        color:WHITE,
      backgroundColor: DARK_BLUE,
      },
    },
    applyFilter: {
      width: '50%',
      backgroundColor: DARK_BLUE,
      borderColor: WHITE,
      borderRadius: "5px",
      border: "2px solid",
      fontFamily: "Karla Bold",
      fontSize: "1.2rem",
      color: WHITE,
      justifyContent: 'center',
      "&:hover": {
        color:WHITE,
      backgroundColor: DARK_BLUE,
      },
    },
    cancelFilter: {
      width: '50%',
      backgroundColor: GREY,
      borderColor: WHITE,
      borderRadius: "5px",
      border: "2px solid",
      color: WHITE,
      justifyContent: 'center',
      "&:hover": {
        color:WHITE,
      backgroundColor: GREY2,
      },
    },
    soccerFieldContainer: {
      justifyContent: 'end',
      alignItems: 'end',
    },
    buttonStat: {
      color: "black",
      backgroundColor: WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
    },
    buttonSubStat: {
      color: LIGHT_BLACK,
      backgroundColor: GREY3,
      fontFamily: "Karla Bold",
      justify: "end","&:hover": {
      backgroundColor: GREY3,
      },
    },
    SubStatContainer: {
      backgroundColor: LIGHT_GREY1,
    },
    buttonFieldStyle: {
      position: 'relative',
      display: 'inline-block',
      width: '25%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: "0px",
      border: "1.5px solid",
      borderColor: GREY,
      zIndex:0,
      "&:hover": {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
      
    },
    fieldClickedButton: {
      position: 'relative',
      display: 'inline-block',
      width: '25%',
      height: '100%',
      backgroundColor: DARK_BLUE,
      borderRadius: "0px",
      border: "1.5px solid",
      borderColor: BLACK,
      zIndex:0,
      "&:hover": {
        backgroundColor: DARK_BLUE,
      },
    },
    fieldBox1: {
      position: 'absolute',
      width:'50%',
      height: '60%',
      top: '20%',
      left:'0%',
      right:'0%',
      borderRadius: "0px",
      border: "1px solid",
      borderColor: GREY,
      backgroundColor: WHITE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    clickedFieldBox1: {
      position: 'absolute',
      width:'50%',
      height: '60%',
      top: '20%',
      left:'0%',
      right:'0%',
      borderRadius: "0px",
      border: "2px solid",
      borderColor: BLACK,
      backgroundColor: DARK_BLUE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: DARK_BLUE,
      },
    },
    fieldBox2: {
      position: 'absolute',
      width:'50%',
      height: '60%',
      top: '20%',
      right:'0%',
      borderRadius: "0px",
      border: "1px solid",
      borderColor: GREY,
      backgroundColor: WHITE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: WHITE,
      },
    },
    clickedFieldBox2: {
      position: 'absolute',
      width:'50%',
      height: '60%',
      top: '20%',
      right:'0%',
      borderRadius: "0px",
      border: "2px solid",
      borderColor: BLACK,
      backgroundColor: DARK_BLUE,
      zIndex: 1,
      "&:hover": {
        backgroundColor: DARK_BLUE,
      },
    },
    containerStyle: {
      position: 'relative',
      display: 'inline-block',
      width: '45%',
      height: 'min(25vh)', // set the minimum height of the container element
      maxHeight: '60vh',
      backgroundImage: `url(${fieldImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: "25%",
    },
  }));
  
export const ChallengeFilter = ({handleStatsButtonClick, buttonStates}) => {
    const classes = useStyles();

    return (
      <Challenges>

        <div className="container">
          <div className="AllCh"><ListItem className={classes.buttonSubStatAll}
              key={"allChallenges"}
              onChange={() => handleStatsButtonClick('allChallenges')} 
              button> ALL CHALLENGES 
              <ListItemText color="primary"/>
              <Switch color="primary" checked={buttonStates["challengeW"] &&  buttonStates["challengeL"] }  />
            </ListItem>
          </div>
          <div className="Challenges">
            <div className="Won">
            <ListItem className={classes.buttonStat}
                  key={"challengeW"}
                  onChange={() => handleStatsButtonClick('challengeW')} 
                  button> WON
                <ListItemText className={classes.buttonStat} />
                <Switch color="primary" checked={buttonStates["challengeW"] }/>
              </ListItem>
            </div>
            <div className="Lost">
                <ListItem className={classes.buttonStat}
                  key={"challengeL"}
                  onChange={() => handleStatsButtonClick('challengeL')} 
                  button> LOST
                    <ListItemText className={classes.buttonStat} />
                    <Switch color="primary" checked={buttonStates["challengeL"] }/>
                </ListItem>
            </div>
          </div>
          <div className="Defensive">
            <ControlButton>
              <Button className={
                  buttonStates["challengeDef"] === true
                  ? classes.chalButtonClicked
                  : classes.chalButton}
                  onClick={() => handleStatsButtonClick('challengeDef')} >
                {`Deffensive`}
              </Button>
            </ControlButton>
          </div>
          <div className="Aerial">
          <ControlButton><Button className={
              buttonStates["challengeAir"] === true
              ? classes.chalButtonClicked
              : classes.chalButton}
              onClick={() => handleStatsButtonClick('challengeAir')} >
            {`Aerial`}
            </Button></ControlButton>
          </div>
          <div className="Offensive">
          <ControlButton> <Button className={
                buttonStates["challengeOff"] === true
                    ? classes.chalButtonClicked
                    : classes.chalButton}
                onClick={() => handleStatsButtonClick('challengeOff')} >
              {`Offensive`}
            </Button>
          </ControlButton>
          </div>
        </div>
          </Challenges>
        
    )


}

const ControlButton = styled.div`
display: flex;
  justify-content: center;
  margin: 2px;
  gap: 10px;
  width: 100%; // Ensure it takes full width of the parent container
`;

const Challenges = styled.div`
.container { 
  margin:3.5%;
  padding: 5px 0 5px  0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  border: 2px solid ${DARK_BLUE};
  border-radius: 30px;
  grid-template-areas:
    "AllCh Defensive"
    "AllCh Defensive"
    "AllCh Aerial"
    "Challenges Aerial"
    "Challenges Offensive"
    "Challenges Offensive";
}

.AllCh { grid-area: AllCh;
  padding-top: 15px;
 }

.Challenges {  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  padding-bottom: 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "Won Lost";
  grid-area: Challenges;
}

.Won { grid-area: Won; }

.Lost { grid-area: Lost; }

.Defensive { grid-area: Defensive;
 }

.Aerial { grid-area: Aerial;
 }

.Offensive { grid-area: Offensive;


`;
