import { useRef } from 'react';
import styled from 'styled-components';

const FullGameModal = (props) => {
  const { videoLink } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  
  return (
    <Container>
      <Wrapper>
        <VideoPlayerContainer>
          <div>
            <video ref={videoRef} className="video-js vjs-default-skin" controls preload="auto" style={{ width: "100%", height: "auto" }}>
              <source src={videoLink} type="video/mp4" />
            </video>
          </div>
        </VideoPlayerContainer>
        
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;  
`;   
const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 90%;
  overflow-y: auto;
  font-family: Karla Bold;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);`;
  
const VideoPlayerContainer = styled.div`
width: 100%;
font-family: Karla Bold;
`;
export default FullGameModal;
