import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  DARK_BLUE,
  GREY,
  LIGHT_GREY1,
  WHITE,
} from "../../../assets/colors";
import curveArrowRight from '../../../assets/images/curveArrowRight.png'
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import { Sequence } from "../../../models/game";
import { useSelector } from "react-redux";
import { updateSelectedSequence, selectEventVideos, selectSelectedSequences, getEvents, setVideoEvents } from "../../../slices/videoSlice";
import StatModal from "./statModal/statModal";
import VideoPlayer from "../../../components/video/videoPlayer/VideoPlayer";
import { selectGamesSummary } from "../../../slices/gamesSlice";
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { PlaylistModal } from "../playlist/playlistModals.tsx/playlistModal";
import { RootState, useAppDispatch } from "../../../app/store";
import SequenceCard from "../../../components/video/sequenceCard";
import Select from '@mui/material/Select';
import { Checkbox, FormControl, InputLabel, Dialog, Grid, IconButton, MenuItem, ListItemIcon, ListItemText, Typography, ListSubheader} from "@mui/material";
import { selectTeamPlayers } from "../../../slices/playerSlice";
import { updateSelectedGames, updateselectedPlayers } from "../../../slices/sequencesSlice";
import SearchIcon from '@mui/icons-material/Search';
import { selectTeamId } from "../../../slices/teamSlice";
import { selectUser } from "../../../slices/userSlice";
import { PLAYER_ACCOUNT } from "../../../models/constant/accountType";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false,
};


const EventVideos = () => {
    const classes = sportsAiGlobalStyles();
    const dispatch = useAppDispatch();
    const [isStatModalOpen, setIsStatModalOpen] = useState<boolean>(false);
    const [hasModalOpened, setHasModalOpened] = useState(false);
    const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const playerId = useSelector(selectUser)?.id;
    const accountType = useSelector(selectUser)?.accountType;
    const games = useSelector(selectGamesSummary);
    const players = useSelector(selectTeamPlayers);
    const teamId = useSelector(selectTeamId);
    const selectedStats = useSelector((state: RootState) => state.sequencesSelection.selectedStats);
    const selectedPlayersRedux = useSelector((state: RootState) => state.sequencesSelection.selectedPlayers);
    const selectedGamesRedux = useSelector((state: RootState) => state.sequencesSelection.selectedGames);
    const videoList : Sequence[] = useSelector(selectEventVideos);
    const selectedSequence = useSelector(selectSelectedSequences);
    const videosPerPage = 8;
    const totalPages = Math.ceil(videoList.length / videosPerPage);
    const slicedVideos: Sequence[] = videoList.slice((currentPage - 1) * videosPerPage, currentPage * videosPerPage);
    
    const videoRef = useRef<HTMLVideoElement>(null);
    const [selectedScene, setSelectedScene] = useState<Sequence | null>(null);
    const [, setSelectedSceneIndex] = useState(0);
    const [loopIntervalDuration, setLoopIntervalDuration] = useState(20);
    const [loopInterval, setLoopInterval] = useState<NodeJS.Timeout | null>(null);
    const delay = loopIntervalDuration/2;

    const handleGameChange = (event, gameId: number) => {
      const isChecked = event.target.checked;
      const updatedSelectedGames = isChecked ? [...selectedGamesRedux, gameId] : selectedGamesRedux.filter((id) => id !== gameId);
      dispatch(updateSelectedGames(updatedSelectedGames));
    };
    const handleSelectAllGames = (event) => {
      if (event.target.checked) {
        const allGameIds = games.map((game) => game.id);
        dispatch(updateSelectedGames(allGameIds));
      } else {
        dispatch(updateSelectedGames([]));
      }
    };

    useEffect(() => {
      if (accountType === PLAYER_ACCOUNT && playerId !== undefined) {
        dispatch(updateselectedPlayers([playerId]));
      }
    }, []);

    const handlePlayersChange = (event, playerId) => {
      const isChecked = event.target.checked;
      const updatedSelectedPlayers = isChecked ? [...selectedPlayersRedux, playerId]: selectedPlayersRedux.filter(id => id !== playerId);
    
      dispatch(updateselectedPlayers(updatedSelectedPlayers));
      if (Object.values(selectedStats).some(stat => stat) && updatedSelectedPlayers.length && selectedGamesRedux.length) {
        dispatch(updateselectedPlayers(updatedSelectedPlayers));
      }
    };
    

    const handleSelectAllPlayers = (event) => {
      if (event.target.checked && 
          Object.values(selectedStats).some(stat => stat) && selectedGamesRedux.length) {
        const allPlayers = players.map((player) => player.id);
        dispatch(updateselectedPlayers(allPlayers));
      } else {
        dispatch(updateselectedPlayers([]));
      }
    };
  
    useEffect(() => {
      if (selectedGamesRedux.length > 0 && selectedPlayersRedux.length > 0 && Object.values(selectedStats).some(stat => stat)) {
        dispatch(getEvents({
          teamId: teamId ? teamId: 0,
          stats: selectedStats,
          players: selectedPlayersRedux,
          games: selectedGamesRedux,
        }));
      } else {
        dispatch(setVideoEvents([]))
      }
    }, [selectedGamesRedux, selectedPlayersRedux, dispatch, selectedStats, teamId]);
    
  
    useEffect(() => {
      if (
        videoList.length === 0 &&
        ( Object.values(selectedStats).filter(stat => stat).length === 0 ||
          selectedGamesRedux.length === 0 ||
          selectedPlayersRedux.length === 0
        ) && !hasModalOpened 
      ) {
          setIsStatModalOpen(true);
          setHasModalOpened(true);
        } 
    }, [videoList, selectedStats, selectedGamesRedux, selectedPlayersRedux, hasModalOpened]);
  
    const selectedStatsCount = Object.values(selectedStats).filter(stat => stat).length; // Count the number of true values

    var opponent_name = {}
    var game_name = {}
    
    useSelector(selectGamesSummary).forEach(element => {
      game_name[element.id] = 
                            element.opponentName+ " (" + 
                            element.teamScore + "-" + 
                            element.opponentScore + ")"
      opponent_name[element.id] = element.opponentName
    });

    const handleJumpToTime = (time, matchID) => {
      if (videoRef.current && time > 0 ) {
        let videoLink = games.find(game => game.id === matchID)?.video_link
        if (videoLink) {
          if (videoRef.current.src !== videoLink) {
            videoRef.current.src = videoLink;
            videoRef.current.load();
          }
        }
        videoRef.current.currentTime = time - delay;
        if (loopInterval) {
          clearInterval(loopInterval);
        }
        const newLoopInterval = setInterval(() => {
          if (videoRef.current ) {
            if (videoRef.current.currentTime >= time + delay) {
              videoRef.current.currentTime = time - delay;
            }
          }
        }, 1000 / loopIntervalDuration);
        setLoopInterval(newLoopInterval);
        return () => {
          clearInterval(newLoopInterval);
        };
      }
    };
    
    const handleCardClick = (index, sequence) => {
      setSelectedSceneIndex(index);
      setSelectedScene(sequence);
    }

    return (
    <Wrapper>
   <Grid container>
      <Grid item xs={9}>
        <FilterSequences>
          <QueryWrapper>
            <Dialog open={isStatModalOpen} 
                    onClose={() => {
                      setIsStatModalOpen(false);
                      setHasModalOpened(true);
                    }}>
              <StatModal setIsStatModalOpen={setIsStatModalOpen} isStatModalOpen={isStatModalOpen}/>
            </Dialog>
            <QueryButtonWrapper
              style={{
                margin: '0px 5px',
                fontSize: '1.10rem',
                fontWeight: 'bold',
                fontFamily: 'Karla',
                backgroundColor: selectedStatsCount > 0 ? DARK_BLUE : WHITE, 
                color: selectedStatsCount > 0 ? WHITE : DARK_BLUE 
              }}
              className={classes.fullWhiteButton}
              onClick={() => setIsStatModalOpen(true)} 
            >
              Stats {selectedStatsCount > 0 && `(${selectedStatsCount})`}
            </QueryButtonWrapper>
            <FormControl 
              style={{ width: '100%', display: 'flex', alignItems: 'center', margin: '0px 5px 0px 0px',justifyContent: 'center'}}>
            <InputLabel 
              id="games-select-label"
              style={{ 
                color: DARK_BLUE,   fontFamily: 'Karla', fontSize: '1.10rem', fontWeight: 'bold', textAlign: 'center', // Center the text
               }} 
              > Games 
            </InputLabel> 
              <Select 
                sx={{ 
                  width: '100%', 
                  background: 'white',
                  color: DARK_BLUE,
                  borderRadius: '10px',
                  fontFamily: 'Karla', fontSize: '1rem', fontWeight: 'bold', fontStyle: 'italic',
                  borderColor: DARK_BLUE,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: DARK_BLUE,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: DARK_BLUE,
                  },
                }} 
                id="games-select"
                multiple
                value={games}
                label="Games"
                renderValue={(selected) => {
                  // Filter the games array to include only those that are both in games and selectedGamesRedux
                  const selectedGames = games.filter(game => selectedGamesRedux.includes(game.id));
                  let renderedValue = '';
                  let totalLength = 0;
                  selectedGames.forEach((game, index) => {
                    const opponent = opponent_name[game.id];
                    const opponentLength = opponent.length;
                    // Check if adding the opponent length exceeds the limit
                    if (totalLength + opponentLength <= 20) {
                      renderedValue += opponent;
                      totalLength += opponentLength;
                      // Add a comma and space if it's not the last item
                      if (index !== selectedGames.length - 1) {
                        renderedValue += ', ';
                        totalLength += 1; // for comma and space
                      }
                    } else {
                      // Stop adding opponents if it exceeds the limit
                      renderedValue += '';
                      return;
                    }
                  });
                  if (selectedGames.length > 0) {
                    renderedValue += `  (${selectedGames.length}) `;
                  }
                  return renderedValue;
                }}
              
                
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={games.length > 0 && selectedGamesRedux.length === games.length}
                      onChange={handleSelectAllGames}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1" style={{ fontFamily: 'Karla', fontWeight: 'bold', fontStyle: 'italic' }}>
                      SELECT ALL
                    </Typography>
                  </ListItemText>
                </MenuItem>
                {
                  games.map((game) => {
                    return (
                      <MenuItem value={game.id}>
                        <Checkbox checked={selectedGamesRedux.includes(game.id)} 
                       onChange={(event) => handleGameChange(event, game.id)}/>
                       <Typography style={{ fontFamily: 'Karla', fontWeight: 'bold' }}>
                        {game_name[game.id]}
                    </Typography>
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            {
              accountType !== PLAYER_ACCOUNT && (
                  <FormControl style={{ width: '100%'}}>
                  <InputLabel 
                    id="players-select-label"
                    style={{ 
                      color: DARK_BLUE,   
                      fontFamily: 'Karla', 
                      fontSize: '1.10rem', 
                      fontWeight: 'bold', 
                    }} 
                  > Players 
                  </InputLabel> 
                  <Select
                    sx={{ 
                      width: '100%', 
                      background: 'white',
                      color: DARK_BLUE,
                      borderRadius: '10px',
                      fontFamily: 'Karla', 
                      fontSize: '1rem', 
                      fontWeight: 'bold', 
                      fontStyle: 'italic',
                      borderColor: DARK_BLUE,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: DARK_BLUE,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: DARK_BLUE,
                      },
                    }} 
                    id="players-select"
                    multiple
                    value={players.map(player => player.id)}
                    label="Players"
                    renderValue={(selected) => {
                      const selectedPlayers = players.filter(player => selectedPlayersRedux.includes(player.id));
                      const positionsCount = {
                        Goalkeeper: 0,
                        Defender: 0,
                        Midfielder: 0,
                        Forward: 0
                      };
      
                      selectedPlayers.forEach(player => {
                        positionsCount[player.position]++;
                      });
      
            const renderedValue = Object.entries(positionsCount)
              .filter(([_, count]) => count > 0)
              .map(([position, count]) => {
                // Map position names to desired abbreviations
                const positionAbbreviation = {
                  Goalkeeper: 'GK',
                  Defender: 'DEF',
                  Midfielder: 'MID',
                  Forward: 'FWD'
                };
                // Use abbreviation if available, otherwise use full position name
                const displayPosition = positionAbbreviation[position] || position;
                return `${displayPosition} (${count})`;
              })
              .join(', ');
      
            return renderedValue;
          }}
          MenuProps={MenuProps}
        >
          {/* Select All */}
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={players.length > 0 && selectedPlayersRedux.length === players.length}
                onChange={handleSelectAllPlayers}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" style={{ fontFamily: 'Karla', fontWeight: 'bold', fontStyle: 'italic' }}>
                SELECT ALL
              </Typography>
            </ListItemText>
          </MenuItem>
      
          {/* Grouping Players */}
          <ListSubheader className={classes.subHeader}>Goalkeepers</ListSubheader>
          {players.filter(player => player.position === 'Goalkeeper').map((player) => (
            <MenuItem key={player.id} value={player.id}>
              <Checkbox checked={selectedPlayersRedux.indexOf(player.id) > -1} 
                onChange={(event) => handlePlayersChange(event, player.id)}
              /> 
              <Typography style={{ fontFamily: 'Karla', fontWeight: 'bold' }}>
                {player.name}
              </Typography>
            </MenuItem>
          ))}
      
          <ListSubheader className={classes.subHeader}>Defenders</ListSubheader>
          {players.filter(player => player.position === 'Defender').map((player) => (
            <MenuItem key={player.id} value={player.id}>
              <Checkbox checked={selectedPlayersRedux.indexOf(player.id) > -1} 
                onChange={(event) => handlePlayersChange(event, player.id)}
              /> 
              <Typography style={{ fontFamily: 'Karla', fontWeight: 'bold' }}>
                {player.name}
              </Typography>
            </MenuItem>
          ))}
      
          <ListSubheader className={classes.subHeader}>Midfielders</ListSubheader>
          {players.filter(player => player.position === 'Midfielder').map((player) => (
            <MenuItem key={player.id} value={player.id}>
              <Checkbox checked={selectedPlayersRedux.indexOf(player.id) > -1} 
                onChange={(event) => handlePlayersChange(event, player.id)}
              /> 
              <Typography style={{ fontFamily: 'Karla', fontWeight: 'bold' }}>
                {player.name}
              </Typography>
            </MenuItem>
          ))}
      
          <ListSubheader className={classes.subHeader}>Forwards</ListSubheader>
          {players.filter(player => player.position === 'Forward').map((player) => (
            <MenuItem key={player.id} value={player.id}>
              <Checkbox checked={selectedPlayersRedux.indexOf(player.id) > -1} 
                onChange={(event) => handlePlayersChange(event, player.id)}
              /> 
              <Typography style={{ fontFamily: 'Karla', fontWeight: 'bold' }}>
                {player.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
              )
            }
          </QueryWrapper>
        </FilterSequences>
      </Grid>
      {/* <Grid item xs={1}>
      </Grid> */}
      <Grid item xs={3}>
      <FilterSequences style={{marginLeft: '1rem'}}>
          <QueryWrapper>
            <Dialog open={isPlaylistModalOpen} onClose={() => setIsPlaylistModalOpen(false)}>
              <PlaylistModal setIsModalOpen={setIsPlaylistModalOpen} />
            </Dialog>
            <QueryButtonWrapper style={{margin: '8px 40px 0px 10px'}} className={classes.fullWhiteButtonPL}
              onClick={() => setIsPlaylistModalOpen(true)} 
            > <PlaylistAddIcon/> Playlist
            </QueryButtonWrapper>
            </QueryWrapper>
        </FilterSequences>
      </Grid>
    </Grid>
    <ContainerGrid>
      <PageNavigator>
          {videoList.length > 0 ? (
              <NavigatorTop>
                <IconButton
                  className={currentPage === 1 ? classes.disabledNextPageArrow : classes.nextPageArrow}
                  onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                <span style={{ color: DARK_BLUE }}>
                  {currentPage} / {totalPages}
                </span>
                
                <IconButton
                className={currentPage === totalPages ? classes.disabledNextPageArrow : classes.nextPageArrow}
                  onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                  disabled={currentPage === totalPages}
                >
                  <KeyboardArrowRight />
                </IconButton>
            </NavigatorTop>
          ) : null}
      </PageNavigator>
      <VideoControl> 
        <VideoPlayerGrid>
        {selectedScene && (Object.values(selectedStats).some(stat => stat) && selectedPlayersRedux.length && selectedGamesRedux.length) && videoList.length > 0? (
              <VideoPlayer
                selectedScene={selectedScene}
                handleJumpTime={handleJumpToTime}
                handleLoopInterval={setLoopIntervalDuration}
                loopIntervalDuration={loopIntervalDuration}
                videoRef={videoRef}
              />
            ) : (<><></><NoScenesMessage>
            {videoList.length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={curveArrowRight}
                    style={{ width: '200px', height:'200px'}}
                    alt="curveArrowRight"
                  />

                <div style={{ fontWeight: 'bold', fontFamily: 'karla bold', fontSize: '2rem' }}>Select a sequence from the list</div>
              </div>
            ) : (
              <SearchIconContainer onClick={() => setIsStatModalOpen(true)}>
                <SearchIcon style={{ width: '10rem', height: '10rem', color: GREY }} />
                <div style={{ fontWeight: 'bold', fontFamily: 'karla bold', fontSize: '3rem', width: '100%', textAlign: 'center' }}>Broaden Your Search</div>
                <div style={{ fontFamily: 'karla', fontSize: '1rem', textAlign: 'center' }}>Try selecting more stats, games or players to see more results.</div>
              </SearchIconContainer>
            )}
          </NoScenesMessage>
          </>
            )}
          </VideoPlayerGrid>
      <SceneList>
        {slicedVideos.map((video, i) => {
                return video.event ? (
                  <SequenceCard
                    sequence={video} 
                    index={i} 
                    isSelected={video.id === selectedScene?.id} 
                    isPlaylistSelected={selectedSequence.includes(video.id)}
                    onCardClick={handleCardClick} 
                    onCheckboxClick={(id) => {dispatch(updateSelectedSequence(id))}} 
                  />
                ) : (
                  <></>
                );
              })}
      </SceneList>
      </VideoControl>
    </ContainerGrid>
    </Wrapper>
    );
    }

const NoScenesMessage = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: ${DARK_BLUE};
  text-align: center;
  font-family: 'Karla Light';
  font-size: 1rem;
  margin: 0rem 0 0 2rem;
`;
  

const SearchIconContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  vertical-align: baseline;
  color: ${DARK_BLUE};
  text-align: center;
  font-family: 'Karla Light';
  font-size: 5rem;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  padding: 5rem; /* Add padding to space out the content from the border */
`;

const Wrapper = styled.div`
    width: 81.5%;
    height: 102.5%;
    position: fixed;
    background-color: ${LIGHT_GREY1};
    font-family: Karla Bold;
  `;
  
const QueryWrapper = styled.div`
  margin-top: 1%;
  width: 100%;
  display: flex;
  flex-direction: row; 
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 10px;
  }
`;


const QueryButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0px 5px 0px 0px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${DARK_BLUE}; /* Add border properties */
  border-radius: 8px; /* Include the border-radius */
  height: 53px;
  background-color: white;
  font-size: 1rem;
  font-family: "Karla Bold";
  cursor: pointer; /* Add pointer cursor */
`;
const NavigatorTop = styled.div`
color: #A9A9A9;
font-size: 15px;
`;

const ContainerGrid = styled.div` 
 display: grid;
 height: 120%;
 align-items: stretch;
 grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  grid-template-rows: 0.1fr 1fr 1fr;
  gap: 0px 0px;
 grid-template-areas: 
   "FilterSequences FilterSequences FilterSequences PageNavigator"
   "VideoControl VideoControl VideoControl VideoControl"
   "VideoControl VideoControl VideoControl VideoControl"; 
 max-height:75%; 
 overflow: hidden; /* Hide the overflow if the content exceeds the max height */
`;

const VideoControl = styled.div`
width: 98%;
margin-top: 1px;
height: 100%; /* Set the desired percentage height relative to the parent component */
display: grid; 
  grid-template-columns: 1fr 1fr 0.6fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "VideoPlayerGrid VideoPlayerGrid SceneList"
    "VideoPlayerGrid VideoPlayerGrid SceneList"
    "VideoPlayerGrid VideoPlayerGrid SceneList"; 
  grid-area: VideoControl; 
  border-bottom: 3px solid ${GREY};
  max-height:98%; 
  box-sizing: border-box; 
  `;


const FilterSequences = styled.div`
  grid-area: FilterSequences; 
  padding: 10px 0px 0px 20px ;
`;

const PageNavigator = styled.div`
  grid-area: PageNavigator; 
  margin-left: 30%;
`;

const VideoPlayerGrid = styled.div`
  grid-area: VideoPlayerGrid; 
  height: 110%; 
  overflow: hidden; /* Hide content that exceeds the parent container */
`;

const SceneList = styled.div`
grid-area: SceneList;
width: 98%;
margin-left:2%;
overflow-y: auto; 
`;


export default EventVideos;