import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ValidationErrors } from "../api/apiError";
import { matchApi } from "../api/matchApi";
import type { RootState } from "../app/store";
import { Game, GameSummary } from "../models/game";

interface GamesState {
  currentGame: Game | undefined;
  latestGame: Game | undefined;
  gamesSummary: GameSummary[];
}

export const initialState: GamesState = {
  currentGame: undefined,
  latestGame: undefined,
  gamesSummary: [],
};

export const gamesSlice = createSlice({
  name: "gamesSlice",
  initialState,
  reducers: {
    setCurrentGame: (state, action: PayloadAction<Game | undefined>) => {
      state.currentGame = action.payload;
    },
    setLatestGame: (state, action: PayloadAction<Game | undefined>) => {
      state.latestGame = action.payload;
    },
    setGamesSummary: (state, action: PayloadAction<GameSummary[]>) => {
      state.gamesSummary = action.payload;
    },
    DESTROY_GAME_SLICE: (state) => {
      state.currentGame = undefined;
      state.gamesSummary = [];
    },
  },
});

export const { setLatestGame, setCurrentGame, setGamesSummary, DESTROY_GAME_SLICE } =
  gamesSlice.actions;

export const getGame = createAsyncThunk(
  "getGame",
  async (gameId: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await matchApi.getMatchStats(gameId);
      dispatch(setCurrentGame(res.data));
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLatestGame = createAsyncThunk(
  "getGame",
  async (games: any, { dispatch, rejectWithValue }) => {
    try {
      if (games.length === 0) {
        return {}
      }
      const res = await matchApi.getMatchStats(games[0].id);
      dispatch(setLatestGame(res.data));
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGamesSummary = createAsyncThunk(
  "getGamesSummary",
  async (
    data: {teamId: number; season: number |undefined},
    { dispatch, rejectWithValue }) => {
    try {
      const {teamId, season} = data
      const res = await matchApi.getGameList(teamId, season);
      if (res.status === 500){
        alert("Error loading team. Please contact support")
      } else if (res.status === 200){
        dispatch(setGamesSummary(res.data));
      }
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectGamesSummary = (state: RootState) =>
  state.gamesSlice.gamesSummary;
export const selectCurrentGame = (state: RootState) =>
  state.gamesSlice.currentGame;
export const selectLatestGame = (state: RootState) =>
  state.gamesSlice.latestGame;
export default gamesSlice.reducer;
