import { Button, makeStyles } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CYAN,
  DARK_BLUE,
  GREY,
  LIGHT_GREY,
  RED,
  RED_TO_GREEN_GRADIENT,
} from "../../../assets/colors";
import { LEAGUE_ACCESS, TEAM_ACCOUNT, TEAM_LEAGUE } from "../../../models/constant/accountType";
import { PlayerPosition } from "../../../models/game";
import {
  selectGamePlayerPositions,
  selectGamePlayerSubs,
} from "../../../slices/playerSlice";
import { selectAccountType } from "../../../slices/userSlice";

const positionCorrection = [192, 100, 75, 63, 52];

const useStyles = makeStyles(() => ({
  playerIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  playerText: {
    cursor: "pointer",
  },
  allPassesBtn: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0.5rem 0 1rem",
    fontWeight: "bold",
    border: `2px solid ${DARK_BLUE}`,
    borderRadius: "4px",
    textTransform: "capitalize",
    "&:disabled": {
      border: `2px solid ${GREY}`,
    },
  },
}));

const PlayerPositions = () => {
  const classes = useStyles();
  const accountType = useSelector(selectAccountType);
  let players: PlayerPosition[][] = useSelector(selectGamePlayerPositions);
  let subs: PlayerPosition[] = useSelector(selectGamePlayerSubs);
  const [currentPlayer, setCurrentPlayer] = useState<number>(0);
  const [activePassesTo, setActivePassesTo] = useState<number[]>([]);
  let domain = 0;
  let isDataValid = players && players.length > 0;

  const playerMap = new Map<number, PlayerPosition>();

  try {
    for (let rowIndex = 0; rowIndex < players.length; rowIndex++) {
      for (
        let playerIndex = 0;
        playerIndex < players[rowIndex].length;
        playerIndex++
      ) {
        const x: number = rowIndex * (500 / players.length) + 30;
        const y: number =
          playerIndex * (350 / players[rowIndex].length) +
          positionCorrection[players[rowIndex].length - 1];

        playerMap.set(players[rowIndex][playerIndex].number, {
          x: x,
          y: y,
          firstName: players[rowIndex][playerIndex].firstName,
          lastName: players[rowIndex][playerIndex].lastName,
          number: players[rowIndex][playerIndex].number,
          isSub: players[rowIndex][playerIndex].isSub,
          passes: players[rowIndex][playerIndex].passes,
          grade: 10,
        });

        for (const passInfo of players[rowIndex][playerIndex].passes) {
          domain = Math.max(passInfo.value, domain);
        }
      }
    }
  } catch (e) {
    isDataValid = false;
  }

  const getGradeColor = (grade: number): string =>
    RED_TO_GREEN_GRADIENT[
      Math.round((grade / 10) * RED_TO_GREEN_GRADIENT.length - 1)
    ];

  const handleClick = (playerNumber?: number) => {
    if (playerNumber && playerNumber !== currentPlayer) {
      setCurrentPlayer(playerNumber);
      const passesTo: number[] = [];
      for (const playerPassTo of (playerMap.get(playerNumber) as PlayerPosition)
        .passes) {
        passesTo.push(playerPassTo.player);
      }
      setActivePassesTo(passesTo);
    } else {
      setCurrentPlayer(0);
      setActivePassesTo([]);
    }
  };

  const getPlayerNumberColor = (playerNumber: number): string => {
    if (playerNumber === currentPlayer) return DARK_BLUE;
    if (activePassesTo.includes(playerNumber)) return RED;
    return "white";
  };

  const renderPlayers = () => {
    return players.map((playerRow: PlayerPosition[]) => {
      return playerRow.map((player: PlayerPosition) => {
        return (
          <g transform={`rotate(90 ${(playerMap.get(player.number) as PlayerPosition).x} ${(playerMap.get(player.number) as PlayerPosition).y})`}>
          <circle
              className={classes.playerIcon}
              cx={(playerMap.get(player.number) as PlayerPosition).x}
              cy={(playerMap.get(player.number) as PlayerPosition).y}
              r={23}
              fill={
                currentPlayer === player.number ||
                activePassesTo.includes(player.number)
                  ? "white"
                  : DARK_BLUE
              }
              strokeWidth={2}
              stroke={DARK_BLUE}
              onClick={() => handleClick(player.number)}
            />
            { accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS || accountType === TEAM_LEAGUE ? 
            <circle
              cx={(playerMap.get(player.number) as PlayerPosition).x + 22}
              cy={(playerMap.get(player.number) as PlayerPosition).y - 18}
              r={12}
              fill={getGradeColor(player.grade)}
              onClick={() => handleClick(player.number)}
            />
            : (<></>)
            }
            {player.isSub ? (
              <>
                <circle
                  cx={(playerMap.get(player.number) as PlayerPosition).x - 20}
                  cy={(playerMap.get(player.number) as PlayerPosition).y - 18}
                  r={7}
                  fill={RED}
                />
                <Autorenew
                  x={(playerMap.get(player.number) as PlayerPosition).x - 25}
                  y={(playerMap.get(player.number) as PlayerPosition).y - 23}
                  width={10}
                  height={10}
                  style={{
                    color: "white",
                  }}
                />
              </>
            ) : (
              <></>
            )}
            { accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS || accountType === TEAM_LEAGUE ? 
              <text
              className={classes.playerText}
              x={(playerMap.get(player.number) as PlayerPosition).x + 22}
              y={(playerMap.get(player.number) as PlayerPosition).y - 13}
              fill={"white"}
              fontSize={12}
              fontWeight="bold"
              textAnchor="middle"
            >
              {player.grade}
            </text> : ( <></>
            )}
            <text
              className={classes.playerText}
              x={(playerMap.get(player.number) as PlayerPosition).x}
              y={(playerMap.get(player.number) as PlayerPosition).y + 5}
              fill={getPlayerNumberColor(player.number)}
              fontSize={26}
              fontWeight="bold"
              textAnchor="middle"
              onClick={() => handleClick(player.number)}
            >
              {player.number}
            </text>
              <text
                className={classes.playerText}
                x={(playerMap.get(player.number) as PlayerPosition).x}
                y={(playerMap.get(player.number) as PlayerPosition).y + 17}
                fill={getPlayerNumberColor(player.number)}
                fontSize={10}
                fontWeight="bold"
                textAnchor="middle"
                onClick={() => handleClick(player.number)}
              >
                {`${
                  player.firstName ? player.firstName.charAt(0).toUpperCase() : ""
                }
                ${
                  player.lastName ? player.lastName.charAt(0).toUpperCase() : ""
                }`}
              </text>
          </g>
        );
      });
    });
  };

  const renderLines = () => {
    return currentPlayer === 0
      ? players.map((playerRow: PlayerPosition[]) => {
          return playerRow.map((player: PlayerPosition) => {
            return (
              <>
                {player.passes.map(
                  (passInfo: { player: number; value: number }) => {
                    return playerMap.get(passInfo.player) ? (
                      <line
                        x1={(playerMap.get(player.number) as PlayerPosition).x}
                        y1={(playerMap.get(player.number) as PlayerPosition).y}
                        x2={
                          (playerMap.get(passInfo.player) as PlayerPosition).x
                        }
                        y2={
                          (playerMap.get(passInfo.player) as PlayerPosition).y
                        }
                        stroke={CYAN}
                        strokeWidth={5}
                      />
                    ) : (
                      <></>
                    );
                  }
                )}
              </>
            );
          });
        })
      : players.map((playerRow: PlayerPosition[]) => {
          return playerRow.map((player: PlayerPosition) => {
            return (
              <>
                {player.passes.map(
                  (passInfo: { player: number; value: number }) => {
                    return currentPlayer === player.number &&
                      playerMap.get(passInfo.player) ? (
                      <>
                        <line
                          x1={
                            (playerMap.get(player.number) as PlayerPosition).x
                          }
                          y1={
                            (playerMap.get(player.number) as PlayerPosition).y
                          }
                          x2={
                            (playerMap.get(passInfo.player) as PlayerPosition).x
                          }
                          y2={
                            (playerMap.get(passInfo.player) as PlayerPosition).y
                          }
                          stroke={CYAN}
                          strokeWidth={5}
                        />
                      </>
                    ) : (
                      <></>
                    );
                  }
                )}
              </>
            );
          });
        });
  };

  const renderPassAmounts = () => {
    return players.map((playerRow: PlayerPosition[]) => {
      return playerRow.map((player: PlayerPosition) => {
        return (
          <>
            {player.passes.map(
              (passInfo: { player: number; value: number }) => {
                return currentPlayer === player.number &&
                  playerMap.get(passInfo.player) ? (
                    <g 
                    transform={`rotate(90 ${(playerMap.get(passInfo.player) as PlayerPosition).x } ${(playerMap.get(passInfo.player) as PlayerPosition).y})`}
                    >
                    <circle
                      cx={(playerMap.get(passInfo.player) as PlayerPosition).x}
                      cy={
                        (playerMap.get(passInfo.player) as PlayerPosition).y + 30
                      }
                      r={13}
                      strokeWidth={1}
                      stroke={"white"}
                      fill={CYAN}
                    />
                    <text
                      className={classes.playerText}
                      x={(playerMap.get(passInfo.player) as PlayerPosition).x}
                      y={
                        (playerMap.get(passInfo.player) as PlayerPosition).y + 35
                      }
                      fill={"white"}
                      fontSize={14}
                      fontWeight="bold"
                      textAnchor="middle"
                    >
                      {passInfo.value}
                    </text>
                  </g>
                ) : (
                  <></>
                );
              }
            )}
          </>
        );
      });
    });
  };

  const renderSubs = () => {
    let i = 0;
    return subs.map((player: PlayerPosition) => {
      return (
        <>
          <circle
            className={classes.playerIcon}
            cx={i * 70 + 40}
            cy={40}
            r={24}
            fill={DARK_BLUE}
          />
          <text
            className={classes.playerText}
            x={i * 70 + 40}
            y={45}
            fill={"white"}
            fontSize={26}
            fontWeight="bold"
            textAnchor="middle"
          >
            {player.number}
          </text>
          <text
            className={classes.playerText}
            x={i * 70 + 40}
            y={58}
            fill={getPlayerNumberColor(player.number)}
            fontSize={10}
            fontWeight="bold"
            textAnchor="middle"
            onClick={() => handleClick(player.number)}
          >
            {`${
              player.firstName ? player.firstName.charAt(0).toUpperCase() : ""
            }
              ${
                player.lastName ? player.lastName.charAt(0).toUpperCase() : ""
              }`}
          </text>
          {player.isSub ? (
            <>
              <circle cx={i * 70 + 18} cy={18} r={12} fill={RED} />
              <Autorenew
                x={i * 70 + 8}
                y={8}
                width={20}
                height={20}
                style={{
                  color: "white",
                }}
              />
              { accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS ? 
              <circle
                cx={i * 70 + 62}
                cy={18}
                r={12}
                fill={getGradeColor(player.grade)}
                onClick={() => handleClick(player.number)}
              />: ( <></>
              )}
              <text
                className={classes.playerText}
                x={i * 70 + 62}
                y={22}
                fill={"white"}
                fontSize={12}
                fontWeight="bold"
                textAnchor="middle"
              > 
                {player.grade}
              </text>
            </>
          ) : (
            <></>
          )}
          {i++}
        </>
      );
    });
  };

  return (
    <Wrapper>
      <Title>Player Positions</Title>
      {isDataValid ? (
        <>
          <Button
            className={classes.allPassesBtn}
            disabled={currentPlayer === 0}
            onClick={() => {
              setCurrentPlayer(0);
              setActivePassesTo([]);
            }}
          >
            Show All Passes
          </Button>
          <ContentWrapper>
            <LegendWrapper>
              <LegendItem>
                <circle cx={12} cy={12} r={12} fill={RED} />
                <Autorenew
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  style={{
                    color: "white",
                  }}
                />
                <text
                  className={classes.playerText}
                  x={46}
                  y={16}
                  fill={"black"}
                  fontSize={14}
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Subs
                </text>
              </LegendItem>
              { accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS || accountType === TEAM_LEAGUE ? 
                <LegendItem>
                  <circle cx={12} cy={12} r={12} fill={getGradeColor(10)} />
                  <text
                    className={classes.playerText}
                    x={12}
                    y={18}
                    fill={"white"}
                    fontSize={20}
                    textAnchor="middle"
                  >
                    #
                  </text>
                  <text
                    className={classes.playerText}
                    x={53}
                    y={16}
                    fill={"black"}
                    fontSize={14}
                    fontWeight="bold"
                    textAnchor="middle"
                  >
                    Rating
                  </text> 
                </LegendItem>
                : ( <></>
              )}
              <LegendItem>
                <circle cx={12} cy={12} r={12} fill={CYAN} />
                <text
                  className={classes.playerText}
                  x={12}
                  y={18}
                  fill={"white"}
                  fontSize={20}
                  textAnchor="middle"
                >
                  #
                </text>
                <text
                  className={classes.playerText}
                  x={60}
                  y={16}
                  fill={"black"}
                  fontSize={14}
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Passes to
                </text>
              </LegendItem>
            </LegendWrapper>
            <FieldWrapper style={{ margin: '6rem'}}>
              <FieldInnerWrapper style={{transform: 'rotate(-90deg)'}}>
                <GoalOutline style={{ marginRight: "-79px" }} />
                <FieldOutline >
                  {renderLines()}
                  {renderPlayers()}
                  {renderPassAmounts()}
                </FieldOutline>
                <GoalOutline style={{ marginLeft: "-79px" }} />
              </FieldInnerWrapper>
              <MidLine style={{transform: 'rotate(-90deg)'}}/>
            </FieldWrapper>
            <ScrollBarWrapper>
              <SubsWrapper>
                <Subs style={{ width: `${subs?.length * 70 + 4}px` }}>
                  {renderSubs()}
                </Subs>
              </SubsWrapper>
            </ScrollBarWrapper>
          </ContentWrapper>
        </>
      ) : (
        <NoDataWrapper>Not Available</NoDataWrapper>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1rem 0rem 0rem 0rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  margin: 2rem 0 0 1rem;
  width: 50%;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FieldInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
`;
const GoalOutline = styled.div`
  width: 75px;
  height: 150px;
  border: 2px solid black;
`;
const MidLine = styled.div`
  height: 375px;
  border: 1px solid black;
  margin-top: -377px;
  position: relative;
`;
const FieldOutline = styled.svg`
  display: flex;
  background-color: transparent;
  width: 500px;
  height: 375px;
  view-box: 0 0 100 100;
  border: 2px solid black;
  border-radius: 8px;
  position: relative;
  z-index: 1;
`;
const LegendWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 400px;
`;
const LegendItem = styled.svg`
  width: 6rem;
  height: 1.5rem;
`;
const ScrollBarWrapper = styled.div`
  height: 96px;
  overflow: hidden;
  max-width: 100%;
`;
const SubsWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;
const Subs = styled.svg`
  display: flex;
  height: 80px;
`;

export default PlayerPositions;
