import styled from "styled-components";
import { Game, GameEvent } from "../../../models/game";
import ball from "./../../../assets/images/ballIcon.png";
import yellowCard from "./../../../assets/images/yellowCard.png";
import redCard from "./../../../assets/images/redCard.png";
import change from "./../../../assets/images/changeIcon.png";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { ErrorOutline, KeyboardArrowRight } from "@material-ui/icons";
import {
  DARK_BLUE,
  GREY,
  LIGHT_BLACK,
  LIGHT_GREY,
  LIGHT_RED,
  RED,
  YELLOW,
} from "../../../assets/colors";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  event: {
    display: "none",
  },
  eventSelected: {
    display: "inherit",
    animation: "fade-in 1s ease",
  },
}));

const GameHeader = (game: Game) => {
  const [openEventId, setOpenEventId] = useState<number>();
  const classes = useStyles();

  const gameEvents = () => {
    const events: GameEvent[] = [];

    events.push(...game.teamGoals);
    events.push(...game.teamPlayerChanges);
    events.push(...game.teamYellowCards);
    events.push(...game.teamRedCards);
    events.push(...game.opponentGoals);
    events.push(...game.opponentPlayerChanges);
    events.push(...game.opponentYellowCards);
    events.push(...game.opponentRedCards);

    const eventsCopy: GameEvent[] = [];

    let id = 0;
    for (const event of events) {
      let newEvent: GameEvent = { ...event };
      newEvent.id = id++;

      switch (event.name) {
        case "yellowCard":
          newEvent.src = yellowCard;
          break;
        case "redCard":
          newEvent.src = redCard;
          break;
        case "goal":
          newEvent.src = ball;
          break;
        case "change":
          newEvent.src = change;
          break;
      }
      eventsCopy.push(newEvent);
    }

    return eventsCopy.sort((a: GameEvent, b: GameEvent) => a.minute - b.minute);
  };

  const handleEventClick = (eventId: number | undefined) => {
    if (openEventId !== eventId) {
      setOpenEventId(eventId);
    } else {
      setOpenEventId(undefined);
    }
  };

  return (
    <Wrapper>
      <ScoreWrapper>
        <div style={{ width: "12rem" }}>
          {game.logo ? (
            <TeamLogo src={game.logo} alt="Logo1" />
          ) : (
            <></>
          )}
          <TeamName>{game.name}</TeamName>
        </div>
        <div style={{ width: "4rem" }}>
          {game.teamYellowCards.length > 0 ? (
            <div style={{ display: "flex", margin: "5px 0px" }}>
              <YellowCardIcon />
              <YellowCardCount>{game.teamYellowCards?.length}</YellowCardCount>
            </div>
          ) : (
            <></>
          )}
          {game.teamRedCards.length > 0 ? (
            <div style={{ display: "flex" }}>
              <RedCardIcon />
              <RedCardCount>{game.teamRedCards?.length}</RedCardCount>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          {game.isLiveGame ? (
            <Minutes>{`${game.minutesPlayed}'`}</Minutes>
          ) : (
            <GameEnded>{" "}</GameEnded>
          )}
          <Score>{`${game.teamScore} - ${game.opponentScore}`}</Score>
          {game.isPenaltySession ? (
            <PenaltiesWrapper>
              {`(${game.teamPenaltySessionGoals} - 
          ${game.opponentPenaltySessionGoals})`}
            </PenaltiesWrapper>
          ) : (
            <></>
          )}{(
            <XGWrapper>
            <XGLabel>xG</XGLabel>
            <XGValues>{`${game.xg} | ${game.xgOpponent}`}</XGValues>
          </XGWrapper>
          ) 
          }
          <Date>
            {game.date} | {game.place}
          </Date>
        </div>
        <div style={{ width: "4rem" }}>
          {game.opponentYellowCards.length > 0 ? (
            <div style={{ display: "flex", margin: "5px 0px" }}>
              <YellowCardIcon />
              <YellowCardCount>
                {game.opponentYellowCards?.length}
              </YellowCardCount>
            </div>
          ) : (
            <></>
          )}
          {game.opponentRedCards.length > 0 ? (
            <div style={{ display: "flex" }}>
              <RedCardIcon />
              <RedCardCount>{game.opponentRedCards?.length}</RedCardCount>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{ width: "12rem" }}>
          {game.opponentLogo ? (
            <TeamLogo src={game.opponentLogo} alt="Logo2" />
          ) : (
            <></>
          )}
          <TeamName>{game.opponent}</TeamName>
        </div>
      </ScoreWrapper>
      {game.dataLossPeriods.length > 0 ? (
        <MissingDataWarningWrapper>
          <IconButton style={{ padding: 0 }}>
            <ErrorOutline style={{ color: RED, height: '1.2rem' }} />
          </IconButton>
          <WarningMessage>{`Data collection was interrupted during the following period(s):`}</WarningMessage>
          {game.dataLossPeriods.map((lossPeriod) => (
            <WarningMessage>{`${lossPeriod.start}min to ${lossPeriod.end}min`}</WarningMessage>
          ))}
        </MissingDataWarningWrapper>
      ) : (
        <></>
      )}
      <HR />
      <ScrollBarWrapper>
        <EventContainer>
          <EventWrapper style={{ paddingRight: "10px" }}>
            {game.logo ? (
              <SmallTeamLogo
                src={game.logo}
                alt="Logo Team 1"
              />
            ) : (
              <></>
            )}
            <div
              style={{
                width: "100%",
                height: "1rem",
                backgroundColor: "white",
              }}
            />
            {game.opponentLogo ? (
              <SmallTeamLogo
                src={game.opponentLogo}
                alt="Logo Team 1"
              />
            ) : (
              <></>
            )}
          </EventWrapper>
          {gameEvents().map((event, index) => {
            return (
              <>
                <EventWrapper key={`${event.name}_${index}`}>
                  {event.team === game.name ? (
                    <>
                      <Event
                        key={`event${index}`}
                        onClick={() => handleEventClick(event.id)}
                      >
                        <img
                          key={`ballIcon${index}`}
                          src={event.src}
                          alt="ballIcon"
                          style={{ marginRight: "5px" }}
                        />
                        {event.name !== "change" ? (
                          <EventInfo
                            key={`eventInfo${index}`}
                            className={
                              openEventId === event.id
                                ? classes.eventSelected
                                : classes.event
                            }
                          >
                            {event.player}
                          </EventInfo>
                        ) : (
                          <EventInfo
                            key={`eventInfo${index}`}
                            className={
                              openEventId === event.id
                                ? classes.eventSelected
                                : classes.event
                            }
                          >
                            {event.player[0]} {`(${event.player[1]})`}
                          </EventInfo>
                        )}

                        <KeyboardArrowRight
                          key={`arrowIcon${index}`}
                          style={{ height: "1rem", width: "1rem" }}
                        />
                      </Event>
                      <EventTimeWrapper key={`eventTimeWrapper${index}`}>
                        <EventTime key={`eventTime${index}`}>
                          {event.minute}'
                        </EventTime>
                      </EventTimeWrapper>
                      <EmptyEvent key={`emptyEvent${index}`} />
                    </>
                  ) : (
                    <>
                      <EmptyEvent key={`emptyEvent${index}`} />
                      <EventTimeWrapper key={`eventTimeWrapper${index}`}>
                        <EventTime key={`eventTime${index}`}>
                          {event.minute}'
                        </EventTime>
                      </EventTimeWrapper>
                      <Event key={`event${index}`}>
                        <img
                          key={`ballIcon${index}`}
                          src={event.src}
                          alt="ballIcon"
                          style={{ margin: "0px 2px" }}
                        />
                        {event.name !== "change" ? (
                          <EventInfo
                            key={`eventInfo${index}`}
                            className={
                              openEventId === event.id
                                ? classes.eventSelected
                                : classes.event
                            }
                          >
                            {event.player}
                          </EventInfo>
                        ) : (
                          <EventInfo
                            key={`eventInfo${index}`}
                            className={
                              openEventId === event.id
                                ? classes.eventSelected
                                : classes.event
                            }
                          >
                            {event.player[0]} {`(${event.player[1]})`}
                          </EventInfo>
                        )}
                      </Event>
                    </>
                  )}
                </EventWrapper>
              </>
            );
          })}
        </EventContainer>
      </ScrollBarWrapper>
      <Separator />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  font-family: Karla Bold;
`;
const GameEnded = styled.div`
  color: ${GREY};
`;
const Minutes = styled.div`
  color: ${RED};
`;
const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const TeamLogo = styled.img`
  height: 3.7rem;
  width: 3.7rem;
`;
const TeamName = styled.div`
  font-size: 1.2rem;
  width: 10rem;
  text-align: center;
  margin: 10px 0px;
`;
const Score = styled.div`
  font-size: 1.75rem;
  margin: 0.75rem 3rem;
  color:${DARK_BLUE}; 
`;
const XGWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: -0.75rem;
  font-size: 1rem;
  color: grey;
`;

const XGLabel = styled.div`
  font-size: 0.75rem;
  color:${LIGHT_RED}; 
`;

const XGValues = styled.div`
  font-size: 1rem;
  color:${LIGHT_RED}; 
`;
const PenaltiesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: -0.75rem;

  color:${GREY}; 
`;
const Date = styled.div`
  color: ${GREY};
  font-size: 0.8rem;
`;
const YellowCardIcon = styled.div`
  height: 1rem;
  width: 0.75rem;
  background-color: ${YELLOW};
  border-radius: 2px;
`;
const YellowCardCount = styled.div`
  margin-left: 8px;
`;
const RedCardIcon = styled.div`
  height: 1rem;
  width: 0.75rem;
  background-color: ${RED};
  border-radius: 2px;
`;
const RedCardCount = styled.div`
  margin-left: 8px;
`;
const MissingDataWarningWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;
const WarningMessage = styled.div`
  margin-left: 0.4rem;
  color: ${LIGHT_RED};
  display: flex;
  align-items: center;
  font-size: 0.75rem;
`;
const HR = styled.div`
  width: 90%;
  height: 1.5px;
  background-color: ${LIGHT_GREY};
  margin: 1.5rem 0;
`;
const ScrollBarWrapper = styled.div`
  width: 90%;
  height: calc(5rem + 17px);
  overflow: hidden;
`;
const EventContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 1rem 0rem;
  overflow-x: scroll;
  overflow-y: hidden;
`;
const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5rem;
  background-color: transparent;
  z-index: 1;
`;
const SmallTeamLogo = styled.img`
  height: 1.5rem;
  width: 1.5rem;
`;
const Separator = styled.div`
  height: 1.75px;
  background-color: ${GREY};
  width: 90%;
  position: relative;
  top: -2.6rem;
  display: flex;
  z-index: 0;
`;
const EventTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const EventTime = styled.div`
  font-size: 0.8rem;
  color: ${DARK_BLUE};
  width: fit-content;
  padding: 0 5px;
  background-color: white;
`;
const EmptyEvent = styled.div`
  height: 1.8rem;
  background-color: white;
`;
const Event = styled.div`
  height: 1.5rem;
  border: 0.15rem solid ${DARK_BLUE};
  border-radius: 4px;
  padding: 0px 5px;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${DARK_BLUE};
  margin: 0px 2px;
  &: hover {
    cursor: pointer;
  }
`;
const EventInfo = styled.div`
  font-size: 0.75rem;
  margin-right: 5px;
  text-transform: capitalize;
`;

export default GameHeader;
