import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import HomeHeader from "../../components/home-page/home-header/home-header";
import TeamStats from "../../components/home-page/team-stats/team-stats";
import PlayersEvolution from "../../components/home-page/players-evolution/players-evolution";
import { useSelector } from "react-redux";
import {
  LIGHT_GREY1,
  LIGHT_GREY2,
} from "../../assets/colors";
import { homePlayerEvolution, sendPageView } from "../../services/gAnalytics";
import { selectAccountType } from "../../slices/userSlice";
import { TEAM_ACCOUNT, PLAYER_ACCOUNT, LEAGUE_ACCESS, TEAM_LEAGUE } from "../../models/constant/accountType";

import TopBar from "../../components/navigation/topbar";
import { LoadingWrapper } from "../../components/navigation/loading";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";

enum StatPage {
  Team,
  Players,
}

const Home = () => <LoadingWrapper comp={<AdminHome/>}/>

const AdminHome = () => {
  const accountType = useSelector(selectAccountType);
  const classes = sportsAiGlobalStyles();
  const [statPage, setStatPage] = useState(StatPage.Team);
  var nameTopbar = (accountType === PLAYER_ACCOUNT || accountType === LEAGUE_ACCESS) ? "Team" : "Home";
  return (
    <Wrapper>
      <HeaderWrapper>
      <TopBar name={nameTopbar}/>
      <HomeHeader />
      {(accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS || accountType === TEAM_LEAGUE) &&
        <Grid container>
          <Grid item xs={6}>
            <Button
              className={
                statPage === StatPage.Team
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setStatPage(StatPage.Team);
                sendPageView();
              }}
            >
              Team stats overview
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={
                statPage === StatPage.Players
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setStatPage(StatPage.Players);
                sendPageView(homePlayerEvolution);
              }}
            >
              Players evolution
            </Button>
          </Grid>
        </Grid>
      }
    </HeaderWrapper>
    {statPage === StatPage.Team ? <TeamStats /> : <PlayersEvolution />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export default Home;
