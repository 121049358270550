import {ListItem, ListItemText, makeStyles, Switch} from "@material-ui/core";
import {  WHITE} from "../../../../../assets/colors";

const useStyles = makeStyles(() => ({
    buttonStat: {
      color: "black",
      backgroundColor: WHITE,
      fontFamily: "Karla Bold",
      fontSize: "1rem",
      "&:hover": {
        backgroundColor: WHITE,
        },
    },
  }));

export const BaseFilter = ({handleStatsButtonClick, statName, buttonStates, title})=> {
    const classes = useStyles();
    return (
        <ListItem 
            className={classes.buttonStat}
            key={statName}
            onChange={() => handleStatsButtonClick(statName.replace(/\s/g, ''))} 
            button
            > 
            {title.toUpperCase()}
            <ListItemText /> 
            <Switch color="primary" checked={buttonStates[statName]}/>
        </ListItem>
    )
}