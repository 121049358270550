import { useSelector } from "react-redux";
import { DARK_BLUE, GREEN, LIGHT_GREY, LIGHT_RED, PURPLE } from "../../../assets/colors";
import { goalTypeCounter } from "../../stats-base/field-zone-events/event-position-tooltip";
import FieldZoneEvent from "../../stats-base/field-zone-events/field-zone-events";
import { selectTeam } from "../../../slices/teamSlice";
import { Team } from "../../../models/team";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { Button, Slider, Typography } from "@material-ui/core";
import Goal from "../../../models/goalScored";
import { sportsAiGlobalStyles } from "../../../shared/globalStyle";

type goaltypesType = "" | "s" | "g" | "k" | "p" | 'r' | 'l' | 'h';

const eventTypes = [
    'Goals',
    'Shots'
]

const getEvents = (teamStats, eventType, gameRange) => {
    let events: Goal[][];
    let indicatorIcons: {title: string, value: number, color: string, code: goaltypesType}[] = []

    if (eventType === 'Goals'){
        const unpackedGoals = teamStats.goals.slice(gameRange[0]-1, gameRange[1]).flatMap((goals) => goals);
        events = [unpackedGoals];
        const goalTypeCounts = goalTypeCounter(events);
        indicatorIcons = [
            {title: 'All', value: goalTypeCounts.total, color: 'grey', code: ''},
            {title: 'Simple Shot', value: goalTypeCounts.s, color: DARK_BLUE, code: 's'},
            {title: 'Direct Free-kick', value: goalTypeCounts.k, color: GREEN, code: 'k'}, 
            {title: 'Dead Ball Assisted Goal', value: goalTypeCounts.g, color: LIGHT_RED, code: 'g'},
            {title: 'Penalty Kicks', value: goalTypeCounts.p, color: PURPLE, code: 'p'},
        ]
    } else {
        const unpackedShots = teamStats.shots.slice(gameRange[0]-1, gameRange[1]).flatMap((shots) => shots);
        events = [unpackedShots];
        const goalTypeCounts = goalTypeCounter(events);
        indicatorIcons = [
            {title: 'All', value: goalTypeCounts.total, color: 'grey', code: ''},
            {title: 'Left Foot', value: goalTypeCounts.l, color:LIGHT_RED  , code: 'l'},
            {title: 'Right Foot', value: goalTypeCounts.r, color: DARK_BLUE , code: 'r'}, 
            {title: 'Head', value: goalTypeCounts.h, color: GREEN, code: 'h'},
        ]
    }
    return {events, indicatorIcons}
}

export interface ZoneEventPositionConfig {
    title: string;
    eventType: any; 
    goalsScored: any; 
    filter: {title: string, games: string}[],
    eventTypes: {title: string, value: number, color: string, code: goaltypesType}[]
}
const ScoredGoalsTypes = () => {
    const classes = sportsAiGlobalStyles();

    const team: Team = useSelector(selectTeam);
    const [eventType, setEventType] = useState('Goals');
    // const [value, setValue] = useState<number>(1);
    const [value, setValue] = useState<number[]>([team.games.length-1, team.games.length]);

    function handleChange(event: ChangeEvent<{}>, value: number | number[]): void {
        setValue(value as number[]);
    }

    const config = getEvents(team,eventType,value)

    return (
            <Wrapper>
            <Title>
            Key Event Position
            </Title>
            <div style={{ display: "flex"}}>
                {eventTypes.map((timeFilter) => {
                return <Button
                    className={
                        eventType === timeFilter
                        ? classes.tabButtonSelectedElement
                        : classes.tabButtonElement
                    }
                    onClick={() => setEventType(timeFilter as string)}
                    style={{marginBottom: '1rem'}}
                    >
                    {timeFilter}
                    </Button>
                })}
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom:'1rem'}}>
                <Typography id="non-linear-slider">
                {value[1] - value[0]+1} Games
                </Typography>
                <Slider
                value={value}
                min={1}
                step={1}
                max={team.games.length}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                style={{ width: '80px', margin: '0 20px', color:DARK_BLUE}}  // Adjust width and margin as needed
                />
            </div>
            <FieldZoneEvent evntsPositions={config.events} indicatorIcons={config.indicatorIcons}/>
        </Wrapper>
    )
};

const Title = styled.div`
  text-transform: uppercase;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 2rem 0rem;
  margin-bottom: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;

export default ScoredGoalsTypes;
