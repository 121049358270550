import { Checkbox } from "@material-ui/core";
import styled from "styled-components";
import { BLACK, DARK_BLUE, GREY2, LIGHT_GREY, LIGHT_GREY2, LIGHT_RED1, RED } from "../../assets/colors";
import { Sequence } from "../../models/game";


const convertStat = (sequence: Sequence): string | undefined  => {
    if (sequence.isKeyPass)
      return "KEY PASS"
    return sequence.event ? sequence.event.toUpperCase() : undefined;
  }

const SequenceCard = ({sequence, index, isSelected, isPlaylistSelected, onCardClick, onCheckboxClick}) => {
  return (
    <VideoContainer key={sequence.id}>
        <VideoWrapper onClick={() => {
            onCardClick(index, sequence);
          }}> 
            <Container isSelected={isSelected}>
              <Row1>
                <PlaylistSelector>
                  <StyledCheckbox 
                    color="primary" 
                    checked={isPlaylistSelected}
                    onClick={(event) => {
                      event.stopPropagation();
                      onCheckboxClick(sequence.id);
                    }}
                    />
                </PlaylistSelector> 
                <Name>{sequence.player}</Name>
                <Minute>{sequence.matchTime}'</Minute>
              </Row1>
              <Row3>
                <Game>{sequence.match}</Game>
              </Row3>
              <Row2>
                <Stat isSuccess = {sequence.isSuccess} >
                  <div className="stat">
                    {convertStat(sequence)}
                  </div>
                </Stat>
              </Row2>
            </Container>
        </VideoWrapper>
    </VideoContainer>
    )
}

const VideoContainer = styled.div`
  width: 100%;
  margin: 0,5rem 0;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  margin-top:2px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    &.Mui-checked {
      color: ${DARK_BLUE}; /* Change the color when checkbox is checked */
    }
  }
`;


const Container = styled.div<{ isSelected: boolean }>`
    width:100%;
    padding: 10px;
    cursor: pointer;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr ; 
    grid-template-rows: 1fr 0,5fr 0,5fr; 
    gap: 0px 0px; 
    grid-template-areas: 
        "Row1 "
        "Row3 "
        "Row2 "; 
    align-content: center; 
    justify-items: stretch; 
    align-items: stretch; 
    border-radius: 15px;
    border: 3px solid ${props => (props.isSelected ? DARK_BLUE : LIGHT_GREY)};
    &:nth-child(even) {
        background-color: #000000;
    }

    &:nth-child(odd) {
        background-color: #ffffff; 
    }
`;

const Row1 = styled.div`
  margin-top: 5px;  
  margin-left: 5%;
  display: grid; 
  grid-template-columns: 0.10fr 0.80fr 0.10fr; 
  grid-template-rows: 1fr; 
  grid-template-areas: 
    "PlaylistSelector Name minute"; 
  grid-area: Row1;
  margin-bottom: 0px; 
  `;

const PlaylistSelector = styled.div`
  grid-area: PlaylistSelector;
`;

const Stat = styled.div<{ isSuccess: boolean }>`
   grid-area: Stat;
   color: ${props => (props.isSuccess === false ? RED : DARK_BLUE )};
   background: ${props => (props.isSuccess === false ? LIGHT_RED1 : LIGHT_GREY2 )};
   border-radius: 5px;
   text-align:center;
   font-size: 14px;
   margin-top:2px;
   padding: 6px;
   font-family: Karla Bold;
   min-width: 80px;
   max-width: 40%;
   max-height: 20px;
`;

const Minute = styled.div` 
    grid-area: minute;
    font-family: Karla Regular;
    color:${GREY2};
    font-size: 18px; 
    margin: 3px 0px 0px 20px;
    `;

const Name = styled.div`  
  grid-area: Name;
  color:${BLACK};
  align-self: center;
`;

const Row2 = styled.div` 
  margin-left: 5%;
  display: grid; 
  grid-template-columns: 1fr; 
  grid-template-rows: 0.25fr;
  margin-bottom: 10px;   
  grid-template-areas: 
    "Stat"; 
  grid-area: Row2; 
`;

const Row3 = styled.div` 
  margin-left: 7%;
  display: grid; 
  grid-template-columns: 1fr; 
  grid-template-rows: 0.5fr; 
  grid-template-areas: 
  "Game"; 
  grid-area: Row3; 
  margin-bottom: 12px;
`;

const Game = styled.div` 
  grid-area: Game;
  font-family: Karla Medium;
  color:${GREY2};
  font-size: 16px;  
`;
export default SequenceCard;
