import { Button, Grid } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../app/store";
import { changePage, Page } from "../../slices/navigationSlice";
import PlayerProfileOverview from "./player-profile-overview";
import PlayerProfileAttacking from "./player-profile-attacking";
import PlayerProfileDefending from "./player-profile-defending";
import { selectAccountType, selectSeason } from "../../slices/userSlice";
import { useSelector } from "react-redux";
import {
  getPlayer,
  selectCurrentPlayer,
  selectCurrentPlayerId,
  setCurrentPlayer,
} from "../../slices/playerSlice";
import {
  DARK_BLUE,
  GREY,
  LIGHT_GREY1,
  LIGHT_GREY,
  LIGHT_GREY2
} from "../../assets/colors";
import logo from "./../../assets/images/Logo_Ball.png";
import { LEAGUE_ACCESS, PLAYER_ACCOUNT, TEAM_ACCOUNT, TEAM_LEAGUE } from "../../models/constant/accountType";
import { selectTeamId } from "../../slices/teamSlice";
import TopBar from "../../components/navigation/topbar";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";

enum PlayerProfileState {
  Overview,
  Attacking,
  Defending,
}

const sizeFilterOptions = new Map<string, number>();
sizeFilterOptions.set("Last Game", 1);
sizeFilterOptions.set("Last 3", 3);
sizeFilterOptions.set("Last 5", 5);
sizeFilterOptions.set("Last 10", 10);
sizeFilterOptions.set("Season", 0);

const PlayerProfile = () => {
  const accountType = useSelector(selectAccountType);
  const classes = sportsAiGlobalStyles();
  const dispatch = useAppDispatch();
  const [sizeFilter, setSizeFilter] = useState("Last Game");
  const [profileState, setProfileState] = useState(PlayerProfileState.Overview);
  const playerId = useSelector(selectCurrentPlayerId);
  const player = useSelector(selectCurrentPlayer);
  const season = useSelector(selectSeason);
  const team_id = useSelector(selectTeamId);
  const gamesPlayed: number = player ? player.matchPlayed : 0;
  sizeFilterOptions.set("Season", gamesPlayed);
  const sizeFilterButtons = () => {
    const buttons: JSX.Element[] = [];

    for (const [buttonName, value] of sizeFilterOptions) {
      buttons.push(
        <Button
          disabled={value > gamesPlayed}
          style={{ padding: "2px 10px", margin: "0px 5px" }}
          key={buttonName}
          className={
            sizeFilter === buttonName
              ? classes.sizeFilterButtonSelected
              : classes.sizeFilterButton
          }
          onClick={() => {
            setSizeFilter(buttonName);
          }}
        >
          {buttonName}
        </Button>
      );
    }

    return buttons;
  };

  useEffect(() => {
    const filterSize = sizeFilterOptions.get(sizeFilter);
    dispatch(setCurrentPlayer(undefined));
    if (playerId) {
      dispatch(
        getPlayer({
          playerId: playerId,
          numMatches: filterSize ? filterSize : 0,
          season: season,
          team_id: team_id
        })
      );
    }
  }, [playerId, dispatch, sizeFilter, season, team_id]);

  const renderPage = () => {
    switch (profileState) {
      case PlayerProfileState.Overview:
        return (
          <PlayerProfileOverview
            player={player}
            sizeFilterButtons={sizeFilterButtons()}
          />
        );
      case PlayerProfileState.Attacking:
        return (
          <PlayerProfileAttacking
            player={player}
            sizeFilterButtons={sizeFilterButtons()}
          />
        );
      case PlayerProfileState.Defending:
        return (
          <PlayerProfileDefending
            player={player}
            sizeFilterButtons={sizeFilterButtons()}
          />
        );
      default:
        return <></>;
    }
  };

  return player ? (
    <Wrapper>
      <HeaderWrapper>
      {accountType === PLAYER_ACCOUNT && <TopBar name={'MyLAB'}/>}
        { (accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS || accountType === TEAM_LEAGUE) &&
          <Button
            className={classes.back}
            onClick={() => {
              dispatch(changePage(Page.Players));
            }}
          >
            <KeyboardArrowLeft /> All players
          </Button>
        }
        <PlayerInfoWrapper>
          <PlayerNameIconWrapper>
            {player.icon ? (
              <PlayerIcon style={{margin: '0.5rem 0.5rem'}} src={player.icon} />
            ) : (
              <UnknownPlayerIcon>
                <div
                  style={{
                    width: "4rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <UnknownPlayerHead />
                </div>
                <div
                  style={{
                    width: "4rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <UnknownPlayerBody />
                </div>
              </UnknownPlayerIcon>
            )}
            <div>
              <PlayerFirstName>{player.name.split(" ")[0]}</PlayerFirstName>
              <PlayerLastName>{player.name.split(" ")[1]}</PlayerLastName>
            </div>
          </PlayerNameIconWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <PlayerStatHeader>Age</PlayerStatHeader>
              <PlayerStatValue>{player.age}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div>
              <PlayerStatHeader>Position</PlayerStatHeader>
              <PlayerStatValue style={{ textTransform: "capitalize" }}>
                {player.position}
              </PlayerStatValue>
            </div>
            <VerticalLine />
            <div>
              <PlayerStatHeader>Number</PlayerStatHeader>
              <PlayerStatValue>#{player.number}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div>
              <PlayerStatHeader>Games Played</PlayerStatHeader>
              <PlayerStatValue>{player.matchPlayed}</PlayerStatValue>
            </div>
          </div>
        </PlayerInfoWrapper>
        <Grid container>
          <Grid item xs={4}>
            <Button
              className={
                profileState === PlayerProfileState.Overview
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setProfileState(PlayerProfileState.Overview);
              }}
            >
              Overview
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={
                profileState === PlayerProfileState.Attacking
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setProfileState(PlayerProfileState.Attacking);
              }}
            >
              Attacking
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={
                profileState === PlayerProfileState.Defending
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setProfileState(PlayerProfileState.Defending);
              }}
            >
              Defending
            </Button>
          </Grid>
        </Grid>
      </HeaderWrapper>
      {renderPage()}
    </Wrapper>
  ) : (
    <Wrapper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingLogo src={logo} alt="SportsAI_Logo" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;
const PlayerInfoWrapper = styled.div`
  width: calc(100% - 4rem);
  margin: 0rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
`;
const PlayerNameIconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
const PlayerIcon = styled.img`
  height: 4rem;
  width: 4rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
  margin-right: 1rem;
`;
const UnknownPlayerIcon = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
  margin-right: 1rem;
  background-color: ${GREY};
  overflow: hidden;
`;
const UnknownPlayerHead = styled.div`
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 100%;
  background-color: white;
  margin-top: 10px;
`;
const UnknownPlayerBody = styled.div`
  height: 4rem;
  width: 3rem;
  border-radius: 100%;
  background-color: white;
  margin-top: 4px;
`;
const PlayerFirstName = styled.div`
  text-transform: capitalize;
  margin-bottom: 0.25rem;
`;
const PlayerLastName = styled.div`
  font-size: 2.2rem;
  text-transform: uppercase;
`;
const PlayerStatHeader = styled.div`
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 5px;
`;
const PlayerStatValue = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: ${DARK_BLUE};
  font-size: 1.2rem;
`;
const VerticalLine = styled.div`
  height: 75%;
  width: 2px;
  background-color: ${LIGHT_GREY};
  margin: 0 1rem;
`;
const LoadingLogo = styled.img`
  width: 15rem;
  animation: blinkAndGrow 2s infinite ease;
`;
export default PlayerProfile;
