import { getGamesSummary, getLatestGame } from "../slices/gamesSlice";
import { Page, changePage, toggleLoading } from "../slices/navigationSlice";
import { getPlayer, getTeamPlayers, getUserPlayerInfos, setCurrentPlayerId } from "../slices/playerSlice";
import { getCompetitions, getTeams, setCurrentTeamId } from "../slices/teamSlice";
import { setSeason, setTeamView } from "../slices/userSlice";
import { getPlaylists } from "../slices/videoSlice";
import { updateSelectedGames, updateselectedPlayers } from "../slices/sequencesSlice";

export const loadData = (dispatch, season: number) => {
  dispatch(setSeason(season));
  dispatch(toggleLoading());
  dispatch(getTeams({season: season, teamId: undefined})).then((team) => {
      dispatch(getCompetitions(season));
      if (team !== undefined) {
        const teamId = team.payload[0].team_id;
        dispatch(setCurrentTeamId(teamId))
        dispatch(getGamesSummary({teamId, season})).then((games) => {
          const selectedGames = games.payload.map(game => game.id);
          dispatch(updateSelectedGames(selectedGames));
          dispatch(getLatestGame(games.payload)).then(() => {
              dispatch(getTeamPlayers({teamId, season})).then((players) => {
                const selectedPlayers = players.payload.map(player => player.id);
                dispatch(updateselectedPlayers(selectedPlayers));
                dispatch(toggleLoading());
                dispatch(getUserPlayerInfos({teamId, season}));
              });
              dispatch(getPlaylists());

          })
        });
      }
    });
}

export const loadPlayerData = (dispatch, season, player) => {
  dispatch(toggleLoading());
  dispatch(getTeams({season: season, teamId: undefined})).then((team) => {
      if (team !== undefined) {
        const teamId = team.payload[0].team_id;
        dispatch(setCurrentTeamId(teamId))
        dispatch(getGamesSummary({teamId, season})).then((games) => {
          dispatch(getLatestGame(games.payload)).then(() => {
              dispatch(getTeamPlayers({teamId, season})).then(() => dispatch(toggleLoading()));
              dispatch(getPlaylists());
          })
        });
      }
    })
  dispatch(getPlayer({
    playerId: player["player"]['id'],
    numMatches:1,
    season:season,
    team_id: undefined
  }));
  dispatch(setCurrentPlayerId(player["player"]['id']));
  dispatch(changePage(Page.PlayerProfile));
}

export const loadLigueData = (dispatch, season: number, teamId: number) => {
  dispatch(toggleLoading());
  dispatch(setTeamView(teamId));
  dispatch(getTeams({season: season, teamId: teamId})).then(() => {
    dispatch(setCurrentTeamId(teamId))
    getCompetitions(season);
      dispatch(getGamesSummary({teamId, season})).then((games) => {
        const selectedGames = games.payload.map(game => game.id);
        dispatch(updateSelectedGames(selectedGames));
        dispatch(getLatestGame(games.payload)).then(() => {
            dispatch(getTeamPlayers({teamId, season})).then((players) => {
              const selectedPlayers = players.payload.map(player => player.id);
              dispatch(updateselectedPlayers(selectedPlayers));
              dispatch(toggleLoading())
            });
            dispatch(getPlaylists());
        })
      });
    });
}