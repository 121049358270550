import styled from "styled-components";
import { GREY, LIGHT_GREY, RED_TO_GREEN_GRADIENT } from "../../../assets/colors";

const SingleProgressBar = ({ title1, percentage1 }) => {
  const getBarColor = (percentage: number) =>
    RED_TO_GREEN_GRADIENT[
      Math.min(
        Math.floor(percentage * (RED_TO_GREEN_GRADIENT.length - 1)),
        RED_TO_GREEN_GRADIENT.length - 1
      )
    ];

  return (
    <Wrapper>
      <Title>{title1}</Title>
      {percentage1 ? (
        <ProgressBarWrapper>
          <Percentage>{percentage1}%</Percentage>
          <div style={{ display: "flex" }}>
            <ProgressBarFilled
              style={{
                width: `calc(100% * ${percentage1 / 100})`,
                backgroundColor: getBarColor(percentage1 / 100),
              }}
            />
            <ProgressBarBackground
              style={{ width: `calc(100% * (1 - ${percentage1 / 100}))` }}
            />
          </div>
        </ProgressBarWrapper>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;
const Title = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;
const NoDataWrapper = styled.div`
  font-size: 1.2rem;
  color: ${GREY};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1rem;
`;
const Percentage = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
  margin-right: 10px;
`;
const ProgressBarWrapper = styled.div`
  width: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 1fr 15fr;
  color: ${GREY};
  align-items: center;
  margin: 0 2rem 2rem;
`;
const ProgressBarFilled = styled.div`
  height: 0.5rem;
  background-color: black;
  border-radius: 2px;
  animation: expand 1s ease;
`;
const ProgressBarBackground = styled.div`
  height: 0.5rem;
  background-color: ${LIGHT_GREY};
  border-radius: 2px;
  animation: expand 1s ease;
`;

export default SingleProgressBar;
