import styled from "styled-components";
import { Button, Grid } from "@material-ui/core"
import { DARK_BLUE, GREY, LIGHT_BLUE, LIGHT_GREY, LIGHT_GREY1, RED } from "../../../../assets/colors";
import { useSelector } from "react-redux";
import { selectEvents, selectPlaylists, selectSelectedSequences, updateSelectedSequence } from "../../../../slices/videoSlice";
import CheckIcon from '@mui/icons-material/Check';
import { black } from "material-ui/styles/colors";
import { sportsAiGlobalStyles } from "../../../../shared/globalStyle";
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalTopbar from "../../../../components/navigation/modalTopbar";
import { useAppDispatch } from "../../../../app/store";

export const SequenceConfirmationModal = ({playlist_id, next, cancel, closeModal}) => {
    const classes = sportsAiGlobalStyles();
    const dispath = useAppDispatch();
    const playlist = useSelector(selectPlaylists).find((playlist) => playlist.id === playlist_id);
    const selectedEvents = useSelector(selectSelectedSequences);
    const selectedSequences = useSelector(selectEvents).filter((event) => selectedEvents.includes(event.id)); 

    const deleteSequence = (sequence_id) => {
        dispath(updateSelectedSequence(sequence_id))
    }
    
    return (
        <Container>
        <Grid container spacing={0}>
            <Grid item xs={12}>
            <ModalTopbar 
                Action={'Add sequence to a playlist'}
                onClose={closeModal}
                showBar={true}
            />
            </Grid>
        </Grid>
            <Title>{playlist?.name}</Title>
            <Table>
            <TableHeader>
                <div style={{ textAlign: "left" }}></div>
                <div style={{ textAlign: "left" }}>Action </div>
                <div style={{ textAlign: "left" }}>Match </div>
                <div style={{ textAlign: "left" }}>Player</div>
                <div></div>
            </TableHeader>
        {
            playlist?.events.map((clip, i) => {
                return(
                    <PlaylistInfo
                    key={i}
                    style={
                        i % 2 === 0
                        ? { backgroundColor: "white" }
                        : { backgroundColor: LIGHT_GREY1 }
                    }
                    onClick={() => {}}
                    >
                    <div><MenuIcon/></div>
                    <div style={{ textAlign: "left", textTransform: "capitalize"}}>
                        <div style={{ display: "inline-block", textAlign: "left", textTransform: "capitalize", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {clip.event} 
                        </div>
                    </div>
                    <div style={{ textAlign: "left", textTransform: "capitalize" }}>{clip.match}</div>
                    <div style={{ textAlign: "left", textTransform: "capitalize" }}>{clip.player}</div>
                    </PlaylistInfo>
                )
            })
        }
        {
            selectedSequences.map((clip, i) => {
                return(
                    <PlaylistInfo
                    key={i}
                    style={
                        i % 2 === 0
                        ? { backgroundColor: "white" }
                        : { backgroundColor: LIGHT_GREY1 }
                    }
                    onClick={() => {}}
                    >
                    <div><MenuIcon/></div>
                    <div style={{ textAlign: "left", textTransform: "capitalize"}}>
                        <div style={{ display: "inline-block", textAlign: "left", textTransform: "capitalize", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {clip.event} 
                        </div>
                        <NewPlaylist style={{ display: "inline-block", verticalAlign: "top" }}> NEW </NewPlaylist>
                    </div>
                    <div style={{ textAlign: "left", textTransform: "capitalize" }}>{clip.match}</div>
                    <div style={{ textAlign: "left", textTransform: "capitalize" }}>{clip.player}</div>
                    <Button 
                        className={classes.transparantButton}
                        onClick={() => {deleteSequence(clip.id)}}
                        >
                        <DeleteIcon style={{color: RED}}/>
                    </Button>
                    </PlaylistInfo>
   
                )
            })
        }
        </Table>
        <Grid container spacing={0}>
        <Grid item xs={7}>
          </Grid>
          <Grid item xs={2}>
              <Button 
                  style={{border: "10px", borderColor: LIGHT_GREY, height: "70px"}}
                  className={classes.transparantButton}
                  onClick={cancel}
              >
                  Cancel
              </Button>
          </Grid>
          <Grid item xs={2}>
              <Button 
                  className={classes.fullGreenButton}
                  onClick={next}
              >
                  <CheckIcon/> Confirm
              </Button>
        </Grid>
      </Grid>
    </Container>
    )
  }  

const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0rem 1rem 2rem;
    text-align: left;
    text-transform: capitalize;
`;

const Container = styled.div`
  display: column;
  justify-content: center;
  align-items: center;
`;

const Table = styled.div`
  width: 600px;
  height: 500px;
  overflow-y: auto;
`;

const NewPlaylist = styled.div`
  background-color: ${LIGHT_BLUE};
  color: ${DARK_BLUE};
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  display: inline-block;
  text-transform: capitalize;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 10% 20% 30% 25% 15%;
  text-align: center;
  align-items: center;
  padding: 10px;
  margin: 0 2rem;
  color: ${GREY};
`;

const PlaylistInfo = styled.div`
  display: grid;
  grid-template-columns: 10% 20% 30% 25% 15%;
  text-align: center;
  align-items: center;
  color: ${black};
  padding: 10px;
  border-radius: 8px;
  margin: 0 2rem;
  > div {
    text-transform: capitalize;
  }
  &:hover {
    cursor: pointer;
  }
`;