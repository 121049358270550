import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import gamesSlice from "../slices/gamesSlice";
import userReducer from "../slices/userSlice";
import navigationReducer from "../slices/navigationSlice";
import playerSlice from "../slices/playerSlice";
import teamSlice from "../slices/teamSlice";
import VideoSlice from "../slices/videoSlice";
import sequencesSlice from "../slices/sequencesSlice";
import competitionSlice from "../slices/competitionSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    page: navigationReducer,
    gamesSlice: gamesSlice,
    playerSlice: playerSlice,
    teamSlice: teamSlice,
    videoSlice: VideoSlice,
    sequencesSelection: sequencesSlice,
    competitionSlice: competitionSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
