import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";
import { sendPageView } from "../services/gAnalytics";

export enum Page {
  Login = "/",
  Home = "/home",
  League = "/league",
  Games = "/games",
  GameStat = "/game-stats",
  LeagueStat = "/league-stats",
  Library = "/library",
  Players = "/players",
  PlayerProfile = "/player-profile",
  CompetitionPlayersStat= "/competition-players-stats",
  CompetitionClubStat="/competition-club-stats",
  Logout = "/logout",
  Profile = "/profile",
}

export enum SequencesType {
  None = "None",
  GoalsScored = "Goals Scored",
  GoalsConceded = "Goals Conceded",
  Shots = "Shots",
  PenaltyShots = "Penalty Shots",
}

interface NavigationState {
  page: Page;
  isLoading: boolean;
  showSequenceModal: boolean;
  statSequencesParams: {
    sequenceType: SequencesType;
    sequenceOptions: string[];
  };
}

export const initialState: NavigationState = {
  page: Page.Home,
  isLoading: false,
  showSequenceModal: false,
  statSequencesParams: {
    sequenceType: SequencesType.None,
    sequenceOptions: [],
  },
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<Page>) => {
      state.page = action.payload;
      sendPageView(state.page);
    },
    toggleLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    setShowSequenceModal: (state, action: PayloadAction<boolean>) => {
      state.showSequenceModal = action.payload;
    },
    statSequencesParams: (state, action: PayloadAction<{sequenceType: SequencesType,sequenceOptions: string[]}>) => {
      state.statSequencesParams = action.payload;
    }
  },
});

export const { changePage, toggleLoading, setShowSequenceModal, statSequencesParams } = navigationSlice.actions;
export const selectShowSequence = (state: RootState) => state.page.showSequenceModal;
export const selectSelequencesParams = (state: RootState) => state.page.statSequencesParams;
export const selectPage = (state: RootState) => state.page.page;
export const selecIsLoading = (state: RootState) => state.page.isLoading;

export default navigationSlice.reducer;
